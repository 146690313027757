<section>
    <div class="card-body">
        <h3 class="mb-0">
            <span class="text-danger">Missing Records</span>
            <small class="text-sm text-gray">
                <br />
                {{ checklist }} Checklist</small
            >
        </h3>

        <div class="icon-section mb-4">
            <div class="mt-4">
                <form>
                    <ng-container *ngFor="let item of checklistArr; let i = index; let last = last">
                        <div class="mb-3" *ngIf="!last; else lastitem">
                            <span>{{ i + 1 }}. </span>
                            <label class="" [for]="item.checklistID.name">
                                {{ item.checklistID.name | titlecase }}
                                <small class="text-danger ml-2"> <i>(Not Uploaded)</i></small>
                            </label>
                        </div>
                        <ng-template #lastitem>
                            <div class="mb-3">
                                <span>{{ i + 1 }}. </span>
                                <label class="" [for]="item.checklistID.name">
                                    {{ item.checklistID.name | titlecase }}
                                    <small class="text-danger ml-2"> <i>(Not Uploaded)</i></small>
                                </label>
                            </div>
                            <div class="mb-3" *ngFor="let item2 of otherChecks; let i2 = index">
                                <span>{{ i + (i2 + 2) }}. </span>
                                <label class="" [for]="">
                                    {{ item2 }}
                                    <small class="text-danger ml-2"> <i>(Missing)</i></small>
                                </label>
                            </div>
                        </ng-template>
                    </ng-container>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-2">
                <button class="btn mr-4 btn-default" (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</section>
