export const SVG_ICONS = [
    'home.svg',
    'attachement.svg',
    'award.svg',
    'success.svg',
    'delete.svg',
    'edit.svg',
    'checklist.svg',
    'carrot-down-black.svg',
    'carrot-right-red.svg',
    'carrot-left-gray.svg',
    'carrot-right-gray.svg',
    'add-circle.svg',
    'add-circle-default.svg',
    'search.svg',
    'calendar.svg',
    'cancel-circle.svg',
    'save-file.svg',
    'eye.svg',
    'error.svg',
    'more.svg',
    'cancel-circle-white.svg',
    'cancel-circle-red.svg',
    'carrot-right-white.svg',
    'carrot-left-white.svg',
    'chat-bubble.svg',
    'warning.svg',
    'target.svg',
    'pdf.svg',
    'xlsx.svg',
    'txt.svg',
];

export const PROVIDER_ICONS = [
    'amex.svg',
    'facebook.svg',
    'linkedin.svg',
    'mastercard.svg',
    'paypal-grey.svg',
    'paypal.svg',
    'paypal-logo.svg',
    'twitter.svg',
    'visa.svg',
    'western-union.svg',
];
