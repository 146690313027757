import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { QueryGenerator } from 'src/app/modules/recruitment/shared/helper';

@Injectable({
    providedIn: 'root',
})
export class SharedApiService {
    url: string = '';
    constructor(
        private http: HttpClient,
        @Inject('url') url: string
    ) {
        if (!url) {
            throw new Error('must specify url');
        }
        this.url = url;
    }

    async create(model: string, body: any) {
        return await this.http.post(`${this.url}/${model}/create`, body).toPromise();
    }
    async createMany(model: string, body: any) {
        return await this.http.post(`${this.url}/${model}/createMany`, body).toPromise();
    }
    async postCustom(model: string, path: string, body: any) {
        return await this.http.post(`${this.url}/${model}/${path}`, body).toPromise();
    }
    async edit(model: string, body: any, id: string) {
        return await this.http.patch(`${this.url}/${model}/update/${id}`, body).toPromise();
    }
    async delete(model: string, id: string) {
        return await this.http.delete(`${this.url}/${model}/delete/${id}`).toPromise();
    }
    async editCustom(model: string, path: string, body: any, id: string) {
        return await this.http.patch(`${this.url}/${model}/${path}/${id}`, body).toPromise();
    }
    async get(model: string, query: any) {
        let headers = {};
        if (query.nospinner) {
            headers = {
                nospinner: 'true',
            };
        }
        delete query.nospinner;
        return await this.http
            .get(`${this.url}/${model}/list${QueryGenerator(query)}`, {
                headers,
            })
            .toPromise();
    }
    async getOne(model: string, id: any) {
        return await this.http.get(`${this.url}/${model}/list/${id}`).toPromise();
    }
    async metrics(model: string, view: string, query: any) {
        let headers = {};
        if (query.nospinner) {
            headers = {
                nospinner: true,
            };
        }
        delete query.nospinner;
        return await this.http
            .get(`${this.url}/${model}/metrics/${view}${QueryGenerator(query)}`, {
                headers,
            })
            .toPromise();
    }
    async custom(model: string, view: string, query: any) {
        let headers = {};
        if (query.nospinner) {
            headers = {
                nospinner: 'true',
            };
        }
        delete query.nospinner;
        return await this.http
            .get(`${this.url}/${model}/${view}${QueryGenerator(query)}`, {
                headers,
            })
            .toPromise();
    }
    async customOne(model: string, view: string, id: any, useSpinner = true) {
        let headers: any = {};
        if (!useSpinner) {
            headers['nospinner'] = 'true';
        }
        return await this.http
            .get(`${this.url}/${model}/${view}/${id}`, {
                headers: {
                    ...headers,
                },
            })
            .toPromise();
    }

    async customPost(model: string, view: string, data: any, useSpinner = true) {
        let headers: any = {};
        if (!useSpinner) {
            headers['nospinner'] = 'true';
        }
        return await this.http
            .post(`${this.url}/${model}/${view}`, data, {
                headers: {
                    ...headers,
                },
            })
            .toPromise();
    }

    async direct({ url, query }: { url: string; query?: any }) {
        let headers: any = {};
        if (query?.nospinner) {
            headers = {
                nospinner: 'true',
            };
        }
        delete query.nospinner;
        return await this.http
            .get(`${url}${QueryGenerator(query)}`, {
                headers: {
                    ...headers,
                },
            })
            .toPromise();
    }
}
