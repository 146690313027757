import { Router } from '@angular/router';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { WorkflowStatus, WorkflowTypeEnum } from '../util';
import { DataTableConfig, filterValue } from 'src/app/interfaces/general';
import { environment } from 'src/environments/environment';
import { PermissionService } from 'src/app/services/permission.service';
import { APPLICATION_PAGE_VIEW_PERMISSIONS, MODULES } from 'src/app/utils/modulesAndPermissions';

@Component({
    selector: 'approval-workflow-dashboard',
    templateUrl: './approval-workflow-dashboard.component.html',
    styleUrls: ['./approval-workflow-dashboard.component.scss'],
})
export class ApprovalWorkflowDashboardComponent implements OnInit, AfterViewInit {
    @ViewChild('viewRequestTmpl') viewRequestTmpl!: TemplateRef<any>;
    @ViewChild('viewRequestType') viewRequestType!: TemplateRef<any>;
    @ViewChild('viewRequestHistoryTmpl') viewRequestHistoryTmpl!: TemplateRef<any>;
    @ViewChild('viewRequestHistoryPendingTmpl') viewRequestHistoryPendingTmpl!: TemplateRef<any>;
    @ViewChild('viewRequestHistoryApprovedTmpl') viewRequestHistoryApprovedTmpl!: TemplateRef<any>;
    @ViewChild('viewRequestHistoryDeclinedTmpl') viewRequestHistoryDeclinedTmpl!: TemplateRef<any>;
    paginatorSetup: any;
    approvalList: any;
    workType: any = WorkflowTypeEnum;
    contentHeader = {
        headerTitle: 'Approval Workflow',
        actionButton: true,
        breadcrumb: {
            links: [
                {
                    name: 'Pending Approvals',
                    isLink: false,
                    link: '/pending-approvals',
                },
            ],
        },
    };
    tableConfig!: DataTableConfig;
    requestConfig!: DataTableConfig;
    approvedConfig!: DataTableConfig;
    declinedConfig!: DataTableConfig;
    url: string = `${environment.myXalaryEndpoint}/v1/approval-workflow`;
    workflowStat = WorkflowStatus;
    requestStatusType = [
        {
            label: 'Assigned Pending Request(s)',
            value: WorkflowStatus.Own,
        },
        {
            label: 'All Pending Request(s)',
            value: WorkflowStatus.Pending,
        },
        {
            label: 'All Approved Request(s)',
            value: WorkflowStatus.Approved,
        },
        {
            label: 'All Declined Request(s)',
            value: WorkflowStatus.Rejected,
        },
    ];
    requestStatus: string = WorkflowStatus.Own;

    constructor(private router: Router) {}

    ngOnInit(): void {}

    async ngAfterViewInit(): Promise<void> {
        this.tableConfig = {
            url: `${this.url}/my/pending`,

            columns: [
                {
                    label: 'Review Type',
                    key: 'type',
                    template: this.viewRequestType,
                },
                {
                    label: 'Action',
                    key: 'type',
                    template: this.viewRequestTmpl,
                },
            ],
            limitDefault: 10,
            limitKey: 'limit',
            pageKey: 'page',
        };

        this.requestConfig = {
            columns: [
                {
                    label: 'Workflow Type',
                    key: 'workflowType',
                    template: this.viewRequestType,
                },
                {
                    label: 'Action',
                    key: 'type',
                    template: this.viewRequestHistoryPendingTmpl,
                },
            ],
            url: `${this.url}/approvalTypes`,
            noSort: false,
        };

        this.approvedConfig = {
            columns: [
                {
                    label: 'Workflow Type',
                    key: 'workflowType',
                    template: this.viewRequestType,
                },
                {
                    label: 'Action',
                    key: 'type',
                    template: this.viewRequestHistoryApprovedTmpl,
                },
            ],
            url: `${this.url}/approvalTypes`,
            noSort: false,
        };

        this.declinedConfig = {
            columns: [
                {
                    label: 'Workflow Type',
                    key: 'workflowType',
                    template: this.viewRequestType,
                },
                {
                    label: 'Action',
                    key: 'type',
                    template: this.viewRequestHistoryDeclinedTmpl,
                },
            ],
            url: `${this.url}/approvalTypes`,
            noSort: false,
        };
    }

    retrieveWorkflowTitle(type: any) {
        return this.workType[type] || type;
    }

    reviewWorkflow(workflow: any, status: WorkflowStatus) {
        const link = this.workType[workflow.type]
            ? `/app-settings/approval-workflow/view/${workflow._id}/${status}`
            : `/app-settings/approval-workflow/${workflow.link}`;
        this.router.navigate([link]);
    }

    requestStatusChange(event: any) {
        this.requestStatus = event;
    }
}
