import { KnobModule } from 'primeng/knob';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { NgModule } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { StepsModule } from 'primeng/steps';
import { DragDropModule } from 'primeng/dragdrop';
import { BlockUIModule } from 'primeng/blockui';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChipModule } from 'primeng/chip';
import { CalendarModule } from 'primeng/calendar';
import { RatingModule } from 'primeng/rating';
import { PasswordModule } from 'primeng/password';
import { InputMaskModule } from 'primeng/inputmask';
import { ChipsModule } from 'primeng/chips';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { StepperModule } from 'primeng/stepper';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { ProgressBarModule } from 'primeng/progressbar';

const PrimeImports = [
    KnobModule,
    AccordionModule,
    BadgeModule,
    DividerModule,
    TooltipModule,
    StepsModule,
    DragDropModule,
    BlockUIModule,
    PanelModule,
    FileUploadModule,
    InputNumberModule,
    ChipModule,
    CalendarModule,
    RatingModule,
    PasswordModule,
    InputMaskModule,
    ChipsModule,
    MultiSelectModule,
    SliderModule,
    AutoCompleteModule,
    MessagesModule,
    DropdownModule,
    OrganizationChartModule,
    StepperModule,
    CheckboxModule,
    FieldsetModule,
    RadioButtonModule,
    TableModule,
    CheckboxModule,
    TimelineModule,
    ProgressBarModule,
];

@NgModule({
    declarations: [],
    imports: PrimeImports,
    exports: PrimeImports,
    providers: [],
})
export class PrimeStyleModule {}
