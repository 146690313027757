import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import endpoints from 'src/app/utils/endpoints';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    private flags: Map<string, boolean> = new Map();

    constructor(private http: HttpClient) { }

    loadFeatureFlags(): Observable<void> {
        return this.http.get<any[]>(endpoints.MX_BE.V1.flags).pipe(
            map((flags) => {
                console.log(flags)
                flags.forEach(flag => this.flags.set(flag.name, flag.enabled));
            }),
            catchError(error => {
                console.error('Failed to load feature flags', error);
                return of(); // Handle error gracefully
            })
        );
    }

    isFeatureEnabled(name: string): boolean {
        return this.flags.get(name) ?? false;
    }
}