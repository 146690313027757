<div class="card p-4" [ngClass]="background">
    <div class="row align-items-center">
        <div class="col-sm-3">
            <img [src]="imageUrl" alt="" />
        </div>
        <div class="col-sm-9">
            <ng-content></ng-content>
        </div>
    </div>
</div>
