import { Router } from '@angular/router';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { ApprovalWorkflowService } from '../approval-workflow.service';
import { GeneralService } from 'src/app/shared/services/general.service';
import { WorkflowTypeEnum } from '../util';
import { DataTableConfig, filterValue } from 'src/app/interfaces/general';
import endpoints from 'src/app/utils/endpoints';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'approval-workflow-dashboard',
    templateUrl: './approval-workflow-dashboard.component.html',
    styleUrls: ['./approval-workflow-dashboard.component.scss'],
})
export class ApprovalWorkflowDashboardComponent implements OnInit, AfterViewInit {
    @ViewChild('viewRequestTmpl') viewRequestTmpl!: TemplateRef<any>;
    @ViewChild('viewRequestType') viewRequestType!: TemplateRef<any>;
    paginatorSetup: any;
    approvalList: any;
    workType: any = WorkflowTypeEnum;
    contentHeader = {
        headerTitle: 'Approval Workflow',
        actionButton: true,
        breadcrumb: {
            links: [
                {
                    name: 'Categories',
                    isLink: false,
                },
            ],
        },
    };
    tableConfig!: DataTableConfig;
    url: string = `${environment.myXalaryEndpoint}/v1/approval-workflow`;
    constructor(
        private router: Router,
        private genSrv: GeneralService,
        private approvalWorkflowService: ApprovalWorkflowService
    ) {}

    ngOnInit(): void {}

    async ngAfterViewInit(): Promise<void> {
        this.tableConfig = {
            url: `${this.url}/my/pending`,

            columns: [
                {
                    label: 'Review Type',
                    key: 'type',
                    template: this.viewRequestType,
                },
                {
                    label: 'Action',
                    key: 'type',
                    template: this.viewRequestTmpl,
                },
            ],
            limitDefault: 10,
            limitKey: 'limit',
            pageKey: 'page',
        };
    }

    retrieveWorkflowTitle(type: any) {
        return this.workType[type] || type;
    }

    reviewWorkflow(workflow: any) {
        const link = this.workType[workflow.type]
            ? `/app-settings/approval-workflow/view/${workflow._id}`
            : `/app-settings/approval-workflow/${workflow.link}`;
        this.router.navigate([link]);
    }
}
