import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HIRINGONBOARDAPPLICANTCHECKLIST } from 'src/app/interfaces/general';

@Component({
    selector: 'app-missing-record',
    templateUrl: './missing-record.component.html',
    styleUrls: ['./missing-record.component.scss'],
})
export class MissingRecordComponent implements OnInit {
    @Input() checklist!: string;
    @Input() checklistArr!: HIRINGONBOARDAPPLICANTCHECKLIST[];
    @Input() otherChecks!: string[];
    //   @Output() buttonOutput = new EventEmitter();

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit(): void {}

    closeModal(): void {
        this.activeModal.dismiss();
    }
}
