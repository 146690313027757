import { Inject, Injectable } from '@angular/core';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { Observable, of, Subscriber } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import {
    BLOB_STORAGE_TOKEN,
    IBlobService,
    IBlobStorage,
    ISasToken,
    ISpeedSummary,
} from 'src/app/interfaces/general';
import { environment } from 'src/environments/environment';

interface AzureCredentials {
    [containers: string]: {
        [recuritementImg: string]: string;
    };
}
@Injectable()
export class AzureBlobStorageService {
    accountName = 'atbstore';
    azureCredentials: AzureCredentials = {
        containers: {
            recuritementImg: 'recruitment-images',
            recuritementDoc: 'recruitment-docs',
        },
        sas: {
            recuritementImg: environment.azureRecuritmentImgSAS,
            recuritementDoc: environment.azureRecuritmentDocSAS,
        },
    };

    constructor(@Inject(BLOB_STORAGE_TOKEN) private blobStorage: IBlobStorage) {}

    // +FILES
    public uploadFileNoProgessIndication(
        containerType: string,
        content: Blob,
        name: string,
        handler: () => void
    ) {
        this.uploadBlob(content, name, this.containerClient(containerType), handler);
    }

    public listFiles(containerType: string): Promise<string[]> {
        return this.listBlobs(this.containerClient(containerType));
    }

    public downloadFiles(containerType: string, name: string, handler: (blob: Blob) => void) {
        this.downloadBlob(name, this.containerClient(containerType), handler);
    }

    public deleteFiles(containerType: string, name: string, handler: () => void) {
        this.deleteBlob(name, this.containerClient(containerType), handler);
    }

    private uploadBlob(content: Blob, name: string, client: ContainerClient, handler: () => void) {
        let blockBlobClient = client.getBlockBlobClient(name);
        blockBlobClient
            .uploadData(content, { blobHTTPHeaders: { blobContentType: content.type } })
            .then(() => handler());
    }
    // -FILES

    private async listBlobs(client: ContainerClient): Promise<string[]> {
        let result: string[] = [];

        let blobs = client.listBlobsFlat();
        for await (const blob of blobs) {
            result.push(blob.name);
        }

        return result;
    }

    private downloadBlob(name: string, client: ContainerClient, handler: (blob: Blob) => void) {
        const blobClient = client.getBlobClient(name);
        blobClient.download().then((resp: any) => {
            resp.blobBody.then((blob: any) => {
                handler(blob);
            });
        });
    }

    private deleteBlob(name: string, client: ContainerClient, handler: () => void) {
        client.deleteBlob(name).then(() => {
            handler();
        });
    }

    private containerClient(containerType: string) {
        return new BlobServiceClient(
            `https://${this.accountName}.blob.core.windows.net?${this.azureCredentials.sas[containerType]}`
        ).getContainerClient(this.azureCredentials.containers[containerType]);
    }

    // Updates
    uploadFileProgressIndicator(
        containerType: string,
        file: File,
        fileName: string
    ): Observable<number> {
        const sasToken: ISasToken = {
            container: this.azureCredentials.containers[containerType],
            filename: fileName,
            storageAccessToken: this.azureCredentials.sas[containerType],
            storageUri: `https://${this.accountName}.blob.core.windows.net?`,
        };

        const customBlockSize = this.getBlockSize(file);
        const options = { blockSize: customBlockSize };
        const blobService = this.createBlobService(
            sasToken.storageAccessToken,
            sasToken.storageUri
        );

        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        return this.uploadFile(blobService, sasToken, file, options);
    }

    private createBlobService(accessToken: string, blobUri: string): IBlobService {
        return this.blobStorage
            .createBlobServiceWithSas(blobUri, accessToken)
            .withFilter(new this.blobStorage.ExponentialRetryPolicyFilter());
    }

    private uploadFile(
        blobService: IBlobService,
        accessToken: ISasToken,
        file: File,
        options: { blockSize: number }
    ): Observable<number> {
        return new Observable<number>((observer) => {
            const speedSummary = blobService.createBlockBlobFromBrowserFile(
                accessToken.container,
                accessToken.filename,
                file,
                options,
                (error) => this.callback(error, observer)
            );
            speedSummary.on('progress', () => this.getProgress(speedSummary, observer));
        }).pipe(startWith(0), distinctUntilChanged());
    }

    private getProgress(speedSummary: ISpeedSummary, observer: Subscriber<number>): void {
        const progress = parseInt(speedSummary.getCompletePercent(2), 10);
        observer.next(progress === 100 ? 99 : progress);
    }

    private callback(error: any, observer: Subscriber<number>): void {
        if (error) {
            observer.error(error);
        } else {
            observer.next(100);
            observer.complete();
        }
    }

    private getBlockSize(file: File): number {
        const size32Mb = 1024 * 1024 * 32;
        const size4Mb = 1024 * 1024 * 4;
        const size512Kb = 1024 * 512;

        return file.size > size32Mb ? size4Mb : size512Kb;
    }
}
