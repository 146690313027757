<div class="main-container">
    <div class="logo">
        <div class="left">
            <img class="custom-logo-header" src="/assets/images/notch-hr-edt.png" />
        </div>

        <div class="right">
            <p>
                <a class="btn btn-custom-bg btn-small" [routerLink]="['/login']">Login</a>
            </p>

            <img src="/assets/images/arrowforward.svg" />
        </div>
    </div>
    <div class="center-logo">
        <img class="custom-logo" src="/assets/images/notch-hr-fav.png" />
    </div>

    <div class="head">
        <h4>Sign Up</h4>
    </div>

    <div *ngIf="signUpSuccessful" class="success">
        <div class="welcome">
            <p>Welcome to NotchHR</p>
        </div>
        <div class="center-logo">
            <img src="/assets/images/success.svg" />
        </div>

        <div class="success-note">
            <p>
                To be sure {{ model.userEmail }} is correct, We have sent a link to it. Please go to
                your email and follow the instructions.. <br />
                Check your Spam folder if you didn’t see it in the inbox.
            </p>
        </div>

        <div *ngIf="signUpSuccessful" class="success-btn">
            <button (click)="login()" class="btn btn-primary">
                Login
                <i class="fa fa-arrow-right mr-1"></i>
            </button>

            <a (click)="resend()">Resend Confirmation Email</a>
        </div>
    </div>

    <form
        #SignupForm="ngForm"
        (ngSubmit)="onSubmit(SignupForm)"
        class="d-flex gap-10 h-100 flex-column"
    >
        <div>
            <div [ngStyle]="{ display: next ? 'flex' : 'none' }" class="input-content wrap">
                <div class="note">
                    <p>Sign Up in two Simple Steps to Start Managing your Employees</p>
                </div>

                <div class="company">
                    <img src="/assets/images/timeline.svg" />
                </div>

                <div class="form-group form-style w-50">
                    <label class="label" for="companyName">What is the name of your company?</label>
                    <input
                        #companyName="ngModel"
                        [(ngModel)]="model.companyName"
                        name="companyName"
                        type="text"
                        required
                        pattern="^[a-zA-Z0-9-\,._' ]*$"
                        autocomplete="off"
                        id="companyName"
                        placeholder="Enter Company Name"
                        minlength="3"
                        maxlength="255"
                        class="form-control"
                    />

                    <div
                        class="mt-2 text-small text-danger"
                        *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched)"
                    >
                        <div *ngIf="companyName?.errors?.required">Company Name is required</div>
                        <div *ngIf="companyName?.errors?.minlength">
                            Company Name must be at least 3 characters long
                        </div>
                        <div *ngIf="companyName?.errors?.pattern">Please Enter A Valid Name</div>
                    </div>
                </div>

                <div class="form-group form-style w-50">
                    <label for="userEmail">What is your email address?</label>
                    <input
                        #userEmail="ngModel"
                        [(ngModel)]="model.userEmail"
                        name="userEmail"
                        type="email"
                        required
                        pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}$"
                        autocomplete="off"
                        id="userEmail"
                        placeholder="Enter Company Email Address"
                        minlength="5"
                        maxlength="255"
                        class="form-control"
                    />

                    <div
                        class="mt-2 text-small text-danger"
                        *ngIf="userEmail?.invalid && (userEmail?.dirty || userEmail?.touched)"
                    >
                        <div *ngIf="userEmail?.errors?.required">Email is required</div>
                        <div *ngIf="userEmail?.errors?.minlength">
                            Email must be at least 5 characters long
                        </div>
                        <div *ngIf="userEmail?.errors?.pattern">
                            Please provide a valid email address
                        </div>
                    </div>
                </div>

                <div class="form-group form-style w-50">
                    <div class="mb-4 w-100">
                        <label for="title">Phone Number</label>
                        <div style="padding: 0.1rem 0.1rem !important" class="phone">
                            <div class="">
                                <select
                                    style="
                                        padding: 0.875rem 0.25rem !important;
                                        border-top: 0px !important;
                                        border-right: 0.1px solid #ccc !important;
                                        border-bottom: 0px !important;
                                        border-left: 0px !important;
                                    "
                                >
                                    <option
                                        style="font-size: 16px"
                                        *ngFor="let country of countries"
                                        [ngValue]="country"
                                    >
                                        {{ country.code }} ({{ country.dial_code }})
                                    </option>
                                </select>
                            </div>
                            <input
                                pattern="[0-9]{10,11}"
                                type="text"
                                #phone="ngModel"
                                maxlength="11"
                                [(ngModel)]="model.phone"
                                name="phone"
                                placeholder="Phone Number"
                            />
                        </div>
                    </div>

                    <!-- <label for="phone">Phone Number</label> -->
                    <!-- <div class="d-flex flex-row">
              <select style="padding: 0.875rem .25rem !important" name="phone" [(ngModel)]="selectedCountry">
                <option style="font-size: 10px;" *ngFor="let country of countries" [ngValue]="country">{{ country.code }} ({{ country.dial_code }})</option>
              </select>
              <input type="text" #phone="ngModel" [(ngModel)]="model.phone" name="phone" class="form-control">
            </div> -->
                    <div
                        class="mt-2 text-small text-danger"
                        *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                    >
                        Please check Phone number digit
                    </div>
                </div>

                <div class="sla">
                    <input
                        #sla="ngModel"
                        [(ngModel)]="model.sla"
                        required
                        type="checkbox"
                        name="sla"
                        class="sla-input"
                    />
                    <label for="sla" class="sla-text ml-2" style="text-decoration: underline;">
                        <a href="https://notchhr.io/terms-of-service" target="_BLANK"
                            >
                        <b>Tick to read and accept the
                        Privacy Policy
                    </b>
                        </a>
                    </label>
                </div>

                <div class="next">
                    <button
                        type="button"
                        [disabled]="
                            !companyName.valid || !userEmail.valid || !phone.valid || !sla.valid
                        "
                        (click)="nextPage()"
                        class="btn btn-primary"
                    >
                        Next
                        <i class="fa fa-arrow-right mr-1"></i>
                    </button>
                </div>
            </div>

            <div
                [ngStyle]="{ display: back ? 'flex' : 'none' }"
                class="input-content wrap input-content2"
            >
                <div class="note">
                    <p>Sign Up in two Simple Steps to Start Managing your Employees</p>
                </div>

                <div class="company">
                    <img src="/assets/images/timeline2.svg" />
                </div>

                <div class="form-group form-style w-50">
                    <label for="firstName">What is your First Name?</label>
                    <input
                        #firstName="ngModel"
                        [(ngModel)]="model.firstName"
                        name="firstName"
                        type="text"
                        required
                        autocomplete="off"
                        id="firstName"
                        placeholder="Enter First Name"
                        minlength="3"
                        maxlength="50"
                        class="form-control"
                    />

                    <div
                        class="mt-2 text-small text-danger"
                        *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                    >
                        <div *ngIf="firstName.errors?.required">First Name is required</div>
                        <div *ngIf="firstName.errors?.minlength">
                            First Name must be at least 3 characters long
                        </div>
                    </div>
                </div>

                <div class="form-group form-style w-50">
                    <label for="lastName">What is your Last Name?</label>
                    <input
                        #lastName="ngModel"
                        [(ngModel)]="model.lastName"
                        name="lastName"
                        type="text"
                        required
                        autocomplete="off"
                        id="lastName"
                        placeholder="Enter Last Name"
                        minlength="3"
                        maxlength="50"
                        class="form-control"
                    />

                    <div
                        class="mt-2 text-small text-danger"
                        *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                    >
                        <div *ngIf="lastName.errors?.required">Last Name is required</div>
                        <div *ngIf="lastName.errors?.minlength">
                            Last Name must be at least 3 characters long
                        </div>
                    </div>
                </div>

                <div class="form-group form-style w-50">
                    <label for="contactSource">How did you discover NotchHR?</label>
                    <select
                        #contactSource="ngModel"
                        name="contactSource"
                        class="form-control"
                        [(ngModel)]="model.contactSource"
                        required
                        id="contactSource"
                    >
                        <option value="null" selected default hidden>Select Source</option>
                        <option value="Sales Representative">Our Sales Representative</option>
                        <option value="Google" selected="true">
                            Search Engine (Google, Yahoo, Bing, etc..)
                        </option>
                        <option value="Online Advert">Online Advert</option>
                        <option value="Glosmartbiz">Glosmartbiz</option>
                        <option value="Blog or Publication">Blog or Publication</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Event">Event</option>
                        <option value="Billboard">Billboard</option>
                        <option value="Referral (From a friend or family)">
                            Referral (From a friend or family)
                        </option>
                        <option value="Email">Email</option>
                        <option value="Radio">Radio</option>
                        <option value="TV">TV</option>
                        <option value="Magazine/Publication">Magazine/Publication</option>
                    </select>

                    <div
                        class="mt-2 text-small text-danger"
                        *ngIf="
                            contactSource.invalid && (contactSource.dirty || contactSource.touched)
                        "
                    >
                        <div *ngIf="contactSource.errors?.required">Contact Source is required</div>
                    </div>
                </div>
                <div class="form-group form-style w-50">
                    <label class="font-weight-bold" for="title"
                        >Password <span class="text-danger">*</span></label
                    >
                    <p-password
                        #password="ngModel"
                        [(ngModel)]="model.password"
                        name="password"
                        [toggleMask]="true"
                        inputStyleClass="form-control "
                    ></p-password>
                </div>
                <div
                    class="mt-2 text-small text-danger"
                    *ngIf="password.invalid && (password.dirty || password.touched)"
                >
                    <div *ngIf="password.errors?.required">Password is required</div>
                    <div *ngIf="password.errors?.minlength">
                        Password must be at least 8 characters long
                    </div>
                </div>

                <div class="next2">
                    <button type="button" (click)="backPage()" class="btn btn-primary">
                        <i class="fa fa-arrow-left mr-1"></i>
                        Back
                    </button>
                    <button
                        [disabled]="!SignupForm.valid && SignupForm.touched"
                        class="btn btn-primary btn2"
                    >
                        Sign Up
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
