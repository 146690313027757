import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class SpinnerService implements OnDestroy {
    public count = 0;
    private spinnerSource = new BehaviorSubject<string>('');
    private totalRequests = new BehaviorSubject<number>(0);
    get spinnerObserver$(): Observable<string> {
        return this.spinnerSource.asObservable();
    }
    constructor() {
        this.count = 0;
        this.totalRequests.subscribe((count) => {
            if (count === 1) {
                this.spinnerSource.next('start');
            } else if (count === 0) {
                this.resetSpinner();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.totalRequests) {
            this.totalRequests.unsubscribe();
        }
    }

    public timeOnSpinner(): void {
        setTimeout(() => this.requestStarted(), 1000);
    }
    public requestStarted(url?: any): void {
        this.count++;
        this.totalRequests.next(this.count);
        // console.log('COUNT INCREASE! Count: ', this.count, 'Total requests: ', this.totalRequests);
    }
    public requestEnded(url?: any): void {
        this.count--;
        this.totalRequests.next(this.count);
        // console.log('COUNT DECREASE! Count: ', this.count, 'Total requests: ', this.totalRequests);
    }
    public resetSpinner(): void {
        this.count = 0;
        this.spinnerSource.next('stop');
        // console.log('COUNT RESET! Count: ', this.count, 'Total requests: ', this.totalRequests);
    }
}
