import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/shared/services/general.service';
import { UtilsService } from '../../dashboard/services/utils.service';
import { ApiService } from '../../dashboard/services/api.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', '../../../../formStyle.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: any;
    loginError: string = '';
    fieldTextType: boolean;

    registerUrl: string;
    forgotPasswordUrl: string;

    constructor(
        private route: Router,
        private genSrv: GeneralService,
        private authService: AuthService,
        private dsUtils: UtilsService,
        private dashApi: ApiService
    ) {
        this.fieldTextType = false;
        this.registerUrl = `${environment.myXalaryV1}/signup`;
        this.forgotPasswordUrl = `${environment.myXalaryV1}/forgot-password`;
    }

    ngOnInit(): void {
        this.loginForm = new UntypedFormGroup({
            email: new UntypedFormControl('', [
                Validators.required,
                Validators.required,
                Validators.required,
                Validators.email,
            ]),
            password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
            rememberMe: new UntypedFormControl(false, [Validators.required]),
        });

        if (!localStorage.getItem('loginPageReload')) {
            localStorage.setItem('loginPageReload', 'no reload');
            location.reload();
        } else {
            localStorage.removeItem('loginPageReload');
        }
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    handleLogin() {
        if (this.loginForm.invalid) {
            return;
        }

        this.loginError = '';

        const { email, password } = this.loginForm.value;
        this.authService.login({ userEmail: email, password }).subscribe(async (res) => {
            if (res.token) {
                this.genSrv.setAppToken(res.token);
                localStorage.setItem('companyID', res.user.companyID);
                this.dsUtils.showNeedHelpModal.next(true);
                const news = await this.dashApi.getFlashNews().toPromise();
                if (news?.length) {
                    this.dsUtils.showFlashNews.next(news);
                }
                const urlParams = new URLSearchParams(window.location.search);
                const redirect = urlParams.get('redirectTo');
                if (redirect) {
                    this.route.navigateByUrl(redirect);
                } else {
                    this.route.navigate(['']);
                }
            }
        });
    }

    resend() {
        if (
            !this.loginForm.get('email').touched ||
            this.loginForm.value.email == '' ||
            this.loginForm.get('email').errors
        ) {
            this.genSrv.alertInfo.next({
                timer: 5000,
                btnClass: 'alert-danger',
                btnIcon: 'error',
                text: 'Invalid email address',
            });
            return;
        }

        this.authService.resendEmail({ userEmail: this.loginForm.value.email }).subscribe((res) => {
            this.genSrv.alertInfo.next({
                text: res.message,
                btnClass: 'alert-success',
                btnIcon: 'done',
                timer: 5000,
            });
            return;
        });
    }
}
