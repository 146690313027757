import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';
import { FeatureFlagComponent } from '../components/feature-flag/feature-flag.component';


@Directive({
    selector: '[appFeatureFlag]'
})
export class FeatureFlagDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private featureFlagService: FeatureFlagService,

    ) { }

    @Input() set appFeatureFlag(props: { flagName: string, showFallBack?: boolean }) {
        if (this.featureFlagService.isFeatureEnabled(props.flagName)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            if (props.showFallBack) {
                this.viewContainer.createComponent(FeatureFlagComponent)
            } else {
                this.viewContainer.clear();
            }
        }
    }
}