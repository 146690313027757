import { APPLICATION_PAGE_VIEW_PERMISSIONS, MODULES } from 'src/app/utils/modulesAndPermissions';
const LICENSE_MODULE = MODULES.License;
const LICENSE_PERMISSIONS = APPLICATION_PAGE_VIEW_PERMISSIONS.License;

export const VIEW_LICENSE = {
    module: LICENSE_MODULE,
    permissions: [LICENSE_PERMISSIONS?.VIEW_LICENSE],
};

export const VIEW_MANAGE_LICENSE = {
    module: LICENSE_MODULE,
    permissions: [LICENSE_PERMISSIONS?.VIEW_MANAGE_LICENSE],
};

export const VIEW_BUY_LICENSE = {
    module: LICENSE_MODULE,
    permissions: [LICENSE_PERMISSIONS?.VIEW_BUY_LICENSE],
};

export const canManageLicense = (permissions: any) => {
    return permissions['ASSIGN_AND_REMOVE_LICENSE'];
};
export const canBuyLicense = (permissions: any) => {
    return permissions['BUY_LICENSE'];
};

export type LicenseCurrency = {
    USD: number;
    NGN: number;
};

export interface LicensePrice {
    annually: LicenseCurrency;
    monthly: LicenseCurrency;
}

export interface CompanyLicense {
    _id: string;
    licensePackageID: LicensePlan;
    createdAt: Date;
    updatedAt: Date;
    currentExpiry: Date;
    name: string;
    payments: [];
    price: {
        unit: number;
        currency: string;
    };
    modules: License['modules'];
    employees: {
        _id: string;
        name: string;
        assignedAt: Date;
    }[];
    pastEmployees: {
        _id: string;
        name: string;
        createdOn: Date;
    }[];
    usedUnits: number;
    availableUnits: number;
    autoRenew: boolean;
    isRenewed: boolean;
    renewalFrequency: string;
}

export interface LicensePlan {
    _id: string;
    createdOn: Date;
    name: string;
    price: LicensePrice;
    employees: {
        minimum?: number;
        maximum?: number;
    };
    users: {
        minimum: Number;
        maximum: Number;
    };
    modules: any;
    isDefault: boolean;
    isAddon: boolean;
    isActive: boolean;
    isDeleted: boolean;
    freeze: boolean;
}

export interface License {
    id: string;
    _id: string;
    name: string;
    price: LicensePrice;
    isAddon: boolean;
    modules: {
        Attendance: boolean;
        Payroll: boolean;
        Events: boolean;
        Leave: boolean;
        Employee: boolean;
        MultiTenancy: boolean;
        Meetings: boolean;
        ChatAndAnnouncement: boolean;
        GrievanceAndDisciplinary: boolean;
        Recruitment: boolean;
        Confirmation: boolean;
        Requisition: boolean;
        ExitManagement: boolean;
        PromotionManagement: boolean;
        ApprovalWorkflow: boolean;
        ELearning: boolean;
        LearningAndDevelopment: boolean;
        TalentManagement: boolean;
        KPIManagement: boolean;
        PerformanceManagement: boolean;
        DMS: boolean;
        ESB: boolean;
        SafetyIncidentReport: boolean;
        EmployeeNetPromoterScore: boolean;
    };
    employees: {
        minimum?: number;
        maximum?: number;
    };
    freeze: boolean;
}

export type LicenseModuleTypes = keyof License['modules'];

interface CartLicense {
    name: string;
    licenseId?: string;
    currentUnits?: number;
    currentExpiry?: Date;
    count: number;
    finratusId: string;
    price: LicenseCurrency;
    employees: License['employees'];
    renewalFrequency: keyof LicensePrice;
    freeze?: boolean;
}

export type CartLicenses = CartLicense[];

const LicenseLocalStorageKey = 'MyXalary-Licenses-Cart';

export function getCartLicenses() {
    const licensesInCartString = localStorage.getItem(LicenseLocalStorageKey);

    if (!licensesInCartString || !Array.isArray(JSON.parse(licensesInCartString))) {
        return { licenses: null, valid: false };
    }

    return { licenses: JSON.parse(licensesInCartString), valid: true };
}

export function setCartLicenses(licenses: CartLicenses) {
    localStorage.setItem(LicenseLocalStorageKey, JSON.stringify(licenses));
}

export const LicenseModuleNameMap = {
    Attendance: 'Attendance',
    Payroll: 'Payroll',
    Events: 'Events',
    Leave: 'Leave',
    Employee: 'Employee',
    MultiTenancy: 'Multi Tenancy',

    Meetings: 'Meetings',
    ChatAndAnnouncement: 'Chat And Announcement',
    GrievanceAndDisciplinary: 'Grievance And Disciplinary',
    Recruitment: 'Recruitment',
    Confirmation: 'Confirmation',
    Requisition: 'Requisition',
    ExitManagement: 'Exit Management',
    PromotionManagement: 'Promotion Management',
    ApprovalWorkflow: 'Approval Workflow',

    ELearning: 'E-Learning',
    LearningAndDevelopment: 'Learning And Development',
    TalentManagement: 'Talent Management',
    KPIManagement: 'KPI Management',
    PerformanceManagement: 'Performance Management',

    DMS: 'DMS',
    ESB: 'ESB',
    SafetyIncidentReport: 'Safety And Incident Report',
    EmployeeNetPromoterScore: 'Employee Net Promoter Score',
};

export function checkNairaCurrency() {
    if (Intl.DateTimeFormat().resolvedOptions().timeZone == 'Africa/Lagos') {
        return true;
    }

    return false;
}
