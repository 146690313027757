import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ALERTBTN } from 'src/app/interfaces/general';

export interface ContentHeader {
    headerTitle: string;
    actionButton: boolean;
    breadcrumb?: {
        links?: Array<{
            name?: string;
            isLink?: boolean;
            link?: string;
            query?: any;
        }>;
    };
}

@Component({
    selector: 'app-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.scss'],
})
export class ContentHeaderComponent implements OnInit, OnChanges, OnDestroy {
    @Input() contentHeader!: any;
    alertInfo!: ALERTBTN;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        this.contentHeader.breadcrumb = { ...this.contentHeader.breadcrumb };
    }

    ngOnInit() {}

    ngOnDestroy(): void {}
}
