<modal-cover title="Select Employees" [template]="employees">
    <div class="p-3 w-100">
        <form class="w-75" [formGroup]="form" style="gap: 0px !important">
            <div class="form-group w-100 mb-4">
                <label for="jobRole">Add Employees <span class="text-danger">*</span></label>
                <div class="app-ng-autocomplete">
                    <ng-select
                        #employeeLst
                        [formControl]="employeeControl"
                        [placeholder]="'Select Employee'"
                        bindLabel="name"
                        class="ng-input"
                        [items]="employeeData"
                        [closeOnSelect]="false"
                        [clearable]="true"
                        (change)="selectEmployee($event)"
                    >
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <app-e-drop-ui
                                [multiple]="true"
                                [selection]="selection"
                                [item]="item"
                            ></app-e-drop-ui>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div style="max-height: 100px; overflow: auto">
                <btn-list
                    style="display: flex; flex-wrap: wrap"
                    [items]="selection.selected"
                    btnClass="btn-pink-light"
                    key="name"
                    (onRemove)="onRemoveEmployee($event)"
                ></btn-list>
            </div>

            <div class="form-group form-style w-100 mb-4">
                <label for="jobRole">Period <span class="text-danger">*</span></label>
                <select name="" id="period" class="form-control rounded" formControlName="period">
                    <option value="">Select Insurance period of time</option>
                    <option *ngFor="let period of insurancePeriod" [value]="period">
                        {{ period }}
                    </option>
                </select>
            </div>

            <div class="form-group form-style w-100 mb-4">
                <label for="jobRole">Start Date <span class="text-danger">*</span></label>
                <input formControlName="startDate" type="month" class="form-control rounded" />
            </div>

            <hr class="divider" />

            <div>
                <p>
                    You can upload the list of employee as a csv file, download the template and
                    upload
                </p>
                <div class="col d-flex flex-row justify-content-between">
                    <div class="pointer" (click)="downloadSchedule()">
                        <i class="fa fa-download text-secondary pr-1" aria-hidden="true"></i>
                        <span class="text-secondary">Download Schedule</span>
                    </div>

                    <div class="pointer" (click)="uploadSchedule()">
                        <i class="fa fa-upload text-secondary pr-1" aria-hidden="true"></i>
                        <span class="text-secondary">Upload Schedule</span>
                        <input
                            type="file"
                            #csvReader
                            name="Upload CSV"
                            id="txtFileUpload"
                            style="display: none"
                            accept=".csv"
                            (change)="uploadListener($event)"
                        />
                    </div>
                </div>
            </div>

            <button
                [disabled]="form.invalid"
                (click)="activeModal.close(this.form.value)"
                class="btn btn-primary"
                type="button"
            >
                Add Employees
            </button>
        </form>
    </div>
</modal-cover>
<ng-template #employees>
    <span class="p-2">{{ currentPlanAmount | currency: 'NGN' : 'symbol-narrow' }}</span>
</ng-template>
