import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
    CartLicenses,
    checkNairaCurrency,
    getCartLicenses,
    License,
    LicenseModuleTypes,
    LicensePrice,
    setCartLicenses,
    VIEW_BUY_LICENSE,
} from '../../../modules/settings/license/util';
import { Subject, lastValueFrom, take } from 'rxjs';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/services/general.service';
import { LicenseService } from '../../../modules/settings/license/license.service';
import { FeatureFlagService } from 'src/app/shared/services/feature-flag.service';

@Component({
    selector: 'app-license-list',
    templateUrl: './license-list.component.html',
    styleUrl: './license-list.component.scss',
})
export class LicenseListComponent implements OnInit, OnChanges {
    cartItemsCount = 0;
    nairaCurrency = true;
    licenses!: License[];
    annualPayment = false;
    paymentPeriod: keyof LicensePrice = 'monthly';
    @Input() moduleName!: string;
    listenerApp = new Subject<any>();
    VIEW_BUY_LICENSE: any = VIEW_BUY_LICENSE;

    licenseModuleNameMap: Record<string, string> = this.fgs.getLicenseModulesMap();

    constructor(
        private router: Router,
        private genSrv: GeneralService,
        private licenseService: LicenseService,
        private fgs: FeatureFlagService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.moduleName.isFirstChange()) {
            this.getMyXalaryLicenses();
        }
    }
    ngOnInit(): void {
        this.listenerApp.asObservable().subscribe((v) => {
            if (v.userHasAccess) {
                this.getMyXalaryLicenses();
                this.computeCartCount();

                this.nairaCurrency = checkNairaCurrency();
                this.licenseModuleNameMap = this.fgs.getLicenseModulesMap();
            }
        });
    }

    async getMyXalaryLicenses() {
        const res: any = await lastValueFrom(
            this.licenseService.getAllOrgNonActiveLicenses().pipe(take(1))
        );
        this.licenses = res.response.filter((v: any) => {
            if (!this.moduleName) return true;
            return v?.modules[this.moduleName];
        });
    }

    toggleAnnualPayment(): void {
        this.annualPayment = !this.annualPayment;
        if (this.annualPayment) {
            this.paymentPeriod = 'annually';
        } else {
            this.paymentPeriod = 'monthly';
        }
    }

    reviewCart() {
        this.router.navigate(['/app-settings/license/cart']);
    }

    addLicenseToCart(licenseIndex: number, employeeCount: string): void {
        if (!(!isNaN(parseFloat(employeeCount)) && isFinite(employeeCount as any))) {
            return this.genSrv.alertInfo.next({
                text: 'Check employee Count',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 5000,
            });
        }

        if (parseFloat(employeeCount) === 0) {
            return this.genSrv.alertInfo.next({
                text: 'Employee count cannot be Zero',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 5000,
            });
        }

        const newLicense = this.licenses[licenseIndex];

        if (
            newLicense.employees.minimum &&
            parseFloat(employeeCount) < newLicense.employees.minimum
        ) {
            return this.genSrv.alertInfo.next({
                text:
                    'You cannot purchase less than ' +
                    newLicense.employees.minimum +
                    ' Employees for this license',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 5000,
            });
        }

        if (
            newLicense.employees.maximum &&
            parseFloat(employeeCount) > newLicense.employees.maximum
        ) {
            return this.genSrv.alertInfo.next({
                text:
                    'You cannot purchase more than ' +
                    newLicense.employees.maximum +
                    ' Employees for this license',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 5000,
            });
        }

        let licensesInCart: CartLicenses = [];
        const cartLicense = getCartLicenses();
        if (cartLicense.valid) {
            licensesInCart = cartLicense.licenses;
        }

        let msg = '';
        const newLicenseId = newLicense._id;
        const existingLicenseIndex = licensesInCart.findIndex(
            (licenseInCart: any) =>
                licenseInCart.finratusId === newLicenseId &&
                licenseInCart.renewalFrequency === this.paymentPeriod
        );

        if (existingLicenseIndex !== -1) {
            msg = 'Increased ' + newLicense.name + ' plan in cart';
            licensesInCart[existingLicenseIndex] = {
                ...licensesInCart[existingLicenseIndex],
                employees: newLicense.employees,
                count: (licensesInCart[existingLicenseIndex].count += parseInt(employeeCount)),
            };
        } else {
            msg = 'Added ' + newLicense.name + ' plan to cart';
            licensesInCart.push({
                name: newLicense.name,
                finratusId: newLicenseId || '',
                count: parseInt(employeeCount),
                employees: newLicense.employees,
                renewalFrequency: this.paymentPeriod,
                price: newLicense.price[this.paymentPeriod],
            });
        }

        setCartLicenses(licensesInCart);

        this.computeCartCount();

        this.genSrv.alertInfo.next({
            text: msg,
            btnClass: 'alert-success',
            btnIcon: 'success',
            timer: 5000,
        });
    }

    computeCartCount() {
        const cartLicense = getCartLicenses();
        if (cartLicense.valid) {
            this.cartItemsCount = cartLicense.licenses.length;
        }
    }

    handleLicensesFilter(modules: any) {
        const entries = Object.entries(modules).filter(([key, value]) => value === true);
        const filteredObject = Object.fromEntries(entries);

        let res: any = [];
        Object.keys(filteredObject).forEach((module) => {
            res.push(this.licenseModuleNameMap[module]);
        });

        return res;
    }

    getActiveModules(index: number): LicenseModuleTypes[] {
        const firstLicense = this.licenses[index];
        return (Object.keys(firstLicense.modules) as LicenseModuleTypes[]).filter(
            (module: LicenseModuleTypes) => firstLicense.modules[module]
        );
    }
}
