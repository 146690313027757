<modal-cover title="Notification" [template]="action">
    <div
        infinite-scroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="10"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        class="w-100 notify-cover d-flex flex-column"
    >
        <div *ngFor="let notify of notifications | async" class="clickable p-2 w-100">
            <div
                *ngIf="notify.bulkUpdate?.title"
                (click)="openProgress(notify.bulkUpdate)"
                class="d-flex flex-column clickable"
            >
                <span class="btn-text error-message"> {{ notify.bulkUpdate?.title }}</span>
                <mat-progress-bar
                    mode="determinate"
                    [value]="
                        ((notify.bulkUpdate?.done || 0) * 100) / (notify.bulkUpdate?.total || 1)
                    "
                ></mat-progress-bar>
                <div class="d-flex w-100 justify-content-between align-items-between">
                    <span class="text-danger count"
                        >Failed: {{ notify.bulkUpdate?.failed || 0 }}</span
                    >
                    <span class="count">
                        {{ notify.bulkUpdate?.done || 0 }}/{{ notify.bulkUpdate?.total || 0 }}</span
                    >
                </div>
            </div>
            <div
                *ngIf="notify.inAppData"
                (click)="open(notify)"
                class="notify-list-item"
                [class.notView]="!notify.inAppViewed"
            >
                <div class="notify-title">{{ notify.inAppData.title }}</div>
                <div class="notify-title-holder">
                    <span class="notify-message">{{ notify.inAppData.message }} </span>
                    <span class="notify-time"> {{ notify.createdOn | date: 'dd/MM/YY h:mm' }}</span>
                </div>
            </div>
            <hr class="hr" />
        </div>
    </div>
    <ng-template #action>
        <button (click)="viewAll()" class="btn btn-default m-1">Viewed All</button>
    </ng-template>
</modal-cover>
