import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {
    @Output() buttonClicked: EventEmitter<any> = new EventEmitter();
    @Input() controlName!: UntypedFormControl;
    @Input() readonly!: boolean;

    constructor() {}

    ngOnInit(): void {}

    buttonClickedHandler() {
        this.buttonClicked.emit();
    }
}
