import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit, OnDestroy {
    @Output() editorContent = new EventEmitter<string>();
    @Output() onEnter = new EventEmitter<string>();
    @Input() noFocusOut: boolean = false;
    @Input() toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];
    @Input() htmlContent = '';
    @Input() disableEditor: boolean = false;
    @Input() disableMenu: boolean = false;
    @Input() plugins: any[] = [];
    @Input() placeholder = 'Say Something';
    @Input() customStyles = {};
    @Input() editorOptions: any = {};
    @Input() removeNodes: any[] = [];
    @Input() removeMarks: any[] = [];
    editor!: Editor;

    constructor() {}

    ngOnInit(): void {
        this.deleteNodes();
        this.deleteMarks();
        this.editor = new Editor(this.editorOptions);
    }

    handleEmitV2($event: any) {
        this.editorContent.emit($event);
    }

    deleteNodes() {
        this.removeNodes.forEach((item) => {
            delete this.editorOptions.schema.nodes[item];
        });
    }

    deleteMarks() {
        this.removeMarks.forEach((item) => {
            delete this.editorOptions.schema.marks[item];
        });
    }

    handleEmit() {
        if (this.noFocusOut) return;
        this.editorContent.emit(this.htmlContent);
    }

    setEditorValue(text: string) {
        this.editor.commands.insertText(text).exec();
    }

    handleKeyEnter(e: any) {
        if (!this.disableMenu) return;
        this.onEnter.emit(this.htmlContent);
    }
    ngOnDestroy(): void {
        const container = Array.from(document.querySelectorAll('.suggestion-item-container'));
        container.forEach((item) => item.setAttribute('style', 'display: none !important'));
        this.editor.destroy();
    }
}
