import { Injectable } from '@angular/core';

import { AppStore } from '../app-data/stores/app.store';
import { BehaviorSubject, lastValueFrom, take } from 'rxjs';
import { AppDispatcher } from '../app-data';
import { UsersService } from '../modules/settings/users/users.service';

interface UserAccessProps {
    allowedPermissions: { permissions: string[]; module: string };
    permissionType?: 'action' | 'page';
}

interface UserHasAccessResponse {
    userHasAccess: boolean;
    userHasAccessErrorMessage: string;
}

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    user: any;
    errorMessage = new BehaviorSubject<UserHasAccessResponse | null>(null);

    constructor(
        private _appStore: AppStore,
        private dispatcher: AppDispatcher,
        private mx: UsersService
    ) {}

    checkUserHasAccess({
        allowedPermissions,
        permissionType = 'page',
    }: UserAccessProps): UserHasAccessResponse {
        let userHasAccess: boolean;
        let userHasAccessErrorMessage: string;

        this._appStore.getUserInfo().subscribe((userInfo) => (this.user = userInfo));
        const userPermissions =
            this.user.permissions[permissionType === 'page' ? 'PageView' : 'ActionBased'][
                allowedPermissions.module
            ];

        if (!userPermissions) {
            userHasAccess = false;
            userHasAccessErrorMessage = `Access Denied. Request module ${allowedPermissions.module} support from your Administrator`;

            return { userHasAccess, userHasAccessErrorMessage };
        }

        const hasAllPermissions = allowedPermissions.permissions.every(
            (permission: string) => userPermissions[permission]
        );
        if (!hasAllPermissions) {
            const missingPermission = allowedPermissions.permissions.find(
                (permission: string) => !userPermissions[permission]
            );

            userHasAccess = false;
            userHasAccessErrorMessage = `Access Denied. Request ${missingPermission} Permission from your Administrator`;

            return { userHasAccess, userHasAccessErrorMessage };
        }

        userHasAccess = true;
        userHasAccessErrorMessage = '';

        return { userHasAccess, userHasAccessErrorMessage };
    }

    async checkUserHasAccessV2({
        allowedPermissions,
        permissionType = 'page',
    }: UserAccessProps): Promise<UserHasAccessResponse> {
        let userHasAccess: boolean;
        let userHasAccessErrorMessage: string;

        this.user = await this._appStore.getUserInfo().pipe(take(1)).toPromise();

        if (!Object.keys(this.user).length) {
            this.user = await this.mx.getProfile().pipe(take(1)).toPromise();
        }

        const userPermissions =
            this.user.permissions[permissionType === 'page' ? 'PageView' : 'ActionBased'][
                allowedPermissions.module
            ];

        if (!userPermissions) {
            const response = {
                userHasAccess: false,
                userHasAccessErrorMessage: `Access Denied. Request module ${allowedPermissions.module} support from your Administrator`,
            };
            this.errorMessage.next(response);

            return response;
        }
        const hasAllPermissions = allowedPermissions.permissions.every(
            (permission: string) => userPermissions[permission]
        );
        if (!hasAllPermissions) {
            const missingPermission = allowedPermissions.permissions.find(
                (permission: string) => !userPermissions[permission]
            );

            const response = {
                userHasAccess: false,
                userHasAccessErrorMessage: `Access Denied. Request ${missingPermission} Permission for ${allowedPermissions.module} module from your Administrator`,
            };
            this.errorMessage.next(response);
            return response;
        }

        const response = {
            userHasAccess: true,
            userHasAccessErrorMessage: '',
        };
        this.errorMessage.next(response);

        return response;
    }
}
