import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import * as fromStore from '../app-data';
import { StoreModule } from '@ngrx/store';
import { TitleCasePipe } from '@angular/common';

@NgModule({
    declarations: [HeaderComponent, SidebarComponent],
    imports: [
        SharedModule,
        AppRoutingModule,
        StoreModule.forFeature(fromStore.APP, fromStore.reducers),
    ],
    exports: [HeaderComponent, SidebarComponent],
    providers: [...fromStore.stores, ...fromStore.dispatchers, TitleCasePipe],
})
export class LayoutModule {}
