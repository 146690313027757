import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ALERTBTN, SIDEBAR } from 'src/app/interfaces/general';
import urlList from 'src/app/services/service-list.json';
import { ConfirmActionComponent } from 'src/app/shared/modals/confirm-action/confirm-action.component';
import appConstants from 'src/app/utils/constants';
import endpoints from 'src/app/utils/endpoints';
import { HandleLicenseModuleCheck } from 'src/app/utils/license';
import { environment } from 'src/environments/environment';
import { SuccessViewComponent } from '../modals/success-view/success-view.component';

dayjs.extend(utc);

const url = environment.myXalaryV1;
const meetingsUrl = environment.meetingsEndpoint;

interface CombinedResponse {
    employees: any[];
    activeEmployees: any[];
    basicEmployees: any[];
    allUsers: { [key: string]: any };
    departments: any[];
    branches: any[];
    cadres: any[];
    years: number[];
    roles: any[];
}

@Injectable({
    providedIn: 'root',
})
export class GeneralService {
    moduleName = new BehaviorSubject<string>('');
    env = environment.env;
    activeModals: BehaviorSubject<NgbModalRef[]> = new BehaviorSubject<NgbModalRef[]>([]);
    endpoint = environment.myXalaryEndpoint;
    recruitEndpoint = environment.recruitmentEndpoint;
    alertInfo = new Subject<ALERTBTN>();
    generalFilter = new Subject();
    meetingsToken!: string;
    defaultModalOptions: NgbModalOptions = {
        size: 'lg',
        centered: true,
        windowClass: 'recipient-modal',
        backdrop: 'static',
    };
    defaultPageOptions = [
        {
            key: '10',
            value: '10',
            selected: true,
        },
        {
            key: '20',
            value: '20',
        },
        {
            key: '50',
            value: '50',
        },
        {
            key: '100',
            value: '100',
        },
    ];
    defaultPeriodOptions = [
        {
            key: 'All',
            value: '',
            selected: true,
        },
        {
            key: 'Last 7 Days',
            value: '7',
        },
        {
            key: 'Last 14 days',
            value: '14',
        },
        {
            key: 'Last 30 days',
            value: '30',
        },
    ];

    constructor(
        private datePipe: DatePipe,
        private modalService: NgbModal,
        private http: HttpClient
    ) {
        this.modalService.activeInstances.subscribe((item) => {
            this.activeModals.next(item);
        });
    }

    convertISODateToLocalDateTime(isoDate: string, format: string = 'YYYY-MM-DDTHH:mm') {
        return dayjs(isoDate).local().format(format);
    }
    convertTime(time24: string): string {
        const formattedTime = this.datePipe.transform(`1970-01-01T${time24}`, 'h:mm a');
        return formattedTime ? formattedTime : 'Invalid time format';
    }

    formatDate(date: string, format: string = 'dd/MM/yyyy'): string {
        return this.datePipe.transform(date, format) || '';
    }

    dismissAllModals() {
        this.modalService.dismissAll();
    }

    handleCopyToClipBoard(data: string) {
        navigator.clipboard.writeText(data).then(
            () => {
                this.alertInfo.next({
                    text: 'Content Copied to clipboard!',
                    btnClass: 'alert-success',
                    btnIcon: 'checklist',
                    timer: 5000,
                });
            },
            (err) => {
                this.alertInfo.next({
                    text: 'Could not Copy',
                    btnClass: 'alert-danger',
                    btnIcon: 'error',
                    timer: 5000,
                });
            }
        );
    }

    getAppToken() {
        return window.localStorage.getItem('APP_TOKEN') || '';
    }

    setAppToken(token: string) {
        return window.localStorage.setItem('APP_TOKEN', token);
    }

    setMeetingToken(token: string) {
        return window.localStorage.setItem('MEETING_TOKEN', token);
    }

    getMeetingToken() {
        return window.localStorage.getItem('MEETING_TOKEN') || '';
    }

    setDashCards(id: string, cards: string[]) {
        return window.localStorage.setItem(`${id}-DASH_CARD`, JSON.stringify(cards));
    }

    getDashCards(id: string) {
        return JSON.parse(window.localStorage.getItem(`${id}-DASH_CARD`) || '[]');
    }

    removeAppToken() {
        return window.localStorage.removeItem('APP_TOKEN');
    }

    filterOnlyActiveEmployees(list: any[]) {
        return list.filter((v) => v.isActive);
    }
    filterOnlyNonTerminated(list: any[]) {
        return list.filter((v) => v.termination?.status !== true);
    }

    transformData(
        list: any[],
        addJobRole: (
            | 'jobRole'
            | 'branch'
            | 'department'
            | 'cadre'
            | 'employeeHireDate'
            | 'staffID'
            | 'leaveCategory'
            | 'phone'
            | 'employeeType'
            | 'teamID'
        )[] = [],
        module?: string
    ) {
        if (module) {
            list = HandleLicenseModuleCheck({
                employees: list,
                module,
            });
        }
        return list.map((item: any) => {
            const {
                _id,
                firstName,
                lastName,
                myx3ID,
                employeeEmail,
                userEmail,
                profileImgUrl,
                jobRole,
                teamID,
                branchID,
                departmentID,
                employeeCadre,
                employeeHireDate,
                staffID,
                leaveCategory,
                userPhone,
                phone,
                employeeType,
            } = item;
            let others: any = {};
            if (jobRole && addJobRole.includes('jobRole')) {
                others['jobRole'] = {
                    id: jobRole.jobRoleId,
                    name: jobRole.name,
                };
            }
            if (employeeHireDate && addJobRole.includes('employeeHireDate')) {
                others['employeeHireDate'] = employeeHireDate;
            }
            if (staffID && addJobRole.includes('staffID')) {
                others['staffID'] = staffID;
            }
            if (teamID && addJobRole.includes('teamID')) {
                others['teamID'] = teamID;
            }
            if (branchID && addJobRole.includes('branch')) {
                others['branch'] = {
                    id: branchID._id,
                    name: branchID.branchName,
                };
            }
            if (departmentID && addJobRole.includes('department')) {
                others['department'] = {
                    id: departmentID._id,
                    name: departmentID.departmentName,
                };
            }
            if (employeeCadre && addJobRole.includes('cadre')) {
                others['cadre'] = {
                    id: employeeCadre._id,
                    name: employeeCadre.cadreName,
                };
            }
            if (leaveCategory && addJobRole.includes('leaveCategory')) {
                others['leaveCategory'] = leaveCategory;
            }
            if (addJobRole.includes('phone')) {
                others['phone'] = userPhone || phone;
            }

            if (employeeType && addJobRole.includes('employeeType')) {
                others['employeeType'] = employeeType;
            }

            return {
                id: _id,
                name: `${firstName} ${lastName}`,
                fid: myx3ID || '',
                admin: !!userEmail,
                email: employeeEmail || userEmail,
                profileImgUrl: profileImgUrl || '',
                ...others,
            };
        });
    }

    filterOnlyValid(list: any[]) {
        return this.filterOnlyActiveEmployees(this.filterOnlyNonTerminated(list));
    }

    preModal(Component: any, options?: NgbModalOptions & { doNotClosePrevious?: boolean }) {
        if (!options?.doNotClosePrevious) {
            this.modalService.hasOpenModals() && this.dismissAllModals();
        }
        const modal = this.modalService.open(Component, {
            ...this.defaultModalOptions,
            ...options,
        });
        modal.result.then(() => {}).catch(() => {});
        return modal;
    }

    openConfirmAction(title: string) {
        const { componentInstance } = this.preModal(ConfirmActionComponent, { size: 'sm' });
        Object.assign(componentInstance, { title });
        return componentInstance;
    }

    getUserInfo() {
        return this.http.get<any>(`${this.endpoint}/v1/users/profile`);
    }

    getUserinitials(name: string, splitAt = ' ', joinBy = '') {
        return name
            ? name
                  .split(splitAt)
                  .map((n) => n[0].toUpperCase())
                  .join(joinBy)
            : '';
    }

    getCombos() {
        interface EmployeesResponse {
            employees: any[];
        }

        interface UsersResponse {
            users: any[];
        }

        interface DepartmentsResponse {
            companyDepartments: any[];
        }

        interface BranchesResponse {
            companyBranches: any[];
        }

        interface CadresResponse {
            allCadre: any[];
        }

        interface RolesResponse {
            data: any[];
        }

        interface SchemeResponse {
            response: any[];
        }
        interface LicenseResponse {
            data: any[];
        }

        interface CombinedResponse {
            employees: any[];
            activeEmployees: any[];
            basicEmployees: any[];
            users: any[];
            allUsers: { [key: string]: any };
            departments: any[];
            branches: any[];
            cadres: any[];
            years: number[];
            roles: any[];
        }

        // Assume comboEndpoints is an array of observables

        let list: any = [
            `${this.endpoint}/v1/employees/listEmployees?type=nofilter`,
            `${this.endpoint}/v1/users/listUsers`,
            `${this.endpoint}/v1/departments/getDepartments`,
            `${this.endpoint}/v1/branches/getBranches`,
            `${this.endpoint}/v1/employees/getAllCadre`,
            `${this.endpoint}/v1/roles/getRoles?limit=1000`,
            `${this.recruitEndpoint}${urlList.settings.fetchJobRoles}?limit=90000`,
            endpoints.MX_BE.V1.salaryScheme.defaults,
            endpoints.MX_BE.V1.license.summary,
        ];
        const comboEndpoints: [
            Observable<EmployeesResponse>,
            Observable<UsersResponse>,
            Observable<DepartmentsResponse>,
            Observable<BranchesResponse>,
            Observable<CadresResponse>,
            Observable<RolesResponse>,
            Observable<SchemeResponse>,
            Observable<LicenseResponse>,
        ] = list.map((url: string) =>
            this.http.get<any>(url).pipe(catchError((error) => of(null)))
        );
        const currentYear = new Date().getFullYear();
        const years = [currentYear];
        for (let i = 1; i <= 10; i++) {
            years.push(currentYear - i);
        }

        return forkJoin(comboEndpoints).pipe(
            map(
                ([
                    employees,
                    users,
                    departments,
                    branches,
                    cadres,
                    roles,
                    jobRoles,
                    defaults,
                    licenses,
                ]: any[]) => {
                    let allUsers: any[] = [];
                    if (employees?.employees) {
                        allUsers = allUsers.concat(
                            employees?.employees.map((v: any) => ({ ...v, isAdmin: false }))
                        );
                    }
                    if (users?.users) {
                        allUsers = allUsers.concat(
                            users.users.map((v: any) => ({ ...v, isAdmin: true }))
                        );
                    }
                    //transform
                    allUsers = allUsers.reduce((prev, cur) => {
                        prev[cur._id] = cur;
                        return prev;
                    }, {});
                    return {
                        employees: employees?.employees,
                        activeEmployees: this.filterOnlyValid(employees?.employees || []),
                        basicEmployees: this.transformData(
                            this.filterOnlyValid(employees?.employees || [])
                        ),
                        users: users?.users,
                        allUsers,
                        departments: departments?.companyDepartments,
                        branches: branches?.companyBranches,
                        cadres: cadres?.allCadre,
                        years,
                        roles: roles?.data,
                        jobRoles: jobRoles?.payload?.data,
                        defaults: defaults.response,
                        licenses: licenses.data,
                    };
                }
            )
        );
    }

    get sideBarMenu(): SIDEBAR[] {
        return [
            {
                icon: 'home',
                title: 'Dashboard',
                feature: appConstants.features.dashboard,
                slug: '',
                noHeader: true,
                subMenu: [],
            },
            {
                icon: 'event_note',
                title: 'Events',
                slug: 'events',
                feature: appConstants.features.events,
                noHeader: true,
                isExternalLink: false,
                externalLink: '',
                subMenu: [],
            },
            {
                icon: 'person',
                title: 'Employee',
                noHeader: true,
                slug: 'employee',
                feature: appConstants.features.employee,
                subMenu: [
                    //   { title: 'Overview', slug: 'employee'},
                    {
                        title: 'All Employees',
                        slug: 'all-employees',
                        feature: appConstants.features.allEmployee,
                    },
                    {
                        title: 'Department',
                        slug: 'dept',
                        feature: appConstants.features.department,
                    },
                    { title: 'Branch', slug: 'branch', feature: appConstants.features.branch },
                    {
                        title: 'Business Unit',
                        slug: 'bu',
                        feature: appConstants.features.businessUnit,
                    },
                    {
                        title: 'Division',
                        slug: 'division',
                        feature: appConstants.features.division,
                    },
                    {
                        title: 'Invite Employees',
                        slug: 'invite-employees',
                        feature: appConstants.features.inviteEmployee,
                    },
                    {
                        title: 'Setup Teams',
                        slug: 'teams',
                        feature: appConstants.features.setupTeams,
                    },
                    { title: 'Bonuses', slug: 'bonus', feature: appConstants.features.bonuses },
                    { title: 'Deductions', slug: 'd', feature: appConstants.features.deductions },
                    {
                        title: 'Tax Reliefs',
                        slug: 'tax',
                        feature: appConstants.features.taxReliefs,
                    },
                    { title: 'Benefits', slug: 'b', feature: appConstants.features.benefits },
                    {
                        title: 'Cadre',
                        slug: 'employee-cadre',
                        feature: appConstants.features.cadre,
                    },
                ],
                isExternalLink: false,
            },
            {
                icon: 'check_circle',
                title: 'Confirmation',
                slug: 'confirmation',
                feature: appConstants.features.confirmation,
                noHeader: true,
                subMenu: [
                    {
                        title: 'Policies',
                        slug: 'policies',
                        feature: appConstants.features.confirmationPolicy,
                    },
                    {
                        title: 'Evaluations',
                        slug: 'evaluations',
                        feature: appConstants.features.confirmationEvaluation,
                    },
                ],
                isExternalLink: false,
            },
            {
                icon: 'check_box',
                title: 'ENPS',
                slug: 'employee-net-promoter-score',
                feature: appConstants.features.enps,
                noHeader: true,
                subMenu: [
                    {
                        title: 'Surveys',
                        slug: 'surveys',
                        feature: appConstants.features.enpsSurvey,
                    },
                ],
                isExternalLink: false,
                prod: true,
                staging: true,
            },
            {
                icon: 'mail',
                title: 'Safety Incident Reports',
                slug: 'safety-incident-reporting',
                feature: appConstants.features.safetyAndIncident,
                noHeader: true,
                subMenu: [
                    {
                        title: 'Reports',
                        slug: 'reports',
                        feature: appConstants.features.safetyAndIncidentReports,
                    },
                ],
                isExternalLink: false,
            },
            {
                icon: 'sell',
                title: 'Payments',
                feature: appConstants.features.oldPayrollDashboard,
                noHeader: true,
                slug: 'payment',
                subMenu: [
                    { title: 'Payroll', slug: 'payroll', feature: appConstants.features.payroll },
                    {
                        title: 'Allowances',
                        slug: 'allowance',
                        feature: appConstants.features.allowances,
                    },
                    {
                        title: 'Remittances',
                        slug: 'remittance',
                        feature: appConstants.features.remittances,
                    },
                ],
                isExternalLink: false,
            },
            {
                icon: 'sell',
                title: 'Payments',
                feature: appConstants.features.payrollDashboard,
                noHeader: true,
                slug: 'payment',
                isExternalLink: false,
            },
            {
                icon: 'check',
                title: 'Grievance & Disciplinary ',
                feature: appConstants.features.grievance,
                slug: 'grievance',
                noHeader: true,
                subMenu: [
                    {
                        title: 'Policy',
                        slug: 'policies',
                        feature: appConstants.features.grievancePolicy,
                    },
                    {
                        title: 'Reports',
                        slug: 'reports',
                        feature: appConstants.features.grievanceReport,
                    },
                    {
                        title: 'Query & Response',
                        slug: 'query-response',
                        feature: appConstants.features.grievanceQuery,
                    },
                    {
                        title: 'Case',
                        slug: 'case-management',
                        feature: appConstants.features.grievanceCase,
                    },
                ],
            },
            {
                icon: 'event_available',
                title: 'Attendance',
                slug: 'attendance',
                feature: appConstants.features.attendance,
                noHeader: true,
                subMenu: [
                    {
                        title: 'Attendance List',
                        slug: 'attendance-list',
                        feature: appConstants.features.attendanceList,
                    },
                    {
                        title: 'Setup',
                        slug: 'setup',
                        feature: appConstants.features.attendanceSetup,
                    },
                    {
                        title: 'Devices List',
                        slug: 'devices-list',
                        feature: appConstants.features.attendanceDeviceList,
                    },
                ],
            },
            {
                icon: 'exit_to_app',
                title: 'Exit Management',
                feature: appConstants.features.exits,
                slug: 'exits',
                prod: true,
                staging: true,
                noHeader: true,
                subMenu: [
                    {
                        title: 'Policies',
                        slug: 'exit-policy',
                        feature: appConstants.features.exitPolicy,
                    },
                    {
                        title: 'Terminations',
                        slug: 'exits-list',
                        feature: appConstants.features.exitList,
                    },
                    {
                        title: 'Requests',
                        slug: 'exit-request',
                        feature: appConstants.features.exitRequests,
                    },
                    {
                        title: 'Settings',
                        slug: 'exit-settings',
                        feature: appConstants.features.exitSettings,
                    },
                ],
                isExternalLink: false,
            },
            {
                icon: 'flight_takeoff',
                title: 'Leave',
                slug: 'leave',
                feature: appConstants.features.leave,
                noHeader: true,
                subMenu: [
                    {
                        title: 'Leave Application',
                        slug: 'leave-application',
                        feature: appConstants.features.leaveApplication,
                    },
                    {
                        title: 'Setup',
                        slug: 'leave-setup',
                        feature: appConstants.features.leaveSetup,
                    },
                ],
            },
            {
                icon: 'monitoring',
                title: 'Promotions',
                slug: 'promotions',
                feature: appConstants.features.promotions,
                noHeader: true,
                subMenu: [
                    {
                        title: 'Policies',
                        slug: 'promotion-policy',
                        feature: appConstants.features.promotionPolicy,
                    },
                    {
                        title: 'Requests',
                        slug: 'promotion-request',
                        feature: appConstants.features.promotionRequests,
                    },
                    {
                        title: 'Report',
                        slug: 'promotion-report',
                        feature: appConstants.features.promotionReport,
                    },
                    {
                        title: 'Committee',
                        slug: 'promotion-committee',
                        feature: appConstants.features.promotionCommittee,
                    },
                    {
                        title: 'Settings',
                        slug: 'cadre-settings',
                        feature: appConstants.features.promotionSettings,
                    },
                ],
                isExternalLink: false,
            },
            {
                icon: 'schedule',
                feature: appConstants.features.tracker,
                title: 'Time Tracker',
                slug: 'tracker',
                subMenu: [
                    {
                        title: 'Dashboard',
                        slug: 'dashboard',
                        feature: appConstants.features.tracker_Dashboard,
                    },
                    {
                        title: 'Employee Dashboard',
                        slug: 'user-dashboard',
                        feature: appConstants.features.tracker_userDashboard,
                    },
                    {
                        title: 'Real Time',
                        slug: 'real-time',
                        feature: appConstants.features.tracker_realTime,
                    },
                    {
                        title: 'Reports',
                        slug: 'reports',
                        feature: appConstants.features.tracker_reports,
                    },
                    {
                        title: 'Timeline',
                        slug: 'timeline',
                        feature: appConstants.features.tracker_timeline,
                    },
                    {
                        title: 'Captures',
                        slug: 'captures',
                        feature: appConstants.features.tracker_captures,
                    },
                    {
                        title: 'Edit Time',
                        slug: 'edit-time',
                        feature: appConstants.features.tracker_editTime,
                    },
                    {
                        title: 'Payroll',
                        slug: 'timesheets',
                        feature: appConstants.features.tracker_payroll,
                    },
                    {
                        title: 'Settings',
                        slug: 'settings',
                        feature: appConstants.features.tracker_settings,
                    },
                ],
                test: true,
                staging: false,
                noHeader: true,
                prod: false,
            },
            {
                icon: 'format_list_bulleted',
                title: 'Projects',
                slug: 'project',
                feature: appConstants.features.project,
                subMenu: [
                    {
                        title: 'Project Dashboard',
                        slug: 'dashboard',
                        feature: appConstants.features.project_dashboard,
                    },
                    {
                        title: 'Project List',
                        slug: 'project-list',
                        feature: appConstants.features.project_list,
                    },
                ],
                test: true,
                staging: false,
                noHeader: true,
                prod: false,
            },
            {
                icon: 'checklist_rtl',
                title: 'Pending Approvals',
                feature: appConstants.features.pendingApproval,
                slug: 'pending-approvals',
                noHeader: true,
                subMenu: [],
            },
            {
                icon: 'work_alert',
                title: 'Recruitment',
                feature: appConstants.features.recruitment,
                noHeader: true,
                slug: 'recruitment',
                subMenu: [
                    // { title: 'Dashboard', slug: '' },
                    {
                        title: 'Job Opportunities',
                        slug: 'job-opportunities',
                        feature: appConstants.features.jobOpportunities,
                    },
                    {
                        title: 'Application Form',
                        slug: 'application-form',
                        feature: appConstants.features.applicationForm,
                    },
                    {
                        title: 'Job Requests',
                        slug: 'job-requests',
                        feature: appConstants.features.jobRequests,
                    },
                    {
                        title: 'Talent Pool',
                        slug: 'talent-pool',
                        feature: appConstants.features.talentPool,
                    },
                    {
                        title: 'Interviews',
                        slug: 'interviews',
                        feature: appConstants.features.interviews,
                    },
                    {
                        title: 'Pre-Employment Test',
                        slug: 'pre-employment-test',
                        feature: appConstants.features.preEmploymentTest,
                    },
                    {
                        title: 'Onboarding',
                        slug: 'onboarding',
                        feature: appConstants.features.onboarding,
                    },
                    {
                        title: 'Settings',
                        slug: 'settings',
                        feature: appConstants.features.recruitmentSettings,
                    },
                ],
                isExternalLink: false,
            },
            {
                icon: 'handyman',
                title: 'Requisition',
                feature: appConstants.features.requisition,
                noHeader: true,
                slug: 'requisition',
                subMenu: [],
                isExternalLink: true,
                externalLink: `${url}requisitions?eaid=${this.getAppToken()}`,
            },
            {
                icon: 'groups',
                title: 'Meetings',
                feature: appConstants.features.meetings,
                noHeader: true,
                slug: 'meetings',
                subMenu: [],
                isExternalLink: true,
                externalLink: `${meetingsUrl}?token=${this.getMeetingToken()}`,
            },
            {
                icon: 'monitor_heart',
                title: 'Health',
                slug: 'health',
                feature: appConstants.features.health,
                noHeader: true,
                subMenu: [
                    // { title: 'Dashboard', slug: 'dashboard'},
                    {
                        title: 'Pay Via MyXalary',
                        slug: 'pay',
                        feature: appConstants.features.healthPay,
                    },
                    {
                        title: 'Buy a Health Plan',
                        slug: 'all-hmo-providers',
                        feature: appConstants.features.healthProviders,
                    },
                    {
                        title: 'Purchase History',
                        slug: 'purchase-history',
                        feature: appConstants.features.purchaseHistory,
                    },
                ],
                isExternalLink: false,
            },
            {
                icon: 'trending_up',
                title: 'Performance',
                feature: appConstants.features.performance,
                noHeader: true,
                id: 'performance',
                slug: 'performance',
                subMenu: [],
            },
            {
                icon: 'chat_bubble',
                title: 'Chats & Announcements',
                feature: appConstants.features.chats,
                noHeader: true,
                slug: 'chats',
                subMenu: [
                    {
                        title: 'Settings',
                        slug: 'settings',
                        feature: appConstants.features.chatSettings,
                    },
                ],
            },
            {
                icon: 'engineering',
                title: 'Job Roles & Competency',
                feature: appConstants.features.jobRole,
                noHeader: true,
                id: 'jobs',
                slug: 'job-roles',
                subMenu: [],
            },
            {
                icon: 'monitoring',
                title: 'Learning & Development',
                feature: appConstants.features.learning,
                slug: 'learning-and-development',
                noHeader: true,
                subMenu: [
                    // { title: 'Overview', slug: 'overview' },
                    {
                        title: 'Trainings',
                        slug: 'trainings',
                        feature: appConstants.features.learningTrainings,
                    },
                    {
                        title: 'Requests',
                        slug: 'requests',
                        feature: appConstants.features.learningRequests,
                    },
                    {
                        title: 'Feedback',
                        slug: 'feedback',
                        feature: appConstants.features.learningFeedback,
                    },
                    {
                        title: 'Courses',
                        slug: 'courses',
                        feature: appConstants.features.learningCourses,
                    },
                    {
                        title: 'Budget & Expenditure',
                        slug: 'budget-expenditure',
                        feature: appConstants.features.learningBudget,
                    },
                    { title: 'ROI', slug: 'roi', feature: appConstants.features.learningROI },
                    {
                        title: 'Vendors',
                        slug: 'vendors',
                        feature: appConstants.features.learningVendors,
                    },
                ],
                isExternalLink: false,
            },
            {
                icon: 'school',
                title: 'E-Learning',
                feature: appConstants.features.eLearning,
                noHeader: true,
                slug: 'elearning',
                subMenu: [
                    {
                        title: 'Upload Course',
                        slug: 'upload',
                        feature: appConstants.features.eLearningUpload,
                    },
                    {
                        title: 'Courses',
                        slug: 'courses',
                        feature: appConstants.features.eLearningCourses,
                    },
                    {
                        title: 'Learning Paths',
                        slug: 'paths',
                        feature: appConstants.features.eLearningPaths,
                    },
                    {
                        title: 'Study Reports',
                        slug: 'study-reports',
                        feature: appConstants.features.eLearningStudyReports,
                    },
                    {
                        title: 'Recommendations',
                        slug: 'recommendations',
                        feature: appConstants.features.eLearningRecommendation,
                    },
                    {
                        title: 'Published Courses',
                        slug: 'published',
                        feature: appConstants.features.eLearningPublished,
                    },
                    {
                        title: 'Unpublished Courses',
                        slug: 'unpublished',
                        feature: appConstants.features.eLearningUnPublished,
                    },
                    {
                        title: 'Attempts',
                        slug: 'attempts',
                        feature: appConstants.features.eLearningAttempts,
                    },
                ],
            },
            {
                icon: 'person',
                title: 'Talent Management',
                slug: 'talent-management',
                feature: appConstants.features.talentManagement,
                noHeader: true,
                subMenu: [
                    // { title: 'Dashboard', slug: '' },
                    {
                        title: 'Career Development',
                        slug: 'career-development',
                        feature: appConstants.features.careerDevelopment,
                    },
                    {
                        title: 'Succession Planning',
                        slug: 'succession-planning',
                        feature: appConstants.features.successionPlanning,
                    },
                    {
                        title: 'Talent Review',
                        slug: 'talent-reviews',
                        feature: appConstants.features.talentReviews,
                    },
                ],
                isExternalLink: false,
            },

            {
                icon: 'pie_chart',
                title: 'Reports',
                feature: appConstants.features.reports,
                noHeader: true,
                slug: 'reports',
                id: 'reports',
                subMenu: [
                    {
                        title: 'Performance Report',
                        slug: 'performance',
                        feature: appConstants.features.performanceReports,
                    },
                    {
                        title: 'Leave Report',
                        slug: 'leave-report',
                        feature: appConstants.features.leaveReports,
                    },
                    {
                        title: 'Recruitment Report',
                        slug: 'recruitment-report',
                        feature: appConstants.features.recruitmentReport,
                    },
                    {
                        title: 'Payroll Report',
                        slug: 'payroll-report',
                        feature: appConstants.features.payrollReport,
                    },
                    {
                        title: 'Employee Report',
                        slug: 'employee-report',
                        feature: appConstants.features.employeeReport,
                    },
                    {
                        title: 'Compensation KPI',
                        slug: 'compensation-kpi',
                        feature: appConstants.features.compensationReport,
                    },
                    {
                        title: 'Culture KPI',
                        slug: 'culture-kpi',
                        feature: appConstants.features.cultureKPI,
                    },
                    {
                        title: 'Employment KPI',
                        slug: 'employment-kpi',
                        feature: appConstants.features.employmentKPI,
                    },
                ],
            },
            {
                icon: 'computer',
                feature: appConstants.features.dms,
                title: 'Document Management System',
                slug: 'dms',
                noHeader: true,
                subMenu: [],
            },
            {
                icon: 'account_tree',
                title: 'Organogram',
                feature: appConstants.features.organogram,
                slug: 'organogram',
                noHeader: true,
                subMenu: [
                    {
                        title: 'Reporting Line Organogram',
                        slug: '',
                        feature: appConstants.features.organogram,
                    },
                    {
                        title: 'Department Organogram',
                        slug: 'department',
                        feature: appConstants.features.organogramDepartment,
                    },
                ],
            },
            {
                icon: 'settings',
                title: 'Settings',
                feature: appConstants.features.approvalWorkflow,
                slug: 'app-settings',
                noHeader: true,
                subMenu: [
                    {
                        title: 'Approval Workflow',
                        slug: 'approval-workflow',
                        feature: appConstants.features.approvalWorkflow,
                    },
                    { title: 'License', slug: 'license', feature: appConstants.features.license },
                    {
                        title: 'Users',
                        slug: 'users',
                        feature: appConstants.features.users,
                    },
                    {
                        title: 'Roles and Permissions',
                        feature: appConstants.features.rolesAndPermission,
                        slug: 'roles-and-permissions',
                    },
                    {
                        title: 'Payroll',
                        feature: appConstants.features.payrollSettings,
                        slug: 'payroll-settings',
                    },
                    {
                        title: 'Wallets',
                        feature: appConstants.features.wallet,
                        slug: 'wallet',
                    },
                    {
                        title: 'Integrations',
                        feature: appConstants.features.integration,
                        slug: 'integrations',
                    },
                    {
                        title: 'Document Settings',
                        feature: appConstants.features.documentSettings,
                        slug: 'document-settings',
                    },
                    {
                        title: 'Activity Logs',
                        feature: appConstants.features.activityLogs,
                        slug: 'activity-logs',
                    },
                ],
            },
        ];
    }

    async getBanks() {
        return await this.http.get<any>(endpoints.MX_BE.V1.utils.getBanks).toPromise();
    }

    async getCountries() {
        return await this.http.get<any>(endpoints.MX_BE.V1.utils.getCountries).toPromise();
    }
    async getCities() {
        return await this.http.get<any>(endpoints.MX_BE.V1.utils.getCities).toPromise();
    }

    async getIndustries() {
        return await this.http.get<any>(endpoints.MX_BE.V1.utils.getIndustries).toPromise();
    }

    async getLicenseSummary() {
        return await this.http.get<any>(endpoints.MX_BE.V1.license.summary).toPromise();
    }

    async uploadFile(formData: any) {
        return await this.http.post<any>(endpoints.LEARNING.V1.files, formData).toPromise();
    }
    getCompanyImage(company: any) {
        return (
            company?.companyImgUrl ||
            `https://ui-avatars.com/api/?background=440F7C&color=FFFFFF&name=${company?.companyName}`
        );
    }
    getEmployeeImage(employee: any) {
        return (
            employee.profileImgUrl ||
            `https://ui-avatars.com/api/?background=F7D3EA&color=D62196&name=${
                employee.name || employee.fullName || employee.firstName + ' ' + employee.lastName
            }`
        );
    }

    gotoV1(link: string) {
        const navigation = url + `${link}?eaid=${this.getAppToken()}`;
        window.location.assign(navigation);
    }
    getActiveDash({ slug, index, route, super: parent }: any) {
        const split: any[] = route.split('/');
        if (parent) {
            return split[index] === slug && split[1] === parent;
        }
        return split[index] === slug;
    }

    openSuccessModal(
        info: {
            title: string;
            subtitle2: string;
            btn: {
                title: string;
                btnStyle: string;
                routerLink: string;
            }[];
        },
        action?: (btn: string) => void
    ) {
        const { componentInstance } = this.preModal(SuccessViewComponent, {
            centered: true,
            windowClass: 'recipient-modal',
        });
        Object.assign(componentInstance, { info });
        componentInstance.buttonOutput.subscribe((btn: string) => {
            action && action(btn);
        });
        return componentInstance;
    }
    requiredTrim(control: AbstractControl) {
        const val = typeof control.value === 'string' ? control.value.trim() : control.value;
        if (!val) {
            return {
                required: true,
            };
        }
        return null;
    }

    formatMoneyNumber(value: number) {
        if (value >= 1e12) {
            return (value / 1e12).toFixed(2) + 'T';
        } else if (value >= 1e9) {
            return (value / 1e9).toFixed(2) + 'B';
        } else if (value >= 1e6) {
            return (value / 1e6).toFixed(2) + 'M';
        } else if (value >= 1e3) {
            return (value / 1e3).toFixed(2) + 'K';
        } else {
            return value.toFixed(2);
        }
    }

    localFilter({ query }: any) {
        const { page, limit, ...rest } = query;
        return (item: any) => {
            const keys = Object.keys(rest);
            let result = [];
            for (let key of keys) {
                let state = null;
                let val = rest[key];
                let keys = key.split('.');
                let itemValue = keys.reduce((result: any, row: any) => {
                    result = result ? result[row] : item[row];
                    return result;
                }, null);

                if (typeof val === 'string') {
                    if (/true|false/.test(val)) {
                        const tyx = val === 'true';
                        if (itemValue === tyx) {
                            state = { key, val, tyx, item: item };
                        }
                    } else {
                        const regex = new RegExp(`^${rest[key]}`, 'i');
                        if (regex.test(itemValue)) {
                            state = { key, val, item: item };
                        }
                    }
                } else if (Array.isArray(val)) {
                    //TODO
                } else if (typeof val === 'object' && Object.keys(val).length) {
                    let trialItem = keys.length > 1 ? item[keys[0]] : item;

                    if (key === 'search') {
                        for (let itemKey in val) {
                            const fields = itemKey.split('.');
                            let itemValue = fields.reduce((result: any, row: any) => {
                                result = result ? result[row] : item[row];
                                return result;
                            }, null);
                            const regex = new RegExp(`^${val[itemKey]}`, 'i');
                            if (regex.test(itemValue)) {
                                state = { key, val, item: item, itemKey };
                            }
                        }
                    } else if (val.operator && val.value) {
                        const currentVal = Number(trialItem[key]);
                        if (
                            this.getOpsValue({
                                operator: val.operator,
                                value: Number(val.value),
                                currentValue: currentVal,
                            })
                        ) {
                            state = { key, val, item: item };
                        }
                    } else {
                        for (let itemKey in val) {
                            const regex = new RegExp(`^${val[itemKey]}`, 'i');
                            if (regex.test(trialItem[itemKey])) {
                                state = { key, val, item: item, itemKey };
                            }
                        }
                    }
                }
                if (state) {
                    result.push(state);
                }
            }
            return result.length === keys.length;
        };
    }

    getOpsValue({ operator, value, currentValue }: any) {
        switch (operator) {
            case '>=': {
                return currentValue >= value;
            }
            case '>': {
                return currentValue > value;
            }
            case '<=': {
                return currentValue <= value;
            }
            case '<': {
                return currentValue < value;
            }
            case '=': {
                return currentValue == value;
            }
        }
        return false;
    }
    setLocalData({ page = 1, query = {}, originalData = [], defaultLimit = 10 }: any) {
        const limit = query.limit || defaultLimit;
        let data = [...originalData];

        if (Object.keys(query).length) {
            data = data.filter(this.localFilter({ query }));
        }
        const length = data.length;
        const totalPages = Math.ceil(length / limit);
        data = data.splice((page - 1) * limit, limit);

        return {
            paginatorSetup: {
                currentPage: page,
                totalCount: length,
                totalPages,
            },
            query,
            data,
        };
    }

    handlePaginateEvent(event: any) {
        const { count, query = {}, originalData = [] } = event;

        return this.setLocalData({ page: count, query, originalData });
    }
}
