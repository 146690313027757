import { createAction, props } from '@ngrx/store';
import { ISettings } from 'src/app/shared/models/interfaces';
import { ChatContact, Combos } from '../../interfaces/general';

export enum AppActionEnums {
    INIT_APP = 'INIT_APP',
    GET_APP_TOKEN = '[MYXALARY] APP TOKEN',
    FETCH_NOTIFICATION = 'FETCH_NOTIFICATION',
    SET_NOTIFICATION = 'SET_NOTIFICATION',
    FETCH_CONTACTS = 'FETCH_CONTACTS',
    SET_CONTACTS = 'SET_CONTACTS',
    SET_USER_INFO = 'SET_USER_INFO',
    FETCH_USER_INFO = 'FETCH_USER_INFO',
    SET_POST = 'SET_POST',
    SET_COMBO = 'SET_COMBO',
    SET_CHAT_CONTACT = 'SET_CHAT_CONTACT',
    FETCH_ACTIVE_CHAT = 'FETCH_ACTIVE_CHATS',
    SET_ACTIVE_CHATS = 'SET ACTIVE CHATS',
    SET_ONLINE_USERS = 'SET_ONLINE_USERS',
    SET_UNREAD_COUNT = 'SET_UNREAD_COUNT',
    SET_CHAT_SETTING = 'SET_CHAT_SETTING',
    FETCH_CHAT_SETTING = 'FETCH_CHAT_SETTING',
    FETCH_COMBO = 'FETCH_COMBO',
}

export interface IInitApp {
    environment: any;
    token: string;
}

export const initializeApp = (payload: { token: string }) => ({
    type: AppActionEnums.INIT_APP,
    payload,
});

export const getBearerToken = () => ({
    type: AppActionEnums.GET_APP_TOKEN,
});
export const fetchNotification = createAction(AppActionEnums.FETCH_NOTIFICATION);
export const setNotification = createAction(
    AppActionEnums.SET_NOTIFICATION,
    props<{ payload: any }>()
);
export const fetchContacts = createAction(AppActionEnums.FETCH_CONTACTS);
export const setContacts = createAction(AppActionEnums.SET_CONTACTS, props<{ payload: any }>());
export const setUserInfo = createAction(AppActionEnums.SET_USER_INFO, props<{ payload: any }>());
export const setPostId = createAction(AppActionEnums.SET_POST, props<{ payload: any }>());
export const fetchUserInfo = createAction(AppActionEnums.FETCH_USER_INFO);
export const setCombo = createAction(AppActionEnums.SET_COMBO, props<{ payload: Combos }>());
export const setChatContact = createAction(
    AppActionEnums.SET_CHAT_CONTACT,
    props<{ payload: ChatContact[] }>()
);
export const setActiveChats = createAction(
    AppActionEnums.SET_ACTIVE_CHATS,
    props<{ payload: any }>()
);
export const setOnlineUsers = createAction(
    AppActionEnums.SET_ONLINE_USERS,
    props<{ payload: any }>()
);
export const setUnReadCount = createAction(
    AppActionEnums.SET_UNREAD_COUNT,
    props<{ payload: any }>()
);
export const setChatSettings = createAction(
    AppActionEnums.SET_CHAT_SETTING,
    props<{ payload: ISettings }>()
);
export const fetchChatSettings = createAction(AppActionEnums.FETCH_CHAT_SETTING);
export const fetchCombos = createAction(AppActionEnums.FETCH_COMBO);
