import { Component, OnInit, OnDestroy } from '@angular/core';

import { ALERTBTN } from 'src/app/interfaces/general';
import { GeneralService } from '../../services/general.service';
import { ViewUploadProgressComponent } from '../view-upload-progress/view-upload-progress.component';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
    alertInfo: ALERTBTN[] = [];

    constructor(private genServ: GeneralService) {}

    ngOnInit() {
        this.genServ.alertInfo.subscribe((res: any) => {
            if (!res.text) return;
            const sn = res.sn || Date.now() + this.alertInfo.length + '';

            if (/^suc|^check/.test(res.btnIcon)) {
                res.btnIcon = 'check';
            }

            const index = this.alertInfo.findIndex((alert) => alert.sn == sn);
            if (index < 0) {
                this.alertInfo.push({
                    ...res,
                    sn,
                });
                !res.progress && this.timer(sn);
            } else {
                this.alertInfo[index] = res;
            }
        });
    }

    timer(sn: string) {
        setTimeout(() => {
            this.closeAlert(sn);
        }, 30000);
    }

    closeAlert(sn: string) {
        const ind = this.alertInfo.findIndex((v) => v.sn === sn);
        if (ind < 0) return;
        this.alertInfo.splice(ind, 1);
    }

    openProgress(data: any) {
        this.genServ.preModal(ViewUploadProgressComponent).componentInstance.data = data;
    }

    ngOnDestroy(): void {
        this.genServ.alertInfo.unsubscribe();
    }
}
