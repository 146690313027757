<div *ngIf="userHasAccess; else unauthorized_user">
    <div class="container-fluid">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <section>
            <div class="row">
                <div class="col-md-6 mb-sm-2 mb-xs-2">
                    <app-card [background]="'primary'" imageUrl="/assets/images/buy_plan.png">
                        <div class="section-card row">
                            <div class="col-sm-6">
                                <p class="text-primary">
                                    {{ label.DASHBOARD.BUY_HEALTH_PLAN }}
                                </p>
                            </div>

                            <app-button-link
                                class="col-sm-6"
                                [link]="'../all-hmo-providers'"
                                [label]="'Buy a Health Plan'"
                                [position]="buttonPosition.centered"
                                color="primary"
                            ></app-button-link>
                        </div>
                    </app-card>
                </div>

                <div class="col-md-6 mb-sm-2 mb-xs-2">
                    <app-card [background]="'secondary'" imageUrl="/assets/images/pay_via.png">
                        <div class="section-card row">
                            <p class="col-sm-6 text-secondary">
                                {{ label.DASHBOARD.PAY_VIA_MYXALARY }}
                            </p>
                            <app-button-link
                                class="col-sm-6"
                                [link]="'../pay'"
                                [label]="'Pay via MyXalary'"
                                [position]="buttonPosition.centered"
                                color="primary"
                            ></app-button-link>
                        </div>
                    </app-card>
                </div>
            </div>
        </section>

        <section class="analytics-container">
            <main-app-analytics
                (infoOutput)="getAnalytics($event.years)"
                [filterOption]="filterOption"
            ></main-app-analytics>
        </section>

        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between w-100">
                    <span class="card-title">HMO Providers Frequently Used</span>
                    <button routerLink="/health/all-hmo-providers" class="btn btn-default">
                        View All HMO
                    </button>
                </div>
            </div>
            <hr />
            <div class="card-body">
                <div *ngIf="!hmoLoading && hmos.length" class="providers-holder">
                    <app-hmo-provider [hmo]="hmo" *ngFor="let hmo of hmos"></app-hmo-provider>
                </div>
                <div
                    *ngIf="!hmoLoading && !hmos.length"
                    class="d-flex w-100 justify-content-center align-items-center flex-column"
                >
                    <mat-icon>warning</mat-icon>
                    <p>You have not used any HMO yet!</p>
                    <button routerLink="/health/all-hmo-providers" class="btn btn-default-outline">
                        View All HMO
                    </button>
                </div>
                <ng-template [ngIf]="hmoLoading">
                    <div class="table-spinner-wrapper">
                        <ng-lottie [options]="loaderOptions"></ng-lottie>
                    </div>
                </ng-template>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between w-100">
                    <span class="card-title">Purchased Plans</span>
                </div>
            </div>
            <hr />
            <div class="card-body">
                <div class="w-100">
                    <app-data-table *ngIf="tableConfig" [options]="tableConfig"></app-data-table>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template let-data="data" #status>
    <div class="d-flex justify-content-center align-items-center gap-10">
        <p-chip
            *ngIf="!data.isRunning; else mainChip"
            class="yellow"
            [label]="'Inactive, ' + data.active + 'days to activation'"
        ></p-chip>
        <ng-template #mainChip>
            <p-chip
                [ngClass]="data.expired ? 'red' : 'green'"
                [label]="
                    data.expired ? 'Expired' : 'Active - ' + data.plans.days + ' days remaining'
                "
            ></p-chip>
            <button (click)="clone(data)" *ngIf="data.expired" class="btn btn-default">
                Buy Again
            </button>
        </ng-template>
    </div>
</ng-template>
<ng-template #unauthorized_user>
    <unauthorized [errorMessage]="userHasAccessErrorMessage"> </unauthorized>
</ng-template>
