<section class="mt-3">
    <app-filter
        *ngIf="!options.hideFilter"
        (onFilter)="onFilterChange($event)"
        [options]="options?.searchOptions"
    ></app-filter>
    <app-export
        [params]="currentParams"
        [data]="exportData"
        *ngIf="options.exportOptions"
        [options]="options.exportOptions"
        [local]="options.useLocalData || false"
        [url]="options.exportOptions?.url || currentUrl"
        [fileName]="options.exportOptions?.fileName || ''"
    ></app-export>
    <div *ngIf="!loading; else loader" class="table-container d-flex flex-column w-100">
        @if (pkg === 'mat-table') {
            <table
                multiTemplateDataRows
                mat-table
                class="w-100"
                [dataSource]="dataSource"
                (matSortChange)="onSort($event)"
                matSort
            >
                <!-- ID Column -->
                <ng-container matColumnDef="#">
                    <th class="fit-column" mat-header-cell *matHeaderCellDef>#</th>
                    <td
                        class="fit-column"
                        mat-cell
                        *matCellDef="let row; let i = dataIndex"
                        [routerLink]="options.router ? options.router() : null"
                    >
                        {{ i + 1 }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row; let i = index">
                        <mat-icon class="mat-icon clickable">
                            {{
                                expandSelection.isSelected(row)
                                    ? 'keyboard_arrow_down'
                                    : 'keyboard_arrow_right'
                            }}
                        </mat-icon>
                    </td>
                </ng-container>
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                            [checked]="isAllChecked"
                            (change)="handleToggle()"
                        ></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row; let i = index">
                        <mat-checkbox
                            [checked]="selection.isSelected(row)"
                            (change)="handleCheck(row)"
                        ></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of options.columns" [matColumnDef]="column.label">
                    <th
                        class="fit-column"
                        [attr.colspan]="column.colSpan"
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        [disabled]="column.noSort || options.noSort"
                    >
                        <app-data-table-custom
                            [data]="column"
                            *ngIf="column.labelTemplate; else labelText"
                            [template]="column.labelTemplate"
                        ></app-data-table-custom>
                        <ng-template #labelText> {{ column.label }}</ng-template>
                        <i *ngIf="column.label === sortCol" class="fa ml-2 fa-spin fa-spinner"></i>
                    </th>
                    <td
                        class="fit-column"
                        [attr.colspan]="column.colSpan"
                        [ngStyle]="column.headerStyle"
                        mat-cell
                        *matCellDef="let row; let i = dataIndex; let last = last; let first = first"
                        [routerLink]="column.router ? column.router(row) : null"
                    >
                        <app-data-table-custom
                            [index]="i"
                            [data]="row"
                            *ngIf="column.template; else text"
                            [template]="column.template"
                        ></app-data-table-custom>
                        <ng-template #text>
                            <span
                                [innerHTML]="
                                    column.getValue
                                        ? column.getValue(row, i, first, last)
                                        : row[column.key]
                                "
                            ></span>
                        </ng-template>
                    </td>
                </ng-container>
                @if (!options?.hideHeader) {
                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                }
                <tr
                    mat-row
                    (click)="toggleRow(row)"
                    *matRowDef="let row; columns: displayColumns"
                ></tr>

                @if (options?.expand) {
                    <tr
                        [hidden]="!expandSelection.isSelected(row)"
                        mat-row
                        *matRowDef="let row; columns: ['expandedDetail']"
                    ></tr>
                }
                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayColumns.length">
                        <div *ngIf="options.childComponent" class="example-element-detail">
                            <ng-container
                                *dynamicComponent="{
                                    component: options.childComponent,
                                    inputs: getExpandOptions(element),
                                }"
                            ></ng-container>
                        </div>
                    </td>
                </ng-container>
            </table>
        } @else {
            <p-table [value]="dataSource" dataKey="key" [tableStyle]="{ 'min-width': '100%' }">
                <ng-template let-index pTemplate="header">
                    <tr>
                        @if (displayColumns.includes('#')) {
                            <th>#</th>
                        }

                        @if (displayColumns.includes('expand')) {
                            <th></th>
                        }
                        @if (displayColumns.includes('checkbox')) {
                            <th>
                                <mat-checkbox
                                    [checked]="isAllChecked"
                                    (change)="handleToggle()"
                                ></mat-checkbox>
                            </th>
                        }
                        <th
                            [attr.colspan]="column.colSpan"
                            *ngFor="let column of options.columns"
                            [attr.pSortableColumn]="
                                !(column.noSort || options.noSort) ? null : column.key
                            "
                        >
                            <p-sortIcon
                                *ngIf="!(column.noSort || options.noSort)"
                                [field]="column.key"
                            />
                            <app-data-table-custom
                                [data]="dataSource"
                                *ngIf="column.labelTemplate; else labelText"
                                [template]="column.labelTemplate"
                            ></app-data-table-custom>
                            <ng-template #labelText> {{ column.label }}</ng-template>
                        </th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-rowIndex="rowIndex"
                    let-row
                    let-expanded="expanded"
                >
                    <tr>
                        @if (displayColumns.includes('#')) {
                            <td>{{ rowIndex + 1 }}</td>
                        }
                        @if (displayColumns.includes('expand')) {
                            <td>
                                <mat-icon [pRowToggler]="row" class="mat-icon clickable">
                                    {{ expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                                </mat-icon>
                            </td>
                        }
                        @if (displayColumns.includes('checkbox')) {
                            <td>
                                <mat-checkbox
                                    [checked]="selection.isSelected(row)"
                                    (change)="handleCheck(row)"
                                ></mat-checkbox>
                            </td>
                        }
                        <td
                            [attr.colspan]="column.colSpan"
                            *ngFor="let column of options.columns"
                            [ngStyle]="column.headerStyle"
                            [routerLink]="column.router ? column.router(row) : null"
                        >
                            <app-data-table-custom
                                [index]="rowIndex"
                                [data]="row"
                                *ngIf="column.template; else text"
                                [template]="column.template"
                            ></app-data-table-custom>
                            <ng-template #text>
                                <span
                                    [innerHTML]="
                                        column.getValue
                                            ? column.getValue(row, rowIndex)
                                            : row[column.key]
                                    "
                                ></span
                            ></ng-template>
                        </td>
                    </tr>
                </ng-template>
                @if (options?.expand) {
                    <ng-template pTemplate="rowexpansion" let-rowIndex="rowIndex" let-rowData>
                        @if (options.childComponent) {
                            <tr>
                                <td [attr.colspan]="displayColumns.length">
                                    <div
                                        *ngIf="options.childComponent"
                                        class="example-element-detail"
                                    >
                                        <ng-container
                                            *dynamicComponent="{
                                                component: options.childComponent,
                                                inputs: getExpandOptions(rowData),
                                            }"
                                        ></ng-container>
                                    </div>
                                </td>
                            </tr>
                        } @else {
                            <tr
                                [ngStyle]="options.childProps?.rowStyle"
                                *ngFor="let row of rowData[options.nestedKey || 'data']"
                            >
                                <td
                                    [attr.colspan]="column.colSpan"
                                    *ngFor="let column of options.childProps?.columns"
                                    [ngStyle]="column.headerStyle"
                                    [routerLink]="column.router ? column.router(row) : null"
                                >
                                    <app-data-table-custom
                                        [index]="rowIndex"
                                        [data]="row"
                                        *ngIf="column.template; else text"
                                        [template]="column.template"
                                    ></app-data-table-custom>
                                    <ng-template #text>
                                        <span
                                            [innerHTML]="
                                                (column.getValue
                                                    ? column.getValue(row, rowIndex)
                                                    : row[column.key]
                                                ) | safeHtml
                                            "
                                        ></span
                                    ></ng-template>
                                </td>
                            </tr>
                        }
                    </ng-template>
                }
            </p-table>
        }
    </div>
    <div *ngIf="!options.hidePagination" class="d-flex justify-content-end mt-3">
        <app-paginator
            [paginatorSetup]="paginatorSetup"
            (paginateEvent)="handlePaginateEvent($event)"
        ></app-paginator>
    </div>
    <ng-template #loader>
        <div class="d-flex justify-content-center align-items-center">
            <div class="table-spinner-wrapper">
                <ng-lottie [options]="loaderOptions"></ng-lottie>
            </div>
        </div>
    </ng-template>
</section>
