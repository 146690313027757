import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnalyticsInfo } from 'src/app/interfaces/general';

@Component({
    selector: 'main-app-analytics-infor-card',
    templateUrl: './analytics-infor-card.component.html',
    styleUrls: ['./analytics-infor-card.component.scss'],
})
export class AnalyticsInforCardComponent implements OnInit, OnChanges {
    @Input() analyticsInfo: AnalyticsInfo[] | AnalyticsInfo[][] = [];
    @Input() analyticsList?: any;
    data: any = [];
    list: any = [];
    DEFAULT_FONT = '0.8rem';
    constructor() {}

    handleData() {
        this.list = [];
        this.data = [];
        if (!Array.isArray(this.analyticsInfo[0])) {
            this.data.push(this.analyticsInfo);
        } else {
            this.data = this.analyticsInfo;
        }

        if (this.analyticsList?.length && !Array.isArray(this.analyticsList[0])) {
            this.list.push(this.analyticsList);
        } else {
            this.list = this.analyticsList || [];
        }
    }
    ngOnInit(): void {
        this.handleData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.handleData();
    }
}
