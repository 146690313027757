import { InjectionToken, TemplateRef } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Route } from '@angular/router';
import { CalendarEvent } from 'angular-calendar';
import { ChartConfiguration, ChartItem } from 'chart.js';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import {
    IFile,
    IRoles,
    iFeedActivity,
    iFeedResponse,
    userInfo,
    userModel,
} from '../shared/models/interfaces';

export interface ALERTBTN {
    text: string;
    btnClass: string;
    btnIcon: string;
    timer?: number;
    progress?: boolean;
    icon?: string;
    data?: {
        total: number;
        done: number;
        failed: number;
    };
    sn?: string;
}

export interface Course {
    course: any;
    facilitator: any;
    elearningFacilitator: string;
    cost: string;
}

export interface Invitees {
    id: string;
    name: string;
    department: string;
    branch: string;
    cadre: string;
}
export interface TrainingModel {
    _id?: string;
    title: string;
    type: string;
    location: string;
    courses: Course[];
    startDate: string;
    endDate: string;
    status?: 'completed' | 'pending' | 'in-progress' | 'canceled';
    startTime: string;
    endTime: string;
    cost: string;
    totalCost: string;
    invitees: Invitees[];
    files: { format: string; name?: string; secure_url: string }[];
    otherCost: {
        name: string;
        cost: string;
        costPerEmployee?: string;
    }[];
    feedBackRequestId?: string;
    trainingRequestId?: string;
    cultureTraining?: boolean;
    metaData?: any;
}

export interface BudgetModel {
    title: string;
    _id?: string;
    department?: {
        id: string;
        name: string;
    };
    for: 'company' | 'department';
    periodType: 'yearly' | 'monthly' | 'quarterly';
    period: string | number;
    amount: number;
    year: Number;
}

export interface CourseModel {
    title: string;
    category: string;
    skills: any[];
    _id?: string;
}

interface SituationModel {
    current: string;
    projectedImprovement: string;
}
interface SupervisorReportModel {
    report: RequestorReportModel[];
    avgScore?: number;
    avgClass?: string;
    supervisor: {
        id: string;
        name: string;
    };
}
interface RequestorReportModel {
    actual: string;
    successRate: number;
    avgScore?: number;
    avgClass?: string;
}
export interface ROIFeedbackModel {
    _id?: string;
    status: 'pending' | 'due' | 'completed';
    requestId: RequestModel;
    evaluationDate: Date;
    situation: SituationModel[];
    requestorReport: RequestorReportModel[];
    supervisorReport: SupervisorReportModel[];
    employee: {
        id: string;
        name: string;
        email?: string;
    };
}
export interface TrainingHoursModel {
    _id?: string;
    type: 'company' | 'department' | 'cadre';
    period: 'quarterly' | 'monthly' | 'yearly';
    list: {
        id: string;
        name: string;
        display?: string;
        hours: number;
    }[];
}

export interface VendorsModel {
    _id?: string;
    name: string;
    email: string;
    phoneNumber?: string;
    isActive?: boolean;
    trainings?: {
        totalAmountPerEmployee: number;
        totalAmountPerTraining: number;
        totalTrainings: number;
        totalInProgress: number;
        totalCompleted: number;
        totalPending: number;
        totalCoursesTaken: number;
    };
    ratings?: {
        rating: number;
        allRating: any[];
    };
}
export interface FeedbackQuestionModel {
    title: string;
    custom?: boolean;
    checked?: boolean;
    disabled?: boolean;
    options?: any[];
    type: feedbackQuestionType;
}
export interface FeedBackRequestModel {
    trainingId: TrainingModel;
    trainingQuestions: FeedbackQuestionModel[];
    specialNote: string;
    uploadCertificate: boolean;
    allQuestions?: boolean;
}

export type feedbackQuestionType = 'text' | 'options' | 'rating';

export interface CommentsModel {
    rating: number;
    comment: string;
    subject: string;
    employee: {
        fullName: string;
        profileImg?: string;
        profileImgUrl?: string;
    };
}
export interface FeedbackResponseModel {
    title: string;
    type: feedbackQuestionType;
    answer: string | number;
}

export interface FeedBackEmployeeResponseModel {
    employee: {
        fullName: string;
        profileImg?: string;
    };
    certificate?: string;
    note?: string;
    questions: FeedbackResponseModel[];
}

export interface FeedBackQuestionResponseModel {
    comments: CommentsModel[];
    responses: FeedBackEmployeeResponseModel[];
}

export interface FeedBackRequestResponseModel {
    training: TrainingModel;
}
export interface RequestModel {
    _id?: string;
    status: string;
    employee: {
        id: string;
        name: string;
    };
    createdOn: string;
    evaluationDate: string;
    situation?: any[];
    training: TrainingModel;
    trainingInformation: TrainingModel;
}
export interface JOB {
    hiringDepartment: {
        id: string;
        name: string;
    };
    jobType: string;
    preEmploymentTest: string;
    preEmploymentTestDuration: number;
    jobRole: {
        id: any;
        name: string;
    };
    location: string;
    experienceLevel: string;
    educationalLevel: string[];
    jobDescription: string;
    vacancyType: string[];
    jobRoleType: string;
    salaryLowerAmount: string;
    salaryUpperAmount: string;
    slotsAvailable: number;
    status: string;
    creatorID?: string;
    applicationCloseDate: string;
    customQuestions?: CUSTOMQUESTIONS[];
    skills: string[];
    additionalDocs: string[];
    additionalDocuments: string[];
    showSalary: boolean;
    replyTo: string;
    published: boolean;
}

export interface CUSTOMQUESTIONS {
    question: string;
    answer: string;
}

export interface JOBROLE {
    _id: string;
    id: string;
    name: string;
    preEmploymentTest?: string;
}
export interface JOBCATEGORY {
    id: string;
    name: string;
}

export interface PAGINATOR {
    currentPage: number;
    totalCount: number;
    totalPages: number;
}
export interface AnalyticsInfo {
    title: string;
    description: string;
    template?: TemplateRef<any>;
    templateData?: any;
    style?: any;
    icon: string;
    avatarBackground: string;
}
export interface AnalyticsList {
    title: string;
    list: string[];
    font?: string;
}

export interface chartButton {
    title: string;
    action: Function;
}

export interface chartOption {
    id: ChartItem;
    options: ChartConfiguration;
    coverClass?: string;
    width?: string;
    height?: string;
    title?: string;
    actions?: chartButton[];
    gradient?: string[];
}
export interface calendarOptions {
    showEventList?: boolean;
    showEvent?: boolean;
    toolTip?: {
        show?: boolean;
    };
    badge?: Number;
    events: AppCalendarEvent[];
    showNavigation: boolean;
}

export interface AppCalendarEvent extends CalendarEvent {
    background?: string;
}
export interface filterOptions {
    title: string;
    useYears?: boolean;
    useMonth?: boolean;
    useMonthDefault?: boolean;
    useYearsDefault?: boolean;
    useDepartment?: boolean;
    filterButtons?: ButtonFilter[];
    template?: TemplateRef<any>;
    analyticsData: AnalyticsInfo[] | AnalyticsInfo[][];
    analyticsList?: AnalyticsList[];
}
export interface DataTableColumn {
    key: string;
    label: string;
    noSort?: boolean;
    sortKey?: string;
    router?: (data: any) => string[];
    headerStyle?: any;
    show?: boolean;
    labelTemplate?: TemplateRef<any>;
    template?: TemplateRef<any>;
    getValue?: Function;
    type?: 'number' | 'string' | 'date';
    colSpan?: number;
}

export interface CycleMembers {
    _id?: string;
    cycle: string;
    period: string;
    setup: membersSetup[];
}

export interface membersSetup {
    department: {
        id: string;
        name: string;
    };
    members: (userModel & { chairman?: boolean })[];
}
export interface DataTableConfig {
    hideFilter?: boolean;
    router?: () => string | string[];
    exportOptions?: {
        fileName: string;
        url?: string;
        types?: ('xlsx' | 'pdf')[];
        config?: {
            labels?: any;
        };
    };
    hidePagination?: boolean;
    searchOptions?: searchOptions[];
    columns: DataTableColumn[];
    noSort?: boolean;
    fixedQuery?: any;
    url?: string;
    showNumbers?: boolean;
    pageKey?: string;
    limitKey?: string;
    limitDefault?: number;
    useLocalData?: boolean;
    useCheckBox?: boolean;
    checkListener?: Subject<any>;
    selectionCompare?: (a: any, b: any) => boolean;
    expand?: boolean;
    nestedKey?: string;
    childComponent?: any;
    childProps?: any;
    hideHeader?: boolean;
    childCount?: number;
    data?: any[];
}

export interface Combos {
    employees: any[];
    users: any[];
    departments: any[];
    branches: any[];
    cadres: any[];
    years: any[];
    activeEmployees: any[];
    basicEmployees: any[];
    allUsers: any;
    roles: any[];
    jobRoles: any[];
}

export interface Swal {
    title: string;
    text: string;
    onClose: Function;
    proceed: Function;
}

export interface filterObjValue {
    operator: '=' | '>=' | '<=' | '>' | '<' | 'is';
    value: string;
}
export interface filterValue {
    [key: string]: filterObjValue | string | number;
}
export interface searchOptions {
    name: string;
    type: 'text' | 'search' | 'select' | 'date' | 'dateRange' | 'compare';
    placeholder?: string;
    width: string;
    transform?: (item: any) => filterValue | string;
    options?: {
        key: string;
        value: any;
        selected?: boolean;
        hidden?: boolean;
        disabled?: boolean;
    }[];
}
export interface ButtonFilter {
    name: string;
    options: any[];
    default?: string;
    placeholder: string;
    value?: string;
    label?: string;
}
export interface INVITEONBOARDAPPLICANTDETAILS {
    _id: string;
    fullName: string;
    createdOn: string;
    location?: string;
    expectedSalary?: number;
    jobID?: string;
    jobRole: { [key: string]: string };
    profileImg: string;
    passportUrl?: string;
    jobOpportunityID?: { _id: string; jobRole: { [key: string]: string } };
    hiringChecklists: {
        checklistID: { _id: string; name: string; type: string; __v: string };
        isCompleted: boolean;
        docUrl?: string;
        _id: string;
    }[];
    onboardingChecklists: {
        checklistID: { _id: string; name: string; type: string; companyID: string; __v: string };
        isCompleted: boolean;
        docUrl?: string;
        _id: string;
    }[];
}

export interface HIRINGONBOARDAPPLICANTCHECKLIST {
    checklistID: { _id: string; name: string; type: string; __v: string };
    isCompleted: boolean;
    _id: string;
}

export interface APPLICANTEMAILTEMPLATEREQ {
    submissionID: string;
    fullName: string;
    alternateInterviewDate?: string;
    alternateInterviewTime?: string;
    interviewLocation?: string;
    interviewer?: string;
    roleName: string;
    profileImg?: string;
}

export interface APPLICANTINVITEFORM {
    interviewer: string;
    interviewLocation: string;
    interviewDate: string;
    interviewTime: string;
    alternateInterviewDate: string;
    alternateInterviewTime: string;
}

export interface APPLICANTHIREFORM {
    cadre: string;
    salaryAmount: string;
    salaryType: string;
    resumptionDate: string;
}

export interface APPLICANTEMAILTEMPLATERES {
    submissionID: string;
    fullName: string;
    roleName: string;
    profileImg: string;
    emailContent: string;
    jobOpportunityID?: string;
}

export interface CHECKLIST {
    companyID: string;
    name: string;
    type: string;
    _id: string;
    added?: boolean;
    content?: string | any;
}

export interface EMAILTEMPLATE {
    content: string;
    name: string;
    queryName: string;
    _id: string;
}

export interface EducationalInformation {
    institution: string;
    degreeType: string;
    degree: string;
    startYear: number;
    endYear: number;
}

export interface WorkExperience {
    company: string;
    title: string;
    startYear: number;
    endYear: number;
}

export interface APPLICATIONPAYLOAD {
    jobOpportunityID: string;
    resumeUrl: string;
    fullName: string;
    email: string;
    phone: string;
    dateOfBirth: string;
    address: string;
    passportUrl: string;
    certificateUrl: string;
    additionalDocUrl: string;
    currentCompany: string;
    educationalLevel: number;
    yearsOfExperience: number;
    otherSkills: string[];
    linkedinUrl: string;
    githubUrl: string;
    portfolioUrl: string;
    otherUrl: string;
    coverLetter: string;
    educationalInformation: EducationalInformation[];
    workExperience: WorkExperience[];
    expectedSalary: number;
    periodOfNotice: string;
    otherInformation: string;
    acceptDataProtection: boolean;
    acceptPrivacyPolicy: boolean;
}

export interface SKILLS {
    name: string;
    _id?: string;
}

export interface BANKS {
    code: string;
    name: string;
    _id: string;
}

export interface DEPARTMENT {
    companyID: string;
    createdAt: string;
    createdOn: string;
    departmentKeyName: string;
    departmentName: string;
    employeeCount: number;
    id: number;
    _id: string;
    modifiedOn: string;
    updatedAt: string;
}

export interface CADRE {
    companyID: string;
    createdAt: string;
    createdOn: string;
    cadreKeyName: string;
    cadreSteps: any[];
    cadreName: string;
    id: number;
    _id: string;
    modifiedOn: string;
    updatedAt: string;
}

export interface ENV {
    production: boolean;
    env: 'prod' | 'staging' | 'test';
    healthEndpoint: string;
    myXalaryV1: string;
    recruitmentEndpoint: string;
    myXalaryEndpoint: string;
    recruitmentApplicantEndpoint: string;
    azureStorageAccountKey: string;
    azureRecuritmentImgSAS: string;
    azureRecuritmentDocSAS: string;
    chatEndpoint: string;
    userInfoEndpoint: string;
    lmsApiEndpoint: string;
    learningEndPoint: string;
    talentManagementEndPoint: string;
    notificationEndPoint: string;
    grievanceEndpoint: string;
    performanceEndPoint: string;
    kpiEndpoint: string;
    confirmationEndpoint: string;
    googleLocation: string;
    dmsEndpoint: string;
    safetyIncidentEndpoint: string;
    isExitReady: boolean;
    meetingsEndpoint: string;
    esb: string;
    enps: string;
    pk: string;
    trackerApiEndpoint: string;
}

export interface whichRoute {
    prod?: boolean;
    test?: boolean;
    staging?: boolean;
}

export interface IROUTES extends whichRoute, Route {}

export interface SIDEBAR {
    icon?: string;
    title: string;
    id?: string;
    slug: string;
    feature: string;
    noHeader?: boolean;
    subMenu?: this[];
    isExternalLink?: boolean;
    staging?: boolean;
    prod?: boolean;
    test?: boolean;
    externalLink?: string;
}
export interface PFA {
    name: string;
}

export interface IAzureStorage {
    Blob: IBlobStorage;
}

export interface IBlobStorage {
    ExponentialRetryPolicyFilter: any;
    createBlobServiceWithSas: (uri: string, sharedAccessToken: string) => IBlobService;
}

export interface ISpeedSummary {
    on: (event: string, callback: () => void) => void;
    getCompletePercent: (len?: number) => string;
    getAverageSpeed: () => string;
    getSpeed: () => string;
}

export interface IBlobService {
    withFilter: (filter: any) => IBlobService;
    createBlockBlobFromBrowserFile: (
        container: string,
        filename: string,
        file: File,
        options: any,
        callback: (error: any, response: any) => void
    ) => ISpeedSummary;
    singleBlobPutThresholdInBytes: number;
}

export interface ISasToken {
    storageUri: string;
    storageAccessToken: string;
    container: string;
    filename: string;
}

export const BLOB_STORAGE_TOKEN = new InjectionToken<IBlobStorage>('BLOB_STORAGE_TOKEN');

export interface SalaryScheme {
    companyID: string;
    createdAt: string;
    items: any[];
    name: string;
    nhfEnabled: boolean;
    nsitEnabled: boolean;
    payrollBudgetEnabled: boolean;
    pensionEnabled: boolean;
    pensionType: any;
    taxEnabled: boolean;
    taxType: any;
    type: string;
    updatedAt: string;
    _id: string;
}

export interface Branches {
    billingID: string;
    branchAddress?: string;
    branchKeyName: string;
    branchName: string;
    _id: string;
}

export interface ChatContact {
    _id?: string;
    name: string;
    firstName: string;
    lastName: string;
    profileImgUrl: string;
    unReadCount: number;
    description?: string;
    lastMessage: string;
    userId: string;
    members?: string[];
    mobileMembers?: any[];
    path?: string;
    admin: iFeedActivity[];
    commentByOnlyAdmins?: boolean;
    lastMessageTime: string;
    category: string;
    online: boolean;
    connectionId?: string;
}
export interface ImagePreview {
    type: 'image' | 'post';
    post?: iFeedResponse;
    files?: IFile[];
    index: number;
}

export interface GroupContact extends ChatContact {
    isAdmin: boolean;
}
export interface Category {
    categoryName: string;
    categoryDescription: string;
}
export interface COMPETENCYCATEGORY {
    categoryName: string;
    categoryDescription: string;
    _id?: string;
}
export interface EvaluationPolicyModel {
    _id?: string;
    department?: {
        id: string;
        name: string;
    };
    cadre?: {
        id: string;
        name: string;
    };
    appliesTo: 'company' | 'cadre' | 'department';
    minimumScore: number;
    lengthBeforeConfirmationNumber: number;
    lengthBeforeConfirmationType: string;
    howLongBeforeRescheduleNumber: number;
    howLongBeforeRescheduleType: string;
    howLongBeforeSecondRescheduleNumber: number;
    howLongBeforeSecondRescheduleType: string;
    howLongBeforeThirdRescheduleNumber: number;
    howLongBeforeThirdRescheduleType: string;
    onConfirmation: string;
    onConfirmationFail: string;
    nextReviewDate: string;
    enforceLength: boolean;
    enforceOnConfirmation: boolean;
    enforceOnConfirmationFail: boolean;
    uploadEvidence: string;
}
export interface Question {
    question: any;
    questionWeight: number;
    range: number;
    _id: string;
}
export interface EvaluationQuestionModel {
    _id?: string;
    questionTitle: string;
    department: string;
    questions: Question[];
}
export interface LineManager {
    userId: string;
    name: string;
    department: string;
    branch: string;
    cadre: string;
    fid: number;
}

export interface ConfirmEmployeeModel {
    employee: any;
    selectedQuestion: any;
    lineManagers: LineManager[];
    selectedEmployee: any;
    _id?: string;
    confirmId?: string;
}

export interface ConfirmEmployeeModel {
    title: string;
    id?: string;
}

export interface EvaluationPolicyModel {
    title: string;
    id?: string;
}
export interface EvaluationQuestionModel {
    title: string;
    id?: string;
}
export interface Recommendation {
    _id?: string;
    score: {
        lower: number;
        upper: number;
    };
    workflow: IRoles[];
    title: string;
}

export type typeOfDate = 'hire-date' | 'fixed-date';
export type whoHasAccess = 'some_employees' | 'all_employees';

export interface IPeopleContent {
    id: string;
    name: string;
}
export interface IPeople {
    branch?: IPeopleContent[];
    department?: IPeopleContent[];
    cadre?: IPeopleContent[];
    status?: IPeopleContent[];
    jobRole?: IPeopleContent[];
}

export type frequency = {
    number: number;
    type: 'month' | 'year';
};
export interface IFeedback {
    reminderDate?: string;
    done?: boolean;
    auto?: boolean;
    scoreEmployee?: boolean;
    use100Mark?: boolean;
    hireDate?: frequency;
    frequency?: frequency;
    questions: FeedbackQuestionsCycle[];
}

export interface QuestionOptions {
    selected?: boolean;
    _id?: string;
    text: string;
    rank: number;
}
export interface IQuestionsCycle {
    question: {
        self: string;
        supervisor: string;
    };
    selfCanView: boolean;
    useForAnalysis: boolean;
    score?: number;
    finalScore?: number;
    isGoal: boolean;
    useToScoreEmployee?: boolean;
    answer?: string;
    type: 'text' | 'option';
    isDefault: boolean;
    selfOptions: Pick<QuestionOptions, 'text'>[];
    supervisorOptions: QuestionOptions[];
    weightedScore?: number;
    expectedPercentageScore?: number;
    actualPercentageScore?: number;
}

export interface FeedbackQuestionsCycle {
    question: string;
    selfCanView?: boolean;
    score?: number;
    useToScoreEmployee?: boolean;
    answer?: string;
    type: 'text' | 'option';
    options: QuestionOptions[];
}

export interface IFQuestionsCycle {
    question: string;
    selfCanView: boolean;
    useForAnalysis: boolean;
    score?: number;
    finalScore?: number;
    useToScoreEmployee?: boolean;
    answer?: string;
    type: 'text' | 'option';
    isDefault: boolean;
    selfOptions: QuestionOptions[];
    supervisorOptions: QuestionOptions[];
}

type cycleType = 'committee' | 'manager';
export interface Cycle {
    _id: string;
    type: cycleType;
    canEdit?: boolean;
    canEditQuestions?: boolean;
    typeOfDate: typeOfDate;
    title: string;
    whoHasAccess: whoHasAccess;
    people: IPeople;
    hasFeedback: boolean;
    feedback?: IFeedback;
    openDate?: string;
    hireDate?: frequency;
    closeDate: string;
    frequency: frequency;
    questions: IQuestionsCycle[];
    allowFileUpload: boolean;
    requireFileUpload: boolean;
    status: 'pending' | 'open';
    numberOfDays: number;
    currentRunningPeriod: currentRunningPeriod;
    periods: currentRunningPeriod[];
    useQuestionsWeight: boolean;
    employeeMustComment: boolean;
    employeeCommentTimeOut?: number;
    use100Mark?: boolean;
    useGoalsForAssessment?: boolean;
    perspectives?: string[];
    goalTotalScore?: number;
}

export interface currentRunningPeriod {
    openDate: string;
    closeDate: string;
}

export type People = {
    title: string;
    typeOfDate: typeOfDate;
    whoHasAccess: whoHasAccess;
    people?: IPeople;
};

export type Type = {
    type: realType;
};

export type Feedback = {
    hasFeedback: boolean;
    feedback: IFeedback;
};

export type Assessment = {
    openDate?: string;
    hireDate?: frequency;
    closeDate?: string;
    frequency?: frequency;
    questions: IQuestionsCycle[];
    allowFileUpload: boolean;
    requireFileUpload: boolean;
    useQuestionsWeight: boolean;
    employeeMustComment: boolean;
    employeeCommentTimeOut?: number;
    use100Mark?: boolean;
    goalTotalScore?: number;
    perspectives?: string[];
    useGoalsForAssessment?: boolean;
};

export type realType = 'committee' | 'manager';

export interface FilledAssessment extends Supervisors {
    _id: string;
    allowFileUpload: boolean;
    type: realType;
    selfSubmitted: boolean;
    use100Mark?: boolean;
    totalScore?: {
        actualPercentageScore: number;
        weightedScore: number;
        expectedPercentageScore: number;
        finalScore: number;
    };
    employeeTotalScore: {
        actualPercentageScore: number;
        weightedScore: number;
        expectedPercentageScore: number;
        finalScore: number;
    };
    selfRating: {
        rate: string;
        description: string;
    };
    finalRating: {
        rate: string;
        description: string;
    };
    self: userInfo;
    supervisors: Supervisors[];
    selfQuest: IFQuestionsCycle[];
    selfView: string;
    score: number;
    selfSubmittedWhen: string;
    recommendation: any;
    selfFiles: any[];
    label: string;
    finalRecommendation: any;
    finalApprovalBy: userInfo;
    finalApprovedDate: string;
    finalSubmitted: boolean;
    finalFiles: any[];
    supervisorSubmissionSaved?: boolean;
    employeeTimeOut?: {
        start: string;
        end: string;
    };
    employeeCommented?: boolean;
    employeeComment?: string;
    employeeCommentFiles?: any[];
    useQuestionsWeight?: boolean;
    supervisorComment: string;
    finalComment: string;
}

export interface Supervisors {
    supervisor: userInfo;
    totalScore?: {
        actualPercentageScore: number;
        weightedScore: number;
        expectedPercentageScore: number;
        finalScore: number;
    };
    supervisorSubmitted: boolean;
    supervisorQuest: IFQuestionsCycle[];
    supervisorSubmittedWhen: string;
    recommendation: any;
    supervisorFiles: any[];
    rating: {
        rate: string;
        description: string;
    };
}
export interface Workflows {
    roleName: string;
    id: string;
    status: 'invalid' | 'waiting' | 'completed' | 'pending';
    employee?: userInfo;
    by?: userInfo;
    approver: userInfo[];
    reason: string;
}
export interface Recommendations {
    id: string;
    workflow: Workflows[];
}

export interface careerTrackForm {
    _id?: string;
    name: string;
    type: 'Cadre' | 'JobRole';
    list: {
        _id: string;
        name: string;
        competency: { title: string; rating: number }[];
        courses: { _id: string; title: string; type: string }[];
        qualifications: { title: string; requirement: string }[];
    }[];
    progression_rule: string[];
    views?: number;
    description: string;
}
export interface InterviewForm {
    _id?: string;
    title: string;
    JobOpportunity?: {
        _id?: string;
        status?: string;
        jobRole?: { [key: string]: string };
    };
    type: 'Virtual' | 'On-site';
    date: string;
    feedback_due_date: string;
    time: string;
    location: string;
    duration: number;
    Interviewers: {
        _id?: string | null;
        email: string;
        fid?: number | null;
        fullName: string;
        profileImgUrl?: string;
        isExternal?: boolean;
    }[];
    questions: { question: string; weight: number }[];
}
export interface TalentReviewQuestionForm {
    _id?: string;
    title: string;
    department: { _id: string; name: string };
    reports?: {
        data: any[];
        paging: {
            totalCount: number;
            totalPages: number;
            currentPage: number;
        };
    };
    categories: {
        _id?: string;
        scores?: any[];
        title: string;
        questions: {
            _id?: string;
            scores?: any[];
            average?: number;
            title: string;
            options: {
                _id?: string;
                title: string;
                weight: number;
                selected?: boolean;
            }[];
        }[];
    }[];
}
export interface TalentReviewForm {
    _id?: string;
    talents: {
        _id: string;
        fullName: string;
        email: string;
        profileImgUrl: string;
        fid: string;
        jobRole?: {
            _id: string;
            name: string;
        };
        department?: {
            _id: string;
            name: string;
        };
        performance?: {
            _id: string;
            score: string;
        };
    }[];
    Question: string | { question: string; weight: number }[];
    evaluators: {
        _id: string;
        fullName: string;
        email: string;
        profileImgUrl: string;
        fid: string;
        jobRole?: {
            _id: string;
            name: string;
        };
        department?: {
            _id: string;
            name: string;
        };
    }[];
    due_date: Date;
    rating?: string;
}
export interface CycleForm {
    people?: {
        title: string;
        typeOfDate: typeOfDate;
        whoHasAccess: whoHasAccess;
        people: IPeople;
    };
    feedback?: {
        hasFeedback: boolean;
        feedback: IFeedback;
    };
    assessment?: {
        openDate?: string;
        hireDate?: frequency;
        frequency: frequency;
        questions: IQuestionsCycle[];
    };
}
type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];
export interface ICycleMenu extends MenuItem {
    data?: People | Feedback | Assessment | Type;
    valid?: boolean;
}

export interface IFiles {
    name: string;
    url: string;
    type: string;
}

export interface IMilestone {
    title: string;
    checked: boolean;
}

export interface PComments {
    rawText?: string;
    mentions: {
        id: string;
        name: string;
        email: string;
    }[];
    _id: string;
    text: string;
    user: userModel;
    date: string;
}
export interface Goals {
    dueDate: string;
    employees: userModel[];
    creator: userModel;
    comments: PComments[];
    files: IFiles[];
    status: 'in-progress' | 'completed';
    owner: userModel;
    shared: userModel[];
    progress: number;
    milestones: IMilestone[];
    _id: string;
    objective: string;
    createdOn: string;
    description: string;
    tags?: string[];
    perspective?: string;
    goalType: 'normal' | 'assessment';
    weight?: number;
    score?: number[];
    kpi?: string;
}

export interface customReport {
    _id?: string;
    title?: string;
    name: string;
    model?: string;
    path?: string;
    host?: string;
    fullUrl?: string;
    query: any;
    xTitle: string;
    yTitle: string;
    chart: ChartConfiguration;
}

export interface zipFile {
    name: string;
    type: 'file' | 'folder';
    blob?: any;
    files?: zipFile[];
}
export interface IAnalytics {
    companyId: string;
    totalCriticalRole: number;
    totalTalents: number;
    vacantRoles: number;
}
export interface ICriticalRole {
    jobRole: {
        _id: string;
        name: string;
    };
    maxVacantTime: {
        length: number;
        date: string;
    };
    _id: string;
    companyId: string;
    talentRequired: number;
    description: string;
    createdAt: Date;
    updatedAt: string;
}

export interface ICreateCriticalRole {
    jobRole: {
        _id: string;
        name: string;
    };
    maxVacantTime: {
        length: number;
        date: string;
    };
    talentRequired: number;
    description: string;
}

export interface IUpdateCriticalRole {
    jobRole: {
        _id: string;
        name: string;
    };
    maxVacantTime?: {
        length?: number;
        date?: string;
    };
    talentRequired?: number;
    description?: string;
}

export interface ICreateSuccessor {
    criticalRoleID: string;
    successor: {
        employeeName: string;
        employeeDepartment: string;
        employeeId: string;
        employeeEmail: string;
        employeeHireDate: Date;
        employeeFID: string;
        profilePics: string;
        employeeJobRole: {
            id: string;
            name: string;
        };
    };
}

export interface IAssignMentor {
    id: string;
    name: string;
    _id: string;
    quickNote: string;
}
export interface IAllCriticalRoles {
    paging: Ipaging;
    criticalRoles: ICriticalRole[];
}

interface Ipaging {
    totalCount: number;
    totalPages: number;
    currentPage: number;
    nextPage: number | null;
    prevPage: number | null;
}

export interface IAllSuccessors {
    paging: Ipaging;
    successors: ISuccessor[];
}

export interface ISuccessor {
    _id: string;
    mentor?: {
        id: string;
        name: string;
        _id: string;
        quickNote: string;
    };
    goals: Goals[];
    training: any[];
    competency:
        | {
              name: string;
              employee_rating: number;
              required_rating: any;
              meets_requirements: boolean;
          }[]
        | null
        | undefined;
    successor: {
        _id: string;
        fullName: string;
        email: string;
        profileImgUrl: string;
        fid: number;
        jobRole: {
            _id: string;
            name: string;
        };
        department: {
            _id: string;
            name: string;
        };
        employeeHireDate: string;
    };
    criticalRoleID: string;
    companyId: string;
}

export interface IAddComment {
    commentBy: {
        _id: string;
        fullName: string;
        email?: string;
        profileImgUrl?: string;
    };
    sentTo: {
        _id: string;
        fullName: string;
        email?: string;
        profileImgUrl?: string;
    };
    comment: string;
    date: Date;
}

export enum AlertRemove {
    REMOVE_SUCCESSOR = 'REMOVE_SUCCESSOR',
    REMOVE_CRITICAL_ROLE = 'REMOVE_CRITICAL_ROLE',
}

export interface PENSION_TYPE {
    _id?: string;
    title: string;
    employer: number;
    employee: number;
}

export interface Attendance {
    selectTeam: any;
    absentDeductable: any;
    // resumptionTime: any;
    // closingTime: any;
    resumptionTime: {
        monday: any;
        tuesday: any;
        wednesday: any;
        thursday: any;
        friday: any;
        saturday: any;
        sunday: any;
    };
    closingTime: {
        monday: any;
        tuesday: any;
        wednesday: any;
        thursday: any;
        friday: any;
        saturday: any;
        sunday: any;
    };
    enablePenalty: boolean;
    holidays: any;
    lateDeductable: any;
    lateGraceTime: number;
    lateTime: number;
    veryLateDeductable: any;
    veryLateTime: number;
    workdays: Object;
    latePenalty: any;
    veryLatePenalty: any;
    absentPenalty: any;
}

export interface AttendanceDevice {
    deviceName: string;
    serialNum: string;
    selectLocation: string;
}

export interface IncidentType {
    _id: string;
    title: string;
    icon: {
        url: string;
        name: string;
    };
    incidentManager: {
        name: string;
        email: string;
    };
}

export interface IncidentReport {
    _id: string;
    incidentReportID: string;
    title: string;
    icon: {
        url: string;
        name: string;
    };
    reporter: {
        locationName: string;
        locationType: string;
        dateOfIncident: string;
        timeOfIncident: string;
        supportedFile: string;
        specificLocation: string;
    };
    summary: {
        specificIncident: string;
        description: string;
        immediateAction: string;
    };
    incidentManager: {
        comment: string;
    };
    finalComment: string;
    reportStatus: string;
}

export interface SignUpModel {
    companyName: string;
    userEmail: string;
    contactSource: string;
    firstName: string;
    lastName: string;
    phone: string;
    password: string;
    sla?: boolean;
}

export interface ActionSteps {
    title: string;
}

export interface Survey {
    title: string;
    isTemplate: boolean;
    question: [];
}
export enum EVENT_TYPE {
    COMPANY_EVENT = 'COMPANY_EVENT',
    PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY',
    EMPLOYEE_ANNIVERSARY = 'EMPLOYEE_ANNIVERSARY',
}

export interface IEvent {
    _id: string;
    name: string;
    eventType: EVENT_TYPE;
    eventTemplateID: string;
    description: string;
    location?: string;
    end_date?: string;
    end_time?: string;
    notification_dateTime?: string;
    start_date?: string;
    start_time?: string;
}

export interface IEventTemplate {
    _id: string;
    name: string;
    body: string;
}
export interface AnniversaryTemplates {
    _id?: string;
    dateOfBirth?: string;
    employeeHireDate?: string;
    promotionDate?: IEvent;
}

export interface IOpenEvent extends Partial<IEvent> {
    eventType: EVENT_TYPE;
}

export type SchemeItem = {
    name: FormControl<string | null>;
    value: FormControl<number | null>;
    pensionEnabled: FormControl<boolean | null>;
    taxEnabled: FormControl<boolean | null>;
    schedule: FormControl<'monthly' | 'quarterly' | 'annually' | null>;
    derivative: FormControl<boolean | null>;
    formula: FormControl<string | null>;
    label: FormControl<string | null>;
    selected: FormControl<boolean | null>;
    isDefault: FormControl<boolean | null>;
    error: FormControl<string | null>;
    meta?: FormControl<any | null>;
};

export type Scheme = {
    taxEnabled: FormControl<boolean | null>;
    taxType: FormControl<string | null>;
    pensionEnabled: FormControl<boolean | null>;
    pensionType: FormControl<string | null>;
    items: FormArray<FormGroup<SchemeItem> | never>;
    nhfEnabled: FormControl<boolean | null>;
    nhisEnabled: FormControl<boolean | null>;
    nsitfEnabled: FormControl<boolean | null>;
};

export type SchemeItemNormal = {
    name: string;
    value: number;
    pensionEnabled: boolean;
    taxEnabled: boolean;
    schedule: 'monthly' | 'quarterly' | 'bi-annually' | 'annually';
    derivative: boolean;
    formula: string;
    label: string;
    selected: boolean;
    isDefault: boolean;
    error: string;
    meta?: any;
};

export type SchemeNormal = {
    taxEnabled: boolean;
    taxType: {
        title: string;
        value: number;
    };
    pensionEnabled: boolean;
    pensionType: {
        name?: string;
        title?: string;
        employer: number;
        employee: number;
    };
    items: SchemeItemNormal[];
    nhfEnabled: boolean;
    nhisEnabled: boolean;
    nsitfEnabled: boolean;
};
