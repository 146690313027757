import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatContact } from 'src/app/interfaces/general';
import { OnlineUsers } from 'src/app/modules/chats-v2/services/chat-socket.service';
import { ISettings } from 'src/app/shared/models/interfaces';
import * as fromActions from '../actions';

//difine state blueprint
export interface IAppState {
    initialized: boolean;
    token: string;
    notification: any[];
    contacts: { [key: string]: any };
    userInfo: { [key: string]: any };
    postId: string;
    combos: { [key: string]: any };
    chatContacts: ChatContact[];
    activeChats: ChatContact[];
    onlineUsers: OnlineUsers[];
    unReadCount: any;
    chatSettings?: ISettings;
}

export const APP = 'myXalary_v2';

// initial state
export const initialAppState: IAppState = {
    initialized: false,
    token: '',
    notification: [],
    contacts: {},
    userInfo: {},
    postId: '',
    combos: {},
    chatContacts: [],
    activeChats: [],
    unReadCount: {},
    onlineUsers: [],
    chatSettings: undefined,
};

export function appReducer(state: IAppState = initialAppState, action: any): IAppState {
    switch (action.type) {
        case fromActions.AppActionEnums.INIT_APP: {
            let tokenInStorage = localStorage.getItem('APP_TOKEN');
            let tokenFromUrl = action.payload.token;
            if (tokenFromUrl) {
                localStorage.setItem('APP_TOKEN', action.payload.token);
            }
            localStorage.removeItem('LMS_TOKEN');
            return {
                ...state,
                token: tokenFromUrl || tokenInStorage,
                initialized: true,
            };
        }
        case fromActions.AppActionEnums.SET_NOTIFICATION: {
            return {
                ...state,
                notification: action.payload,
            };
        }
        case fromActions.AppActionEnums.SET_CONTACTS: {
            return {
                ...state,
                contacts: action.payload,
            };
        }
        case fromActions.AppActionEnums.SET_USER_INFO: {
            return {
                ...state,
                userInfo: action.payload,
            };
        }
        case fromActions.AppActionEnums.SET_POST: {
            return {
                ...state,
                postId: action.payload,
            };
        }
        case fromActions.AppActionEnums.SET_COMBO: {
            return {
                ...state,
                combos: action.payload,
            };
        }
        case fromActions.AppActionEnums.SET_CHAT_CONTACT: {
            return {
                ...state,
                chatContacts: action.payload,
            };
        }
        case fromActions.AppActionEnums.SET_ACTIVE_CHATS: {
            return {
                ...state,
                activeChats: action.payload,
            };
        }
        case fromActions.AppActionEnums.SET_ONLINE_USERS: {
            return {
                ...state,
                onlineUsers: action.payload,
            };
        }
        case fromActions.AppActionEnums.SET_UNREAD_COUNT: {
            return {
                ...state,
                unReadCount: action.payload,
            };
        }
        case fromActions.AppActionEnums.SET_CHAT_SETTING: {
            return {
                ...state,
                chatSettings: action.payload,
            };
        }
        default:
            return state;
    }
}

export interface IAppStateGeneral {
    app: IAppState;
}
// selectors
const selectFeature = createFeatureSelector<IAppStateGeneral>(APP);

export const initialized = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.initialized;
});
export const notification = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.notification;
});

export const globalContacts = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.contacts;
});

export const selectBearerToken = createSelector(
    selectFeature,
    (state: IAppStateGeneral) => state.app.token
);

export const userInfo = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.userInfo;
});
export const combos = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.combos;
});
export const postId = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.postId;
});

export const chatContact = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.chatContacts;
});

export const activeChats = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.activeChats;
});
export const unReadCount = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.unReadCount;
});
export const onlineUsers = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.onlineUsers;
});

export const chatSettings = createSelector(selectFeature, (state: IAppStateGeneral) => {
    return state.app.chatSettings;
});
