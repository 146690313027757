import { OnInit, Component, Input } from '@angular/core';
import { LegendItem as Legend } from 'chart.js';
import { chartOption } from 'src/app/interfaces/general';

export interface LegendItem extends Legend {
  fillStyle: string;
  label: string;
  percentage: string;
  text: string;
}

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent implements OnInit {
  legendData: LegendItem[] = [];
  @Input() chartOption!: chartOption;

  constructor() { }

  ngOnInit(): void {
    const { labels, datasets } = this.chartOption.options.data;
    const backgroundColor = datasets?.[0]?.backgroundColor;
    const data = ((datasets[0]?.data as (number | null)[]) ?? []).filter(
      (val): val is number => typeof val === 'number'
    );

    const total = data.reduce((sum, val) => sum + val, 0);

    if (labels && Array.isArray(backgroundColor)) {
      this.legendData = (labels as string[]).map((label, i) => {
        const percentage = total > 0 ? ((data[i] / total) * 100).toFixed(0) : '0';
        const text =
          label.length > 15 ? `${label.substring(0, 15)}...` : label.trim();
        return {
          fillStyle: backgroundColor[i],
          label,
          percentage,
          text,
        };
      });
    }
  }

}
