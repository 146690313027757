<div class="position-relative">
    <a
        class="position-absolute top-0 end-0 text-primary p-4 h5"
        routerLink="/register"
        style="top: 0; right: 0"
    >
        Sign Up
        <i class="fas fa-solid fa-arrow-right"></i>
    </a>
</div>

<div class="container-wrap">
    <img
        style="width: 150px"
        src="../../../../assets/images/notch-hr.png"
        class="img-fluid"
        alt="NotchHR Logo"
    />

    <div style="width: 35%" class="row justify-content-center">
        <div class="col-md-12">
            <div class="wrap d-md-flex">
                <div class="login-wrap p-4 p-md-5">
                    <p class="mb-4 h4 text-primary text-center">Sign In</p>

                    <form (ngSubmit)="handleLogin()" [formGroup]="loginForm" autocomplete="on">
                        <div class="form-group form-style w-100 mb-3">
                            <label class="label font-weight-bold" for="email-field">Email</label>
                            <input
                                id="email-field"
                                class="form-control"
                                type="email"
                                placeholder="Email"
                                formControlName="email"
                            />
                            <div
                                class="mt-2 text-small text-danger"
                                *ngIf="
                                    loginForm.get('email').touched && loginForm.get('email').errors
                                "
                            >
                                <div *ngIf="loginForm.get('email').errors.required">
                                    Email is required
                                </div>
                                <div *ngIf="loginForm.get('email').errors.pattern">
                                    Please enter a valid email address
                                </div>
                            </div>
                        </div>
                        <div class="form-group form-style w-100">
                            <label class="font-weight-bold" for="title"
                                >Password <span class="text-danger">*</span></label
                            >
                            <p-password
                                [toggleMask]="true"
                                formControlName="password"
                                [feedback]="false"
                                inputStyleClass="form-control "
                            ></p-password>
                        </div>
                        <h3 class="text-danger text-center" *ngIf="loginError !== ''">
                            {{ loginError }}
                        </h3>
                        <div class="form-group form-style">
                            <button
                                type="submit"
                                [disabled]="loginForm.invalid && loginForm.touched"
                                class="form-control btn btn-primary rounded"
                                style="
                                    padding-top: 12px !important;
                                    padding-bottom: 12px !important;
                                    font-size: 15px !important;
                                "
                            >
                                Sign In
                            </button>
                        </div>
                        <div class="form-group form-style d-md-flex">
                            <div class="w-50 text-left">
                                <label class="checkbox-wrap checkbox-primary mb-0">
                                    Remember Me
                                    <input type="checkbox" formControlName="rememberMe" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="w-50 text-md-right">
                                <a [routerLink]="['/forgot-password']" class="text-primary">
                                    Forgot Password
                                </a>
                            </div>
                        </div>
                    </form>

                    <div class="text-center">
                        <a (click)="resend()" class="text-primary text-lg" role="button">
                            Resend Confirmation email
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
