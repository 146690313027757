import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxEditorModule } from 'ngx-editor';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { StarRatingModule } from 'angular-star-rating';
import { LottieModule } from 'ngx-lottie';

import { CoreDirectivesModule } from 'src/@core/scss/base/directives/directives';
import { MatStyleModule } from '../mat-style.module';
import { SettingsService } from '../modules/recruitment/settings-component/service/settings.service';
import { Analytics } from '../services/analytics/analytics.service';
import { Chart } from '../services/chart/chart.service';
import { CustomDateFormatter } from '../utils/calendar';
import * as fromComponents from './components';
import { SnackbarModule } from './modals/snackbar/snackbar.module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ErrorIntercept } from '../interceptors/error.interceptor';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { SharedApiService } from './services/api.service';
import { PrimeStyleModule } from '../prime-ng.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { EllipsesPipe } from './pipes/ellipses.pipe';
import { CsvServicesService } from './services/csv-services.service';
import { NumberInputPipe } from './pipes/number-input.pipe';
import { Angular4PaystackModule } from 'angular4-paystack';
import { environment } from 'src/environments/environment';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DynamicComponentDirective } from './directives/loadInput';
import { FormulaScanner } from '../modules/payment/services/scanner.service';
import { FeatureFlagDirective } from './directives/feature-flags.directive';
import { TrimInputDirective } from './directives/app-trim.directive';
import { TranslatePipe } from './pipes/translatePipe.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

export const playerFactory = (): Promise<any> =>
    import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');

const sharedModules: any[] = [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule,
    NgbModule,
    CoreDirectivesModule,
    MatStyleModule,
    AutocompleteLibModule,
    SnackbarModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxEditorModule,
    StarRatingModule.forRoot(),
    PrimeStyleModule,
    NgxChartsModule,
    MatTooltipModule,
];

@NgModule({
    declarations: [
        ...fromComponents.components,
        SafeUrlPipe,
        SafeHtmlPipe,
        EllipsesPipe,
        NumberInputPipe,
        DynamicComponentDirective,
        FeatureFlagDirective,
        TrimInputDirective,
        TranslatePipe,
    ],
    providers: [
        Analytics,
        Chart,
        DatePipe,
        SettingsService,
        TitleCasePipe,
        CsvServicesService,
        SharedApiService,
        FormulaScanner,
        { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorIntercept,
            multi: true,
        },
    ],
    imports: [
        ...sharedModules,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        NgbPopoverModule,
        MatButtonToggleModule,
        InfiniteScrollModule,
        Angular4PaystackModule.forRoot(environment.pk),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        ...fromComponents.components,
        sharedModules,
        MatButtonToggleModule,
        SafeUrlPipe,
        SafeHtmlPipe,
        EllipsesPipe,
        NumberInputPipe,
        DynamicComponentDirective,
        FeatureFlagDirective,
        TrimInputDirective,
        TranslatePipe,
    ],
})
export class SharedModule {}
