<div style="width: 100px" class="">
    <ng-select
        [placeholder]="'Country'"
        bindLabel="name"
        [items]="countries"
        class="ng-input"
        [(ngModel)]="country"
        (change)="onInputChange($event)"
        [clearable]="false"
    >
    </ng-select>
</div>
