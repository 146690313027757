<div class="NgxEditor__Wrapper" *ngIf="editor" [ngClass]="customStyles">
    <ngx-editor-menu *ngIf="!disableMenu" [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
    <ngx-editor
        style="width: 100%"
        [editor]="editor"
        [placeholder]="placeholder"
        name="htmlContent"
        [(ngModel)]="htmlContent"
        (focusOut)="handleEmit()"
        (keyup.enter)="handleKeyEnter($event)"
        [disabled]="disableEditor"
        (ngModelChange)="handleEmitV2($event)"
    >
    </ngx-editor>
</div>
