import { Component, Input, OnInit } from '@angular/core';
import { chartOption } from '../../../../interfaces/general';
import { Chart } from 'chart.js';
import CPD from 'chartjs-plugin-datalabels';
import { KPICARD, KPIData } from 'src/app/shared/models/interfaces';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { constants } from '../../constants';

@Component({
    selector: 'app-donut-card',
    templateUrl: './donut-card.component.html',
    styleUrls: ['./donut-card.component.scss', '../../shared.scss'],
})
export class DonutCardComponent implements OnInit {
    chartOptions!: chartOption;
    @Input()
    name!: string;
    @Input()
    config!: KPICARD;

    loaderOptions = constants.loaderOptions;
    years: any[] = [];
    @Input()
    loading: boolean = true;
    typeSelected!: any;
    url!: string;

    kpiData!: KPIData;

    // endpoint = endpoints.KPI_BE.V1;
    reload = new Subject();
    constructor(private http: HttpClient) {}

    ngOnInit(): void {
        const currentYear = new Date().getFullYear();
        this.typeSelected = currentYear;
        for (let i = 10; i >= 0; i--) {
            const value = currentYear - i;
            this.years.push({
                label: `${value}`,
                value,
            });
        }
        this.fetchData();
    }

    async fetchData() {
        this.loading = true;
        const result: any = await this.http
            .get(`${this.config.url}${this.typeSelected}`, { headers: { nospinner: 'true' } })
            .toPromise();
        this.loading = false;

        this.kpiData = result.data;
        let labels = this.config?.labels || [];
        let data = this.kpiData.data || [];
        let backgroundColor = ['#440f7c', '#d62196'];
        const xy = this.kpiData.data;
        if (xy && typeof xy[0] === 'object') {
            labels = xy.map((v) => v.x);
            data = xy.map((v) => v.y);
            backgroundColor = this.getColors(xy.length);
        }
        this.chartOptions = {
            id: this.name,
            height: '300px',
            width: '100%',
            options: {
                type: 'doughnut',
                plugins: [
                    CPD,
                    {
                        id: 'backgroundCircle',
                        beforeDatasetsDraw(chart, args, options) {
                            const ctx = chart.ctx;
                            ctx.save();
                            const data: any = <any>chart.getDatasetMeta(0).data[0];
                            const xCoor = data.x;
                            const yCoor = data.y;
                            const innerRadius = data.innerRadius;
                            const outerRadius = data.outerRadius;
                            const width = outerRadius - innerRadius;
                            const angle = Math.PI / 180;

                            ctx.beginPath();
                            ctx.lineWidth = width;
                            ctx.strokeStyle = 'grey';
                            ctx.arc(xCoor, yCoor, outerRadius - width / 2, 0, angle * 360, false);
                            ctx.stroke();
                        },
                    },
                ],
                data: {
                    labels,
                    datasets: [
                        {
                            label: '',
                            data,
                            backgroundColor,
                        },
                    ],
                },
                options: {
                    responsive: false,
                    plugins: {
                        datalabels: {
                            anchor: 'start',
                            formatter: function (value, context) {
                                return value + '%';
                            },
                            clip: true,
                            labels: {
                                title: {
                                    font: {
                                        family: 'Poppins',
                                        size: 14,
                                        style: 'normal',
                                        weight: 600,
                                        lineHeight: 'normal',
                                    },
                                },
                            },
                            align: 'start',
                            color: ['#440F7C', '#D62196'],
                        },
                        legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                                usePointStyle: true,
                                pointStyle: 'circle',
                            },
                        },
                    },
                    scales: {
                        x: {
                            ticks: {
                                display: false,
                            },

                            grid: {
                                display: false,
                                drawBorder: false,
                            },
                            title: {
                                display: false,
                            },
                            min: 0,
                        },
                        y: {
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false,
                                drawBorder: false,
                            },
                            title: {
                                display: false,
                            },
                            min: 0,
                        },
                    },
                },
            },
        };
    }
    onSelectType(event: any) {
        this.fetchData();
    }

    getColors(length: number) {
        let letters = '0123456789ABCDEF';
        let colors = [];
        while (length--) {
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            colors.push(color);
        }
        return colors;
    }
}
