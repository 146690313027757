import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { statesList } from '../shared/statesList';

interface UpdateEmployeeProps {
    id: string;
    payload: Record<string, string>;
}

@Injectable({
    providedIn: 'root',
})
export class EmployeeModuleServiceService {
    user: any;
    employees$: BehaviorSubject<any> = new BehaviorSubject([]);
    employeesData$: BehaviorSubject<any> = new BehaviorSubject({});
    taxAuthoritys = statesList;
    utils$: BehaviorSubject<any> = new BehaviorSubject<any>({
        countries: [],
        timezones: [],
        pfas: [],
    });

    constructor(private http: HttpClient) {}
    endpointVersion = {
        currentVersion: '/v1/',
    };

    appToken() {
        return localStorage.getItem('APP_TOKEN');
    }
    companyID() {
        return localStorage.getItem('CID');
    }

    generateAvatar(name: string) {
        // const image = `https://api.dicebear.com/5.x/open-peeps/svg?seed=${name}`; dicebear avatar
        const image = `https://ui-avatars.com/api/?background=F7D3EA&color=D62196&name=${name}`;
        return image;
    }

    getEmployees = (params?: any) => {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/listEmployees`,
                {
                    params,
                }
            )
            .toPromise();
    };

    downloadEmployees(type: number) {
        //@ts-ignore
        return this.http
            .post(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }users/downloadEmployees`,
                { type: type },
                { observe: 'response', responseType: 'blob' }
            )
            .toPromise();
    }

    getLeaveData = () => {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }leavemgt/viewAllLeaveApplications`
            )
            .toPromise();
    };

    checkEmployeeExistence(employeeId: string): Observable<boolean> {
        const url = `${
            environment.myXalaryEndpoint + this.endpointVersion.currentVersion
        }employees/findEmployee/${employeeId}`;
        return this.http.get<boolean>(url);
    }

    eachEmployeeProfile = (data: any) => {
        return this.http.get<any>(
            `${
                environment.myXalaryEndpoint + this.endpointVersion.currentVersion
            }employees/viewEmployee/${data}`
        );
    }; 

    getEmployeeLeaveStatus(employeeID: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/leavemgt/onLeaveStat/${employeeID}`);
    }

    employeeTrainingHours = (
        id: string,
        { type, value, year }: { type: string; value: string; year: number }
    ) => {
        return this.http.get<any>(
            `${environment.learningEndPoint}/trainingHours/employee/${id}?type=${type}&value=${value}&year=${year}`
        );
    };

    resendEmployeeInvite(data: any) {
        return this.http.post<any>(
            `${
                environment.myXalaryEndpoint + this.endpointVersion.currentVersion
            }employees/resendInvite`,
            data
        );
    }

    fetchPayslips(data: any) {
        return this.http.get<any>(
            `${
                environment.myXalaryEndpoint + this.endpointVersion.currentVersion
            }payroll/payslips/get/${data}`
        );
    }

    // Bonus API

    // /profileLogs/:employeeID
    fetchEmployeeLogs(id: any) {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/profileLogs/${id}`
            )
            .toPromise();
    }

    // Deductions API

    // Teams Services

    // Tax relief services

    // Branch APIs

    //Department Services

    // Employee Profile & Invite
    activateEmployees(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/activateEmployees`,
                data
            )
            .toPromise();
    }
    confirmBulkEmployees(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/confirmEmployees`,
                data
            )
            .toPromise();
    }
    deActivateEmployees(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/deactivateEmployees`,
                data
            )
            .toPromise();
    }

    terminateEmployee(employeeID: any, data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/terminateEmployee/${employeeID}`,
                data
            )
            .toPromise();
    }

    revertTermination(employeeID: any, data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/revertTermination/${employeeID}`,
                data
            )
            .toPromise();
    }

    patchEmployee(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/updateEmployee/${data._id}`,
                data
            )
            .toPromise();
    }

    nonWorkflowPatchEmployee(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/non-workflow-updateEmployee/${data._id}`,
                data
            )
            .toPromise();
    }

    updateEmployeePersonalInfo({ id, payload }: UpdateEmployeeProps) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/update-employee-information/${id}`,
                payload
            )
            .toPromise();
    }

    updateEmployeeCompensationInfo({ id, payload }: UpdateEmployeeProps) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/update-employee-compensation/${id}`,
                payload
            )
            .toPromise();
    }

    updateEmployeeBankInfo({ id, payload }: UpdateEmployeeProps) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/update-employee-bank-information/${id}`,
                payload
            )
            .toPromise();
    }

    inviteEmployee(data: any) {
        return this.http
            .post<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/addEmployee`,
                data
            )
            .toPromise();
    }
    uploadEmployeesInvite(data: any) {
        return this.http
            .post<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/uploadEmployees`,
                data
            )
            .toPromise();
    }

    // Cadre Service

    // Payroll Service

    getSalarySchemes() {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }/salaryscheme/get`
            )
            .toPromise();
    }

    getSalaryScheme(id: any) {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }/salaryscheme/get/${id}`
            )
            .toPromise();
    }

    addEmployeesToSalaryScheme(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }salaryscheme/employees/update`,
                data
            )
            .toPromise();
    }

    // Utils pfa, countries & timezone

    getPfas() {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }util/find-pfas`
            )
            .toPromise();
    }

    getCountry() {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }util/find-countrys`
            )
            .toPromise();
    }

    // Bank Api
    public getBanks() {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }util/find-banks`
            )
            .toPromise();
    }

    public setupBankAccount(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/bankaccount/setup/${data._id}`,
                data
            )
            .toPromise();
    }
    public setupMultipleBankAccounts(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/bankaccount/multiple/setup`,
                data
            )
            .toPromise();
    }
    public resolveBankAccount(account: any, bank: any) {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/bankaccount/resolve/${account}/${bank}`
            )
            .toPromise();
    }
    public resolveBankAccounts(data: any) {
        return this.http
            .post<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/bankaccount/multiple/resolve`,
                data
            )
            .toPromise();
    }
    public confirmBankAccounts(data: any) {
        return this.http
            .post<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/bankaccount/multiple/confirm`,
                data
            )
            .toPromise();
    }

    //Requisition Services

    fetchWorktools() {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }/worktool/requests`
            )
            .toPromise();
    }

    fetchConsumables() {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }/worktool/requests?worktoolType=consumables`
            )
            .toPromise();
    }

    fetchCashAdvance() {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }/requisition/cash-advance`
            )
            .toPromise();
    }

    fetchCashReimbursement() {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }/requisition/cash-reimbursement`
            )
            .toPromise();
    }

    fetchOtherRequest() {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }/requisition/other/requests`
            )
            .toPromise();
    }

    deleteOtherRequest(id: any) {
        return this.http.delete(
            `${
                environment.myXalaryEndpoint + this.endpointVersion.currentVersion
            }bonuses/delete/${id}`
        );
    }
    // Recruitment API plugin
    // jobs/recruited-employees/?status=onboarded&page=1&limit=20

    getOnboardedEmployee() {
        return this.http
            .get<any>(
                `${
                    environment.recruitmentEndpoint + this.endpointVersion.currentVersion
                }/jobs/recruited-employees/?status=pending&page=1&limit=1000000`
            )
            .toPromise();
    }

    // Reason for setting limit to 1000000 is due to the fact that the data is paginated from the backend and for analytical reasons we need all records at once
    // Of which the backend did not provision. In case the backend provision kindly reset the limit to what it is supposed to be according to the API documentation

    //Billing Service

    getBillings() {
        return this.http
            .get<any>(
                `${environment.myXalaryEndpoint + this.endpointVersion.currentVersion}billing/get`
            )
            .toPromise();
    }

    /** Attendance Metrics */
    getAttendanceMetrics(data: any) {
        return this.http.get<any>(
            `${
                environment.myXalaryEndpoint + this.endpointVersion.currentVersion
            }attendance/daily-hours-worked/${data.employeeID}/${data.month}/${data.year}`
        );
    }

    /** Employee Manager-Subordinate */
    assignManager(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/assignManager/${data.employeeID}/${data.managerID}`,
                ''
            )
            .toPromise();
    }

    assignSubordinate(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/assignSubordinates/${data.employeeID}`,
                data
            )
            .toPromise();
    }

    removeSubordinate(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/removeSubordinate/${data.employeeID}/${data.subordinateID}`,
                ''
            )
            .toPromise();
    }

    removeManager(data: any) {
        return this.http
            .patch<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }employees/removeManager/${data.employeeID}`,
                ''
            )
            .toPromise();
    }

    /** Payroll API */
    approveTerminationPayroll(data: any) {
        return this.http
            .post<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }payroll/approveTerminationPayroll`,
                data
            )
            .toPromise();
    }

    /** Recruitment API */
    searchSkill(params: string) {
        return this.http.get<any>(
            `${
                environment.recruitmentEndpoint + this.endpointVersion.currentVersion
            }settings/search-skills?search=${params}`
        );
    }

    public createSkill(payload: any): Observable<any> {
        const url =
            environment.recruitmentEndpoint +
            this.endpointVersion.currentVersion +
            'settings/add-skill';
        return this.http.post(url, payload);
    }

    public searchJobRoles(params: string): Observable<any> {
        const url =
            environment.recruitmentEndpoint +
            this.endpointVersion.currentVersion +
            'jobs/search-templates?name=' +
            params;
        return this.http.get(url);
    }

    /** Work History API */
    fetchEmployeeWorkHistory(id: any) {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }workHistory/fetchWorkHistory/${id}`
            )
            .toPromise();
    }

    addEmployeeWorkHistory(data: any) {
        return this.http
            .post<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }workHistory/addWorkHistory`,
                data
            )
            .toPromise();
    }

    updateEmployeeWorkHistory(data: any) {
        return this.http
            .put<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }workHistory/updateWorkHistory/${data.empId}/${data.whId}`,
                data.payload
            )
            .toPromise();
    }

    removeWorkExperience(data: any) {
        return this.http
            .delete<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }workHistory/removeWorkHistory/${data.empId}/${data.whId}/${data.payload}`
            )
            .toPromise();
    }

    /** Academic & Professional History */
    fetchEmployeeAcademicHistory(id: any) {
        return this.http
            .get<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }academicHistory/fetchHistory/${id}`
            )
            .toPromise();
    }

    addEmployeeAcademicHistory(data: any) {
        return this.http
            .post<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }academicHistory/addHistory`,
                data
            )
            .toPromise();
    }

    updateEmployeeHistory(data: any) {
        return this.http
            .put<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }academicHistory/updateHistory/${data.empId}/${data.whId}`,
                data.payload
            )
            .toPromise();
    }

    removeAcademicExperience(data: any) {
        return this.http
            .delete<any>(
                `${
                    environment.myXalaryEndpoint + this.endpointVersion.currentVersion
                }academicHistory/removeHistory/${data.empId}/${data.whId}/${data.payload}`
            )
            .toPromise();
    }

    /** API for file upload - Exposed using SNFramework */
    uploadFile(data: any) {
        return this.http.post<any>(
            `${environment.myXalaryEndpoint + this.endpointVersion.currentVersion}util/files`,
            data
        );
    }
}
