<div
    class="position-fixed p-3"
    style="
        top: 0;
        right: 0;
        z-index: 10000;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    "
>
    <div
        *ngFor="let alert of alertInfo"
        class="alert-box box-shadow d-flex p-1 gap-10 justify-content-center align-items-center float-right mt-1"
        [ngClass]="alert.btnClass || 'alert-success'"
        style="align-self: flex-end"
    >
        <ng-container *ngIf="!alert.progress; else progress">
            <mat-icon class="icon">{{ alert.btnIcon }}</mat-icon>
            <span class="btn-text error-message"> {{ alert?.text }}</span>
            <mat-icon (click)="closeAlert(alert.sn || '0')" class="icon clickable">cancel</mat-icon>
        </ng-container>
        <ng-template #progress>
            <mat-icon class="icon">sync</mat-icon>
            <div (click)="openProgress(alert.data)" class="d-flex flex-column clickable">
                <span class="btn-text error-message"> {{ alert?.text }}</span>
                <mat-progress-bar
                    mode="determinate"
                    [value]="((alert.data?.done || 0) * 100) / (alert.data?.total || 1)"
                ></mat-progress-bar>
                <div class="d-flex w-100 justify-content-between align-items-between">
                    <span class="text-danger count">Failed: {{ alert.data?.failed }}</span>
                    <span class="count"> {{ alert.data?.done }}/{{ alert.data?.total }}</span>
                </div>
            </div>
            <mat-icon
                *ngIf="alert.data?.done == alert.data?.total"
                (click)="closeAlert(alert.sn || '0')"
                class="icon clickable"
                >cancel</mat-icon
            >
        </ng-template>
    </div>
</div>
