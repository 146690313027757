<ng-select
    (ngModelChange)="onSelectChange($event)"
    [clearable]="false"
    [closeOnSelect]="!multiple"
    [items]="employees"
    [loading]="loading"
    [multiple]="multiple"
    [ngModel]="selected"
    [virtualScroll]="true"
    [searchFn]="searchFn"
    bindLabel="name"
    id="employee-select"
>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="app-employee-select-option">
            <input *ngIf="multiple" id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
            <div class="app-employee-select-info">
                <app-profile-image [user]="item"></app-profile-image>
                <div class="employee-info">
                    <span class="name"> {{ item.name }} </span>
                    <span class="email">{{ item.email }} </span>
                </div>
            </div>
        </div>
    </ng-template>
</ng-select>
