<div *ngIf="moduleName" class="card">
    <div class="card-body">
        <p style="text-align: center; font-size: 17px; font-weight: 900">
            Hey there!, You do not have license to access this module. Please contact your admin or
            check plans available for this module
        </p>
        <div class="d-flex p-1">
            <app-license-list [moduleName]="moduleName"></app-license-list>
        </div>
    </div>
</div>
