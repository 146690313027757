<div
    *ngIf="hmoPlan"
    class="card p-0 hmo-plan-container"
    [ngClass]="{ active: hmoPlan?.employees?.length }"
>
    <div class="hmo-plan-header p-3" (click)="openEmployeeSearchModal()">
        {{ hmoPlan.name | titlecase }} -
        {{ hmoPlan.amount | currency: '₦' }}
    </div>
    <div class="p-2 plan-details" [ngClass]="{ 'border-bottom': hmoPlan.employees?.length }">
        <ul class="p-0">
            <li *ngFor="let feature of hmoPlan.details">
                <i class="fa fa-check mr-2"></i>
                {{ feature | titlecase }}
            </li>
        </ul>
    </div>
    <div class="mx-2 my-3 plan-employees-container" *ngIf="hmoPlan.employees?.length">
        <ngb-accordion
            class="rounded"
            activeIds="{{ hmoPlan.employees?.length ? 'employee-panel-1' : '' }}"
        >
            <ngb-panel id="employee-panel-1" title="Simple" class="rounded">
                <ng-template ngbPanelHeader let-opened="opened">
                    <div
                        class="w-100 accordion-button plan-employees-header"
                        [class.collapsed]="!opened"
                    >
                        Selected Employees
                        <button ngbPanelToggle class="accordion-toggle p-0 border-0">
                            <i class="fa fa-caret-down"></i>
                        </button>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="plan-employee-list">
                        <single-item-with-icon
                            *ngFor="let employee of hmoPlan.employees; let index = index"
                            [employee]="employee"
                            (itemClicked)="removeEmployee(index)"
                        ></single-item-with-icon>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>
