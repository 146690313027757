import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-progress-activities',
    templateUrl: './progress-activities.component.html',
    styleUrls: ['./progress-activities.component.scss'],
})
export class ProgressActivitiesComponent implements OnInit {
    @Input() title!: string;
    @Input() icon: string = 'eye';
    @Input() actBtn: string = 'View';
    @Input() link: string = 'View';
    @Input() route: any = 'any';
    @Input() progressValue!: number;
    mode: 'determinate' | 'indeterminate' = 'determinate';
    constructor(private router: Router) {}

    ngOnInit(): void {}

    view() {
        this.router.navigate([this.route]);
    }
}
