import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Angular4PaystackModule, PaystackOptions } from 'angular4-paystack';

@Component({
    selector: 'app-paystack',
    templateUrl: './paystack.component.html',
    styleUrls: ['./paystack.component.scss'],
})
export class PaystackComponent implements OnChanges {
    @Input()
    options: PaystackOptions = {
        amount: 0,
        email: '',
        ref: '',
    };
    @Input()
    title = 'Pay';

    @Input()
    disabled: boolean = false;

    @Input()
    init!: Function;
    @Output()
    onSuccessFull = new EventEmitter();

    @Output()
    onCancel = new EventEmitter();

    canceling: boolean = false;
    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {}

    paymentInit() {}

    paymentDone(ref: any) {
        this.onSuccessFull.emit(ref);
    }

    paymentCancel() {
        this.onCancel.emit('');
    }
}
