import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SnackbarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string; error: boolean }) {
        const genericErrorMessage = 'Failed';

        if (!this.data.message || typeof this.data.message !== 'string') {
            this.data.message = genericErrorMessage;
        }
    }
}
