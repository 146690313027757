import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';

import urlList from './service-list.json';
import { environment } from 'src/environments/environment';
import { GeneralService } from '../shared/services/general.service';

const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private url: string = environment.myXalaryEndpoint;

    constructor(
        private http: HttpClient,
        private genSrv: GeneralService
    ) {}

    register(data: any): Observable<any> {
        return this.http.post<any>(`${this.url}/v1/users/signup`, data);
    }

    login = (data: any): Observable<any> => {
        const obs = this.http.post<any>(`${this.url}${urlList.login.login}`, data);

        return obs;
    };

    resendEmail = (data: any) => {
        return this.http.post<any>(`${this.url}${urlList.login.resendConfirmation}`, data);
    };

    forgot(data: any): Observable<any> {
        return this.http.patch<any>(`${this.url}/v1/users/forgotPassword`, data);
    }

    public decodedToken = () => helper.decodeToken(this.genSrv.getAppToken());
    public isExpired = () => helper.isTokenExpired(this.genSrv.getAppToken());

    isLoggedIn(): boolean {
        return this.decodedToken() && !this.isExpired();
    }

    confirmation(token: string) {
        const body = { token };
        return this.http.patch<any>(`${this.url}/v1/users/confirm`, body);
    }

    validateOtp(otp: any) {
        return this.http.post<any>(`${this.url}/v1/users/validate-otp`, otp);
    }

    resendOtp(body: any) {
        return this.http.post<any>(`${this.url}/v1/users/resend-otp`, body);
    }
}
