<nav class="sidebar sidebar-offcanvas bg-white" id="sidebar">
    <div class="text-center navbar-brand-wrapper mt-2 d-flex align-items-center justify-content-center bg-white">
        <a class="navbar-brand brand-logo" [href]="v1DashboardUrl"><img class="sidebar-logo"
                src="/assets/images/notch-hr-edt.png" alt="logo" /></a>
        <a class="navbar-brand brand-logo-mini" [href]="v1DashboardUrl"><img height="30"
                src="/assets/images/notch-hr-fav.png" alt="logo" /></a>
    </div>
    <ul class="nav gap-10">
        <ng-container *ngFor="let menu of sidebarMenu">
            <li class="nav-item" *appFeatureFlag="{ flagName:menu.feature}">
                <a *ngIf="menu.isExternalLink; else appRoute" class="nav-link" [href]="menu.externalLink">
                    <i class="material-symbols-outlined menu-icon">{{ menu.icon }}</i>
                    <span class="menu-title">{{ menu.title }}</span>
                </a>
                <ng-template #appRoute>
                    <span [routerLink]="'/' + menu.slug">
                        <a style="width: 90%" class="nav-link" data-toggle="collapse" data-menu="main"
                            [href]="'#' + (menu.slug || menu.title)" aria-expanded="false" aria-controls="ui-basic"
                            [ngClass]="{ activeDash: getActiveMenu({ slug: menu.slug, index: 1 }) }">
                            <i matTooltip="Info about the action"
                                class="material-symbols-outlined menu-icon">{{ menu.icon }}</i>
                            <span class="menu-title">{{ menu.title }}</span>
                            <i *ngIf="menu.subMenu?.length" class="menu-arrow menu-icon"></i>
                        </a>
                    </span>
                    <div style="width: 90%" *ngIf="menu?.subMenu?.length" class="collapse" [id]="menu.slug">
                        <ul class="nav w-100 flex-column sub-menu">
                            <ng-container *ngFor="let subMenu of menu.subMenu">
                                <li *appFeatureFlag="{ flagName:subMenu.feature}" [ngClass]="{
                                active: getActiveMenu({
                                    super: menu.slug,
                                    slug: subMenu.slug,
                                    index: 2,
                                }),
                            }" class="nav-item">
                                    <a *ngIf="subMenu.isExternalLink; else internalRoute" [href]="subMenu.externalLink"
                                        target="_blank" class="nav-link">{{ subMenu?.title }}</a>
                                    <ng-template #internalRoute>
                                        <a class="nav-link"
                                            [routerLink]="'/' + menu.slug + '/' + subMenu.slug">{{ subMenu?.title }}</a>
                                    </ng-template>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </ng-template>
            </li>
        </ng-container>
    </ul>
</nav>