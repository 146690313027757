import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { CSVRecord } from '../models/CsvRecord';
import XLSX from 'xlsx';

@Injectable()
export class CsvServicesService {
    records!: any[];
    constructor() {}

    downloadFile(data: any[], fileName: string) {
        const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        const csv = data.map((row) =>
            header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
        );
        csv.unshift(header.join(','));
        const csvArray = csv.join('\r\n');

        const a = document.createElement('a');
        const blob = new Blob([csvArray], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = `${fileName}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    uploadListener($event: any) {
        return new Promise((resolve, reject) => {
            let text = [];
            let files = $event.srcElement.files;

            if (this.isValidCSVFile(files[0])) {
                let input = $event.target;
                let reader = new FileReader();
                reader.readAsText(input.files[0]);

                reader.onload = () => {
                    let csvData = reader.result;
                    let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

                    let headersRow = this.getHeaderArray(csvRecordsArray);

                    const records = this.getDataRecordsArrayFromCSVFile(
                        csvRecordsArray,
                        headersRow
                    );

                    resolve(records);
                };

                reader.onerror = function () {
                    console.log('error is occured while reading file!');
                    reject('error is occured while reading file!');
                };
            } else {
                alert('Please import valid .csv file.');
                reject('Please import valid .csv file.');
            }
        });
    }

    getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerArray: string[]) {
        let csvArr = [];

        for (let i = 1; i < csvRecordsArray.length; i++) {
            let curruntRecord = (<string>csvRecordsArray[i]).split(',');
            if (curruntRecord.length == headerArray.length) {
                let csvRecord: any = {};
                headerArray.forEach((header, index) => {
                    csvRecord[header] = curruntRecord[index].trim().replaceAll(/['"]/g, '');
                });
                csvArr.push(csvRecord);
            }
        }
        return csvArr;
    }

    isValidCSVFile(file: any) {
        return file.name.endsWith('.csv');
    }

    getHeaderArray(csvRecordsArr: any) {
        let headers = (<string>csvRecordsArr[0]).split(',');
        let headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }

    async importFromExcel(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const arrayBuffer = e.target.result;
                const data = new Uint8Array(arrayBuffer);
                const arr = Array.from(data, (byte) => String.fromCharCode(byte)).join('');
                const workbook = XLSX.read(arr, { type: 'binary' });

                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                resolve(jsonData);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
        });
    }
}
