type HandleLicenseModuleCheckProps = {
    module: string;
    employees: any[];
};
export function mergeLicenseModulesStatus(profile: any) {
    if (!profile) return;
    if (!profile.companyLicense?.licenseId?.licensePackageID) return;

    let allEmployeeModules = JSON.parse(
        JSON.stringify(profile.companyLicense.licenseId.licensePackageID.modules)
    );
    if (profile.addonLicenses && profile.addonLicenses.length > 0) {
        for (let x = 0; x < profile.addonLicenses.length; x++) {
            if (profile.addonLicenses[x]?.licenseId?.licensePackageID) {
                let addonEmployeeModules =
                    profile.addonLicenses[x].licenseId.licensePackageID.modules;
                let result = Object.entries(appModules);
                for (let i = 0; i < result.length; i++) {
                    allEmployeeModules[result[i][1].key] =
                        allEmployeeModules[result[i][1].key] ||
                        addonEmployeeModules[result[i][1].key];
                }
            }
        }
    }

    return allEmployeeModules;
}
export function checkLicenseModulesStatus(profile: any, module: string): boolean {
    if (!profile) return false;

    return (
        checkFilterForCompanyLicense(module, profile) ||
        checkFFilterForAddonLicense(module, profile)
    );
}

export function HandleLicenseModuleCheck({ employees, module }: HandleLicenseModuleCheckProps) {
    return employees.filter(
        (value: any) =>
            checkFilterForCompanyLicense(module, value) ||
            checkFFilterForAddonLicense(module, value)
    ); // Check employee assigned license
}

function checkFFilterForAddonLicense(module: string, employee: any) {
    if (!employee.addonLicenses) return false;

    for (let j = 0; j < employee.addonLicenses.length; j += 1) {
        if (
            employee.addonLicenses[j].licenseId &&
            employee.addonLicenses[j].licenseId._id &&
            employee.addonLicenses[j].licenseId.licensePackageID
        ) {
            if (
                new Date(employee.addonLicenses[j].licenseId.currentExpiry) > new Date(Date.now())
            ) {
                if (employee.addonLicenses[j].licenseId.licensePackageID.modules[module]) {
                    return true;
                }
            }
        }
    }

    return false;
}

function checkFilterForCompanyLicense(module: string, employee: any) {
    if (!employee.companyLicense) return false;

    if (
        employee.companyLicense.licenseId &&
        employee.companyLicense.licenseId._id &&
        employee.companyLicense.licenseId.licensePackageID
    ) {
        if (new Date(employee.companyLicense.licenseId.currentExpiry) > new Date(Date.now())) {
            if (employee.companyLicense.licenseId.licensePackageID.modules[module]) {
                return true;
            }
        }
    }

    return false;
}

export const appModules = {
    Attendance: {
        key: 'Attendance',
        label: 'Attendance',
        default: false,
    },
    Payroll: {
        key: 'Payroll',
        label: 'Payroll',
        default: false,
    },
    Events: {
        key: 'Events',
        label: 'Events',
        default: false,
    },
    Leave: {
        key: 'Leave',
        label: 'Leave',
        default: false,
    },
    MultiTenancy: {
        key: 'MultiTenancy',
        label: 'Multi Tenancy',
        default: false,
    },
    Meetings: {
        key: 'Meetings',
        label: 'Meetings',
        default: false,
    },
    ChatAndAnnouncement: {
        key: 'ChatAndAnnouncement',
        label: 'Chat And Announcement',
        default: false,
    },
    GrievanceAndDisciplinary: {
        key: 'GrievanceAndDisciplinary',
        label: 'Grievance And Disciplinary',
        default: false,
    },
    Confirmation: {
        key: 'Confirmation',
        label: 'Confirmation',
        default: false,
    },
    Requisition: {
        key: 'Requisition',
        label: 'Requisition',
        default: false,
    },
    ExitManagement: {
        key: 'ExitManagement',
        label: 'Exit Management',
        default: false,
    },
    PromotionManagement: {
        key: 'PromotionManagement',
        label: 'Promotion Management',
        default: false,
    },
    ApprovalWorkflow: {
        key: 'ApprovalWorkflow',
        label: 'Approval Workflow',
        default: false,
    },
    ELearning: {
        key: 'ELearning',
        label: 'E-Learning',
        default: false,
    },
    LearningAndDevelopment: {
        key: 'LearningAndDevelopment',
        label: 'Learning And Development',
        default: false,
    },
    TalentManagement: {
        key: 'TalentManagement',
        label: 'Talent Management',
        default: false,
    },
    KPIManagement: {
        key: 'KPIManagement',
        label: 'KPI Management',
        default: false,
    },
    PerformanceManagement: {
        key: 'PerformanceManagement',
        label: 'Performance Management',
        default: false,
    },
    DMS: {
        key: 'DMS',
        label: 'DMS',
        default: false,
    },
    ESB: {
        key: 'ESB',
        label: 'ESB',
        default: false,
    },
    Employee: {
        key: 'Employee',
        label: 'Employee',
        default: false,
    },
    Recruitment: {
        key: 'Recruitment',
        label: 'Recruitment',
        default: false,
    },
    SafetyIncidentReport: {
        key: 'SafetyIncidentReport',
        label: 'Safety And Incident Report',
        default: false,
    },
    EmployeeNetPromoterScore: {
        key: 'EmployeeNetPromoterScore',
        label: 'Employee Net Promoter Score',
        default: false,
    },
};

export default appModules;
