import { Component, Input, OnInit } from '@angular/core';
import { AppSocketService } from '../../services/app-socket.service';

@Component({
    selector: 'app-view-upload-progress',
    templateUrl: './view-upload-progress.component.html',
    styleUrl: './view-upload-progress.component.scss',
})
export class ViewUploadProgressComponent implements OnInit {
    progress!: any;
    @Input()
    data!: any;
    constructor(private appSocket: AppSocketService) {}
    ngOnInit(): void {
        this.progress = this.data;
        this.appSocket.bulkProgress.subscribe((item: any) => {
            if (item && item.taskID == this.data.taskID) {
                this.progress = item;
            }
        });
    }
}
