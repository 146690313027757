import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type filterType = { [key: string]: any };

@Injectable({
    providedIn: 'any',
})
export class Analytics {
    #filterValues: BehaviorSubject<filterType> = new BehaviorSubject({});

    updateFilterValue(value: filterType) {
        let currentValue = this.#filterValues.getValue();
        currentValue = { ...currentValue, ...value };
        this.#filterValues.next(currentValue);
    }

    getFilterValues() {
        return this.#filterValues;
    }

    toSentenceCase(word: string) {
        return word
            .split(' ')
            .map((v: string) => {
                const firstLetter = v[0];
                if (!firstLetter) return v;
                return firstLetter.toUpperCase() + v.substring(1, v.length).toLowerCase();
            })
            .join(' ');
    }
}
