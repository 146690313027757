import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import endpoints from 'src/app/utils/endpoints';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    endpoints = endpoints.MX_BE.V1.users;
    valueChanges = new Subject();

    constructor(private http: HttpClient) {}
    getUsers() {
        return this.http.get(this.endpoints.getUsers);
    }
    updateRole(payload: any, id: string) {
        return this.http.patch(this.endpoints.updateUser + id, { ...payload });
    }
    activateUser(id: string) {
        return this.http.patch(this.endpoints.activateUser + id, {});
    }
    deactivateUser(id: string) {
        return this.http.patch(this.endpoints.deactivateUser + id, {});
    }
    resetPassword(payload: any) {
        return this.http.patch(this.endpoints.resetPassword, { ...payload });
    }
    createUser(payload: any) {
        return this.http.post(this.endpoints.addUser, { ...payload });
    }

    request2FAUpdate() {
        return this.http.get(this.endpoints.request2FAUpdate);
    }

    update2FA(payload: any) {
        return this.http.post(this.endpoints.verifyUpdate2FA, payload);
    }

    updateUser(payload: any) {
        return this.http.patch(this.endpoints.updateUser, { ...payload });
    }

    changePassword(payload: any) {
        return this.http.patch(this.endpoints.changePassword, { ...payload });
    }
    getProfile() {
        return this.http.get(this.endpoints.getProfile);
    }

    removeBrowser(payload: any) {
        return this.http.delete(this.endpoints.removeBrowser, {
            body: payload,
        });
    }
}
