<section>
    <app-modal-cover>
        <div class="row">
            <div class="col-lg-12" style="padding: 0 25px 25px 25px">
                <h2>View Payroll Audit</h2>
                <p>All payroll activities are logged here</p>
                <img
                    src="/assets/images/payment/view-payroll-audit.png"
                    class="mt-2 mb-4"
                    height="300px"
                    width="100%"
                />
                <h2>Recent Activities</h2>
                <div *ngFor="let log of auditList" class="p-2" style="border-bottom: 0.5px solid">
                    <span class="row">
                        <span class="col-md-1">
                            <i
                                class="fa fa-circle mt-4 ml-2"
                                style="color: #00ff19; vertical-align: middle; font-size: 10px"
                            ></i>
                        </span>
                        <span class="col-md-11">
                            {{ log.message }} for {{ log.month | titlecase }}, {{ log.year }} on
                            {{ log.createdAt | date: 'medium' }}. The Workflow Stage is
                            {{ removeUnderscores(log?.stage[0]?.stage) }}.
                            <br />
                            {{ log.comment ? 'Comment:' : '' }}
                            {{ log.comment }}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </app-modal-cover>
</section>
