import { Component } from '@angular/core';

@Component({
  selector: 'app-feature-flag',
  templateUrl: './feature-flag.component.html',
  styleUrl: './feature-flag.component.scss'
})
export class FeatureFlagComponent {

}
