import { CurrencyPipe } from '@angular/common';
import {
    AfterContentChecked,
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { PermissionService } from 'src/app/services/permission.service';
import { TableConfigsService } from 'src/app/services/table-configs.service';
import { iContentHeader } from 'src/app/shared/models/contentheader';
import { GeneralService } from 'src/app/shared/services/general.service';
import { environment } from 'src/environments/environment';
import { DataTableConfig, filterValue } from '../../../../interfaces/general';
import { IPurchase } from './../../health.data';
import { HealthService } from './../../health.service';
import { HealthPurchaseDetailsComponent } from './purchase-details/purchase-details.component';
import { IHealthPayment } from '../pay-via-myxalary/pay-via-myxalary.data';

@Component({
    selector: 'app-payment-history',
    templateUrl: './payment-history.component.html',
    styleUrls: ['./payment-history.component.scss'],
    providers: [CurrencyPipe],
})
export class PaymentHistoryComponent implements OnInit, AfterViewInit {
    @ViewChild('status') status!: TemplateRef<any>;
    @ViewChild('menu') menu!: TemplateRef<any>;
    userHasAccess: boolean = false;
    userHasAccessErrorMessage: string = '';

    contentHeader: iContentHeader | undefined;
    pageSize = 10;
    limit = 10;
    hmoPurchases!: IPurchase[];

    tableConfig!: DataTableConfig;

    constructor(
        private route: ActivatedRoute,
        private _healthService: HealthService,
        private permissionService: PermissionService,
        private ts: TableConfigsService,
        private currencyPipe: CurrencyPipe,
        private genService: GeneralService,
        private dcs: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnInit(): void {
        const { userHasAccess, userHasAccessErrorMessage } =
            this.permissionService.checkUserHasAccess({
                allowedPermissions: this.route.snapshot.data.canAccess,
            });
        this.userHasAccess = userHasAccess;
        this.userHasAccessErrorMessage = userHasAccessErrorMessage;

        this.setup();
    }

    setup() {
        if (this.userHasAccess) {
            this.contentHeader = {
                headerTitle: 'Health',
                actionButton: true,
                breadcrumb: {
                    type: '',
                    links: [
                        {
                            name: 'Health',
                            isLink: true,
                            link: '/health/dashboard',
                        },
                        {
                            name: 'Purchase History',
                            isLink: false,
                        },
                    ],
                },
            };
        }
    }

    ngAfterViewInit() {
        this.tableConfig = {
            searchOptions: [
                {
                    name: 'limit',
                    width: '15%',
                    type: 'select',
                    options: this.ts.defaultPageOptions,
                },
                {
                    name: 'createdOn',
                    width: '15%',
                    type: 'select',
                    transform: this.ts.periodTransform,
                    options: this.ts.defaultPeriodOptions,
                },
                {
                    name: 'search',
                    width: '55%',
                    type: 'search',
                    transform: (value: any): filterValue => {
                        const cols = ['providerId.providerName'].join('?');
                        return {
                            operator: '=',
                            value: `${cols}^${value}~`,
                        };
                    },
                },
                {
                    name: 'status',
                    width: '40%',
                    type: 'select',
                    options: [
                        {
                            key: 'All Status',
                            value: '',
                            selected: true,
                        },
                        {
                            key: 'Paid',
                            value: 'PAID',
                        },
                        {
                            key: 'Not Paid',
                            value: 'NOT PAID',
                        },
                    ],
                },
            ],
            columns: [
                {
                    label: 'HMO Provider',
                    key: 'providerId.providerName',
                    getValue: (data: any) => {
                        return data.providerId?.providerName;
                    },
                },
                {
                    label: 'Cost',
                    key: 'plans.amount',
                    noSort: true,
                    getValue: (data: any) => {
                        const amount = data.plans.reduce(
                            (sum: any, cur: any) =>
                                (sum +=
                                    cur.amount *
                                    cur.employees.length *
                                    this._healthService.getMultiplier(cur.period)),
                            0
                        );
                        return this.currencyPipe.transform(amount, 'NGN', 'symbol-narrow');
                    },
                },

                {
                    label: 'Date Created',
                    key: 'createdOn',
                    getValue: (data: any) => {
                        return dayjs(data.createdOn).format('DD MMM, YYYY');
                    },
                },
                {
                    label: 'Status',
                    key: 'status',
                    template: this.status,
                },
                {
                    label: 'Action',
                    key: 'status',
                    noSort: true,
                    template: this.menu,
                },
            ],
            url: environment.healthEndpoint + 'payments',
        };
        this.dcs.detectChanges();
    }

    viewDetails(row: any) {
        const modal = this.genService.preModal(HealthPurchaseDetailsComponent);
        modal.componentInstance.data = row;
    }

    clone(data: IHealthPayment) {
        const url = data.type === 'provider' ? `/health/hmo/${data.providerId._id}` : '/health/pay';
        this.router.navigateByUrl(url, {
            state: {
                data: { ...data },
            },
        });
    }
}
