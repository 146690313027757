<div *ngIf="loaded" class="container-scroller">
    <div class="container-fluid">
        <app-sidebar></app-sidebar>
        <div class="main-panel p-3">
            <app-header class="myheader"></app-header>
            <div class="content-wrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
