import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from '../../services/utils.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, take } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { GeneralService } from 'src/app/shared/services/general.service';
import { AppStore } from 'src/app/app-data';

@Component({
    selector: 'app-transfer',
    templateUrl: './transfer.component.html',
    styleUrls: ['./transfer.component.scss', '../../../../../formStyle.scss'],
})
export class TransferComponent implements OnInit {
    formData: UntypedFormGroup = new UntypedFormGroup({
        amount: new UntypedFormControl('', [Validators.required, Validators.min(100)]),
        beneficiary: new UntypedFormControl('', [Validators.required]),
        narration: new UntypedFormControl('', [Validators.required]),
        otp: new UntypedFormControl('', [Validators.required]),
        pin: new UntypedFormControl('', [Validators.required]),
        reference: new UntypedFormControl('', [Validators.required]),
        token: new UntypedFormControl('', [Validators.required]),
    });

    searchBenefit = new Subject<string>();
    benefitList = new BehaviorSubject<any[]>([]);
    loadingBenefits: boolean = false;
    user!: any;
    loadingOTP: boolean = false;
    otpBtn = {
        color: '#D62196',
        label: '+ Get OTP',
    };
    constructor(
        private utils: UtilsService,
        private api: ApiService,
        private gs: GeneralService,
        private _appStore: AppStore
    ) {}

    ngOnInit(): void {
        this.getBeneficiaries();
        this.benefits();
        this.getBalance();
    }

    openAddBenefit() {
        this.utils.addBenefit();
    }

    get balance() {
        return this.utils.balance;
    }

    async benefits() {
        this.user = await this._appStore.getUserInfo().pipe(take(1)).toPromise();
        this.searchBenefit
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                tap(() => (this.loadingBenefits = true))
            )
            .subscribe((text: string) => {
                this.getBeneficiaries(text, false);
            });
    }

    async getBeneficiaries(term?: string, loader = true) {
        let query: any = {};
        if (term) {
            query['accountName'] = `${term}~`;
        }
        const result: any = await this.api.getBeneficiaries(query, loader);
        this.benefitList.next(result?.data?.data);
        this.loadingBenefits = false;
    }

    async getBalance() {
        await this.api.getBalance();
    }
    get canRequestOTP() {
        return this.user.permissions.ActionBased.Settings.SETUP_OTP;
    }
    async getOTP() {
        if (this.loadingOTP) return;
        this.loadingOTP = false;
        this.otpBtn = {
            color: '#440f7c',
            label: 'Generating OTP...',
        };
        const token: any = await this.api.getOTP();
        this.formData.patchValue({
            token: token.response,
        });
        this.otpBtn = {
            color: 'green',
            label: 'OTP Successfully Sent to Registered Phone Number and Email Address...Click to Resend',
        };
        this.loadingOTP = false;
        this.gs.alertInfo.next({
            text: token.message,
            btnClass: 'alert-success',
            btnIcon: 'checklist',
            timer: 3000,
        });
    }

    async save() {
        const data = this.formData.value;
        if (!this.user.companyData.isCompliant) {
            return this.gs.alertInfo.next({
                text: 'Your Compliance is still pending!!!',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 3000,
            });
        }
        if (Number(data.amount) < 100) {
            return this.gs.alertInfo.next({
                text: 'Amount Should not be less than 100',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 3000,
            });
        }
        if (Number(data.amount) > Number(this.balance.getValue())) {
            return this.gs.alertInfo.next({
                text: 'Insufficient Funds',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 3000,
            });
        }
        await this.api.transfer(data);
        this.utils.openSuccessView('Transfer Successful');
        this.utils.transferTable.next(1);
    }
}
