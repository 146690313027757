import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SettingsService } from '../../../modules/recruitment/settings-component/service/settings.service';
import { GeneralService } from '../../services/general.service';

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.scss'],
})
export class SkillsComponent implements OnInit {
    constructor(
        private settingServ: SettingsService,
        private genSrv: GeneralService
    ) {}
    @ViewChild('skills') skills!: any;
    @Output()
    onSelect = new EventEmitter();
    @Input() useRating = false;
    @Input() useDescription = false;
    skillInput: any = {
        name: '',
    };
    ratings: any;
    jobRoleSkillLoader = false;
    skillsData: any[] = [];
    ngOnInit(): void {
        this.settingServ.getcompetencyLevels().subscribe((result) => {
            this.ratings = result.payload.data;
        });
    }
    onSearchSkills(searchVal: string) {
        this.jobRoleSkillLoader = true;
        this.settingServ.searchSkill(searchVal).subscribe((res) => {
            const { payload } = res;
            this.skillsData = payload.data;
            this.jobRoleSkillLoader = false;
        });
    }

    selectEventSkills(item: any) {
        if (this.useRating) return;
        this.onSelect.emit({ id: item._id, name: item.name });
        // this.skills.clear();
    }

    onAdd() {
        const skillData: any = {
            id: this.skillInput.name._id,
            name: this.skillInput.name.name,
            rating: this.skillInput.rating,
        };

        // Only add description if it's provided (not empty)
        if (this.skillInput.description?.trim()) {
            skillData.description = this.skillInput.description;
        }

        this.onSelect.emit(skillData);

        this.skillInput = { name: '', rating: 0, description: '' };
    }

    get isValid() {
        return (
            this.skillInput.name &&
            this.skillInput.name._id &&
            this.skillInput.rating > 0 &&
            this.skillInput.rating <= 5
        );
    }

    handleAddSkills(skills: any) {
        const newSkill = skills.query.toLowerCase();
        const payload = {
            name: newSkill,
        };

        this.settingServ.createSkill(payload).subscribe((res: any) => {
            const { status, payload } = res;
            if (status === 'success') {
                if (!this.useRating) {
                    this.selectEventSkills(payload);
                } else {
                    this.skillInput.name = payload;
                }
                this.skills.close();
                this.genSrv.alertInfo.next({
                    text: `Competency Added`,
                    btnClass: 'alert-success',
                    btnIcon: 'checklist',
                    timer: 5000,
                });
            }
        });
    }
}
