import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    handleError(error: any) {
        // handle all type of error logic here
    }
}
