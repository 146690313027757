<modal-cover [title]="data?.title">
    <section class="w-100 p-3">
        <div class="d-flex flex-column gap-10">
            <span class="btn-text error-message">{{
                progress?.done !== progress?.total ? 'Uploading....' : 'Completed'
            }}</span>
            <mat-progress-bar
                mode="determinate"
                [value]="((progress?.done || 0) * 100) / (progress?.total || 1)"
            ></mat-progress-bar>
            <div class="d-flex w-100 justify-content-between align-items-between">
                <span class="text-danger count">Failed: {{ progress?.failed }}</span>
                <span class="count"> {{ progress?.done }}/{{ progress?.total }}</span>
            </div>
            <div class="d-flex flex-column">
                <span>Messages</span>
                <p-divider></p-divider>
                <div style="max-height: 400px; overflow: auto" class="d-flex flex-column gap-10">
                    <div
                        *ngFor="let message of progress.messages"
                        class="text-danger align-items-center d-flex gap-10"
                    >
                        <mat-icon>error</mat-icon>
                        <span>{{ message }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</modal-cover>
