<modal-cover [title]="title">
    <div class="w-100">
        <div class="d-flex pb-3 flex-column justify-content-between align-items-center gap-5">
            <p style="font-size: 14px; font-weight: 500; padding: 2%; text-align: center">
                {{ text }}
            </p>
            <div class="d-flex gap-10">
                <button (click)="proceedSwal(false)" class="btn btn-danger">Close</button>

                <button (click)="proceedSwal(true)" class="btn btn-primary">Proceed</button>
            </div>
        </div>
    </div>
</modal-cover>
