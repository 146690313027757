import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { iContentHeader } from 'src/app/shared/models/contentheader';
import { iHmoProvider } from 'src/app/shared/models/hmoProvider';
import { iEmployee, iTableData } from 'src/app/shared/models/interfaces';
import { GeneralService } from 'src/app/shared/services/general.service';
import { HealthStore } from '../../health-data/stores/health.store';
import { iPlan } from './../../../../shared/models/hmoProvider';
import { HealthDispatcher } from './../../health-data/dispatcher/health.dispatcher';
import { HealthService } from './../../health.service';
import {
    dummyPlanBreakdown,
    IHealthPlans,
    IHealthProvider,
    insurancePeriodEnum,
    IplanBreakdownControl,
} from './../pay-via-myxalary/pay-via-myxalary.data';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { PaymentPackageForm } from '../../health.data';
import $ from 'jquery';
import { SuccessViewComponent } from 'src/app/shared/modals/success-view/success-view.component';
import { SuccessComponent } from 'src/app/modules/learning/containers/success/success.component';
import dayjs from 'dayjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
    selector: 'app-hmo-provider-view',
    templateUrl: './hmo-provider-view.component.html',
    styleUrls: ['./hmo-provider-view.component.scss'],
})
export class HmoProviderViewComponent implements OnInit {
    hmoProvider$: BehaviorSubject<iHmoProvider> = new BehaviorSubject<iHmoProvider>(
        {} as iHmoProvider
    );
    hmoProvider!: IHealthProvider;
    contentHeader!: iContentHeader;
    providerId: string;
    canBuyPlan: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    paymentForm!: UntypedFormGroup;
    planBreakdown: iTableData = dummyPlanBreakdown;
    planBreakdownData!: IplanBreakdownControl[];
    userHasAccessErrorMessage!: string;
    userHasAccess: boolean = false;
    constructor(
        private _healthService: HealthService,
        private gs: GeneralService,
        private router: Router,
        private _formBuilder: UntypedFormBuilder,
        private permissionService: PermissionService,
        private route: ActivatedRoute
    ) {
        this.providerId = route.snapshot.params.id;
    }

    ngOnInit(): void {
        this.contentHeader = {
            headerTitle: 'Health',
            actionButton: true,
            breadcrumb: {
                type: '',
                links: [
                    {
                        name: 'All HMO Providers',
                        isLink: true,
                        link: '/health/all-hmo-providers',
                    },
                    {
                        name: this?.hmoProvider?.providerName || '',
                        isLink: false,
                    },
                ],
            },
        };

        const { userHasAccess, userHasAccessErrorMessage } =
            this.permissionService.checkUserHasAccess({
                allowedPermissions: this.route.snapshot.data.canAccess,
            });
        this.userHasAccess = userHasAccess;
        this.userHasAccessErrorMessage = userHasAccessErrorMessage;
        if (this.userHasAccess) {
            this.setup();
        }
    }

    setup() {
        this._healthService.fetchProvidersById(this.providerId).subscribe((value) => {
            this.hmoProvider = value;
            const plans = history.state.data?.plans || [];
            this.paymentForm = this._formBuilder.group({
                providerId: [value._id, Validators.required],
                plans: this._formBuilder.array(
                    value.plans.map((plan: any) => {
                        const curPlan = plans?.find(
                            (val: IHealthPlans) => val.name.toLowerCase() == plan.name.toLowerCase()
                        );
                        if (curPlan) {
                            plan.startDate = dayjs(curPlan.startDate).format('YYYY-MM');
                            plan.period = curPlan.period;
                            plan.employees = curPlan.employees;
                        }
                        return this.addNewCustomPlan(plan);
                    }),
                    [Validators.minLength(1)]
                ),
                autoRenewal: [false],
            });

            this.plans.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe({
                next: (values: IplanBreakdownControl[]) => {
                    this.calculatePlanBreakdown();
                },
                complete: () => {},
            });
            this.hmoProvider.logo = this.gs.getEmployeeImage({
                profileImgUrl: value.logo,
                name: value.providerName,
            });
            this.calculatePlanBreakdown();
        });
    }

    get plans() {
        return this.paymentForm.get('plans') as UntypedFormArray;
    }

    addNewEmployees(item: any) {
        return this._formBuilder.group({
            name: this._formBuilder.control(item.name, [Validators.required]),
            email: this._formBuilder.control(item.email, [Validators.email]),
            profileImgUrl: this._formBuilder.control(item.profileImgUrl),
            id: this._formBuilder.control(item.id, [Validators.required]),
            phone: this._formBuilder.control(item.phone, [Validators.required]),
        });
    }
    addNewCustomPlan(data: any) {
        return this._formBuilder.group({
            name: this._formBuilder.control(data?.name, [Validators.required]),
            period: this._formBuilder.control(data?.period, [Validators.required]),
            startDate: this._formBuilder.control(data?.startDate, [Validators.required]),
            amount: this._formBuilder.control(data?.amount, [
                Validators.required,
                Validators.min(1),
            ]),
            details: this._formBuilder.array(data.details),
            employees: this._formBuilder.array(
                data?.employees
                    ? data?.employees?.map((item: any) => this.addNewEmployees(item))
                    : [],
                [Validators.required]
            ),
        });
    }

    employeeAdded(data: IHealthPlans, i: number) {
        const plan = this.plans.at(i);
        plan.patchValue({
            period: data.period,
            startDate: data.startDate,
        });
        const employees = plan.get('employees') as UntypedFormArray;
        employees.clear();
        employees.reset();
        data.employees.forEach((e: any) => {
            employees.push(this.addNewEmployees(e));
        });
    }

    private calculatePlanBreakdown() {
        const values: PaymentPackageForm[] = this.plans.value.filter(
            (item: any) => item.employees?.length
        );
        this.planBreakdownData = values.map((data) => ({
            ...data,
            planAmount: Number(String(data.amount).replace(/[^0-9.-]+/g, '')),
            cost:
                Number(String(data.amount).replace(/[^0-9.-]+/g, '')) *
                data.employees.length *
                this._healthService.getMultiplier(data.period),
            quantity: data.employees.length,
            period:
                (data.period || 'N/A') +
                ' (' +
                this._healthService.getMultiplier(data.period) +
                ' months)',
        }));

        this.planBreakdown = {
            ...this.planBreakdown,
            body: this.planBreakdownData,
        };
    }

    async buyPlan() {
        const val = this.paymentForm.value;
        const validPlans = val.plans
            ?.filter((plan: any) => plan.employees?.length)
            .map((v: any) => {
                const { details, ...rest } = v;
                return rest;
            });
        const payload: any = {
            ...val,
            type: 'provider',
            plans: validPlans,
        };

        const payment = await this._healthService.makePayment(payload).toPromise();
        const openSuccess = this.gs.preModal(SuccessComponent);
        const instance = openSuccess.componentInstance;
        instance.config = {
            title: 'Plans Payment Initiated!',
            btns: [
                {
                    title: 'View HMO',
                    btnStyle: 'btn btn-primary',
                },
                {
                    title: 'View Payroll',
                    btnStyle: 'btn btn-primary',
                },
            ],
            svgIcon: 'success',
        };
        instance.action.subscribe((text: string) => {
            if (text === 'View HMO') {
                this.router.navigate(['/health/all-hmo-providers']);
            } else if (text === 'View Payroll') {
                this.router.navigate([
                    '/payment/allowance/medical/view-medical/',
                    payment?.response?.payments?.payrollId,
                ]);
            }
            openSuccess.dismiss();
        });
    }
}
