import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Role, IRoles } from '../../models/interfaces';
import { BehaviorSubject } from 'rxjs';
import appConstants from 'src/app/utils/constants';

@Component({
    selector: 'app-afl-ngs',
    template: ` <ng-select
        [(ngModel)]="value"
        [placeholder]="'Select Role'"
        class="ng-input"
        (change)="handle($event)"
        [items]="list | async"
        bindValue="id"
        bindLabel="label"
        [clearable]="false"
    >
    </ng-select>`,
    styleUrls: ['./approval-flow.component.scss'],
})
export class AFLNGS implements OnInit, OnChanges {
    @Input()
    roles: Role[] = [];

    @Input()
    index!: number;

    @Input()
    value!: any;
    @Input()
    allList: Role[] = [];

    list = new BehaviorSubject<Role[]>([]);
    @Output()
    handleSelect = new EventEmitter();
    constructor() {}

    ngOnInit(): void {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.allList) {
            this.getList();
        }
    }
    getList() {
        const list = [...this.allList];
        const items = list.splice(0, this.index).map((v: any) => v.id + v.name);
        let newRoles = this.roles.filter(
            (value: Role) =>
                !items.includes(value.id + value.name) || value.id === 'specific_person'
        );
        if (
            appConstants.ROLES.MANGERSMANAGER === this.value &&
            !newRoles.find((v: any) => v.id === appConstants.MM.id)
        ) {
            newRoles.push(appConstants.MM);
        }
        newRoles = newRoles.map((value: any) => {
            let label = value.name;
            if (Object.values(appConstants.APPROVALTYPES).includes(value.type)) {
                label += `(${value.type})`;
            }
            return {
                ...value,
                label,
            };
        });
        this.list.next(newRoles);
    }

    handle($event: any) {
        this.handleSelect.emit($event);
    }
}
