<section>
    <modal-cover title="Transaction Details">
        <div *ngIf="data" class="d-flex w-100 flex-column pl-5 pr-5 pb-5 mt-2">
            <div class="row w-100 mb-3">
                <div class="col-lg-12 col-sm-12">
                    <div class="w-100 d-flex mt-4">
                        <div class="p-1 mr-4">
                            <div class="title">Amount</div>
                            <div class="value">{{ data?.amount | currency: '₦' }}</div>
                        </div>
                    </div>
                    <hr />
                    <div class="w-100 d-flex mt-4">
                        <div class="p-1 mr-4">
                            <div class="title">Type</div>
                            <div class="value">{{ data?.type | titlecase }}</div>
                        </div>
                    </div>
                    <hr />
                    <div class="w-100 d-flex mt-4">
                        <div class="p-1 mr-4">
                            <div class="title">Description</div>
                            <div class="value">{{ data?.description | titlecase }}</div>
                        </div>
                    </div>
                    <hr />
                    <div class="w-100 d-flex mt-4">
                        <div class="p-1 mr-4">
                            <div class="title">Date Sent</div>
                            <div class="value">{{ data?.created_date | date: 'mediumDate' }}</div>
                        </div>
                    </div>
                    <hr />
                    <ng-template [ngIf]="data.customer">
                        <div class="w-100 d-flex mt-4">
                            <div class="p-1 mr-4">
                                <div class="title">Sender</div>
                                <div class="value">{{ data?.customer }}</div>
                            </div>
                        </div>
                        <hr />
                    </ng-template>
                    <ng-template [ngIf]="data.status">
                        <div class="w-100 d-flex mt-4">
                            <div class="p-1 mr-4">
                                <div class="title">Status</div>
                                <div
                                    class="value"
                                    [ngClass]="
                                        data.status === 'success' ? 'badge-success' : 'badge-danger'
                                    "
                                >
                                    {{ data?.status }}
                                </div>
                                <span
                                    class="add-bene clickable"
                                    (click)="openRetry = !openRetry"
                                    *ngIf="data.type === 'Debit' && data.status !== 'success'"
                                >
                                    Click to Retry
                                </span>
                            </div>
                        </div>
                        <hr />
                    </ng-template>

                    <div *ngIf="openRetry === true" class="w-100 d-flex mt-4">
                        <div class="p-1 mr-4">
                            <form (ngSubmit)="retryTransaction()">
                                <div class="form-group form-style w-100">
                                    <label class="font-weight-bold" for="title"
                                        >Pin <span class="text-danger">*</span></label
                                    >
                                    <p-password
                                        [feedback]="false"
                                        [formControl]="pinControl"
                                        inputStyleClass="form-control "
                                    ></p-password>
                                </div>
                                <div>
                                    <button class="btn btn-primary">Retry Transaction</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="w-100 d-flex mt-4">
                        <div class="p-1 mr-4">
                            <div class="title">Reference</div>
                            <div class="value">{{ data.reference }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal-cover>
</section>
