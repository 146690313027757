import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pdf-display',
    templateUrl: './pdf-display.component.html',
    styleUrls: ['./pdf-display.component.scss'],
})
export class PdfDisplayComponent implements OnInit {
    @Input() docUrl: string = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
    constructor() {}

    ngOnInit(): void {}
}
