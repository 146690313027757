import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/shared/services/general.service';
import { EmployeeSearchComponent } from '../../containers';
import { IHealthPlans } from '../../containers/pay-via-myxalary/pay-via-myxalary.data';
import { iPlan } from './../../../../shared/models/hmoProvider';

@Component({
    selector: 'app-hmo-plan',
    templateUrl: './hmo-plan.component.html',
    styleUrls: ['./hmo-plan.component.scss'],
})
export class HmoPlanComponent implements OnInit {
    @Input() hmoPlan!: IHealthPlans;
    @Input() allPlans!: IHealthPlans[];
    @Input() planIndex!: number;
    @Output() onEmployeeAdded: EventEmitter<any> = new EventEmitter();

    constructor(private genService: GeneralService) {}

    ngOnInit(): void {}

    openEmployeeSearchModal() {
        const modal = this.genService.preModal(EmployeeSearchComponent, {
            windowClass: 'window-class',
        });
        const instance = modal.componentInstance;
        instance.planIndex = this.planIndex;
        instance.plans = this.allPlans;
        modal.result.then((result) => {
            if (typeof result === 'string') return;
            const employees = result.employees;

            this.hmoPlan.employees = employees;
            this.hmoPlan.startDate = result.startDate;
            this.hmoPlan.period = result.period;
            this.onEmployeeAdded.emit({ ...this.hmoPlan });
        });
    }

    removeEmployee(index: number) {
        this.hmoPlan.employees = this.hmoPlan.employees?.filter(
            (emp, empIndex) => empIndex != index
        );
        this.onEmployeeAdded.emit({ ...this.hmoPlan });
    }
}
