<div class="table-container border rounded" *ngIf="tableData">
    <table
        class="table table-bordered table-responsive w-100"
        [ngClass]="{ rounded: this.tableData.config.isBorderRadius }"
    >
        <thead>
            <tr>
                <!-- Checkbox column -->
                <th>
                    <input
                        type="checkbox"
                        (change)="toggleSelectAll($event)"
                        [checked]="areAllRowsSelected()"
                    />
                </th>
                <th class="table-head-index">#</th>
                <th *ngFor="let header of tableData.header">{{ header.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tableData.body; let i = index">
                <!-- Checkbox for each row -->
                <td>
                    <input
                        type="checkbox"
                        [(ngModel)]="item.selected"
                        (change)="onRowSelectionChange()"
                    />
                </td>
                <td>{{ i + 1 }}</td>
                <td *ngFor="let headerTitle of tableData.header">
                    <ng-container *ngIf="!headerTitle?.isCurrency">
                        <span [ngClass]="{ status: headerTitle.key === 'status' }">{{
                            item[headerTitle.key]
                        }}</span>
                    </ng-container>

                    <ng-template #employees>
                        <div class="d-flex flex-column">
                            <span *ngFor="let empl of item.employees">{{ empl.name }}</span>
                        </div>
                    </ng-template>

                    <ng-container *ngIf="headerTitle?.isCurrency">
                        <span [ngbPopover]="employees" triggers="mouseenter:mouseleave">{{
                            item[headerTitle.key] | currency: '₦'
                        }}</span>
                    </ng-container>
                </td>
            </tr>
            <tr *ngIf="this.tableData.config?.total?.display">
                <td [colSpan]="colspan"></td>
                <td>Total</td>
                <td class="font-weight-bolder">
                    {{ this.tableData.config?.total?.amount | currency: '₦' }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
