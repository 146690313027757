export const constants: any = {
    PAGES: {
        REPORT: 'Reports',
        PERFORMANCE: 'performance',
        COMPENSATIONKPI: 'Compensation KPI',
        CULTUREKPI: 'Culture KPI',
        DASHBOARD: 'Dashboard',
    },
    CATEGORIES: {
        CADRE: 'cadre',
        DEPARTMENT: 'department',
        BRANCH: 'branch',
    },
    CATEGORY_FILTER: [
        {
            label: 'Cadre',
            value: 'cadre',
        },
        {
            label: 'Department',
            value: 'department',
        },
        {
            label: 'Branch',
            value: 'branch',
        },
    ],
    CHART: ['line', 'bar'],
    loaderOptions: {
        path: './assets/motions/loader.json',
    },
    COLORS: ['#440F7C', '#D62196', '#2a061e', '#ea90ca', '#5E5873', '#F7D3EA', '#a187bd'],
    MONTHS: {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
    },
    MONTHS_REVERSED: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
    },
};
