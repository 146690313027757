<div *ngIf="userHasAccess; else unauthorized_user">
    <div class="container-fluid">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <section class="card">
            <div class="card-header">
                <h4 class="card-title">Transaction History</h4>
            </div>

            <div *ngIf="tableConfig" class="card-body pt-0">
                <app-data-table [options]="tableConfig"></app-data-table>
            </div>
        </section>
    </div>
</div>
<ng-template let-data="data" #status>
    <div class="d-flex justify-content-center align-items-center gap-10">
        <p-chip
            [ngClass]="data.status == 'NOT PAID' ? 'red' : 'green'"
            [label]="data.status"
        ></p-chip>
    </div>
</ng-template>

<ng-template let-data="data" #menu>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button (click)="viewDetails(data)" mat-menu-item>
            <span>View Details</span>
        </button>
        <button (click)="clone(data)" mat-menu-item>
            <span>Clone</span>
        </button>
        <button
            [routerLink]="['/payment/allowance/medical/view-medical/', data.payments.payrollId]"
            mat-menu-item
        >
            <span>View Payroll</span>
        </button>
    </mat-menu>
</ng-template>

<ng-template #unauthorized_user>
    <unauthorized [errorMessage]="userHasAccessErrorMessage"> </unauthorized>
</ng-template>
