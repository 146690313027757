<section *ngIf="chartOptions" class="w-100" id="basic-alerts">
    <div
        *ngIf="chartOptions.id"
        [ngClass]="chartOptions.coverClass ?? ''"
        class="chart-container"
        style="position: relative"
        [style.height]="chartOptions.height ?? null"
        [ngStyle]="{ width: chartOptions.width ?? DEFAULT_WIDTH }"
    >
        <div class="w-100"><canvas [id]="chartOptions.id"></canvas></div>
        <app-chart-legend class="w-100" *ngIf="!chartOptions.options?.options?.plugins?.legend?.display && useCustomLegend" [chartOption]="chartOptions"></app-chart-legend>
    </div>
</section>
