import { format } from 'path';

// Keep this in sync with the backend
export const WorkflowTypeEnum = {
    EIU1: 'Employee Information Updates',
    LR2: 'Leave Request',
    EES3: 'Employee Employment Status Updates',
    EC4: 'Employee Compensation Updates',
    R5: 'Requisition',
    TR6: 'Training Request',
    EBI7: 'Employee Bank Information Updates',
    JR8: 'Job Request',
    AG9: 'Assessment Goals',
    EX10: 'Employee Final Benefits',
    RCA11: 'Requisition -  Cash Advance',
    RWT12: 'Requisition -  Work Tools & Consumables',
    RCO13: 'Requisition -  Cash Reimbursement',
    RO14: 'Requisition -  Other Requisitions',
    EX11: 'Employee Termination/Resignation Request',
};
export const moneyFormat = (value: any) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(value);
};

const employeeTypeFormat = (value: any) => {
    return value == 'clocked' ? 'Full-Time Employee' : 'Outsourced Employee';
};

const formatFiles = (value: any) => {
    if (value && value.length) {
        return value.map((file: any) => {
            return file.url;
        });
    }
};

const stripHtmlTags = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};

export const WorkflowTypeDataMap: any = {
    AG9: [
        { key: 'objective', label: 'Goal Name' },
        { key: 'description', label: 'Description' },
        { key: 'perspective', label: 'Perspective' },
        { key: 'weight', label: 'Weight' },
        { key: 'goalType', label: 'Goal Type' },
        {
            key: 'milestones',
            label: 'Milestones',
            format: (value: any) => {
                if (value && value.length) {
                    return value.map((milestone: any) => {
                        return milestone.title;
                    });
                }
            },
        },
        { key: 'kpi', label: 'KPI' },
        { key: 'files', label: 'Files', format: formatFiles },
    ],
    EC4: [
        {
            key: 'annualSalary',
            label: 'Annual Salary',
            format: moneyFormat,
        },
        { key: 'employeeCadreName', label: 'Cadre' },
        { key: 'employeeCadreStepName', label: 'Cadre Step' },
        { key: 'items', label: 'Pay Items' },
        { key: 'name', label: 'Item Name' },
        { key: 'value', label: 'Value', format: moneyFormat },
        {
            key: 'pensionEnabled',
            label: 'Pensionable',
            format: (val: any) => {
                return val === true ? 'Yes' : 'No';
            },
        },
        {
            key: 'taxEnabled',
            label: 'Taxable',
            format: (val: any) => {
                return val === true ? 'Yes' : 'No';
            },
        },
        {
            key: 'schedule',
            label: 'Schedule',
        },
        {
            key: 'branchName',
            label: 'Branch',
        },
        { key: 'departmentName', label: 'Department' },
        { key: 'divisionName', label: 'Division' },
        { key: 'businessUnitName', label: 'Business Unit' },
        { key: 'employeeCadreName', label: 'Cadre' },
        { key: 'employeeCadreStepName', label: 'Cadre Step' },
        { key: 'taxID', label: 'Tax ID' },
        { key: 'pfa', label: 'PFA' },
        { key: 'teamName', label: 'Team' },
    ],
    EES3: [
        { key: 'date', label: 'Termination Date' },
        { key: 'isActive', label: 'Deactivate Employee' },
        {
            key: 'status',
            label: 'Status',
            format: (value: any) => (value ? 'Terminate' : 'Revert-Termination'),
        },
        { key: 'reason', label: 'Reason', format: (value: any) => stripHtmlTags(value) },
        {
            key: 'prorated',
            label: 'Prorated',
            format: (value: any) => (value.status ? 'Yes' : 'No'),
        },
    ],
    EX10: [
        { key: 'balance', label: '' },
        { key: 'payslip', label: '' },
        { key: 'period', label: 'Payment Period' },
        { key: 'startDate', label: 'Start Date' },
        { key: 'endDate', label: 'End Date' },
        { key: 'Basic', format: moneyFormat },
        { key: 'name' },
        { key: 'amount', format: moneyFormat },
        { key: 'benefitName', label: 'Benefit Name' },
        { key: 'Housing', format: moneyFormat },
        { key: 'Transport', format: moneyFormat },
        { key: 'Leave', format: moneyFormat },
        { key: 'Medical', format: moneyFormat },
        { key: 'benefits', label: 'Exit Benefits' },
        { key: 'bonuses' },
        { key: 'deductions' },
        { key: 'net', format: moneyFormat },
        { key: 'OtherAllowance', label: 'Other Allowances' },
        { key: 'allowance', format: moneyFormat },
    ],
    EIU1: [
        { key: 'firstName', label: 'First Name' },
        { key: 'lastName', label: 'Last Name' },
        { key: 'middleName', label: 'Middle Name' },
        { key: 'dateOfBirth', label: 'Date of Birth' },
        { key: 'employeeEmail', label: 'Email' },
        { key: 'phone', label: 'Phone' },
        { key: 'address', label: 'Address' },
        { key: 'city', label: 'City' },
        { key: 'state', label: 'State' },
        { key: 'country', label: 'Country' },
        { key: 'religion', label: 'Religion' },
        { key: 'employeeTribe', label: 'Tribe' },
        { key: 'workArrangement', label: 'Work Arrangement' },
        { key: 'employeeType', label: 'Employee Type', format: employeeTypeFormat },
        { key: 'gender', label: 'Gender' },
        { key: 'Employement Type', label: 'employementType' },
    ],
    EBI7: [
        { key: 'bank_name', label: 'Bank Name' },
        { key: 'account_name', label: 'Account Name' },
        { key: 'account', label: 'Account Number' },
    ],
    LR2: [
        { key: 'email', label: 'email' },
        { key: 'address', label: 'Address' },
        { key: 'contactNo', label: 'Contact Number' },
        { key: 'isPaid', label: 'Paid' },
        { key: 'employeeName', label: 'Full Name' },
        { key: 'hasAssignee', label: 'Has Assignee' },
        { key: 'leaveReason', label: 'Leave Reason' },
        { key: 'leaveStartDate', label: 'Commencement Date' },
        { key: 'leaveEndDate', label: 'End Date' },
        { key: 'leaveTypeName', label: 'Leave Type' },
        { key: 'supervisorName', label: 'Supervisor' },
        { key: 'workAssigneeName', label: 'Assigned To' },
        { key: 'leaveAllowanceApplied', label: 'Applied Leave Allowance' },
    ],
    RCO13: [
        { key: 'reason', label: 'Reason' },
        { key: 'amount', format: moneyFormat },
    ],
    RCA11: [
        { key: 'reason', label: 'Reason' },
        { key: 'amountRequested', label: 'Amount Requested' },
        { key: 'priority', label: 'Priority' },
    ],
    RO14: [
        { key: 'reason', label: 'Reason', format: (value: any) => value ?? 'N/A' },
        { key: 'status', label: 'Status', format: (value: any) => value ?? 'N/A' },
        { key: 'priority', label: 'Priority', format: (value: any) => value ?? 'N/A' },
        {
            key: 'requisitionName',
            label: 'Requisition Name',
            format: (value: any) => value ?? 'N/A',
        },
    ],
    RWT12: [
        { key: 'res', label: 'Worktool Type', format: (value: any) => value?.worktoolType },
        { key: 'res', label: 'Worktool Name', format: (value: any) => value?.worktoolName },
        { key: 'requestType', label: 'Request Type', format: (value: any) => value ?? 'N/A' },
        { key: 'priority', label: 'Priority', format: (value: any) => value ?? 'N/A' },
        { key: 'status', label: 'Status', format: (value: any) => value ?? 'N/A' },
        { key: 'quantity', label: 'Quantity', format: (value: any) => value ?? 'N/A' },
    ],

    EX11: [
        { key: 'exit', label: '' },
        { key: 'reason', label: '' },
        {
            key: 'lastWorkingDay',
            label: 'Last Working Day',
            format: (value = '') => value?.split('T')[0],
        },
        { key: 'comment', label: 'Comment' },
        { key: 'name', label: 'Name' },
        { key: 'document', label: 'Document' },
    ],
    JR8: [
        { key: 'jobRole', label: 'Job Role', format: (value: any) => value?.name },
        { key: 'jobRoleType', label: 'Job Role Type' },
        { key: 'jobType', label: 'Job Type' },
        { key: 'justification', label: 'Justification' },
        { key: 'location', label: 'Location' },
        { key: 'vacancyType', label: 'Vacancy Type' },
        { key: 'slotsAvailable', label: 'Slot Avaialble' },
        {
            key: 'cadre',
            label: 'Cadre',
            format: (value: any) => {
                let cadre: string[] = [];
                value.map((item: any) => cadre.push(item.cadreName));
                return cadre;
            },
        },
        { key: 'experienceLevel', label: 'Experience Level' },
        {
            key: 'applicationCloseDate',
            label: 'Application Close Date',
            format: (value: string) => value?.split('T')[0],
        },
        { key: 'educationalLevel', label: 'Educational Level' },
        {
            key: 'hiringDepartment',
            label: 'Hiring Department',
            format: (value: any) => value?.name,
        },
        {
            key: 'hiringManager',
            label: 'Hiring Manager',
            format: (value: any) => {
                return value?.name ?? value?.firstName + ' ' + value?.lastName;
            },
        },
        { key: 'additionalDocuments', label: 'Additional Documents' },
    ],
};

// Keep this in sync with the backend
export const WorkflowOptionsEnum: { [key: string]: string } = {
    CompanyRole: 'Company Role',
    ManagerReportsTo: 'Manager (Reports to)',
    ManagersManager: "Manager's Manager",
    SpecificPerson: 'Specific Person',
};

export function flipObject(obj: Record<string, string>): Record<string, string> {
    const flippedObj: Record<string, string> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            flippedObj[obj[key]] = key;
        }
    }
    return flippedObj;
}

export function mapObjectKeyToKey(obj: Record<string, string>): Record<string, string> {
    const mapping: Record<string, string> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            mapping[key] = key;
        }
    }
    return mapping;
}

export function cloneObject(toClone: object): any {
    return Object.assign({}, toClone);
}

export function splitAndAddSpaces(inputString: string) {
    return inputString
        .split(/(?=[A-Z])/)
        .join(' ')
        .trim();
}

export function capitalizeString(inputString: string) {
    if (inputString.length < 0) return inputString;

    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export function buildWorkflowEmailTable(workflowProcess: any) {
    const WorkflowOptionsKeyEnum = mapObjectKeyToKey(WorkflowOptionsEnum);

    let tableHTML =
        '<table class="tg"> ' +
        '<thead>' +
        '    <tr>' +
        '        <th class="tg-0pky">Approval Type</th>' +
        '        <th class="tg-0pky">Approvers</th>' +
        '        <th class="tg-0pky">Status</th>' +
        '     <th class="tg-0pky" > Comment </th>' +
        '    </tr>' +
        '</thead><tbody>';

    for (let i = 0; i < workflowProcess.length; i++) {
        const element = workflowProcess[i];

        let type = element.type;
        if (type === WorkflowOptionsKeyEnum.CompanyRole) {
            type += `: ${element.roleName}`;
        }
        let status = element.status;
        let approvers = '';

        // Check if "approvers" key exists and build the approvers list
        if (element.approvers && element.approvers.length > 0) {
            approvers = '<ul style="list-style-type:none !important;">';
            for (const approver of element.approvers) {
                approvers += `<li>${approver.name}</li>`;
            }
            approvers += '</ul>';
        } else {
            approvers = '<ul style="list-style-type:none;">Pending Current Approval</ul>';
        }

        // Add a new row to the HTML table
        tableHTML += `<tr><td class="tg-0lax">${splitAndAddSpaces(
            type
        )}</td><td class="tg-0lax">${approvers}</td><td class="tg-0lax">${capitalizeString(
            status
        )}</td>
        <td class="tg-0lax">${element.comment || ''}</td>
        
        </tr>`;
    }

    tableHTML += '</tbody></table>';

    return tableHTML;
}
