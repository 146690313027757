import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
    @Input() errorMessage: string = '';

    constructor() {}

    ngOnInit() {}
}
