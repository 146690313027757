import {
    MODULES as MM,
    APPLICATION_PAGE_VIEW_PERMISSIONS as MAPVP,
    APPLICATION_ACTION_BASED_PERMISSIONS as AABP,
} from 'myxalary-essentials';

export const MODULES = MM;

export const APPLICATION_PAGE_VIEW_PERMISSIONS = MAPVP;

export const APPLICATION_ACTION_BASED_PERMISSIONS = AABP;
