import { Component, Input, OnInit } from '@angular/core';
import { IHealthProvider } from '../../containers/pay-via-myxalary/pay-via-myxalary.data';

@Component({
    selector: 'app-hmo-provider',
    templateUrl: './hmo-provider.component.html',
    styleUrls: ['./hmo-provider.component.scss'],
})
export class HmoProviderComponent implements OnInit {
    @Input() hmo!: IHealthProvider;

    constructor() {}

    ngOnInit(): void {}
}
