import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
    @Input() config!: any;
    @Output() action = new EventEmitter();
    constructor() {}

    ngOnInit(): void {}

    handleButton(title: string) {
        this.action.emit(title);
    }
}
