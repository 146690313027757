<modal-cover [swal]="swal" title="Enter OTP">
    <section class="p-5 h-100 w-100">
        <form [formGroup]="formData" (ngSubmit)="move()" class="d-flex gap-10 h-100 flex-column">
            <div class="form-group form-style w-100">
                <label class="font-weight-bold" for="title"
                    >Pin <span class="text-danger">*</span></label
                >
                <p-password
                    [toggleMask]="true"
                    [feedback]="false"
                    formControlName="pin"
                    inputStyleClass="form-control "
                ></p-password>
            </div>
            <div class="form-group form-style w-100">
                <label class="font-weight-bold" for="title"
                    >OTP<span class="text-danger">*</span></label
                >
                <p-inputMask
                    formControlName="otp"
                    class="w-100"
                    inputStyleClass="form-control w-100 "
                    mask="999999"
                    placeholder="xxxxxx"
                ></p-inputMask>
                <span
                    [style.color]="otpBtn.color"
                    (click)="getOTP()"
                    class="add-bene clickable"
                    for="title"
                    >{{ otpBtn.label }}</span
                >
            </div>
            <div class="d-flex btn-grp justify-content-between">
                <button [disabled]="formData.invalid" type="submit" class="btn btn-primary">
                    Continue
                </button>
            </div>
        </form>
    </section>
</modal-cover>
