import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

type svgIconName = 'success' | 'danger' | 'error';
interface SUCCESSMODALINFO {
    title: string;
    subtitle?: string;
    subtitle2?: string;
    btn: Btn[];
    svgIcon: svgIconName;
}
interface Btn {
    title: string;
    btnStyle: string;
}
@Component({
    selector: 'app-success-view',
    templateUrl: './success-view.component.html',
    styleUrls: ['./success-view.component.scss'],
})
export class SuccessViewComponent implements OnInit, OnDestroy {
    @Input() info!: SUCCESSMODALINFO;
    @Output() buttonOutput = new EventEmitter();
    //   contentHeader = {
    //     headerTitle: 'Recruitment',
    //     actionButton: true,
    //     breadcrumb: {
    //       links: [
    //         {
    //           name: 'Dashboard',
    //           isLink: true,
    //           link: '/recruitment',
    //         },
    //         {
    //           name: 'Talent Pool',
    //           isLink: true,
    //           link: '/recruitment/talent-pool',
    //         },
    //         {
    //           name: 'Add New Talent',
    //           isLink: false,
    //         },
    //       ],
    //     },
    //   };

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.info.svgIcon = this.info.svgIcon || 'success';
    }

    handleButtonEvent(title: string) {
        this.buttonOutput.emit(title);
        this.closeModal();
    }

    closeModal(): void {
        this.activeModal.dismiss();
    }

    ngOnDestroy(): void {
        this.buttonOutput.emit('Return to Dashboard');
    }
}
