import { IPurchase } from './../../health.data';
import { ActionReducerMap, createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import * as fromHealth from './health.reducer';

export const HEALTH = 'health-data';

export interface IHealthState {
    health: fromHealth.IState;
}

export const reducers: ActionReducerMap<IHealthState> = {
    health: fromHealth.reducer,
};
export * from './health.reducer';

//selectors
const selectFeature = createFeatureSelector<IHealthState>(HEALTH);

export const selectHmoProviders = createSelector(
    selectFeature,
    (state: IHealthState) => state.health.hmoProviders
);

export const selectEmployees = createSelector(
    selectFeature,
    (state: IHealthState) => state.health.employees
);

export const selectPurchaseData = createSelector(
    selectFeature,
    (state: IHealthState) => state.health.purchaseData
);

export const selectPurchaseHistories = createSelector(
    selectFeature,
    (state: IHealthState) => state.health.purchaseHistories
);

export const selectExpiringPlans = createSelector(
    selectFeature,
    (state: IHealthState) => state.health.expiringPlans
);
