<app-lang></app-lang>
<div *ngIf="!showOtp" class="position-relative">
    <a
        class="position-absolute top-0 end-0 text-primary p-4 h5"
        routerLink="/register"
        style="top: 0; right: 0"
    >
        {{ 'Sign Up' | appTranslate }}
        <i class="fas fa-solid fa-arrow-right"></i>
    </a>
</div>

<div *ngIf="showOtp" class="position-relative">
    <a
        class="position-absolute top-0 end-0 text-primary p-4 h5"
        (click)="setDefault()"
        style="top: 0; right: 0"
    >
        {{ 'Login' | appTranslate }}
        <i class="fas fa-solid fa-arrow-right"></i>
    </a>
</div>

<div class="container-wrap">
    <img
        style="width: 150px"
        src="../../../../assets/images/notch-hr.png"
        class="img-fluid"
        alt="NotchHR Logo"
    />
    <div *ngIf="!showOtp" style="width: 35%" class="row justify-content-center">
        <div class="col-md-12">
            <div class="wrap d-md-flex">
                <div class="login-wrap p-4 p-md-5">
                    <p class="mb-4 h4 text-primary text-center">{{ 'Sign In' | appTranslate }}</p>

                    <form (ngSubmit)="handleLogin()" [formGroup]="loginForm" autocomplete="on">
                        <div class="form-group form-style w-100 mb-3">
                            <label class="label font-weight-bold" for="email-field">Email</label>
                            <input
                                id="email-field"
                                class="form-control"
                                type="email"
                                placeholder="Email"
                                formControlName="email"
                            />
                            <div
                                class="mt-2 text-small text-danger"
                                *ngIf="
                                    loginForm.get('email').touched && loginForm.get('email').errors
                                "
                            >
                                <div *ngIf="loginForm.get('email').errors.required">
                                    {{ 'Email is required' | appTranslate }}
                                </div>
                                <div *ngIf="loginForm.get('email').errors.pattern">
                                    {{ 'Please enter a valid email address' | appTranslate }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group form-style w-100">
                            <label class="font-weight-bold" for="title"
                                >{{ 'Password' | appTranslate }}
                                <span class="text-danger">*</span></label
                            >
                            <p-password
                                [toggleMask]="true"
                                formControlName="password"
                                [feedback]="false"
                                inputStyleClass="form-control "
                            ></p-password>
                        </div>
                        <h3 class="text-danger text-center" *ngIf="loginError !== ''">
                            {{ loginError }}
                        </h3>
                        <div class="form-group form-style">
                            <button
                                type="submit"
                                [disabled]="loginForm.invalid && loginForm.touched"
                                class="form-control btn btn-primary rounded"
                                style="
                                    padding-top: 12px !important;
                                    padding-bottom: 12px !important;
                                    font-size: 15px !important;
                                "
                            >
                                {{ 'Sign in' | appTranslate }}
                            </button>
                        </div>
                        <div class="form-group form-style d-md-flex">
                            <div class="w-50 text-left">
                                <label class="checkbox-wrap checkbox-primary mb-0">
                                    {{ 'Remember this Browser' | appTranslate }}
                                    <input type="checkbox" formControlName="rememberMe" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="w-50 text-md-right">
                                <a [routerLink]="['/forgot-password']" class="text-primary">
                                    {{ 'Forgot Password' | appTranslate }}
                                </a>
                            </div>
                        </div>
                    </form>

                    <div class="text-center">
                        <a (click)="resend()" class="text-primary text-lg" role="button">
                            {{ 'Resend Confirmation email' | appTranslate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showOtp" class="w-100 h-100 d-flex justify-content-center align-items-center">
        <div class="row justify-content-center w-100" style="margin-block: 10rem;">
            <div class="my-10 form-group form-style w-100 text-center">
                <label class="font-weight-bold" for="title">
                    <h2>{{ 'One Time Password (OTP)' | appTranslate }}</h2>
                    <p>{{ 'Please enter the OTP sent to your mail' | appTranslate }}</p>
                </label>
    
                <div class="flex justify-content-center">
                    <p-inputOtp [(ngModel)]="otp" [length]="6" class="flex justify-content-center">
                        <ng-template pTemplate="input" let-token let-events="events">
                            <input 
                                pInputText
                                class="custom-otp-input" 
                                (input)="events.input($event)" 
                                (keydown)="events.keydown($event)" 
                                type="text" 
                                [attr.value]="token" 
                                maxlength="1" />
                        </ng-template>
                    </p-inputOtp>
                </div>                
    
                <!-- Button Group Centered -->
                <div class="mt-4 d-flex justify-content-center gap-3">
                    <button (click)="submitOtp()" class="btn btn-primary">
                        {{ 'Submit' | appTranslate }}
                    </button>
                    <button (click)="resendOtp()" class="btn btn-outline-primary">
                        {{ 'Resend OTP' | appTranslate }}
                    </button>
                </div>
            </div>        
        </div>
    </div>
</div>
