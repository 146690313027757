import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GeneralService } from 'src/app/shared/services/general.service';
import { UtilsService } from '../../services/utils.service';
import { AppStore } from 'src/app/app-data';
import { take } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-view-transactions',
    templateUrl: './view-transactions.component.html',
    styleUrls: ['./view-transactions.component.scss', '../../../../../formStyle.scss'],
})
export class ViewTransactionsComponent implements OnInit {
    @Input()
    data!: any;
    user!: any;
    openRetry: boolean = false;
    pinControl = new UntypedFormControl('', [Validators.required]);
    constructor(
        private api: ApiService,
        private gs: GeneralService,
        private utils: UtilsService,
        private _appStore: AppStore,
        private modal: NgbModal
    ) {}

    ngOnInit(): void {
        this.init();
    }

    get balance() {
        return this.utils.balance;
    }

    async init() {
        await this.api.getBalance();
        this.user = await this._appStore.getUserInfo().pipe(take(1)).toPromise();
    }

    async retryTransaction() {
        if (!this.user.companyData.isCompliant) {
            return this.gs.alertInfo.next({
                text: 'Your Compliance is still pending!!!',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 3000,
            });
        }
        if (Number(this.data.amount) < 100) {
            return this.gs.alertInfo.next({
                text: 'Amount Should not be less than 100',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 3000,
            });
        }
        if (Number(this.data.amount) > Number(this.balance.getValue())) {
            return this.gs.alertInfo.next({
                text: 'Insufficient Funds',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 3000,
            });
        }

        await this.api.transfer({
            beneficiary: this.data.beneficiary,
            pin: this.pinControl.value,
            amount: Number(this.data.amount),
        });
        await this.api.getBalance();

        this.gs.alertInfo.next({
            text: 'Transfer Successful',
            btnClass: 'alert-success',
            btnIcon: 'checklist',
            timer: 3000,
        });
        this.modal.dismissAll();
    }
}
