import { iTableData } from './../../../../shared/models/interfaces';
export interface iAnalyticsItem {
    avatar: string;
    title: number;
    description: string;
    currency?: string;
}

export interface iAnalyticsData {
    year: number;
    items: iAnalyticsItem[];
}

// export const analyticsData: iAnalyticsData[] = [
//   {
//     year: 2021,
//     items: [
//       {
//         avatar: '/assets/icons/user.png',
//         title: '78',
//         description: 'Registered Employees',
//       },
//       {
//         avatar: '/assets/icons/emp_neutral.png',
//         title: '12',
//         description: 'Unregistered Employees',
//       },
//       {
//         avatar: '/assets/icons/emp_danger.png',
//         title: '78',
//         description: 'Expiring Plans',
//       },
//       {
//         avatar: '/assets/icons/dollar.png',
//         title: '2000000',
//         description: 'Total Spent',
//         currency: '₦',
//       },
//     ],
//   },
//   {
//     year: 2022,
//     items: [
//       {
//         avatar: '/assets/icons/user.png',
//         title: '100',
//         description: 'Registered Employees',
//       },
//       {
//         avatar: '/assets/icons/emp_neutral.png',
//         title: '120',
//         description: 'Unregistered Employees',
//       },
//       {
//         avatar: '/assets/icons/emp_danger.png',
//         title: '780',
//         description: 'Expiring Plans',
//       },
//       {
//         avatar: '/assets/icons/dollar.png',
//         title: '1000000',
//         description: 'Total Spent',
//         currency: '₦',
//       },
//     ],
//   },
// ];

export const expiringPlansData: iTableData = {
    header: [
        {
            title: 'Employee Name',
            key: 'employeeName',
        },
        {
            title: 'HMO Provider',
            key: 'hmoProvider',
        },
        {
            title: 'Plan Name',
            key: 'planName',
        },
        {
            title: 'Cost',
            key: 'cost',
        },
        {
            title: 'Start Date',
            key: 'startdate',
        },
        {
            title: 'End Date',
            key: 'enddate',
        },
        {
            title: 'Status',
            key: 'status',
        },
    ],
    body: [],
    config: {},
};

export const paymentHistories: iTableData = {
    header: [
        {
            title: 'HMO Provider',
            key: 'providerName',
        },
        {
            title: 'Cost',
            key: 'cost',
            isCurrency: true,
        },
        {
            title: 'Start Date',
            key: 'startDate',
        },
        {
            title: 'Status',
            key: 'status',
        },
    ],
    body: [],
    config: {
        allowExport: true,
    },
};
