<section>
    <modal-cover [title]="config.title">
        <div class="w-100 m-4">
            <div class="d-flex justify-content-center flex-column align-items-center">
                <div class="icon-section mb-4">
                    <mat-icon [svgIcon]="config.svgIcon" class="icon"></mat-icon>
                </div>
                <h4 class="icon-section mb-4">
                    {{ config.text }}
                </h4>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center align-items-center mt-2">
                    <button
                        class="btn mr-4"
                        [ngClass]="btn.btnStyle"
                        *ngFor="let btn of config.btns"
                        (click)="handleButton(btn.title)"
                    >
                        {{ btn.title }}
                    </button>
                </div>
            </div>
        </div>
    </modal-cover>
</section>
