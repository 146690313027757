<div class="avatar-group">
    <ng-container *ngFor="let invitee of data; let i = index">
        <div
            *ngIf="i < max"
            (click)="returnData(invitee)"
            data-toggle="tooltip"
            data-popup="tooltip-custom"
            [ngStyle]="i > 0 ? nstyle : {}"
            [ngClass]="avgClass"
            placement="top"
            container="body"
            title=""
            class="avatar pull-up my-0"
            [ngbTooltip]="key ? invitee[key].name : invitee.name"
        >
            <img
                src="{{ getImage(key ? invitee[key] : invitee) }}"
                alt="Avatar"
                height="26"
                width="26"
            />
        </div>
    </ng-container>
    <ng-container *ngIf="data.length > max">
        <div
            (click)="returnData({})"
            [ngStyle]="nstyle"
            [ngClass]="avgExtraClass"
            class="avatar pull-up my-0"
        >
            <span class="count">+{{ data.length - max }}</span>
        </div>
    </ng-container>
</div>
