import { Component, OnInit } from '@angular/core';
import { HealthDispatcher } from './health-data';

@Component({
    selector: 'app-health-wrapper',
    template: ` <router-outlet></router-outlet> `,
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthWrapperComponent implements OnInit {
    constructor(private _healthDispatcher: HealthDispatcher) {}

    ngOnInit(): void {
        // this._healthDispatcher.fetchHmoProviders();
        //  this._healthDispatcher.fetchEmployees();
        // ]]this._healthDispatcher.fetchPurchaseHistories({ limit: 10, skip: 1 });
    }
}
