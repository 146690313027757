<modal-cover [title]="'Switch Organization'">
    <div class="container">
        <div class="row companies-container">
            <div
                *ngFor="let company of companies"
                class="col col-12 col-sm-4 col-md-4 col-lg-3 mb-3"
            >
                <div
                    class="p-3 company-card text-center"
                    (click)="handleSelectOrganization(company)"
                >
                    <input
                        type="checkbox"
                        class="company-checkbox"
                        [disabled]="true"
                        [checked]="selectedCompanyId === company._id"
                    />
                    <img [src]="getImage(company)" alt="company-logo" class="mt-2 img" />
                    <p [ngbTooltip]="company.companyName" class="mt-2 cname">
                        {{ company.companyName }}
                    </p>
                    <span [ngClass]="company.isActive ? 'text-success' : 'text-danger'">{{
                        company.isActive ? 'Active' : 'Deactivated'
                    }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col col-12">
                <button
                    class="my-4 btn btn-primary w-50 btn-lg rounded-lg"
                    (click)="handleSwitchOrganization()"
                    style="padding: 14px !important; font-size: 16px !important"
                    [disabled]="!selectedCompanyId || activeCompanyId === selectedCompanyId"
                >
                    Continue
                </button>
            </div>
        </div>
    </div>
</modal-cover>
