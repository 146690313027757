import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { combineLatest, forkJoin, of } from 'rxjs';
import { catchError, combineAll, tap } from 'rxjs/operators';

import { AppDispatcher } from 'src/app/app-data';
import { environment } from 'src/environments/environment';

import { SETTINGS_ROLE } from 'src/app/shared/models/constants';
import { CustomIconService } from 'src/app/utils/custom-icon.service';
import { GeneralService } from '../../../shared/services/general.service';
import { Chatv2Service } from '../../chats-v2/services/chatv2.service';
import { UserService } from '../../lms/services/user.service';
import { AppSocketService } from 'src/app/shared/services/app-socket.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UtilsService } from '../../dashboard/services/utils.service';

@Component({
    selector: 'app-authenticated',
    templateUrl: './authenticated.component.html',
    styleUrls: ['./authenticated.component.scss'],
})
export class AuthenticatedComponent implements OnInit {
    title = 'Myxalary2';
    loaded: boolean = false;
    endpoint = environment.myXalaryEndpoint;

    constructor(
        private _http: HttpClient,
        private genSrv: GeneralService,
        private _dispatcher: AppDispatcher,
        private lmsUserService: UserService,
        private cs: Chatv2Service,
        private customIconService: CustomIconService,
        private appSocket: AppSocketService,
        private notify: NotificationService,
        private dashUtils: UtilsService
    ) {
        this.customIconService.init();
    }

    ngOnInit(): void {
        let comboEndpoints: any = [
            `${this.endpoint}/v1/users/profile`,
            `${environment.notificationEndPoint}api/v1/notification/list?notificationTypes=in-app,bulkUpdate&sort=createdOn:-1&limit=10`,
        ];
        const token = new URLSearchParams(window.location.search).get('eaid');
        if (token) {
            this.genSrv.setAppToken(token);
        }
        comboEndpoints = comboEndpoints.map((url: string) =>
            this._http.get<any>(url).pipe(catchError((error) => of(null)))
        );
        combineLatest([forkJoin(comboEndpoints), this.genSrv.getCombos()]).subscribe(
            async ([[user, notification], combos]: any[]) => {
                if (!user) {
                    return this.genSrv.alertInfo.next({
                        text: `Could not fetch User Info`,
                        btnClass: 'alert-danger',
                        btnIcon: 'error',
                        timer: 5000,
                    });
                }
                await this.lmsUserService
                    .generateLMSToken(user._id, user.companyID)
                    .catch((err) => {
                        console.log(err);
                    });
                user.canViewChatSettings = SETTINGS_ROLE.includes(user.userRole.toUpperCase());
                this._dispatcher.setUserInfo({ ...user });
                this._dispatcher.setCombo({ ...combos });
                this._dispatcher.setNotification(notification?.data?.data);
                this.notify.notifyCount.next(notification?.data?.totalNotViewed);
                this.appSocket.listeners();
                this.cs.setupChat();
                this.dashUtils.dashcards.next(this.genSrv.getDashCards(user._id));
                this.fetchToken();
                //setCombos
                this.loaded = true;
            }
        );
    }

    fetchToken() {
        this._http
            .post<any>(
                `${environment.myXalaryEndpoint}/v1/integration/generate-external-token/user/meetings-integration?useragent=web`,
                {}
            )
            .pipe(
                tap((data) => {
                    const token = data.response;
                    this.genSrv.setMeetingToken(token);
                })
            )
            .subscribe();
    }
}
