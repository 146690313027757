import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-data-table-custom',
    template:
        "<ng-container [ngTemplateOutletContext]='{ data: data , index: index}' [ngTemplateOutlet]='template'></ng-container>",
})
export class DataTableCustom implements OnInit {
    @Input() template!: TemplateRef<any>;
    @Input() data: any;
    @Input() index: any;
    constructor() {}
    ngOnInit(): void {}
}
