<div>
    <mat-icon
        svgIcon="carrot-left-gray"
        [ngStyle]="{
            cursor: paginatorSetup.currentPage > 1 ? 'pointer' : 'no-drop',
        }"
        (click)="paginatorSetup.currentPage > 1 ? paginate('less') : ''"
        class="icon"
    ></mat-icon>
    <span class="pagination-counter"
        >{{ paginatorSetup.currentPage }} of {{ paginatorSetup.totalPages }}</span
    >
    <mat-icon
        svgIcon="carrot-right-gray"
        [ngStyle]="{
            cursor: paginatorSetup.currentPage < paginatorSetup.totalPages ? 'pointer' : 'no-drop',
        }"
        (click)="paginatorSetup.currentPage < paginatorSetup.totalPages ? paginate('add') : ''"
        class="icon"
    ></mat-icon>
</div>
