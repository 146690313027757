<table *ngIf="!isJobShow" class="table table-bordered w-100 text-center">
    <thead class="table-bordered w-100">
        <tr>
            <th class="text-bold">#</th>
            <th class="text-bold" style="width: 200px !important">Title</th>
            <th class="text-bold">Required Rating</th>
            <th class="text-bold">Employee Rating</th>
            <th class="text-bold">Remark</th>
        </tr>
    </thead>
    <tbody class="w-100">
        <tr *ngFor="let skill of employeeSkills; let i = index">
            <td>{{ i + 1 }}</td>
            <td class="text-capitalize" style="width: 200px !important">
                <span class="truncate-text" [ngbTooltip]="skill.name">{{ skill.name }}</span>
            </td>
            <td>{{ skill.required_rating ? skill.required_rating : '0' }}/5</td>
            <td>
                <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        [attr.fill]="star <= skill.employee_rating ? '#FFD700' : '#5E5873'"
                    >
                        <!-- Insert star SVG path code here -->
                        <path
                            d="M12 2L14.398 8.52L21 9.24L16.82 14.16L18.395 21L12 17.76L5.605 21L7.18 14.16L3 9.24L9.602 8.52L12 2Z"
                        />
                    </svg>
                </ng-container>
                <!-- {{skill.employee_rating?skill.employee_rating:"0" }}/5  -->
            </td>
            <td>
                <svg
                    *ngIf="skill.meets_requirements"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <path
                        d="M7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85651 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85651 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85651 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85651 14 7 14ZM5.6 10.5L11.9 4.55L10.85 3.5L5.6 8.4L3.15 5.95L2.1 7L5.6 10.5Z"
                        fill="#3AA14E"
                    />
                </svg>
                <svg
                    *ngIf="!skill.meets_requirements"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <path
                        d="M7 0C3.129 0 0 3.129 0 7C0 10.871 3.129 14 7 14C10.871 14 14 10.871 14 7C14 3.129 10.871 0 7 0ZM10.5 9.513L9.513 10.5L7 7.987L4.487 10.5L3.5 9.513L6.013 7L3.5 4.487L4.487 3.5L7 6.013L9.513 3.5L10.5 4.487L7.987 7L10.5 9.513Z"
                        fill="#FF0000"
                    />
                </svg>
            </td>
        </tr>
    </tbody>
</table>

<div *ngIf="isJobShow" class="w-100">
    <h2 class="text-bold ml-1 mt-5">Job Role Competency</h2>
    <table class="table table-bordered w-100 text-center">
        <thead class="table-bordered w-100">
            <tr>
                <th class="text-bold">#</th>
                <th class="text-bold" style="width: 20px !important">Title</th>
                <th class="text-bold">Required Rating</th>
            </tr>
        </thead>
        <tbody class="w-100">
            <tr *ngFor="let skill of jobRoleSkills; let i = index">
                <td>{{ i + 1 }}</td>
                <td class="text-capitalize" style="width: 200px !important">
                    <span class="truncate-text" [ngbTooltip]="skill.skill">{{ skill.skill }}</span>
                </td>
                <td>{{ skill.rating ? skill.rating : '0' }}/5</td>
                <td>
                    <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            [attr.fill]="star <= skill.rating ? '#FFD700' : '#5E5873'"
                        >
                            <!-- Insert star SVG path code here -->
                            <path
                                d="M12 2L14.398 8.52L21 9.24L16.82 14.16L18.395 21L12 17.76L5.605 21L7.18 14.16L3 9.24L9.602 8.52L12 2Z"
                            />
                        </svg>
                    </ng-container>
                    <!-- {{skill.employee_rating?skill.employee_rating:"0" }}/5  -->
                </td>
            </tr>
        </tbody>
    </table>
</div>
