import { Component, ContentChild, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';
import { checkLicenseModulesStatus } from 'src/app/utils/license';

@Component({
    selector: 'app-auth-wrapper',
    templateUrl: './auth-wrapper.component.html',
    styleUrls: ['./auth-wrapper.component.scss'],
})
export class AuthWrapperComponent implements OnInit {
    userHasAccess: boolean = false;
    userHasAccessErrorMessage: string = '';
    @ContentChild(TemplateRef)
    templateRef!: TemplateRef<any>;
    @Input()
    permission!: any;

    @Input()
    modules: any = {};

    @Input()
    module!: any;

    @Input()
    listener!: Subject<any>;
    constructor(private permissionService: PermissionService) {}

    ngOnInit(): void {
        if (this.permission) {
            const { userHasAccess, userHasAccessErrorMessage } =
                this.permissionService.checkUserHasAccess({
                    allowedPermissions: this.permission,
                });
            this.userHasAccess = userHasAccess;
            this.userHasAccessErrorMessage = userHasAccessErrorMessage;
            if (this.module) {
                this.modules = this.modules ?? {};
                const license = checkLicenseModulesStatus(this.modules, this.module?.key);
                if (userHasAccess && !license) {
                    this.userHasAccess = false;
                    this.userHasAccessErrorMessage = `This Employee does not have ${this.module?.label} License`;
                }
            }
            this.listener?.next({
                userHasAccess: this.userHasAccess,
                userHasAccessErrorMessage: this.userHasAccessErrorMessage,
            });
        } else if (this.module) {
            this.modules = this.modules ?? {};
            const license = checkLicenseModulesStatus(this.modules, this.module?.key);
            this.userHasAccess = license;
            if (!license) {
                this.userHasAccessErrorMessage = `This Employee does not have ${this.module?.label} License`;
            }
            this.listener?.next({
                userHasAccess: license,
                userHasAccessErrorMessage: this.userHasAccessErrorMessage,
            });
        }
    }
}
