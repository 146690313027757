<div class="main-container">
    <app-lang></app-lang>
    <div class="logo">
        <div class="left">
            <img class="custom-logo-header" src="/assets/images/notch-hr-edt.png" />
        </div>

        <div class="right">
            <p>
                <a class="btn btn-custom-bg" [routerLink]="['/login']">{{
                    'Login' | appTranslate
                }}</a>
            </p>

            <img src="/assets/images/arrowforward.svg" />
        </div>
    </div>

    <div class="center-logo">
        <img class="custom-logo" src="/assets/images/notch-hr.png" />
    </div>

    <div class="head">
        <h4>{{ 'Forgot Password' | appTranslate }}</h4>
    </div>

    <form
        #ForgotForm="ngForm"
        (ngSubmit)="onSubmit(ForgotForm)"
        class="d-flex gap-10 h-100 flex-column"
    >
        <div class="input-content wrap">
            <div class="note">
                <p>{{ 'Enter Your Email Address to Reset Password' | appTranslate }}</p>
            </div>

            <div class="form-group form-style w-50">
                <label for="userEmail">{{ 'Email' | appTranslate }}</label>
                <input
                    #userEmail="ngModel"
                    [(ngModel)]="model.userEmail"
                    name="userEmail"
                    type="email"
                    required
                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}$"
                    autocomplete="off"
                    id="userEmail"
                    [placeholder]="'Enter email address' | appTranslate"
                    minlength="5"
                    maxlength="255"
                    class="form-control"
                />

                <div
                    class="mt-2 text-small text-danger"
                    *ngIf="userEmail?.invalid && (userEmail.dirty || userEmail.touched)"
                >
                    <div *ngIf="userEmail?.errors?.required">
                        {{ 'Email is required' | appTranslate }}
                    </div>
                    <div *ngIf="userEmail?.errors?.minlength">
                        {{ 'Email must be at least 5 characters long' | appTranslate }}
                    </div>
                    <div *ngIf="userEmail?.errors?.pattern">
                        {{ 'Please provide a valid email address' | appTranslate }}
                    </div>
                </div>
            </div>

            <div class="next">
                <button [disabled]="!ForgotForm.valid" class="btn btn-primary">
                    {{ 'Reset Password' | appTranslate }}
                </button>
            </div>
        </div>
    </form>
</div>
