import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import endpoints from 'src/app/utils/endpoints';

@Component({
    selector: 'app-employee-categories',
    templateUrl: './employee-categories.component.html',
    styleUrls: ['./employee-categories.component.scss'],
})
export class EmployeeCategoriesComponent implements OnInit {
    url = `${endpoints.MX_BE.V1.employees.getEmployeeCategory}`;
    data: any[] = [];
    @Output()
    change = new EventEmitter();
    @Input()
    selected: { [key: string]: any } = {};
    constructor(private http: HttpClient) {}

    ngOnInit(): void {
        this.http.get(this.url).subscribe((result: any) => {
            const data = result.data[0];
            for (let keys in data) {
                this.data.push({
                    category: keys,
                    data: data[keys],
                });
            }
        });
    }

    onSelect(category: string, data: any) {
        category = category.replace(' ', '');
        if (!this.selected[category]) {
            this.selected[category] = [
                {
                    ...data,
                    id: data._id,
                },
            ];
        } else {
            const index = this.selected[category].findIndex((v: any) => v.id === data._id);
            index > -1
                ? this.selected[category].splice(index, 1)
                : this.selected[category].push({
                      ...data,
                      id: data._id,
                  });
        }
        this.change.emit(this.selected);
    }

    selectNumb(category: string) {
        category = category.replace(' ', '');
        let num = 0;
        let employees: any[] = [];
        for (let items of this.selected[category]) {
            let item = this.data.find((v: any) => v.category.replace(' ', '') === category);
            if (!item) continue;
            item = item.data.find((v: any) => v._id === items.id);
            if (!item) continue;
            num += item.noOfEmployees;
            employees = [...employees, ...item.employees];
        }
        return {
            count: num,
            employees,
            label: num === 0 ? '' : `(${num} Employee(s))`,
        };
    }

    isChecked(item: any, category: string) {
        category = category.replace(' ', '');
        const index = this.selected[category]?.findIndex((v: any) => v.id === item._id);
        return index > -1;
    }

    get totalSelected() {
        const allEmployees = Object.keys(this.selected).reduce((p: any, c: any) => {
            return p.concat(this.selectNumb(c).employees);
        }, []);
        return [...new Set(allEmployees)].length;
    }
}
