import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IRoles, Role } from '../../models/interfaces';
import { HttpClient } from '@angular/common/http';
import endpoints from 'src/app/utils/endpoints';
import constants from 'src/app/modules/performance/utils/constants';
import appConstants from 'src/app/utils/constants';
import { BehaviorSubject } from 'rxjs';
import { AppStore } from 'src/app/app-data';
import { take } from 'rxjs/operators';
import { Combos } from 'src/app/interfaces/general';
import { GeneralService } from '../../services/general.service';

@Component({
    selector: 'app-approval-flow',
    templateUrl: './approval-flow.component.html',
    styleUrls: ['./approval-flow.component.scss'],
})
export class ApprovalFlowComponent implements OnInit {
    formInput!: UntypedFormGroup;
    allRoles: Role[] = [];
    MM = {
        id: 'managersmanager',
        name: 'mangers manager',
        type: 'managersmanager',
    };
    noAdd: boolean = false;
    defaultRoles: Role[] = [
        {
            id: 'manager',
            name: 'manager',
            type: 'manager',
        },
        {
            id: 'specific_person',
            name: 'specific person',
            type: 'person',
        },
    ];
    possibleRoles: Role[] = [];
    users: any[] = [];
    possibleUsers = new BehaviorSubject<any[]>([]);
    @Output()
    getValues = new EventEmitter();
    @Input()
    workflows: IRoles[] = [];
    cadres: any[] = [];
    constructor(
        private fb: UntypedFormBuilder,
        private http: HttpClient,
        private store: AppStore,
        private gs: GeneralService
    ) {}

    async setup() {
        try {
            const result: any = await this.http.get(endpoints.MX_BE.V1.roles.getRoles).toPromise();
            this.allRoles = [
                ...this.defaultRoles,
                ...result.data.map((v: any) => ({
                    id: v._id,
                    name: v.roleName,
                    type: appConstants.APPROVALTYPES.ADMIN_ROLES,
                })),
            ];
        } catch (e) {}

        const value: Combos = await this.store.getCombos().pipe(take(1)).toPromise();
        this.users = this.gs.transformData(
            this.gs.filterOnlyValid(value.employees.concat(value.users))
        );
        this.cadres = value.cadres.map((value: any) => ({
            id: value._id,
            name: value.cadreName,
            type: appConstants.APPROVALTYPES.CADRES,
        }));
        this.allRoles = [...this.allRoles, ...this.cadres];
        this.possibleRoles = this.allRoles;
        this.possibleUsers.next(this.users);

        this.formInput = this.fb.group({
            levels: this.fb.array(this.initialRole(), [Validators.min(1)]),
        });

        this.formInput.get('levels')?.valueChanges.subscribe((value) => {
            this.removeMangersManager(value);
            const userIds = value.filter((v: any) => v.employee).map((v: any) => v.employee.id);
            this.removePossibleUser(userIds);
            this.getValues.emit(value);
        });
    }

    removePossibleUser(value: any) {
        const current = this.possibleUsers
            .getValue()
            .map((v) => ({ ...v, disabled: value.includes(v.id) }));
        this.possibleUsers.next([...current]);
    }

    removeMangersManager(value: any) {
        const mmIndex = value.findIndex((v: any) => v.id === this.MM.id);
        if (mmIndex > -1) {
            const mIndex = value.findIndex((v: any) => v.id === this.defaultRoles[0].id);
            if (!(mIndex > -1)) {
                this.list.removeAt(mmIndex);
            }
        }
    }
    initialRole() {
        if (this.workflows?.length) {
            return this.workflows.map((value: any) => {
                if (value.name === appConstants.ROLES.OWNER) {
                    this.noAdd = true;
                }
                return this.addLevel(value);
            });
        }
        return [this.addLevel({})];
    }
    addLevel(data: any) {
        const employee = data?.employee
            ? { employee: this.fb.control(data.employee, [Validators.required]) }
            : {};
        return this.fb.group({
            id: this.fb.control(data?.id || '', [Validators.required]),
            name: this.fb.control(data?.name || '', [Validators.required]),
            type: this.fb.control(data?.type || '', [Validators.required]),
            ...employee,
        });
    }
    get list() {
        return this.formInput.controls['levels'] as UntypedFormArray;
    }
    ngOnInit(): void {
        this.setup();
    }

    removeLevel(i: number) {
        this.list.removeAt(i);
    }

    addLevelToForm() {
        this.list.push(this.addLevel({}));
    }

    handleSelect(event: Role, i: number) {
        //if owner then disable add and possible roles is []
        const indexForMM = this.possibleRoles.findIndex((v: any) => v.id == this.MM.id);

        if (indexForMM > -1) {
            this.possibleRoles.splice(indexForMM, 1);
            this.possibleRoles = [...this.possibleRoles];
        }
        this.noAdd = false;
        if (event.name === appConstants.ROLES.OWNER) {
            this.noAdd = true;
            let j = i + 1;
            while (this.list.at(j)) {
                this.list.removeAt(j);
            }
        }
        if (event.name === appConstants.ROLES.MANAGER) {
            this.possibleRoles.push(this.MM);
        }
        if (event.id !== this.defaultRoles[1].id) {
            const oldIndex = this.list.value.findIndex((v: any) => v.id === event.id);
            if (oldIndex > -1) {
                this.list.removeAt(oldIndex);
            }
        }
        const ctrl = this.list.at(i) as UntypedFormGroup;
        if (event.id === this.defaultRoles[1].id) {
            ctrl.addControl('employee', this.fb.control('', [Validators.required]));
        } else {
            ctrl.get('employee') && ctrl.removeControl('employee');
        }
        ctrl.patchValue(event);
    }

    get canNotAdd(): boolean {
        return (
            this.list.value.find((value: any) => !value.id) || this.noAdd || !this.formInput.valid
        );
    }
}
