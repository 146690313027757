<section>
    <div class="d-flex pl-3 pt-4 w-100 justify-content-between mb-2">
        <h4
            [ngbTooltip]="title"
            class="text-truncate"
            style="font-size: 1.2rem; font-weight: 900; max-width: 50%"
        >
            {{ title }}
        </h4>
        <ng-container [ngTemplateOutlet]="template"></ng-container>
        <div class="modal-close-icon" (click)="closeModal()">
            <mat-icon svgIcon="cancel-circle-white" class="icon"></mat-icon>
        </div>
    </div>
    <hr />
    <div class="d-flex mt-2 w-100">
        <ng-content></ng-content>
    </div>
</section>
