import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_TOKEN } from 'src/app/app-data';
import { UserService } from 'src/app/modules/lms/services/user.service';
import { AppStore } from './../../app-data/stores/app.store';
import { ErrorHandlerService } from './error-handler.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    constructor(
        private _errorHandler: ErrorHandlerService,
        private _store: AppStore,
        private lmsUserService: UserService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem(APP_TOKEN);
        const lmsToken = this.lmsUserService.getLmsToken();
        const otherHeaders: any = {};
        let authHeaders: any = {
            Authorization: `Bearer ${token}`,
            lmsAuthorization: `Bearer ${lmsToken}`,
        };
        if (req.headers.get('nospinner')) {
            otherHeaders['nospinner'] = 'true';
        }
        if (req.headers.get('noauth')) {
            authHeaders = {};
        }
        const clonedRequest = req.clone({
            setHeaders: {
                ...otherHeaders,
                ...authHeaders,
            },
        });

        return next.handle(clonedRequest).pipe(
            catchError((err) => {
                this._errorHandler.handleError(err);
                return throwError(err);
            })
        );
    }
}
