<div class="snackbar-container" [ngClass]="{ unhappy: data.error, happy: !data.error }">
    <div *ngIf="!data.error" class="text-center icon">
        <mat-icon svgIcon="success-white" color="success"></mat-icon>
    </div>
    <div *ngIf="data.error" class="text-center icon">
        <mat-icon svgIcon="cancel-circle" color="success"></mat-icon>
    </div>
    <div class="pl-2 text-left">
        {{ data.message }}
    </div>
</div>
