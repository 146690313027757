import { Injectable, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Notify } from '../models/interfaces';
import { AppSocketService } from './app-socket.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppDispatcher, AppStore } from 'src/app/app-data';
import { HttpClient } from '@angular/common/http';
import { QueryGenerator } from 'src/app/modules/recruitment/shared/helper';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    notifyCount = new BehaviorSubject<number>(0);
    url = environment.notificationEndPoint + 'api/v1/';
    notificationData = new BehaviorSubject<any>(null);
    constructor(
        private modal: NgbModal,
        private router: Router,
        private socket: AppSocketService,
        private appStore: AppStore,
        private dispatcher: AppDispatcher,
        private http: HttpClient
    ) {
        this.router.onSameUrlNavigation = 'reload';
    }

    openNotification(data: Notify) {
        this.modal.dismissAll();
        if (!data.inAppViewed) {
            this.socket.appSocket.emit(
                'view_notification',
                {
                    id: data._id,
                },
                async (data: Notify) => {
                    await this.updateNotification(data, true);
                }
            );
        }
        if (data.inAppData.path) {
            this.notificationData.next(data.inAppData.data);
            this.router.navigate([data.inAppData.path], {
                state: {
                    ...data.inAppData.data,
                },
            });
        }
    }

    viewAll() {
        this.modal.dismissAll();
        this.socket.appSocket.emit('view_all_notification', {}, async (data: Notify) => {
            await this.updateNotificationAll();
        });
    }

    async updateNotification(data: Notify, remove = false) {
        let allNotification = JSON.parse(
            JSON.stringify(await this.appStore.getNotification().pipe(take(1)).toPromise())
        );
        let count = this.notifyCount.getValue();
        if (remove) {
            const updatedNotification = allNotification.findIndex((notify: Notify) => {
                return notify._id === data._id;
            });
            if (!(updatedNotification > -1)) return;
            allNotification[updatedNotification] = data;
            count--;
        } else {
            count++;
            allNotification = [data, ...allNotification];
        }
        this.notifyCount.next(count);
        this.dispatcher.setNotification([...allNotification]);
    }

    async updateNotificationAll() {
        this.fetch({});
    }

    fetch({
        notificationTypes = 'in-app,bulkUpdate',
        page = 1,
        limit = 20,
        sort = 'createdOn:-1',
    }: any) {
        this.http
            .get<any>(
                `${this.url}notification/list${QueryGenerator({ notificationTypes, page, sort, limit })}`
            )
            .subscribe(async (data) => {
                this.notifyCount.next(data?.data?.totalNotViewed);
                if (page == 1) {
                    this.dispatcher.setNotification([...data.data.data]);
                } else {
                    let allNotification = JSON.parse(
                        JSON.stringify(
                            await this.appStore.getNotification().pipe(take(1)).toPromise()
                        )
                    );
                    this.dispatcher.setNotification([...allNotification, ...data.data.data]);
                }
            });
    }
}
