import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AppStore } from '../app-data';
import { inject } from '@angular/core';
import { Combos } from '../interfaces/general';
import { lastValueFrom, Subscription, take } from 'rxjs';
import { GeneralService } from '../shared/services/general.service';

export const licenseGuardGuard: CanActivateFn = async (route, state) => {
    const store = inject(AppStore);
    const router = inject(Router);
    const genServ = inject(GeneralService);
    return await handleLicenseCheck(router, store, route, genServ);
};

export const licenseGuardChild: CanActivateChildFn = async (route, state) => {
    const store = inject(AppStore);
    const router = inject(Router);
    const genServ = inject(GeneralService);
    return await handleLicenseCheck(router, store, route, genServ);
};

const handleLicenseCheck = async (
    router: Router,
    store: AppStore,
    route: any,
    genServ: GeneralService
) => {
    const moduleName = route.data?.moduleName;
    if (!moduleName) return true;

    let combos: Combos = JSON.parse(
        JSON.stringify(await lastValueFrom(store.getCombos().pipe(take(1))))
    );
    if (!Object.keys(combos).length) {
        const res = await genServ.getLicenseSummary();
        combos.licenses = res.data;
    }
    const activeModules = combos.licenses?.modules || [];
    if (activeModules.includes(moduleName)) return true;
    genServ.moduleName.next(moduleName);
    router.navigate(['/no-license'], {
        state: {
            moduleName,
        },
    });
    return false;
};
