<div class="row">
    <div class="col-md-12 mx-auto">
        <form class="mx-auto w-100">
            <div class="form-group"></div>
            <div class="form-group">
                <label
                    for="teamName"
                    class="file-label"
                    style="display: flex; margin: auto; width: 70%"
                >
                    <img src="./assets/images/no-data.svg" style="width: 100%" alt="No Data" />
                </label>
            </div>
        </form>
    </div>
</div>
