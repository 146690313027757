import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppActionEnums, setCombo } from '../actions';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { GeneralService } from '../../shared/services/general.service';
import { EMPTY, pipe } from 'rxjs';
import { fetchUserInfo, setUserInfo } from '../actions/app.actions';

@Injectable()
export class AppEffects {
    constructor(
        private _actions: Actions,
        private gs: GeneralService
    ) {}

    fetchCombos = createEffect(() =>
        this._actions.pipe(
            ofType(AppActionEnums.FETCH_COMBO),
            mergeMap(() =>
                this.gs.getCombos().pipe(
                    map((providers) => setCombo({ payload: providers })),
                    catchError((error) => {
                        console.log(error);
                        return EMPTY;
                    })
                )
            )
        )
    );

    fetchUserInfo = createEffect(() =>
        this._actions.pipe(
            ofType(AppActionEnums.FETCH_USER_INFO),
            mergeMap(() =>
                this.gs.getUserInfo().pipe(
                    map((providers) => setUserInfo({ payload: providers })),
                    catchError((error) => {
                        console.log(error);
                        return EMPTY;
                    })
                )
            )
        )
    );
}
