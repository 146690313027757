import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HealthStore } from './../../health-data/stores/health.store';

@Component({
    selector: 'app-hmo-providers',
    templateUrl: './hmo-providers.component.html',
    styleUrls: ['./hmo-providers.component.scss'],
})
export class HmoProvidersComponent implements OnInit {
    hmoProviders$!: Observable<any[]>;

    constructor(private _healthStore: HealthStore) {}

    ngOnInit(): void {
        //  this.hmoProviders$ = this._healthStore.getHmoProviders();
    }
}
