import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, lastValueFrom, take } from 'rxjs';

@Pipe({
    name: 'appTranslate',
    pure: false,
})
export class TranslatePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}
    transform(value: string, args?: any): string {
        const original = value;
        if (!value) return ''; // Handle empty values
        const translated = this.translate.instant(value.toLowerCase(), args);
        return original.toLowerCase() === translated ? original : translated;
    }
}
