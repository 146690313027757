export default {
    PAGE_TITLE: {
        ASSESSMENT_CYCLE: 'Assessment Cycle',
        GOALS_SETTING: 'Goals Settings',
        GOALS: 'Goals',
        RECOMMENDATION: 'Recommendations',
        PERFORMANCE: 'Performance',
        CREATE_CYCLE: 'Create Assessment Cycle',
        FEEDBACK: 'Feedback',
        ASSESSMENT: 'Assessment',
        ASSESSMENT_RATING: 'Assessment Rating Settings',
        ALL_GOALS: 'All Goals',
    },
    MODELS: {
        GOAL_SETTINGS: 'goalssettings',
        RECOMMENDATION: 'recommendations',
        ASSESSMENT_CYCLE: 'assessmentcycle',
        ASSESSMENT: 'assessments',
        GOALS: 'goals',
        FEED_BACK: 'feedbacks',
        REPORTS: 'reports',
        COST_OF_WORKFORCE: 'costofworkforce',
        RETURN_OF_INVESTMENT: 'returnofinvestment',
        REVENUE: 'revenue',
        COST_OF_HIRE: 'costofhire',
        COMMITTEE_SETUP: 'committeeSetup',
        RATINGS: 'ratings',
    },
    whoCanCreate: [
        {
            value: 'all_employees',
            label: 'All Employees',
        },
        {
            value: 'all_managers',
            label: 'All Managers',
        },
        {
            value: 'some_employees',
            label: 'Some Employees (Department,Branch, Cadre, JobRole)',
        },
    ],
    SOME_EMPLOYEES: 'some_employees',
    ALL_MANAGERS: 'all_managers',
    SCHEDULE_OPTIONS_HIRE_DATE: [
        {
            label: '3 Months After Employee Hire Date',
            value: {
                number: 3,
                type: 'month',
            },
        },
        {
            label: '6 Months After Employee Hire Date',
            value: {
                number: 6,
                type: 'month',
            },
        },
        {
            label: '12 Months After Employee Hire Date',
            value: {
                number: 12,
                type: 'month',
            },
        },
    ],
    SCHEDULE_OPTIONS_FIXED_DATE: [
        {
            label: 'Every 3 Months',
            value: {
                number: 3,
                type: 'month',
            },
        },
        {
            label: 'Every 6 Months',
            value: {
                number: 6,
                type: 'month',
            },
        },
        {
            label: 'Every 12 Months',
            value: {
                number: 12,
                type: 'month',
            },
        },
    ],
    DEFAULT_FEED_BACK_QUESTION: [
        {
            question: `What are some things {name} does well?`,
            type: 'text',
        },
        {
            question: `How could {name} improve ?`,
            type: 'text',
        },
    ],
    DEFAULT_ASSESSMENT_QUESTION: [
        {
            question: {
                self: 'How well does {companyName} recognize my value?',
                supervisor: 'If {name} got a job offer elsewhere, I would...',
            },
            selfCanView: false,
            useForAnalysis: true,
            engagementOrValue: 'value',
            type: 'option',
            isDefault: true,
            supervisorOptions: [
                {
                    text: 'do everything I could to keep {name}',
                    rank: 5,
                },
                {
                    text: 'do Convince {name} to stay because {name} would be difficult to replace.',
                    rank: 4,
                },
                {
                    text: 'think about it. {name} does a decent job.',
                    rank: 3,
                },
                {
                    text: 'Wish {name} well. Finding a better replacement will be easy.',
                    rank: 2,
                },
                {
                    text: 'be relieved.',
                    rank: 1,
                },
            ],
            selfOptions: [
                {
                    text: 'I feel I am highly valued.',
                },
                {
                    text: 'I sometimes feel valued.',
                },
                {
                    text: "I'm not sure others value what I do.",
                },
                {
                    text: "I don't feel valued at {companyName}",
                },
            ],
        },
        {
            question: {
                self: 'What would have the greatest impact on my ability to do my best work more often?',
                supervisor: 'How engaged is {name} at work?',
            },
            selfCanView: false,
            useForAnalysis: true,
            engagementOrValue: 'engage',
            type: 'option',
            isDefault: true,
            supervisorOptions: [
                {
                    text: 'high engagement and a great attitude.',
                    rank: 5,
                },
                {
                    text: 'good engagement and a mostly positive attitude.',
                    rank: 4,
                },
                {
                    text: 'medium engagement and a mostly positive attitude.',
                    rank: 3,
                },
                {
                    text: 'low engagement and is burned out.',
                    rank: 2,
                },
                {
                    text: 'no engagement and is checked out.',
                    rank: 1,
                },
            ],
            selfOptions: [
                {
                    text: 'The company direction or purpose was better defined.',
                },
                {
                    text: 'Coworkers were more committed to doing great work.',
                },
                {
                    text: 'Work that better fits my strengths.',
                },
                {
                    text: 'Nothing, I have all I need.',
                },
            ],
        },
        {
            question: {
                self: 'What are some things I do well ?',
                supervisor: 'What are some things {name} does well?',
            },
            selfCanView: true,
            useForAnalysis: false,
            type: 'text',
            isDefault: true,
        },
        {
            question: {
                self: 'How could I improve ?',
                supervisor: 'How could {name} improve?',
            },
            selfCanView: true,
            useForAnalysis: false,
            type: 'text',
            isDefault: true,
        },
    ],
    QUESTION_TYPES: {
        OPTION: 'option',
        TEXT: 'text',
    },
};
