import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';
import { TableConfigsService } from 'src/app/services/table-configs.service';
import { iContentHeader } from 'src/app/shared/models/contentheader';
import { environment } from 'src/environments/environment';
import { DataTableConfig, filterValue } from '../../../../interfaces/general';
import appConstants from '../../../../utils/constants';
import { LABEL } from '../../health.data';
import { IHealthProvider } from '../pay-via-myxalary/pay-via-myxalary.data';
import { buttonPositionEnum } from './../../../../shared/components/button-link/button-link.data';
import { HealthService } from './../../health.service';
import { iAnalyticsData } from './dashboard.data';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [CurrencyPipe],
})
export class DashboardComponent implements OnInit, AfterViewInit {
    @ViewChild('status') status!: TemplateRef<any>;
    userHasAccess: boolean = false;
    userHasAccessErrorMessage: string = '';
    loaderOptions = appConstants.loaderOptions;
    contentHeader!: iContentHeader;
    buttonPosition = buttonPositionEnum;
    hasAnalytics: Subject<boolean> = new Subject();
    analyticsItems: BehaviorSubject<iAnalyticsData> = new BehaviorSubject({} as iAnalyticsData);
    label = LABEL;
    year: number = 0;
    limit: number = 10;
    hasExpiringPlans$: Observable<boolean> = of(false);
    isDestroyed$: Subject<boolean> = new Subject();
    analyticsYears: BehaviorSubject<number[]> = new BehaviorSubject([] as number[]);

    hmos: IHealthProvider[] = [];

    filterOption: any = {
        title: 'Key  Metrics',
        useYears: true,
        analyticsData: [
            {
                title: '0',
                description: 'Employees with Active Subscription',
                icon: 'user-plus',
                avatarBackground: 'bg-light-primary',
            },
            {
                title: '0',
                description: 'Total Subscriptions',
                icon: 'user-minus',
                avatarBackground: 'bg-light-danger',
            },
            {
                title: '0',
                description: 'Active Subscriptions',
                icon: 'briefcase',
                avatarBackground: 'bg-light-info',
            },
            {
                title: '0',
                description: 'Total Spent',
                icon: 'dollar-sign',
                avatarBackground: 'bg-light-success',
            },
        ],
    };

    tableConfig!: DataTableConfig;
    hmoLoading: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private _healthService: HealthService,
        private permissionService: PermissionService,
        private currencyPipe: CurrencyPipe,
        private ts: TableConfigsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        const { userHasAccess, userHasAccessErrorMessage } =
            this.permissionService.checkUserHasAccess({
                allowedPermissions: this.route.snapshot.data.canAccess,
            });
        this.userHasAccess = userHasAccess;
        this.userHasAccessErrorMessage = userHasAccessErrorMessage;

        this.setup();
    }

    ngAfterViewInit(): void {
        this.tableConfig = {
            searchOptions: [
                {
                    name: 'limit',
                    width: '15%',
                    type: 'select',
                    options: this.ts.defaultPageOptions,
                },
                {
                    name: 'createdOn',
                    width: '15%',
                    type: 'select',
                    transform: this.ts.periodTransform,
                    options: this.ts.defaultPeriodOptions,
                },
                {
                    name: 'search',
                    width: '50%',
                    type: 'search',
                    transform: (value: any): filterValue => {
                        const cols = [
                            'providerId.providerName',
                            'plans.name',
                            'plans.employees.name',
                        ].join('?');
                        return {
                            operator: '=',
                            value: `${cols}^${value}~`,
                        };
                    },
                },
                {
                    name: 'expired',
                    width: '20%',
                    type: 'select',
                    options: [
                        {
                            key: 'All Status',
                            value: '',
                            selected: true,
                        },
                        {
                            key: 'Expired',
                            value: 'true',
                        },
                        {
                            key: 'Active',
                            value: 'false',
                        },
                    ],
                },
            ],
            columns: [
                {
                    label: 'Employee Name',
                    key: 'plans.employees.name',
                    getValue: (data: any) => {
                        return data.plans.employees.name;
                    },
                },

                {
                    label: 'HMO Provider',
                    key: 'providerId.providerName',
                    getValue: (data: any) => {
                        return data.providerId.providerName;
                    },
                },
                {
                    label: 'Plan Name',
                    key: 'plans',
                    getValue: (data: any) => {
                        return data.plans.name;
                    },
                },
                {
                    label: 'Cost',
                    key: 'plans.amount',
                    getValue: (data: any) => {
                        return this.currencyPipe.transform(
                            Number(data.plans.amount) *
                                this._healthService.getMultiplier(data.plans.period),
                            'NGN',
                            'symbol-narrow'
                        );
                    },
                },
                {
                    label: 'Start Period',
                    key: 'plans.startDate',
                    getValue: (data: any) => {
                        return dayjs(data.plans.startDate).format('MMM, YYYY');
                    },
                },
                {
                    label: 'End Period',
                    key: 'plans.endDate',
                    getValue: (data: any) => {
                        return dayjs(data.plans.endDate).format('MMM, YYYY');
                    },
                },
                {
                    label: 'Status',
                    key: 'expired',
                    template: this.status,
                },
            ],
            url: environment.healthEndpoint + '/payments/expiring-plans',
        };
    }

    setup() {
        if (this.userHasAccess) {
            this.year = new Date().getFullYear();
            this.loadHMOProviders();
            this.getAnalytics();

            this.contentHeader = {
                headerTitle: 'Health',
                actionButton: true,
                breadcrumb: {
                    type: '',
                    links: [
                        {
                            name: 'Dashboard',
                            isLink: true,
                            link: '/dashboard',
                        },
                    ],
                },
            };
        }
    }

    getAnalytics(year?: string) {
        let qry: any = {};
        if (year) {
            qry['year'] = year;
        }
        this._healthService.fetchAnalytics(qry).subscribe((item) => {
            this.filterOption.analyticsData[0].title = item.totalEmployees;
            this.filterOption.analyticsData[1].title = item.totalPlans;
            this.filterOption.analyticsData[2].title = item.totalActiveSubscription;
            this.filterOption.analyticsData[3].title = this.currencyPipe.transform(
                item.totalAmountSpent,
                'NGN',
                'symbol-narrow'
            );
        });
    }

    async loadHMOProviders() {
        this.hmoLoading = true;
        const result = await this._healthService
            .fetchProvidersUsed(
                {
                    limit: 5,
                },
                true
            )
            .toPromise();
        this.hmos = result?.data;
        this.hmoLoading = false;
    }

    clone(data: any) {
        const url = data.type === 'provider' ? `/health/hmo/${data.providerId._id}` : '/health/pay';
        data.plans.employees = [data.plans.employees];
        data.plans = [data.plans];
        this.router.navigateByUrl(url, {
            state: {
                data: { ...data },
            },
        });
    }
}
