import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppDispatcher, AppStore } from '../app-data';

@Injectable()
export class EmployeeInterceptor implements HttpInterceptor {
    methods = ['POST', 'PUT', 'PATCH'];
    urls = [
        environment.myXalaryEndpoint + '/v1/employees',
        environment.myXalaryEndpoint + '/v1/branches',
        environment.myXalaryEndpoint + '/v1/departments',
    ];
    constructor(private appStore: AppDispatcher) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const isUrl = this.urls.some((url: string) => {
            return request.url.toLowerCase().includes(url.toLowerCase());
        });
        let shouldUpdateCombo = false;
        if (this.methods.includes(request.method.toUpperCase()) && isUrl) {
            shouldUpdateCombo = true;
        }
        return next.handle(request).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    if (shouldUpdateCombo) {
                        this.appStore.fetchCombo();
                    }
                }
            })
        );
    }
}
