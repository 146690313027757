<button
    class="btn btn-disabled default m-2"
    style="display: flex !important"
    [ngClass]="btnClass"
    [ngStyle]="btnStyle"
    type="button"
    *ngFor="let item of items; let i = index"
    (click)="handleClick(i, item)"
>
    {{ key ? item[key] : item }}
    <mat-icon
        (click)="handleRemove(i, item)"
        *ngIf="remove"
        svgIcon="cancel-circle"
        class="cancel-icon"
    ></mat-icon>
</button>
