import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { apiResponse } from './../../shared/models/interfaces';
import {
    IHealthPayment,
    IHealthProvider,
} from './containers/pay-via-myxalary/pay-via-myxalary.data';
import { Analytics, IBuyPlanPayload } from './health.data';
import { SuccessComponent } from '../learning/containers/success/success.component';
import { GeneralService } from 'src/app/shared/services/general.service';

@Injectable()
export class HealthService {
    baseApi = environment.healthEndpoint;
    payments = new Subject<IHealthPayment>();

    constructor(
        private _http: HttpClient,
        private gs: GeneralService,
        private _router: Router
    ) {}

    fetchProviders(query: any = { page: 1, limit: 10 }, loaders = false) {
        let headers;
        if (loaders) {
            headers = {
                nospinner: 'true',
            };
        }
        return this._http
            .get<apiResponse<any>>(`${this.baseApi}providers`, {
                params: query,
                headers,
            })
            .pipe(map((response) => response.response));
    }

    fetchProvidersById(id: string) {
        return this._http
            .get<apiResponse<any>>(`${this.baseApi}providers/notchhr/${id}`, {})
            .pipe(map((response) => response.response as IHealthProvider));
    }

    fetchProvidersUsed(query: any = { page: 1, limit: 10 }, loaders = false) {
        let headers;
        if (loaders) {
            headers = {
                nospinner: 'true',
            };
        }
        return this._http
            .get<any>(`${this.baseApi}payments/get-hmo-used`, {
                params: query,
                headers,
            })
            .pipe(map((response) => response.data));
    }

    fetchAnalytics(body: any = {}): Observable<Analytics> {
        return this._http
            .get<apiResponse<Analytics>>(`${this.baseApi}analytics`, {
                params: { ...body },
            })
            .pipe(
                map((payload) => {
                    return payload.response as Analytics;
                })
            );
    }

    getAnalyticsYears(): Observable<number[]> {
        return this._http.get<apiResponse<number[]>>(`${this.baseApi}payments/years/all`).pipe(
            map((payload) => {
                return payload.response?.length ? payload.response : [new Date().getFullYear()];
            })
        );
    }

    makePayment(payload: IHealthPayment) {
        const url = `${this.baseApi}payments/initiate`;
        return this._http.post<apiResponse<any>>(url, payload);
    }

    buyPlan(payload: IBuyPlanPayload) {
        const url = `${this.baseApi}payments/initiate?providerId=${payload.providerId}`;
        delete payload.providerId;
        this._http.post<apiResponse<any>>(url, payload).subscribe({
            next: (data) => {
                // this.handleSuccessModal();
            },
        });
    }

    getMultiplier(period: string) {
        let multiplier = 1;
        switch (period) {
            case 'monthly':
                multiplier = 1;
                break;
            case 'biannually':
                multiplier = 6;
                break;
            case 'quarterly':
                multiplier = 3;
                break;
            case 'yearly':
                multiplier = 12;
                break;
        }
        return multiplier;
    }

    handleSuccessModal(payment: any) {
        const openSuccess = this.gs.preModal(SuccessComponent);
        const instance = openSuccess.componentInstance;
        instance.config = {
            title: 'Plans Payment Initiated!',
            btns: [
                {
                    title: 'View HMO',
                    btnStyle: 'btn btn-primary',
                },
                {
                    title: 'View Payroll',
                    btnStyle: 'btn btn-primary',
                },
            ],
            svgIcon: 'success',
        };
        instance.action.subscribe((text: string) => {
            if (text === 'View HMO') {
                this._router.navigate(['/health/all-hmo-providers']);
            } else if (text === 'View Payroll') {
                this._router.navigate([
                    '/payment/allowance/medical/view-medical/',
                    payment?.payments.payrollId,
                ]);
            }
            openSuccess.dismiss();
        });
    }
}
