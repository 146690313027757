import { Component, Input, OnInit, TemplateRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Swal } from 'src/app/interfaces/general';
import { GeneralService } from '../../services/general.service';

@Component({
    selector: 'modal-cover',
    templateUrl: './modal-cover.component.html',
    styleUrls: ['./modal-cover.component.scss'],
})
export class ModalCoverComponent implements OnInit, OnDestroy {
    @Input() template!: TemplateRef<any>;
    @Input() title!: string;
    @Input() titleTemplate!: TemplateRef<any>;
    @Input() hideHeader: boolean = false;
    @Input() swal!: Swal | null;
    currentInstance!: NgbModalRef;
    constructor(
        private _active: NgbActiveModal,
        private modal: NgbModal,
        private cdr: ChangeDetectorRef,
        private gs: GeneralService
    ) {}

    ngOnInit(): void {
        this.gs.activeModals.asObservable().subscribe((result) => {
            this.currentInstance = result[result.length - 1];
        });
    }

    async closeModal() {
        this.currentInstance.close('close');
    }

    ngOnDestroy(): void {}
}
