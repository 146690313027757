import { Component, Input, TemplateRef } from '@angular/core';

interface AccordionItem {
    title: string;
    content: TemplateRef<any> | null; // Updated type to accept TemplateRef or null
}

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
    @Input() items: AccordionItem[] = [];

    openedIndex: number = -1;

    toggle(index: number) {
        if (this.openedIndex === index) {
            this.openedIndex = -1;
        } else {
            this.openedIndex = index;
        }
    }
}
