import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ellipses',
})
export class EllipsesPipe implements PipeTransform {
    transform(value: string, length: number = 70, ...args: unknown[]): string {
        if (value.length <= length) return value;
        return value.substring(0, length) + '...';
    }
}
