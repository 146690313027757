import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { iEmployee } from 'src/app/shared/models/interfaces';
import { INSURANCE_PERIOD, employees } from '../pay-via-myxalary/pay-via-myxalary.data';
import { CsvServicesService } from './../../../../shared/services/csv-services.service';
import { HealthDispatcher } from './../../health-data/dispatcher/health.dispatcher';
import { HealthStore } from './../../health-data/stores/health.store';
import { GeneralService } from '../../../../shared/services/general.service';
import { AppStore } from 'src/app/app-data';
import { Combos } from 'src/app/interfaces/general';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import SelectionModel from 'src/app/utils/localSelection';

@Component({
    selector: 'app-employee-search',
    templateUrl: './employee-search.component.html',
    styleUrls: ['./employee-search.component.scss', '../../../../../formStyle.scss'],
})
export class EmployeeSearchComponent implements OnInit {
    @Input() payload: any;
    @ViewChild('csvReader') csvReader!: ElementRef;
    @ViewChild('employeeLst') empList!: NgSelectComponent;

    @Input() planIndex!: number;
    @Input() plans!: any[];

    selectedEmployee!: iEmployee;
    selectedEmployees: any[] = [];
    allSelectedEmployees: iEmployee[] = [];
    insurancePeriod = INSURANCE_PERIOD;
    period = '';
    startDate = null;

    selection = new SelectionModel(true, [], 'id');
    employeeData: any[] = [];
    employeeControl = new UntypedFormControl('');
    form = new UntypedFormGroup({
        employees: new UntypedFormArray([], [Validators.required, Validators.minLength(1)]),
        period: new UntypedFormControl('', [Validators.required]),
        startDate: new UntypedFormControl('', [Validators.required]),
    });
    currentPlanAmount: number = 0;
    constructor(
        public activeModal: NgbActiveModal,
        private _healthStore: HealthStore,
        private _healthDispatcher: HealthDispatcher,
        private _csvService: CsvServicesService,
        private _genService: GeneralService,
        private _appStore: AppStore
    ) {}

    ngOnInit(): void {
        this._appStore
            .getCombos()
            .pipe(take(1))
            .subscribe((combos: Combos) => {
                this.selectedEmployees = this.plans
                    .reduce((res, cur, index) => {
                        if (index === this.planIndex) return res;
                        res = res.concat(cur?.employees || []);
                        return res;
                    }, [])
                    .map((item: any) => item.id);
                const temp = combos.activeEmployees.filter((v) => {
                    if (!v.salaryScheme) return false;
                    if (!v?.salaryScheme?.items?.length) return false;
                    const medical = v?.salaryScheme?.items?.find(
                        (v: any) => v.name.toLowerCase() === 'medical' && v.schedule === 'annually'
                    );
                    return medical;
                });
                this.employeeData = this._genService
                    .transformData(temp, ['phone'])
                    .filter((item) => !this.selectedEmployees.includes(item.id));
            });
        const currentPlan = this.plans[this.planIndex];

        this.currentPlanAmount = currentPlan.amount || 0;

        this.form.patchValue({
            period: currentPlan?.period,
            startDate: currentPlan?.startDate,
        });

        if (currentPlan.employees) {
            currentPlan.employees.forEach((employ: any) => {
                this.selectEmployee(employ);
            });
        }
    }

    selectEmployee($event: any) {
        if (!$event) return;
        this.selection.toggle($event);
        this.employeeControl.reset();
        this.populateForm();
    }

    get employees() {
        return this.form.get('employees') as UntypedFormArray;
    }

    populateForm() {
        this.employees.reset();
        this.employees.clear();
        this.selection.selected.forEach((selected) => {
            this.employees.push(new UntypedFormControl(selected));
        });
    }

    onRemoveEmployee($event: any) {
        this.selection.toggle(this.selection.selected[$event]);
        this.populateForm();
    }

    addEmployee(event: any) {
        const emp = { ...this.selectedEmployee, id: `${this.selectedEmployee.id}` };
        this.selectedEmployee = {};
        if (this.selectedEmployees.find((_emp) => _emp.id === emp.id)) {
            return;
        }

        this.selectedEmployees = [...this.selectedEmployees, emp];
    }

    removeEmployee(empIndex: number) {
        this.selectedEmployees = this.selectedEmployees.filter((emp, index) => index !== empIndex);
    }

    downloadSchedule() {
        this._csvService.downloadFile(
            this.employeeData.map((v) => ({ id: v.id, name: v.name })),
            'emp_list_template'
        );
    }

    uploadSchedule() {
        this.csvReader.nativeElement.click();
    }

    async uploadListener(event: any) {
        try {
            const data: any[] = (await this._csvService.uploadListener(event)) as Array<any>;
            this.csvReader.nativeElement.value = '';
            this.selectedEmployees.length = 0;

            if (!data.length) return;

            for (let employee of data) {
                if (this.selectedEmployees.includes(employee.id)) {
                    this._genService.alertInfo.next({
                        text: `${employee.name} already selected`,
                        btnClass: 'alert-warning',
                        btnIcon: 'error',
                    });
                    continue; //employee already selected
                }
                const current = this.employeeData.find((v) => v.id === employee.id);
                if (!current) {
                    this._genService.alertInfo.next({
                        text: `${employee.name} does not exist`,
                        btnClass: 'alert-danger',
                        btnIcon: 'error',
                    });
                    continue;
                }
                this.selectEmployee(current);
            }
        } catch (error) {
            console.log('errror', error);
        }
    }
}
