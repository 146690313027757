import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AppStore } from 'src/app/app-data';

@Component({
    selector: 'app-notchhr-wrapped',
    templateUrl: './notchhr-wrapped.component.html',
    styleUrls: ['./notchhr-wrapped.component.scss'],
})
export class NotchhrWrappedComponent implements OnInit {
    wrapData: WrapReport = {} as WrapReport;
    user: any = {};
    constructor(
        private api: ApiService,
        private _appStore: AppStore
    ) {}

    ngOnInit(): void {
        this.getData();
    }

    getData() {
        this._appStore.getUserInfo().subscribe((value) => {
            if (Object.keys(value).length) {
                this.user = { ...value };
            }
        });

        this.api.getWrappedInfo().then((res: any) => {
            this.wrapData = res.data;
        });
    }

    timeFormatter(time: any, format: string) {
        if (format == 'H') {
            return (time / (3600 * 1000)).toFixed(2) || '0.00';
        } else if (format == 'D') {
            return Math.floor(time / (3600 * 24 * 1000));
        } else {
            return time.toFixed(2) || '0.00';
        }
    }
}

interface AttendanceStatistics {
    averageAttendance: number;
    topDepartment: {
        _id: string;
        departmentName: string;
        timeInCount: number;
    };
    topAttendanceDate: {
        date: string;
        percentage: number;
    };
}

interface AttendanceModule {
    statistics: AttendanceStatistics;
}

interface EmployeeModule {
    newEmployeesCount: number;
    terminatedEmployeesCount: number;
    activeEmployeesCount: number;
    previousYearActiveCount: number;
    percentageIncrease: number;
}

interface LeaveModule {
    leaveTypeName: string;
    totalLeaveDays: number;
    percentageApprovedEmployees: number;
}

interface PayrollModule {
    totalPayrolls: number;
    totalPaidAmount: number;
    timePaid: string;
    formattedTotalPaidAmount: string;
}

interface MostEnrolledCourse {
    _id: string;
    name: string;
    tutor: string;
    organization: string;
    duration: string;
    thumbnail: string;
    tags: string[];
    category: string;
    status: number;
    sections: string[];
    price: number;
    currency: string;
    isScorm: boolean;
    skills: string[];
    preReqCourse: string[];
    finalAssessment: string;
    certificateOn: string;
    noOfReviews: number;
    noOfViews: number;
    notificationSent: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
    rating: number;
}

interface ElearningModule {
    totalTime: number;
    mostEnrolledCourse: MostEnrolledCourse;
    totalEnrollments: number;
    mostCompletions: number;
}

interface DepartmentWithHighestRank {
    _id: string;
    name: string;
    totalGoals: number;
    completed: number;
    companyID: string;
    percentage: number;
}

interface PerformanceModule {
    companyCompletedGoalInPercentage: string;
    companyCompletedGoals: number;
    departmentWithHighestRank: DepartmentWithHighestRank;
}

interface WrapReport {
    _id: string;
    year: number;
    attendanceModule: AttendanceModule;
    employeeModule: EmployeeModule;
    leaveModule: LeaveModule;
    payrollModule: PayrollModule;
    elearningModule: ElearningModule;
    performanceModule: PerformanceModule;
    createdOn: string;
    modifiedOn: string;
    createdAt: string;
    updatedAt: string;
    id: number;
    __v: number;
}
