import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnlineUsers } from 'src/app/modules/chats-v2/services/chat-socket.service';
import { ISettings } from 'src/app/shared/models/interfaces';
import { ChatContact, Combos } from '../../interfaces/general';
import {
    fetchNotification,
    initializeApp,
    setCombo,
    setContacts,
    setNotification,
    setUserInfo,
} from '../actions';
import {
    fetchCombos,
    setActiveChats,
    setChatContact,
    setChatSettings,
    setOnlineUsers,
    setUnReadCount,
    fetchUserInfo,
} from '../actions/app.actions';

export interface IInitializeApp {
    token: string;
}

@Injectable({
    providedIn: 'root',
})
export class AppDispatcher {
    constructor(private _store: Store<any>) {}

    initializeApp(payload: IInitializeApp) {
        this._store.dispatch(initializeApp(payload));
    }
    fetchNotification() {
        this._store.dispatch(fetchNotification());
    }
    setNotification(payload: any) {
        this._store.dispatch(setNotification({ payload }));
    }
    setContacts(payload: any) {
        this._store.dispatch(setContacts({ payload }));
    }
    setUserInfo(payload: any) {
        this._store.dispatch(setUserInfo({ payload: { ...payload } }));
    }
    setCombo(payload: Combos) {
        this._store.dispatch(setCombo({ payload: { ...payload } }));
    }
    setChatContact(payload: ChatContact[]) {
        this._store.dispatch(setChatContact({ payload }));
    }
    setActiveChats(payload: ChatContact[]) {
        this._store.dispatch(setActiveChats({ payload }));
    }
    setOnlineUsers(payload: OnlineUsers[]) {
        this._store.dispatch(setOnlineUsers({ payload }));
    }
    setUnReadCount(payload: OnlineUsers[]) {
        this._store.dispatch(setUnReadCount({ payload }));
    }
    setChatSettings(payload: ISettings) {
        this._store.dispatch(setChatSettings({ payload }));
    }
    fetchCombo() {
        this._store.dispatch(fetchCombos());
    }
    fetchUserInfo() {
        this._store.dispatch(fetchUserInfo());
    }
}
