import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    activeChats,
    chatContact,
    chatSettings,
    combos,
    globalContacts,
    initialized,
    notification,
    onlineUsers,
    selectBearerToken,
    unReadCount,
    userInfo,
} from '../reducers';

@Injectable({
    providedIn: 'root',
})
export class AppStore {
    constructor(private _store: Store<any>) {}

    initialized(): Observable<boolean> {
        return this._store.pipe(select(initialized));
    }

    getBearerToken(): Observable<string> {
        return this._store.pipe(select(selectBearerToken));
    }
    getNotification(): Observable<any[]> {
        return this._store.pipe(select(notification));
    }
    getContacts(): Observable<any> {
        return this._store.pipe(select(globalContacts));
    }
    getUserInfo(): Observable<any> {
        return this._store.pipe(select(userInfo));
    }
    getCombos(): Observable<any> {
        return this._store.pipe(select(combos));
    }
    getChatContact(): Observable<any> {
        return this._store.pipe(select(chatContact));
    }
    getActiveChats(): Observable<any> {
        return this._store.pipe(select(activeChats));
    }
    getUnReadCount(): Observable<any> {
        return this._store.pipe(select(unReadCount));
    }
    getOnlineUsers(): Observable<any> {
        return this._store.pipe(select(onlineUsers));
    }
    getChatSettings(): Observable<any> {
        return this._store.pipe(select(chatSettings));
    }
}
