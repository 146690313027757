<div *ngIf="userHasAccess; else unauthorized_user">
    <div class="container-fluid">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Health Management Organizations (HMO)</h3>
            </div>
            <div class="card-body">
                <app-filter (onFilter)="onFilter($event)" [options]="filterOption"></app-filter>
                <div class="all-hmo-card-container">
                    <ng-container *ngFor="let hmo of allHmoArray$ | async">
                        <app-hmo-provider [hmo]="hmo"></app-hmo-provider>
                    </ng-container>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <app-paginator
                        [paginatorSetup]="paging"
                        (paginateEvent)="onPageChange($event)"
                    ></app-paginator>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #unauthorized_user>
    <unauthorized [errorMessage]="userHasAccessErrorMessage"> </unauthorized>
</ng-template>
