// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ENV } from 'src/app/interfaces/general';

export const environment: ENV = {
    production: false,
    env: 'test',
    healthEndpoint: 'https://hmoapi-test.notchhr.io/v1/',
    myXalaryV1: 'https://test2.notchhr.io/',
    recruitmentEndpoint: 'https://recruitment-test.notchhr.io',
    myXalaryEndpoint: 'https://api-test.notchhr.io',
    recruitmentApplicantEndpoint: 'https://jobs-test.notchhr.io',
    azureStorageAccountKey:
        'cd08xfObx6RzFxz9O92cjytY/dAN98jgMddpzDMJlNpZO9BWOBNMCR6DCz83sugfvndWqWtM40sCtV/A+g5ynQ==;',
    azureRecuritmentImgSAS:
        'sv=2020-10-02&si=recruitment-images-1813A548CE2&sr=c&sig=Ls6lwlADLwXErNO%2B6GKct5ahmCEYngrDu2Pql2V964Q%3D',
    azureRecuritmentDocSAS:
        'sv=2020-10-02&si=recruitment-docs-1812435AC27&sr=c&sig=y9Tw4o%2FCLkPwYCeUrHyXAj4EhvZ7yp3LXWvpLLJAvQ0%3D',
    chatEndpoint: 'https://chat-test.notchhr.io/',
    userInfoEndpoint: 'https://api-test.notchhr.io/v1/',
    lmsApiEndpoint: 'https://lms-test.notchhr.io/api/v2/',
    learningEndPoint: 'https://lnd-test.notchhr.io/api/v1',
    talentManagementEndPoint: 'https://talent-test.notchhr.io/api/v1',
    notificationEndPoint: 'https://notifications-test.notchhr.io/',
    grievanceEndpoint: 'https://grievance-test.notchhr.io/api/v1',
    performanceEndPoint: 'https://performance-test.notchhr.io/api',
    confirmationEndpoint: 'https://confirmation-test.notchhr.io/confirmation/',
    kpiEndpoint: 'https://reports-test.notchhr.io/api',
    googleLocation: 'http://www.google.com/maps/place/',
    dmsEndpoint: 'https://document-test.notchhr.io/api/v1/',
    safetyIncidentEndpoint: 'https://safety-incident-test.notchhr.io',
    meetingsEndpoint: 'https://meetings-admin-test.notchhr.io',
    esb: 'https://esb-test.notchhr.io',
    enps: 'https://enps-backend-test.notchhr.io/api/v1',
    isExitReady: true,
    pk: 'pk_test_9355c1d0251eb11893439609e42dcacfad163f57',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
