import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { dir } from 'console';
import { from, Observable, of, Subject } from 'rxjs';
import { combineAll, map, takeLast, last } from 'rxjs/operators';
import { ISasToken } from 'src/app/interfaces/general';
import { GenerateFileName } from 'src/app/modules/recruitment/shared/helper';
import { AzureBlobStorageService } from 'src/app/services/azure/azure-blob-storage.service';
import { GeneralService } from '../../services/general.service';
import { DmsService } from 'src/app/modules/dms/service/dms.service';
interface IUploadProgress {
    filename: string;
    filenameDB: string;
    progress: number;
    status: string;
}
@Component({
    selector: 'app-uploads-azure',
    templateUrl: './uploads-azure.component.html',
    styleUrls: ['./uploads-azure.component.scss'],
})
export class UploadsAzureComponent implements OnInit {
    @Input() btnName!: string;
    @Input() svgIcon!: string;
    @Input() containerName!: string;
    @Input() module!: string;
    @Input() containerUploadDir!: string;
    @Input() showUploads!: boolean;
    @Input() multiple: boolean = false;
    @Input() disableUpload: boolean = false;
    @Input() inputID!: string;
    @Input() fileFormat!: string;
    @Output() uploadedFileUrl = new EventEmitter();
    fileName!: string;
    docBlobUrlPrefix = 'https://atbstore.blob.core.windows.net/';
    containers: any = {
        recuritementImg: 'recruitment-images',
        recuritementDoc: 'recruitment-docs',
    };
    uploads: string[] = [];
    uploadsDownloaded: string[] = [];
    loading = false;
    disableDeletion = false;
    filesSelected = false;
    uploadProgressData$!: Observable<IUploadProgress[]>;
    uploadProgress$: Subject<IUploadProgress[]> = new Subject();
    multipleFilesEmitted: IUploadProgress[] = [];

    constructor(
        private blobService: AzureBlobStorageService,
        private dmsService: DmsService,
        private genServ: GeneralService
    ) {}

    ngOnInit(): void {}

    public fetchFiles() {
        this.blobService.listFiles(this.containerName).then((list) => {
            this.uploads = list;
            const array: string[] = [];
            for (let name of this.uploads) {
                this.blobService.downloadFiles(this.containerName, name, (blob) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        array.push(reader.result as string);
                    };
                });
            }
        });
    }

    uploadFileNoIndicator(e: any) {
        this.loading = true;
        let file = e.target.files[0];
        let dir = `${this.containerUploadDir}${GenerateFileName()}${file.name.substr(
            file.name.indexOf('.')
        )}`;

        this.blobService.uploadFileNoProgessIndication(this.containerName, file, dir, () => {
            this.fetchFiles();
            this.loading = false;
            this.btnName = `${this.containerUploadDir}${file.name}`;
            this.genServ.alertInfo.next({
                text: 'Saved',
                btnClass: 'alert-success',
                btnIcon: 'checklist',
            });
            this.uploadedFileUrl.emit({
                url: `${this.docBlobUrlPrefix}${this.containers[this.containerName]}/${dir}`,
            });
        });
    }

    public downloadFile(name: string) {
        this.blobService.downloadFiles(this.containerName, name, (blob) => {
            let url = window.URL.createObjectURL(blob);
            window.open(url);
        });
    }

    public deleteFile(name: string) {
        this.blobService.deleteFiles(this.containerName, name, () => {
            this.fetchFiles();
            this.genServ.alertInfo.next({
                text: 'Deleted',
                btnClass: 'alert-danger',
                btnIcon: 'checklist',
            });
        });
    }

    // Update
    onFileChange(event: any): void {
        if (event.target.files.length) {
            this.uploadProgressData$ = from(event.target.files as FileList).pipe(
                map((file) => {
                    const format =
                        this.fileFormat === 'PNG,JPEG,JPG' ? 'image/' : 'application/pdf';
                    if (file.type.startsWith(format)) {
                        this.loading = true;
                        this.disableDeletion = true;
                        const fileName = `${this.containerUploadDir}${GenerateFileName()}${file.name.substring(
                            file.name.indexOf('.')
                        )}`;
                        this.fileName = fileName;
                        return this.uploadFile(file, fileName, this.module);
                    } else {
                        return of(this.mapProgressInvalidFile(file));
                    }
                }),
                combineAll()
            );
            this.uploadProgressData$
                .pipe(
                    map((res: any) => {
                        this.uploadProgress$.next(res);
                        return res;
                    })
                )
                .pipe(takeLast(1))
                .subscribe((lastResult: any) => {
                    this.emitDataOnUploadCompletion(lastResult);
                });
        } else {
            this.btnName = 'Upload File';
        }
    }

    uploadAdditionalFile() {
        const addtionalFile: any = document.getElementById('addMultipleFile');
        addtionalFile.click();
    }

    onFIleChangeAddtional(event: any): void {
        if (event.target.files.length) {
            this.uploadProgressData$ = from(event.target.files as FileList).pipe(
                map((file) => {
                    const format =
                        this.fileFormat === 'PNG,JPEG,JPG' ? 'image/' : 'application/pdf';
                    if (file.type.startsWith(format)) {
                        this.loading = true;
                        this.disableDeletion = true;
                        const fileName = `${this.containerUploadDir}${GenerateFileName()}${file.name.substring(
                            file.name.indexOf('.')
                        )}`;
                        this.fileName = fileName;
                        return this.uploadFile(file, fileName);
                    } else {
                        return of(this.mapProgressInvalidFile(file));
                    }
                }),
                combineAll()
            );
            this.uploadProgressData$
                .pipe(
                    map((res: any, i: any) => {
                        const newData = [...res, ...this.multipleFilesEmitted];
                        this.uploadProgress$.next(newData);
                        return newData;
                    })
                )
                .pipe(takeLast(1))
                .subscribe((lastResult: any) => {
                    this.emitDataOnUploadCompletion(lastResult);
                });
        }
    }

    handleReupload(fileData: IUploadProgress, index: number = -1) {
        if (fileData) {
            this.disableDeletion = true;
            const btnDelete: any = index > -1 ? document.getElementById(`delete${index}`) : null;
            btnDelete ? ((btnDelete.disabled = true), (btnDelete.innerHTML = 'Deleting')) : null;
            if (this.multiple) {
                if (this.multipleFilesEmitted.indexOf(fileData) > -1) {
                    this.multipleFilesEmitted.splice(
                        this.multipleFilesEmitted.indexOf(fileData),
                        1
                    );
                }
                this.uploadProgress$.next(this.multipleFilesEmitted);
                this.uploadedFileUrl.emit(this.processEmittedArr(this.multipleFilesEmitted));
                if (this.multipleFilesEmitted.length === 0) {
                    this.btnName = 'Select All Files';
                }
            } else {
                this.uploadProgress$.next([]);
                this.uploadedFileUrl.emit([]);
                this.btnName = 'Upload File';
            }
            this.disableDeletion = false;
            btnDelete ? ((btnDelete.disabled = false), (btnDelete.innerHTML = 'Remove')) : null;
        }
    }

    private emitDataOnUploadCompletion(finalProgress: IUploadProgress[]) {
        this.loading = false;
        this.disableDeletion = false;
        if (!this.multiple) {
            this.btnName = `/${finalProgress[0].filename}`;
        } else {
            this.multipleFilesEmitted = finalProgress;
            this.btnName = 'Files Uploaded';
        }
        this.uploadedFileUrl.emit(this.processEmittedArr(finalProgress));
    }

    private uploadFile(
        file: File,
        fileName: string,
        module: string = '',
        employeeId?: string
    ): any {
        if (!module) {
            this.genServ.alertInfo.next({
                text: 'Module Name is Required for file Upload',
                btnClass: 'alert-danger',
                btnIcon: 'error',
                timer: 5000,
            });
            return;
        }
        const formData = new FormData();
        formData.append(
            `${fileName.split('.').slice(0, -1).join('.')}-${Date.now()}.${fileName.split('.').pop()}`,
            file
        );
        formData.append('module', module);
        if (employeeId) {
            formData.append('employeeID', employeeId);
        }

        return from(this.dmsService.uploadFile(formData)).pipe(
            map((progress) => {
                return this.mapProgress(
                    file,
                    fileName,
                    progress,
                    progress == 0 ? 'Processing' : progress === 100 ? 'Uploaded' : 'Uploading'
                );
            })
        );
    }

    private mapProgress(
        file: File,
        filenameDB: string,
        progress: number,
        status: string
    ): IUploadProgress {
        return {
            filename: file.name,
            filenameDB,
            progress: progress,
            status: status,
        };
    }
    private mapProgressInvalidFile(
        file: File,
        filenameDB?: string,
        progress?: number,
        status?: string
    ): IUploadProgress {
        return {
            filename: file.name,
            filenameDB: 'unknown',
            progress: 0,
            status: 'Invalid File - Not Uploaded',
        };
    }

    private processEmittedArr(arr: IUploadProgress[]) {
        let dirs: string[] = [];
        arr.forEach((el) => {
            if (el.filenameDB !== 'unknown') {
                dirs.push(
                    `${this.docBlobUrlPrefix}${this.containers[this.containerName]}/${el.filenameDB}`
                );
            }
        });
        return dirs;
    }
}
