import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { GeneralService } from 'src/app/shared/services/general.service';
import { SwitchCompanyModalService } from 'src/app/shared/services/switch-company.service';

import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-companies-list',
    templateUrl: './companies-list.component.html',
    styleUrls: ['./companies-list.component.scss'],
})
export class CompaniesListComponent implements OnInit {
    display$!: Observable<string>;

    endpoint = environment.myXalaryEndpoint;
    companies!: any[];
    activeCompanyId!: string;
    selectedCompanyId: string = '';

    constructor(
        private route: Router,
        private _http: HttpClient,
        private genSrv: GeneralService
    ) {}

    ngOnInit(): void {
        this.handleReadOrganizations();
    }

    handleReadOrganizations() {
        this._http.get<any>(`${this.endpoint}/v1/users/available-organizations`).subscribe(
            (response) => {
                this.companies = response.companies;
                this.selectedCompanyId = response.activeCompanyId;
                this.activeCompanyId = this.selectedCompanyId;
            },
            (err) => {
                const error = err.details.message || err.message || err.error;
                return this.genSrv.alertInfo.next({
                    text: error,
                    btnClass: 'alert-danger',
                    btnIcon: 'error',
                    timer: 5000,
                });
            }
        );
    }

    handleSelectOrganization(company: any) {
        if (!company.isActive) return;
        this.selectedCompanyId = company._id;
    }

    getImage(company: any) {
        return this.genSrv.getCompanyImage(company);
    }

    handleSwitchOrganization() {
        if (!this.selectedCompanyId) {
            this.genSrv.alertInfo.next({
                timer: 5000,
                btnClass: 'alert-danger',
                btnIcon: 'error',
                text: 'Please select an organization',
            });
            return;
        }
        this._http
            .put<any>(`${this.endpoint}/v1/users/switch-organization/${this.selectedCompanyId}`, {})
            .subscribe(
                (response) => {
                    this.route.navigateByUrl('/').then(() => {
                        window.location.href = this.route.url;
                    });
                },
                (err) => {
                    const error = err.details.message || err.message || err.error;
                    return this.genSrv.alertInfo.next({
                        text: error,
                        btnClass: 'alert-danger',
                        btnIcon: 'error',
                        timer: 5000,
                    });
                }
            );
    }
}
