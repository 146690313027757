import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { IFile } from '../../models/interfaces';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoComponent implements OnInit, AfterViewInit {
    @ViewChild('target', { static: true }) target!: ElementRef;
    @Input() size: {
        height: string;
        width: string;
    } = {
        height: '100px',
        width: '100px',
    };
    // See options: https://videojs.com/guides/options
    @Input() options!: any;
    @Input()
    useThumbnail: boolean = false;
    player: any;

    @Input()
    src!: IFile;

    @Input()
    gHeight!: any;

    @Input()
    gWidth!: any;
    @Input()
    hideControls!: boolean;
    @Input()
    extra: string = '';
    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        const [_, name] = /\/(\w+).\w+$/.exec(this.src.url) || [];
        this.src.name = (name || Math.round(78).toString()) + this.extra;
        console.log(this.src, this.gHeight);
        // this.player = videoJs(this.target.nativeElement, this.options, function onPlayerReady() {
        // });
    }
    ngOnDestroy() {
        // if (this.player) {
        //   this.player.dispose();
        // }
    }

    ngAfterViewInit() {
        // Select elements here
        const video = <any>document.getElementById(`video-${this.src.name}`);
        const videoControls = document.getElementById(`video-controls-${this.src.name}`);
        const playButton = document.getElementById(`play-${this.src.name}`);
        const playbackIcons = document.querySelectorAll('.playback-icons use');
        const timeElapsed = document.getElementById(`time-elapsed-${this.src.name}`);
        const duration = document.getElementById(`duration-${this.src.name}`);
        const progressBar = <HTMLProgressElement>(
            document.getElementById(`progress-bar-${this.src.name}`)
        );
        const seek = <HTMLInputElement>document.getElementById(`seek-${this.src.name}`);
        const seekTooltip = document.getElementById(`seek-tooltip-${this.src.name}`);
        const volumeButton = document.getElementById(`volume-button-${this.src.name}`);
        const volumeIcons = document.querySelectorAll('.volume-button use');
        const volumeMute = document.querySelector('use[href="#volume-mute"]');
        const volumeLow = document.querySelector('use[href="#volume-low"]');
        const volumeHigh = document.querySelector('use[href="#volume-high"]');
        const volume = <HTMLInputElement>document.getElementById(`volume-${this.src.name}`);
        const playbackAnimation = document.getElementById(`playback-animation-${this.src.name}`);
        const fullscreenButton = document.getElementById(`fullscreen-button-${this.src.name}`);
        const videoContainer = document.getElementById(`video-container-${this.src.name}`);
        const fullscreenIcons = fullscreenButton?.querySelectorAll('use');
        const pipButton = <HTMLButtonElement>document.getElementById(`pip-button-${this.src.name}`);

        if (
            !(
                video ||
                videoControls ||
                playButton ||
                timeElapsed ||
                duration ||
                progressBar ||
                seek ||
                seekTooltip ||
                volumeButton ||
                volumeIcons ||
                volumeMute ||
                volumeLow ||
                volumeHigh ||
                volume ||
                playbackAnimation ||
                fullscreenButton ||
                videoContainer ||
                fullscreenIcons ||
                pipButton
            )
        )
            return;
        const videoWorks = !!document.createElement('video').canPlayType;
        if (videoWorks && video) {
            video.controls = false;
            if (!this.hideControls) {
                videoControls?.classList.remove('hidden');
            }
        }

        function togglePlay() {
            if (video.paused || video.ended) {
                video.play();
            } else {
                video.pause();
            }
        }

        // updatePlayButton updates the playback icon and tooltip
        // depending on the playback state
        function updatePlayButton() {
            playbackIcons.forEach((icon) => icon.classList.toggle('hidden'));

            if (video.paused) {
                playButton?.setAttribute('data-title', 'Play (k)');
            } else {
                playButton?.setAttribute('data-title', 'Pause (k)');
            }
        }

        // formatTime takes a time length in seconds and returns the time in
        // minutes and seconds
        function formatTime(timeInSeconds: any) {
            const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

            return {
                minutes: result.substr(3, 2),
                seconds: result.substr(6, 2),
            };
        }

        // initializeVideo sets the video duration, and maximum value of the
        // progressBar
        function initializeVideo() {
            const videoDuration = <any>Math.round(video.duration);
            seek?.setAttribute('max', videoDuration);
            progressBar?.setAttribute('max', videoDuration);
            const time = formatTime(videoDuration);
            if (!duration) return;
            duration.innerText = `${time.minutes}:${time.seconds}`;
            duration?.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
        }

        // updateTimeElapsed indicates how far through the video
        // the current playback is by updating the timeElapsed element
        function updateTimeElapsed() {
            const time = formatTime(Math.round(video.currentTime));
            if (!timeElapsed) return;
            timeElapsed.innerText = `${time.minutes}:${time.seconds}`;
            timeElapsed.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
        }

        // updateProgress indicates how far through the video
        // the current playback is by updating the progress bar
        function updateProgress() {
            if (!seek || !progressBar) return;
            seek.value = <any>Math.floor(video.currentTime);
            progressBar.value = Math.floor(video.currentTime);
        }

        // updateSeekTooltip uses the position of the mouse on the progress bar to
        // roughly work out what point in the video the user will skip to if
        // the progress bar is clicked at that point
        function updateSeekTooltip(event: any) {
            const skipTo = <any>(
                Math.round(
                    (event.offsetX / event.target.clientWidth) *
                        parseInt(event.target.getAttribute('max'), 10)
                )
            );
            seek.setAttribute('data-seek', skipTo);
            const t = formatTime(skipTo);
            if (!seekTooltip) return;
            seekTooltip.textContent = `${t.minutes}:${t.seconds}`;
            const rect = video.getBoundingClientRect();
            seekTooltip.style.left = `${event.pageX - rect.left}px`;
        }

        // skipAhead jumps to a different point in the video when the progress bar
        // is clicked
        function skipAhead(event: any) {
            const skipTo = event.target.dataset.seek
                ? event.target.dataset.seek
                : event.target.value;
            video.currentTime = skipTo;
            progressBar.value = skipTo;
            seek.value = skipTo;
        }

        // updateVolume updates the video's volume
        // and disables the muted state if active
        function updateVolume() {
            if (video.muted) {
                video.muted = false;
            }

            video.volume = <any>volume?.value;
        }

        // updateVolumeIcon updates the volume icon so that it correctly reflects
        // the volume of the video
        function updateVolumeIcon() {
            if (!volumeMute || !volumeButton || !volumeLow || !volumeHigh) return;
            volumeIcons.forEach((icon) => {
                icon.classList.add('hidden');
            });

            volumeButton.setAttribute('data-title', 'Mute (m)');

            if (video.muted || video.volume === 0) {
                volumeMute.classList.remove('hidden');
                volumeButton.setAttribute('data-title', 'Unmute (m)');
            } else if (video.volume > 0 && video.volume <= 0.5) {
                volumeLow.classList.remove('hidden');
            } else {
                volumeHigh.classList.remove('hidden');
            }
        }

        // toggleMute mutes or unmutes the video when executed
        // When the video is unmuted, the volume is returned to the value
        // it was set to before the video was muted
        function toggleMute() {
            video.muted = !video.muted;
            if (!volume) return;
            if (video.muted) {
                volume.setAttribute('data-volume', volume.value);
                volume.value = <any>0;
            } else {
                volume.value = <any>volume.dataset.volume;
            }
        }

        // animatePlayback displays an animation when
        // the video is played or paused
        function animatePlayback() {
            playbackAnimation?.animate(
                [
                    {
                        opacity: 1,
                        transform: 'scale(1)',
                    },
                    {
                        opacity: 0,
                        transform: 'scale(1.3)',
                    },
                ],
                {
                    duration: 500,
                }
            );
        }

        // toggleFullScreen toggles the full screen state of the video
        // If the browser is currently in fullscreen mode,
        // then it should exit and vice versa.
        function toggleFullScreen() {
            if (document.fullscreenElement) {
                document.exitFullscreen();
                // } else if (document.webkitFullscreenElement) {
                //   // Need this to support Safari
                //   document?.webkitExitFullscreen();
                // } else if (videoContainer?.webkitRequestFullscreen) {
                //   // Need this to support Safari
                //   videoContainer?.webkitRequestFullscreen();
            } else {
                videoContainer?.requestFullscreen();
            }
        }

        // updateFullscreenButton changes the icon of the full screen button
        // and tooltip to reflect the current full screen state of the video
        function updateFullscreenButton() {
            fullscreenIcons?.forEach((icon) => icon.classList.toggle('hidden'));

            if (document.fullscreenElement) {
                fullscreenButton?.setAttribute('data-title', 'Exit full screen (f)');
            } else {
                fullscreenButton?.setAttribute('data-title', 'Full screen (f)');
            }
        }

        // togglePip toggles Picture-in-Picture mode on the video
        // async function togglePip() {
        //   try {
        //     if ('pictureInPictureElement' in document) {
        //       if (video !== document.pictureInPictureElement && pipButton) {
        //         pipButton.disabled = true;
        //         await video.requestPictureInPicture();
        //       } else {
        //         await document.exitPictureInPicture();
        //       }
        //     }
        //   } catch (error) {
        //     console.error(error);
        //   } finally {
        //     pipButton && (pipButton.disabled = false);
        //   }
        // }

        // hideControls hides the video controls when not in use
        // if the video is paused, the controls must remain visible
        function hideControls() {
            if (video.paused) {
                return;
            }

            videoControls?.classList.add('hide');
        }

        // showControls displays the video controls
        function showControls() {
            videoControls?.classList.remove('hide');
        }

        // keyboardShortcuts executes the relevant functions for
        // each supported shortcut key
        function keyboardShortcuts(event: any) {
            const { key } = event;
            switch (key) {
                case 'k':
                    togglePlay();
                    animatePlayback();
                    if (video.paused) {
                        showControls();
                    } else {
                        setTimeout(() => {
                            hideControls();
                        }, 2000);
                    }
                    break;
                case 'm':
                    toggleMute();
                    break;
                case 'f':
                    toggleFullScreen();
                    break;
                case 'p':
                    //togglePip();
                    break;
            }
        }

        // Add eventlisteners here
        playButton?.addEventListener('click', togglePlay);
        video.addEventListener('play', updatePlayButton);
        video.addEventListener('pause', updatePlayButton);
        video.addEventListener('loadedmetadata', initializeVideo);
        video.addEventListener('timeupdate', updateTimeElapsed);
        video.addEventListener('timeupdate', updateProgress);
        video.addEventListener('volumechange', updateVolumeIcon);
        video.addEventListener('click', togglePlay);
        video.addEventListener('click', animatePlayback);
        video.addEventListener('mouseenter', showControls);
        video.addEventListener('mouseleave', hideControls);
        videoControls?.addEventListener('mouseenter', showControls);
        videoControls?.addEventListener('mouseleave', hideControls);
        seek?.addEventListener('mousemove', updateSeekTooltip);
        seek?.addEventListener('input', skipAhead);
        volume?.addEventListener('input', updateVolume);
        volumeButton?.addEventListener('click', toggleMute);
        fullscreenButton?.addEventListener('click', toggleFullScreen);
        videoContainer?.addEventListener('fullscreenchange', updateFullscreenButton);
        //   pipButton?.addEventListener('click', togglePip);

        document.addEventListener('DOMContentLoaded', () => {
            if (!('pictureInPictureEnabled' in document)) {
                pipButton?.classList.add('hidden');
            }
        });
        video.addEventListener('keyup', keyboardShortcuts);
    }
}
