import { Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '../../../services/permission.service';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../services/general.service';

@Component({
    selector: 'app-no-license',
    templateUrl: './no-license.component.html',
    styleUrl: './no-license.component.scss',
})
export class NoLicenseComponent implements OnInit {
    moduleName!: string;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private genServ: GeneralService,
        private permission: PermissionService
    ) {}

    ngOnInit(): void {
        this.genServ.moduleName.subscribe((item) => {
            this.moduleName = item;
            if (!this.moduleName) {
                this.router.navigate(['dashboard']);
            }
        });
    }
}
