<section class="main">
    <div class="main-section section-width mt-4">
        <div class="d-flex justify-content-between section">
            <p class="section-title">{{ title }}</p>
            <p class="section-view pointer" (click)="view()">
                <i [data-feather]="icon" class="avatar-icon mr-2"></i>{{ actBtn }}
            </p>
        </div>
        <div class="d-flex section-width p-2">
            <mat-progress-bar [mode]="mode" [value]="progressValue" class="mt-2"></mat-progress-bar>
            <span class="mb-2 ml-2">{{ progressValue }}%</span>
        </div>
    </div>
</section>
