<div class="accordion">
    <div *ngFor="let item of items; let i = index" class="accordion-item">
        <div class="accordion-body">
            <div class="accordion-top_header border_bottom">
                <div class="accordion-title">
                    {{ item.title }}
                </div>
                <span class="accordion-drop_down" (click)="toggle(i)"
                    ><mat-icon>keyboard_arrow_down</mat-icon></span
                >
            </div>
            <div *ngIf="openedIndex === i" class="accordion-content">
                <ng-container *ngTemplateOutlet="item.content"></ng-container>
            </div>
        </div>
    </div>
</div>
