import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ContentHeaderComponent } from './content-header.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [ContentHeaderComponent, BreadcrumbComponent],
    imports: [CommonModule, RouterModule, MatIconModule],
    exports: [ContentHeaderComponent],
})
export class ContentHeaderModule {}
