import { Component, Input } from '@angular/core';
import { iButton } from 'src/app/shared/models/iButton';
@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent {
    @Input() buttonObject?: iButton;
    @Input() imageUrl?: string;
    @Input() background: string = 'primary';
}
