<form>
    <div class="d-flex gap-10" [ngClass]="{'flex-column':isColumn}">
        <div class="form-group form-style ">
            <label class="font-weight-bold" for="title">{{applyToLabel}}
                <span class="text-danger">*</span></label>
            <select [formControl]="applyToFrm" (change)="onSelectApplyTo()" class="form-control">
                <option *ngFor="let item of applyTo" [value]="item">{{ item }}</option>
            </select>
        </div>
        @if(list.length){
        <div class="form-group ">
            <label class="font-weight-bold" for="title">{{listLabel}} <span class="text-danger">*</span></label>
            <div class="app-ng-autocomplete ml-1">
                <ng-select #listTemplate [formControl]="listControl" [placeholder]="'Select'" bindLabel="value"
                    class="ng-input" [closeOnSelect]="false" [items]="list" (change)="onSelectList($event)"
                    [clearable]="false">
                    <ng-template ng-header-tmp>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-primary" type="button" (click)="onSelectAll()">
                                Select All
                            </button>
                            <button class="btn btn-link" type="button" (click)="onClearAll()">
                                Clear All
                            </button>
                        </div>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div class="d-flex w-100 gap-10 align-items-center">
                            <p-checkbox [ngModelOptions]={standalone:true}
                                [ngModel]="inviteesSelection.isSelected(item)" [binary]="true" inputId="binary" />
                            <div *ngIf="applyToForm.value.applyTo === 'employees'" class="mention-house m-1">
                                <app-profile-image [user]="item"></app-profile-image>
                                <div class="name-house">
                                    <span class="name"> {{ item.name }} </span>
                                    <span class="time">{{ item.email }} </span>
                                </div>
                                <hr />
                            </div>
                            <div *ngIf="applyToForm.value.applyTo !== 'employees'" class="mention-house m-1">
                                <div class="name-house">
                                    <span class="name"> {{ item.value }} </span>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        }
    </div>
    @if(listForm.value.length){
    <div style="height:50px; max-height:400px; overflow:auto ">
        <btn-list (onRemove)="removeList($event)" key="value" style="display: flex; flex-wrap: wrap"
            [items]="listForm.value" btnClass="btn btn-primary" [btnStyle]="{ padding: '2%' }"></btn-list>
    </div>
    }
</form>