import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-user-template',
    templateUrl: './user-template.component.html',
    styleUrls: ['./user-template.component.scss'],
})
export class UserTemplateComponent implements OnInit {
    @Input() users: any[] = [];

    constructor() {}

    ngOnInit(): void {
        this.users.forEach((u) => {
            if (!u.profileImgUrl) {
                u.profileImgUrl = `'https://ui-avatars.com/api/?background=F7D3EA&color=D62196&name=${u.name || u.fullName || u.firstName + ' ' + u.lastName}'`;
            }
        });
    }
}
