<modal-cover [title]="'Add Beneficiary'">
    <div class="row w-100">
        <section class="h-100 col-md-6 col-sm-12 w-100">
            <form
                [formGroup]="formData"
                (ngSubmit)="add()"
                style="gap: 5px"
                class="p-3 d-flex h-100 flex-column"
            >
                <div class="form-group form-style w-100">
                    <label class="font-weight-bold" for="title"
                        >Account Number<span class="text-danger">*</span></label
                    >
                    <p-inputMask
                        mask="9999999999"
                        formControlName="account"
                        placeholder="0123456789"
                        class="w-100"
                        inputStyleClass="form-control "
                        inputId="integeronly"
                    >
                    </p-inputMask>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="title"
                        >Banks<span class="text-danger">*</span></label
                    >
                    <div class="app-ng-autocomplete ml-1">
                        <ng-select
                            [placeholder]="'Select Beneficiary'"
                            bindLabel="name"
                            class="ng-input"
                            [items]="banks"
                            [hideSelected]="true"
                            formControlName="bank"
                            [clearable]="true"
                        >
                        </ng-select>
                    </div>
                    <span
                        *ngIf="canVerify"
                        (click)="verifyAccount()"
                        [style.color]="label.color"
                        class="add-bene clickable"
                        for="title"
                        >{{ label.text }}</span
                    >
                </div>
                <mat-checkbox
                    *ngIf="formData.value.account_name"
                    style="word-wrap: break-word"
                    [formControl]="confirm"
                    >Please confirm that you want to add <b>{{ formData.value.account_name }}</b> to
                    your beneficiary list</mat-checkbox
                >
                <div class="d-flex btn-grp justify-content-between">
                    <button [disabled]="!confirm.value" type="submit" class="btn btn-primary">
                        Add Beneficiary
                    </button>
                </div>
            </form>
        </section>
        <div class="col-md-6 col-sm-12">
            <div class="bene-image"></div>
        </div>
    </div>
</modal-cover>
