import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'mx-masked-text',
    templateUrl: './masked-text.component.html',
    styleUrls: ['./masked-text.component.scss'],
})
export class MaskedTextComponent implements OnInit, OnChanges {
    @Input() originalText: any;
    masked: boolean = true;
    maskedText: any;

    toggleMask() {
        this.masked = !this.masked;
    }
    constructor() {
        // this.change(this.originalText);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.originalText.currentValue) {
            this.change(changes.originalText.currentValue);
        }
    }

    ngOnInit(): void {}

    change(text: string) {
        this.maskedText = text.replace(/./g, '*');
    }
}
