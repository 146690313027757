<ng-template #modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="dismiss()"
            *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
            [disabled]="
                modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()
            "
        >
            {{ modalConfig.dismissButtonLabel }}
        </button>
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="close()"
            *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
            [disabled]="
                modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()
            "
        >
            {{ modalConfig.closeButtonLabel }}
        </button>
    </div>
</ng-template>
