import { ExpiringPlansComponent } from './expiring-plans/expiring-plans.component';
import { HmoProvidersComponent } from './hmo-providers/hmo-providers.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PayViaMyxalaryComponent } from './pay-via-myxalary/pay-via-myxalary.component';
import { AllHmoProvidersComponent } from './all-hmo-providers/all-hmo-providers.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmployeeSearchComponent } from './employee-search/employee-search.component';
import { HmoProviderViewComponent } from './hmo-provider-view/hmo-provider-view.component';
import { HealthPurchaseDetailsComponent } from './payment-history/purchase-details/purchase-details.component';

export const containers: any[] = [
    AllHmoProvidersComponent,
    PayViaMyxalaryComponent,
    DashboardComponent,
    PaymentHistoryComponent,
    HmoProvidersComponent,
    ExpiringPlansComponent,
    EmployeeSearchComponent,
    HmoProviderViewComponent,
    HealthPurchaseDetailsComponent,
];

export * from './all-hmo-providers/all-hmo-providers.component';
export * from './pay-via-myxalary/pay-via-myxalary.component';
export * from './dashboard/dashboard.component';
export * from './payment-history/payment-history.component';
export * from './employee-search/employee-search.component';
export * from './hmo-provider-view/hmo-provider-view.component';
export * from './payment-history/purchase-details/purchase-details.component';
