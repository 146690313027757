import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PAGINATOR } from '../../../../interfaces/general';
import { expiringPlansData } from '../dashboard/dashboard.data';
import { iTableData } from './../../../../shared/models/interfaces';
import { HealthDispatcher } from './../../health-data/dispatcher/health.dispatcher';
import { HealthStore } from './../../health-data/stores/health.store';
import { IPurchase, TableAction, TableActions } from './../../health.data';

@Component({
    selector: 'app-expiring-plans',
    templateUrl: './expiring-plans.component.html',
    styleUrls: ['./expiring-plans.component.scss'],
})
export class ExpiringPlansComponent implements OnInit {
    expiringPlans: iTableData = expiringPlansData;
    displayedColumns: string[] = ['#', 'providerName', 'cost', 'createdAt', 'status', 'actions'];
    dataSource!: MatTableDataSource<any>;
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    pageSize = 10;
    @Input() year!: any;
    @Input() limit!: number;
    paginatorSetup: PAGINATOR = {
        currentPage: 1,
        totalCount: 0,
        totalPages: 1,
    };

    constructor(
        private _healthStore: HealthStore,
        private _router: Router,
        private _storeDispatcher: HealthDispatcher,
        private _route: ActivatedRoute
    ) {}

    tableActions: TableActions = [
        {
            title: 'View',
            clickHandler: (item: IPurchase) => {
                this._storeDispatcher.setPurchaseItem(item);
                this._router.navigate(['health/purchase-history/details', item._id], {
                    // skipLocationChange: true,
                    //   relativeTo: this._route,
                });
            },
        },
        {
            title: 'Renew',
            clickHandler: (item: IPurchase) => {
                this._storeDispatcher.setPurchaseItem(item);
                this._router.navigate(['health/renew'], { skipLocationChange: true });
            },
        },
    ];

    ngOnInit(): void {
        this._healthStore
            .getExpiringPlans()
            .pipe(
                filter((data: { data: IPurchase[]; paging: PAGINATOR }) => !!data.data.length)
                // take(1)
            )
            .subscribe({
                next: (data) => {
                    console.log(data);
                    this.expiringPlans.body = data.data;
                    this.dataSource = new MatTableDataSource(
                        data.data.map((data, index) => ({
                            ...data,
                            providerName: data.provider.providerName,
                            index: index + 1,
                        }))
                    );
                    this.paginatorSetup = { ...data.paging };
                    this.dataSource.sort = this.sort;
                },
            });
    }

    viewDetails(row: any) {
        this._storeDispatcher.setPurchaseItem(row);
        this._router.navigateByUrl('health/renew');
    }

    showRenew(item: IPurchase, action: TableAction) {
        if (action.title === 'View') return true;

        return item.type === 'myxalary';
    }
    handlePaginateEvent(event: any) {
        const { count } = event;
        this._storeDispatcher.fetchExpiringPlans({ year: this.year, skip: count, limit: 1 });
    }
}
