import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { utils, writeFileXLSX } from 'xlsx';
import {
    CourseCard,
    CourseListType,
    CourseV2,
    DashboardFilterType,
    DropdownOption,
    Employee,
    FileToUpload,
    LearningPath,
    SortingMethod,
    UserCourseRow,
    UserQuiz,
} from '../lms.types';
import { HttpService } from './http.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class LMSService {
    constructor(
        private httpService: HttpService,
        private userService: UserService,
        private http: HttpClient
    ) {}

    /**
     * This method is for fetching the courses list from backend
     * @param {CourseListType} type          type of the course
     * @param {number} noOfItems             how many courses you want to fetch
     * @param {number} page                  page number
     * @param {string} tag                   selected tag filter
     * @param {string} category              which category the courses should belong to
     * @param {string} searchQuery           if there is any text search query
     * @param {string} sorting               sorting method (default: 'recent)
     * @returns
     */
    async getCourses(
        type: CourseListType,
        noOfItems: number,
        page: number,
        tag: string,
        category: string,
        searchQuery: string,
        sorting: SortingMethod = 'recent',
        skill: string = ''
    ) {
        const data: any = {
            organization: this.userService.getOrganizationId(),
            itemsPerPage: noOfItems,
            page,
        };

        if (tag) {
            data.tags = tag;
        }
        if (category) {
            const c = JSON.stringify([category]);
            data.categories = c;
        }
        if (searchQuery !== '') {
            data.searchQuery = searchQuery;
        }
        if (skill !== '') {
            data.skill = skill;
        }
        data.isAdmin = true;

        data.sorting = sorting;

        let url = '/course/courses';

        if (type) {
            url += `/${type}`;
        }

        return this.httpService.getRequest<{ courses: CourseV2[]; count: number }>(url, data);
    }

    /**
     * Fetch the details of a course based on courseID
     * @param courseId id of the course you want to fetch
     * @returns {Promise<CourseV2>}
     */
    async getCourseDetails(courseId: string): Promise<CourseV2> {
        return (await this.httpService.getRequest('/course/course', { courseId })) as CourseV2;
    }

    /**
     * Get Categories List
     * @returns {Promise<DropdownOption[]>} List of categories options
     */
    async getCategories(): Promise<DropdownOption[]> {
        const organization = this.userService.getOrganizationId();
        return (await this.httpService.getRequest('/category/catOptions', {
            organization,
        })) as DropdownOption[];
    }

    /**
     * Get Tags List
     * @returns {Promise<DropdownOption[]>} List of Tags options
     */
    async getTags(): Promise<DropdownOption[]> {
        return (await this.httpService.getRequest('/tag/tagsOptions', {
            organization: this.userService.getOrganizationId(),
        })) as DropdownOption[];
    }

    async fetchLearningPath(id: string) {
        return this.httpService.getRequest<LearningPath>(`learningPath/getOne/${id}`);
    }

    publishUnpublishCourse(courseId: string, publish: boolean, notificationType: string, notificationTypeId: string, employeeList?: Employee[]) {
        const companyData = this.userService.getUserInfo().companyData;

        const data = {
            courseId,
            orgName: companyData?.companyName,
            token: this.userService.getBearerToken(),
            publish,
            notificationType,
            notificationTypeId,
            employeeList,
        };
        return this.httpService.postRequest('course/publish', data);
    }

    async recommendCourse(course: CourseV2, users: Employee[], purpose: string) {
        const courseData = {
            id: course._id,
            name: course.name,
        };
        const companyData = this.userService.getUserInfo().companyData;
        return await this.httpService.postRequest('course/recommendCourse', {
            course: courseData,
            users,
            purpose,
            organization: {
                name: companyData?.companyName,
                id: companyData?._id,
            },
        });
    }

    async recommendPath(path: LearningPath, users: Employee[]) {
        const pathData = {
            _id: path._id,
            name: path.name,
        };
        const companyData = this.userService.getUserInfo().companyData;
        return await this.httpService.postRequest('learningPath/recommend', {
            path: pathData,
            users,
            orgName: companyData?.companyName,
        });
    }

    async getRecommendations() {
        return await this.httpService.getRequest('course/adminRecommendations', {
            organization: this.userService.getOrganizationId(),
        });
    }

    async getPathsRecommendations() {
        return await this.httpService.getRequest('learningPath/recommendations');
    }

    async getLearningCurveArray(year: string, filterType?: DashboardFilterType, filterValue?: string): Promise<any[]> {
        return (await this.httpService.getRequest('/dashboard/learningCurve', {
            year,
            organization: this.userService.getOrganizationId(),
            filterType,
            filterValue,
        })) as any[];
    }

    async getQuizPassing(selectedYear: string, selectedMonth: string, filterType?: DashboardFilterType, filterValue?: string): Promise<any> {
        return await this.httpService.getRequest('/dashboard/quizPassing', {
            organization: this.userService.getOrganizationId(),
            year: selectedYear,
            month: selectedMonth,
            filterType,
            filterValue,
        });
    }

    getDashboardStats(filterType?: DashboardFilterType, filterValue?: string) {
        return this.httpService.getRequest('/dashboard/stats', {
            organization: this.userService.getOrganizationId(),
            filterType,
            filterValue,
        }) as Promise<any>;
    }

    getDashboardEarningChart(year: number, filterType: DashboardFilterType, filterValue?: string) {
        return this.httpService.getRequest<{ month: string; earnings: number; courses: number }[]>('/dashboard/earningChart', {
            year,
            filterType,
            filterValue,
        });
    }

    getTimeSpentData(selectedYear: number, selectedMonth: number, selectedWeek: number, filterType?: DashboardFilterType, filterValue?: string): Promise<any> {
        const sDate = new Date(`${+selectedMonth + 1}-${+selectedWeek * 7 + 1}-${selectedYear}`);
        let eDate;
        if (selectedWeek === 4) {
            eDate = new Date(selectedYear, +selectedMonth + 1, 1);
        } else {
            eDate = new Date(`${+selectedMonth + 1}-${+selectedWeek * 7 + 7 + 1}-${selectedYear}`);
        }
        return this.httpService.getRequest('/dashboard/timeSpent', {
            organization: this.userService.getOrganizationId(),
            sDate,
            eDate,
            filterType,
            filterValue,
        });
    }

    addCategory(name: string): Promise<any> {
        const data = {
            name,
            organization: this.userService.getOrganizationId(),
        };
        return this.httpService.postRequest('category/category', data);
    }

    addTag(name: string): Promise<any> {
        const data = {
            name,
            organization: this.userService.getOrganizationId(),
        };
        return this.httpService.postRequest('tag/tag', data);
    }

    querySearchCourses(query: string): Promise<CourseCard[]> {
        return this.httpService.getRequest<CourseCard[]>('/course/search', { query }) as Promise<CourseCard[]>;
    }

    saveNewCourse(course: CourseV2, files: FileToUpload[]) {
        course.preReqCourse = course.preReqCourse.map((c) => (c as CourseCard)._id);

        const data = new FormData();
        data.append('course', JSON.stringify(course));
        for (let file of files) data.append(file.fileId, file.file);
        return this.httpService.postRequest<{ _id: string }>('/course/add', data);
    }

    updateCourse(course: CourseV2, files: FileToUpload[], deletedResources: string[]) {
        course.preReqCourse = course.preReqCourse.map((c) => (c as CourseCard)._id);

        const data = new FormData();
        data.append('course', JSON.stringify(course));
        data.append('deletedResources', JSON.stringify(deletedResources));
        for (let file of files) data.append(file.fileId, file.file);
        return this.httpService.putRequest('/course/update', data);
    }

    saveNewLearningPath(learningPath: LearningPath, files: (FileToUpload | null)[]) {
        const data = new FormData();
        data.append('learningPath', JSON.stringify(learningPath));
        for (let file of files) if (file) data.append(file.fileId, file.file);

        return this.httpService.postRequest<{ _id: string }>('/learningPath/saveLearningPath', data);
    }

    updateLearningPath(learningPath: LearningPath, files: (FileToUpload | null)[]) {
        const { _id: pathId, name, courses, thumbnail, jobRole, category } = learningPath;

        const data = new FormData();
        data.append('pathId', pathId as string);
        data.append('courses', JSON.stringify(courses));
        data.append('name', name);
        data.append('jobRole', JSON.stringify(jobRole));
        data.append('category', JSON.stringify(category));

        if (thumbnail.isDirty) {
            data.append('thumbnail', JSON.stringify(thumbnail));
        }

        for (let file of files) if (file) data.append(file.fileId, file.file);

        return this.httpService.putRequest<{ updated: boolean }>('learningPath/update', data);
    }

    deleteLearningPath(pathId: string) {
        return this.httpService.deleteRequest<{ deleted: boolean }>('learningPath/deletePath', { pathId });
    }

    findJobRoles(query: string) {
        return this.http
            .get(`${environment.recruitmentEndpoint}/v1/jobs/job-role-templates?search=${query}&page=1&limit=10`)
            .pipe(map((data: any) => data.payload.data));
    }

    findSkills(query: string) {
        return this.http
            .get(`${environment.recruitmentEndpoint}/v1/settings/fetch-all-skills?search=${query}&page=1&limit=10`)
            .pipe(map((data: any) => data.payload.data));
    }

    fetchLearningPaths(
        noOfItems: number,
        page: number,
        category: string | null,
        jobRole: string | undefined,
        searchQuery: string,
        sorting: SortingMethod = 'recent',
        type: CourseListType = 'all'
    ) {
        const data: any = { items: noOfItems, page, sorting };
        if (category) {
            const c = JSON.stringify([category]);
            data.selectedCategories = c;
        }
        if (searchQuery !== '') {
            data.searchQuery = searchQuery;
        }
        if (jobRole) {
            data.jobRole = jobRole;
        }

        return this.httpService.getRequest<{ count: number; paths: LearningPath[] }>(`/learningPath/get/${type}`, data);
    }

    publishUnpublishPath(pathId: string, publish: boolean) {
        const data = {
            pathId,
            token: this.userService.getBearerToken(),
            publish,
        };
        return this.httpService.postRequest('learningPath/publish', data);
    }

    fetchSuggestedPathCourses(skills: string[]) {
        return this.httpService.postRequest<CourseCard[]>(`learningPath/generate`, { skills });
    }

    deleteCourse(courseId: string) {
        return this.httpService.deleteRequest<{ deleted: true }>('course/deleteCourse', { courseId });
    }

    fetchQuizAttempts(exhausted: boolean) {
        return this.httpService.getRequest<UserQuiz[]>('quiz/getAttempts', { exhausted });
    }

    fetchCourseAttempts(exhausted: boolean, courseId?: string) {
        const data: any = { exhausted };
        if (courseId) {
            data.courseId = courseId;
        }
        return this.httpService.getRequest<UserCourseRow[]>('course/course-attempts', data);
    }

    allowExtraAttempt(studentId: string, quizId: string, increments: number = 1) {
        return this.httpService.postRequest('quiz/allowAttempt', { studentId, quizId, increments });
    }

    allowExtraCourseAttempt(studentId: string, courseId: string, increments: number = 1) {
        return this.httpService.postRequest('course/add-attempt', { studentId, courseId, increments });
    }

    fetchDepartments() {
        return this.http.get<any>(environment.myXalaryEndpoint + '/v1/departments/getDepartments').toPromise();
    }

    fetchCadres() {
        return this.http.get<any>(environment.myXalaryEndpoint + '/v1/employees/getAllCadre').toPromise();
    }

    async fetchAllEmployees(departmentId?: string) {
        const token = this.userService.getBearerToken();

        let queryParams = `token=${token}`;

        if (departmentId) {
            queryParams += `&departmentId=${departmentId}`;
        }

        const headers = { 'Content-Type': 'application/json', 'lmsAuthorization': `Bearer ${this.userService.getLmsToken()}` };

        let res = await this.http.get<any>(`${environment.lmsApiEndpoint}user/listEmployees?${queryParams}`, { headers }).toPromise();
        if (res.success) return res.data.employees as Employee[];

        return null;
    }

    exportToExcel(rows: any[], columnMapping: any = {}, fileName: string = 'Export.xlsx') {
        const mappedRows = rows.map((row) => {
            const mappedRow: any = {};
            for (const key in row) {
                mappedRow[columnMapping[key] ?? key] = row[key];
            }
            return mappedRow;
        });

        const ws = utils.json_to_sheet(mappedRows);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        writeFileXLSX(wb, fileName);
    }

    sendCourseReminder(courseId: string, courseName: string, filterType: string, filterValue: string, employeeList?: Employee[]) {
        const companyData = this.userService.getUserInfo().companyData;

        return this.httpService.postRequest('course/reminder', {
            courseId,
            orgName: companyData?.companyName,
            filterType,
            filterValue,
            employeeList,
            courseName,
        });
    }

    sendRecommendationReminders(mailArray: any[], type = 'courses') {
        const orgName = this.userService.getUserInfo()?.companyData?.companyName;

        return this.httpService.postRequest('course/sendReminder', { mailArr: mailArray, organization: orgName, type, recommendation: true });
    }
}
