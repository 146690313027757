import { iAnalyticsItem } from './../../../containers/dashboard/dashboard.data';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-analytics-item',
    template: ` <div class="d-flex flex-row align-items-center">
        <div class="avatar mr-2">
            <img [src]="item.avatar" alt="" srcset="" />
        </div>
        <div class="a-details">
            <h5 class="m-0">
                {{ item.currency ? (item.title | currency: item.currency) : item.title }}
            </h5>
            <span class="fs-6">{{ item.description }}</span>
        </div>
    </div>`,
    styles: [``],
})
export class AnalyticsItemComponent {
    @Input() item!: iAnalyticsItem;
}
