import { Component, Input, Output, EventEmitter } from '@angular/core';
import { iEmployee } from 'src/app/shared/models/interfaces';

@Component({
    selector: 'single-item-with-icon',
    template: ` <div
        class="p-2 mr-2 mb-2 flex flex-row justify-content-between each-employee rounded"
    >
        <span class="pr-2">{{ employee.name }}</span>
        <i class="fa fa-times rounded-circle remove-user-icon" (click)="onClick()"></i>
    </div>`,
    styleUrls: ['./single-item-with-icon.component.scss'],
})
export class SingleItemWithIconComponent {
    @Input() employee!: iEmployee;
    @Output() itemClicked: EventEmitter<any> = new EventEmitter();

    onClick() {
        this.itemClicked.emit();
    }
}
