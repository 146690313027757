<div>
    <section>
        <div class="card-body">
            <h4 class="card-title">Jon</h4>
            <p class="text-sm text-gray">Wick</p>

            <div class="icon-section mb-4">
                <mat-icon [svgIcon]="'checklist'" class="icon"></mat-icon>
            </div>

            <div class="row">
                <div class="col-12 mt-2">
                    <button class="btn mr-4">Ayou</button>
                </div>
            </div>
        </div>
    </section>
</div>
