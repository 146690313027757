import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
    options: AnimationOptions = {
        path: './assets/motions/loader.json',
    };

    showSpinner = false;

    constructor(
        private cdRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {}

    ngOnInit(): void {
        this.init();
    }

    init(): void {
        this.spinnerService.spinnerObserver$.subscribe((status) => {
            this.showSpinner = status === 'start';
            this.cdRef.detectChanges();
        });
    }

    animationCreated(event: any): void {
        // console.log(event), 'fd';
    }
}
