import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { iHmoProvider } from 'src/app/shared/models/hmoProvider';
import { iEmployee } from 'src/app/shared/models/interfaces';
import { PAGINATOR } from '../../../../interfaces/general';
import { IPurchase } from '../../health.data';
import {
    fetchEmployees,
    fetchExpiringPurchase,
    fetchHmoProviders,
    fetchPurchaseHistories,
    setEmployees,
    setExpiringPurchase,
    setHmoProviders,
    setPurchaseHistories,
    setPurchaseItem,
} from '../actions';

@Injectable()
export class HealthDispatcher {
    constructor(private _store: Store<any>) {}

    fetchHmoProviders(query: any) {
        this._store.dispatch(fetchHmoProviders(query));
    }

    setHmoProviders(payload: iHmoProvider[]) {
        this._store.dispatch(setHmoProviders({ payload }));
    }

    fetchEmployees() {
        this._store.dispatch(fetchEmployees());
    }

    setEmployees(payload: iEmployee[]) {
        this._store.dispatch(setEmployees({ payload }));
    }

    setPurchaseItem(payload: IPurchase) {
        this._store.dispatch(setPurchaseItem({ payload }));
    }

    fetchPurchaseHistories(payload: any) {
        this._store.dispatch(fetchPurchaseHistories({ payload }));
    }

    setPurchaseHistories(payload: { data: IPurchase[]; paging: PAGINATOR }) {
        this._store.dispatch(setPurchaseHistories({ payload }));
    }

    fetchExpiringPlans(payload: any) {
        this._store.dispatch(fetchExpiringPurchase({ payload }));
    }

    setExpiringPlans(payload: { data: IPurchase[]; paging: PAGINATOR }) {
        this._store.dispatch(setExpiringPurchase({ payload }));
    }
}
