import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import endpoints from 'src/app/utils/endpoints';
import { QueryGenerator } from '../../recruitment/shared/helper';
import { UtilsService } from './utils.service';
import { AppStore } from 'src/app/app-data';
import { take } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiService extends HttpClient {
    endpoint = endpoints.MX_BE.V1.billing;
    main = endpoints.MX_BE.V1;
    endpointV2 = endpoints.MX_BE.V2.billing;
    constructor(
        handle: HttpHandler,
        private util: UtilsService,
        private appStore: AppStore
    ) {
        super(handle);
    }

    async setRolePermission(data: any) {
        return await this.put(this.endpoint.setPermission, data).toPromise();
    }

    async setPin(data: any) {
        return await this.put(this.endpoint.setPin, data).toPromise();
    }
    async getOTP() {
        return await this.get(this.endpoint.getOTP, {
            headers: {
                nospinner: 'true',
            },
        }).toPromise();
    }

    async getCompliance() {
        return await this.get(this.endpoint.getCompliance).toPromise();
    }

    async updateCompliance(data: any) {
        return await this.put(this.endpointV2.updateCompliance, data).toPromise();
    }
    async getBalance(headers?: any) {
        if (this.util.balance.getValue()) {
            return this.util.balance.getValue();
        }
        const result: any = await this.get(this.endpoint.getBalance, {
            headers,
        }).toPromise();
        this.util.balance.next(result.response);
        return result.response;
    }

    async verifyAccount(account: string, bankCode: string) {
        return await this.get(
            `${endpoints.MX_BE.V1.employees.verifyBankDetails}/${account}/${bankCode}`,
            {
                headers: {
                    nospinner: 'true',
                },
            }
        ).toPromise();
    }
    async addBeneficiary(data: any) {
        return await this.post(`${this.endpoint.createBeneficiary}`, data).toPromise();
    }
    async getBeneficiaryTransactions(recipientCode: string) {
        return await this.get(
            `${this.endpoint.getWallet}?recipientCode=${recipientCode}`
        ).toPromise();
    }
    async getBeneficiaries({ page = 1, limit = 10, ...query }: any, loader: boolean) {
        let headers: any = {};
        if (!loader) {
            headers['nospinner'] = 'true';
        }
        const result: any = await this.get(
            `${this.endpointV2.getBeneficiaries}${QueryGenerator({ page, limit, ...query })}`,
            {
                headers: {
                    ...headers,
                },
            }
        ).toPromise();
        this.util.totalBeneficiciaries$.next(result.data?.paging?.totalCount);
        return result;
    }
    async deleteBeneficiary(id: string) {
        return await this.delete(`${this.endpoint.deleteBeneficiary}/${id}`).toPromise();
    }

    async initiateTransaction(query: any) {
        return await this.get(
            `${this.endpoint.initiateTransaction}${QueryGenerator(query)}`
        ).toPromise();
    }

    async getVirtualAccounts() {
        const wallet = this.util.wallets$.getValue();
        if (wallet.length) {
            return wallet;
        }
        const user: any = await this.appStore.getUserInfo().pipe(take(1)).toPromise();
        let result: any = await this.get(`${this.endpoint.getVirtualAccounts}`).toPromise();
        result = result.response;
        if (!result?.length) {
            result = await this.createVirtualAccounts({
                name: user.companyData?.companyName,
                reference: user.companyData?._id,
                bvn: user.companyData?.bvn,
            });
            result = result.response;
        }
        this.util.wallets$.next(
            result
                .filter((item: any) => {
                    return !['paystack', 'flutterwave'].includes(item.channel.toLowerCase());
                })
                .sort((item1: any, item2: any) => {
                    if (item1.channel < item2.channel) {
                        return -1;
                    }
                    if (item1.channel > item2.channel) {
                        return 1;
                    }
                    return 0;
                })
        );
        return result;
    }

    async createVirtualAccounts(data: any) {
        return await this.post(`${this.endpoint.createVirtualAccounts}`, data, {
            headers: {
                nospinner: 'true',
                noerroralert: 'true',
            },
        }).toPromise();
    }

    async createBilling(type: string, data: any) {
        return await this.post(`${this.endpoint.createBilling}?type=${type}`, data).toPromise();
    }

    async transfer(data: any) {
        return await this.post(`${this.endpoint.transfer}`, data).toPromise();
    }

    async getTransactions(query: any) {
        const result: any = await this.get(
            `${this.endpoint.getWallet}${QueryGenerator(query)}`
        ).toPromise();
        this.util.transactions$.next(result.response);
        return result;
    }

    async getTransaction(type: any, ref: any) {
        return await this.get(`${this.endpoint.getTransaction}/${type}/${ref}`, {
            headers: {
                noerroralert: 'true',
            },
        }).toPromise();
    }

    async reportTransaction(data: any) {
        return await this.post(`${this.endpoint.reportTransaction}`, data).toPromise();
    }

    async getAttendanceDashboard(query: any = {}) {
        return await this.get(
            `${endpoints.MX_BE.V1.attendance.dashboard}${QueryGenerator(query)}`,
            {
                headers: {
                    nospinner: 'true',
                },
            }
        ).toPromise();
    }
    getPayrollAnalytics() {
        return this.get(`${this.main.payroll.analytics}`);
    }

    getMedicalAnalytics() {
        return this.get<any>(`${this.main.medical.analytics}`);
    }

    getLeaveAnalytics() {
        return this.get<any>(`${this.main.leave.analytics}`);
    }

    getTaxAndPensionAnalytics() {
        return this.get<any>(`${this.main.taxandpension.analytics}`);
    }

    updateCompanyProfile(payload: any) {
        return this.patch<any>(`${this.main.users.updateCompanyProfile}`, payload);
    }

    getFlashNews() {
        return of([
            {
                title: 'A Must Read',
                message: `<p  style='padding:10px'>Hello there,<br />
          We're writing to inform you about upcoming changes to sending funds to Providus accounts. Effective July 1st
          , Providus Bank will introduce the following charges:<br />
          <ul>
<li>Account maintenance charge: <b>₦1,000</b> per every  <b>₦1,000,000</b> sent to a Providus account.</li>
<li> Inflow charge: <b>0.05 % (capped at ₦500) </b> for every amount received by a Providus account.</li> </ul>
          <p>To avoid these additional fees, we recommend funding your wallet using the Wema Bank account provided. 
          Feel free to reach out to us at </p> <br />
<p><b>hello@notchhr.io</b>
or call <b>09137173363 or 09137173400</b> if you have any questions.We'll be happy to provide expert guidance. </p>
          Thank you for your understanding,<br/>
      The NotchHR Team<p/>`,
            },
        ]);
    }
}
