<modal-cover title="Payment Details">
    <div class="d-flex p-5 m-1 w-100 flex-column gap-5">
        <main-app-analytics-infor-card
            [analyticsInfo]="analyticsData"
        ></main-app-analytics-infor-card>
        <div class="d-flex flex-column gap-5 p-3" *ngFor="let plan of data.plans">
            <p-divider [ngStyle]="{ width: '100%' }"></p-divider>
            <main-app-analytics-infor-card
                [analyticsInfo]="getCard(plan)"
            ></main-app-analytics-infor-card>
            <table class="table table-bordered">
                <thead>
                    <th>Employee Name</th>
                    <th>Employee Email</th>
                </thead>
                <tbody>
                    <tr *ngFor="let employees of plan.employees">
                        <td>{{ employees.name }}</td>
                        <td>{{ employees.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</modal-cover>
