<div *ngIf="userHasAccess; else unauthorized_user">
    <div class="container-fluid">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <!-- form component -->
        <section class="card p-3">
            <div class="card-header" style="padding: 0px !important">
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <h3 class="title">Pay HMO via NotchHR</h3>
                    <img *ngIf="logoUrl" [src]="logoUrl" class="provider-logo" alt="" />
                </div>
            </div>
            <p-divider></p-divider>

            <div *ngIf="paymentForm" class="payHmo-wrapper mt-3">
                <form class="payHmo-form" [formGroup]="paymentForm">
                    <div class="form-group">
                        <label for="jobRole">Select HMO <span class="text-danger">*</span></label>

                        <div class="app-ng-autocomplete">
                            <ng-select
                                [typeahead]="searchProviders"
                                bindLabel="providerName"
                                [loading]="loadingProviders"
                                [placeholder]="'Select Provider'"
                                formControlName="providerId"
                                [items]="providers | async"
                                class="ng-input"
                                [clearable]="true"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="d-flex gap-5 align-items-center">
                                        <img
                                            *ngIf="item.logo"
                                            style="border-radius: 50%; object-fit: contain"
                                            height="40"
                                            width="40"
                                            [src]="item.logo"
                                        />
                                        <b>{{ item.providerName }}</b>
                                    </div>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <div class="d-flex gap-5 align-items-center">
                                        <img
                                            *ngIf="item.logo"
                                            style="border-radius: 50%; object-fit: contain"
                                            height="50"
                                            width="50"
                                            [src]="item.logo"
                                        />
                                        <b>{{ item.providerName }}</b>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="hmo-user-details p-2 rounded mb-4">
                        <label>Use this section to organize your HMO payment packages</label>
                        <div
                            formArrayName="plans"
                            class="row mt-3"
                            *ngFor="let package of plans.controls; let i = index"
                        >
                            <ng-container [formGroupName]="i">
                                <div class="col-sm-5">
                                    <div class="form-group form-style">
                                        <label for="jobRole"
                                            >Amount(per month)<span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            placeholder="Enter Amount"
                                            class="form-control rounded p-2"
                                            formControlName="amount"
                                            (blur)="transformAmount(i, $event)"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-7">
                                    <div class="form-group form-style">
                                        <label for=""
                                            >Select Employee
                                            <span class="text-danger">*</span></label
                                        >
                                        <input
                                            style="color: green; cursor: pointer"
                                            class="s-employ"
                                            type="text"
                                            readonly
                                            (click)="openEmployeeSearchModal(i)"
                                            [placeholder]="
                                                package.value.employees.length +
                                                ' Employees Selected'
                                            "
                                            class="form-control rounded p-2"
                                        />
                                        <span class="text-danger"
                                            >{{ package.value.period || 'period not set' }}({{
                                                package.value.startDate || 'start date not set'
                                            }})</span
                                        >
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="row w-100 m-0">
                            <div class="col d-flex flex-row justify-content-between">
                                <div class="pointer" (click)="downloadSchedule()">
                                    <i
                                        class="fa fa-download text-secondary pr-1"
                                        aria-hidden="true"
                                    ></i>
                                    <span class="text-secondary">Download Schedule</span>
                                </div>

                                <div class="pointer" (click)="uploadSchedule()">
                                    <i
                                        class="fa fa-upload text-secondary pr-1"
                                        aria-hidden="true"
                                    ></i>
                                    <span class="text-secondary">Upload Schedule</span>
                                    <input
                                        type="file"
                                        #csvReader
                                        name="Upload CSV"
                                        id="txtFileUpload"
                                        style="display: none"
                                        accept=".csv"
                                        (change)="uploadListener($event)"
                                    />
                                </div>

                                <div class="add-new" (click)="addNewLine()" *ngIf="plans.valid">
                                    <i
                                        class="fa fa-plus-circle text-secondary pr-1"
                                        aria-hidden="true"
                                    ></i>
                                    <span class="text-secondary">Add New Line</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group d-flex align-items-center mb-2">
                        <label for="period" class="pr-3">Auto Renewal</label>
                        <app-toggle [controlName]="recurrentControl"></app-toggle>
                    </div> -->

                    <div formGroupName="accountManager" class="form-group form-style">
                        <label for="nameOfAccountManager"
                            >Name of your relationship manager
                            <span *ngIf="hmoControl.value">
                                at
                                {{ hmoControl.value.providerName }}
                            </span>
                            <span class="text-danger">*</span></label
                        >
                        <input
                            type="text"
                            name="nameOfAccountManager"
                            placeholder="Enter name of Account manager"
                            class="form-control rounded"
                            formControlName="name"
                        />
                    </div>
                    <div formGroupName="accountManager" class="form-group form-style">
                        <label for="emailOfAccountManager"
                            >Email of your relationship manager
                            <span *ngIf="hmoControl.value">
                                at
                                {{ hmoControl.value.providerName }}
                            </span>
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            type="email"
                            name="emailOfAccountManager"
                            placeholder="Enter email Address of Account mananger"
                            class="form-control rounded"
                            formControlName="email"
                        />
                    </div>
                    <div>
                        <button
                            (click)="submit()"
                            [disabled]="paymentForm.invalid"
                            class="btn btn-primary"
                        >
                            Save
                        </button>
                    </div>
                </form>

                <div class="payHmo-aside">
                    <!--Transaction breakdown component-->
                    <div *ngIf="planBreakdown.body.length" class="d-flex flex-column mb-4">
                        <span class="tr-bd"> Transaction Breakdown </span>
                        <span class="tr-ds"
                            >(Hover on the amount of each plan to see the beneficiaries)</span
                        >
                        <app-table *ngIf="planBreakdown" [tableData]="planBreakdown"></app-table>
                    </div>

                    <img
                        class="dummy-image w-50"
                        src="/assets/images/pay_via.png"
                        alt="person"
                        *ngIf="!planBreakdown.body.length"
                    />
                </div>
            </div>
        </section>
    </div>
</div>

<ng-template #unauthorized_user>
    <unauthorized [errorMessage]="userHasAccessErrorMessage"> </unauthorized>
</ng-template>
