import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStore } from 'src/app/app-data';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    userInfo: any = {};
    token: string = '';

    constructor(
        appStore: AppStore,
        private http: HttpClient
    ) {
        appStore.getUserInfo().subscribe((userInfo) => (this.userInfo = userInfo));
        appStore.getBearerToken().subscribe((token) => (this.token = token));
    }

    getUserInfo() {
        return this.userInfo;
    }

    getUserFullName() {
        return `${this.userInfo.firstName} ${this.userInfo.lastName}`;
    }

    getUserId() {
        return this.userInfo.email;
    }

    getBearerToken() {
        return this.token || localStorage.getItem('APP_TOKEN');
    }

    getOrganizationId() {
        // return 'TEST_ORG';
        return this.userInfo.companyID;
    }

    generateLMSToken(userId: string, organization: string) {
        // console.log(this.getLmsToken())
        // if (this.getLmsToken()) {
        //     return;
        // }
        return this.http
            .post(`${environment.lmsApiEndpoint}/user/adminToken`, {
                userId,
                organization,
            })
            .toPromise()
            .then((response: any) => {
                if (response.success) {
                    const token = response.data;
                    localStorage.setItem('LMS_TOKEN', token);
                }
            });
    }

    getLmsToken() {
        return localStorage.getItem('LMS_TOKEN') || '';
    }
}
