import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SuccessComponent } from '../containers/success/success.component';
import { environment } from 'src/environments/environment';
import { ViewAuditComponent } from '../containers/view-audit/view-audit.component';
import { enums } from '../payroll.enums';
import { SwalComponent } from '../containers/swal/swal.component';
import { ViewPayrollAuditComponent } from '../containers/view-payroll-audit/view-payroll-audit.component';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import endpoints from 'src/app/utils/endpoints';
import { FormGroup } from '@angular/forms';
import { Payroll, PayrollForm } from '../payroll.model';
import { QueryGenerator } from '../../recruitment/shared/helper';
import { GetPayrollDetailsParams } from '../../tracker/types/tracker.types';
@Injectable({
    providedIn: 'root',
})
export class PayrollService {
    defaultModalOptions: NgbModalOptions = {
        size: 'lg',
        centered: true,
        windowClass: 'modal',
        backdrop: 'static',
    };
    MONTHS = enums.MONTHS;
    baseUrl = endpoints.MX_BE.V1.payroll;
    workflowChanges = new Subject();
    payrollForm: Payroll = <Payroll>{
        year: new Date().getFullYear(),
        month: this.MONTHS[new Date().getMonth()],
        schemeType: 'gross',
        type: 'payroll',
        country: {
            name: 'Nigeria',
            currency: 'NGN',
            currencyDisplay: 'symbol-narrow',
            schemeTypes: enums.schemeTypes,
        },
    };
    payrollFormData: FormGroup = new FormGroup({});
    currentPayslips: any[] = [];
    countries!: any;
    constructor(
        private http: HttpClient,
        private modalService: NgbModal
    ) {}

    createSalaryScheme(data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/create`, data)
            .toPromise();
    }

    createEmployeeSalaryScheme(data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/create/employee`, data)
            .toPromise();
    }
    createEmployeeSalarySchemeBulk(data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/create/employee/bulk`, data)
            .toPromise();
    }

    getSalarySchemes() {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/get`)
            .toPromise();
    }

    getSalaryScheme(id: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/get/${id}`)
            .toPromise();
    }

    updateSalaryScheme(id: any, data: any) {
        return this.http
            .put<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/update/${id}`, data)
            .toPromise();
    }
    setupSchemeType() {
        return this.http
            .patch<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/setup-scheme-type`, {})
            .toPromise();
    }

    addPayItem(payload: any) {
        return this.http
            .patch<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/add-item`, { ...payload })
            .toPromise();
    }
    getPayItems(payload: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/list/pay-item`, {
                params: payload,
            })
            .toPromise();
    }
    deletePayItem(id: string) {
        return this.http
            .delete<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/delete-item/${id}`)
            .toPromise();
    }

    initiateOtherAllowance() {
        return this.http.post<any>(
            `${environment.myXalaryEndpoint}/v1/other-allowances/initialize`,
            ''
        );
    }

    deleteSalaryScheme(id: any) {
        return this.http
            .delete<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/delete/${id}`)
            .toPromise();
    }

    createPayroll(data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/payroll/create`, data)
            .toPromise();
    }

    approveTerminationPayroll(data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/payroll/approveTerminationPayroll`, data)
            .toPromise();
    }

    getPayrolls(params?: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/get`, { params })
            .toPromise();
    }

    getPayroll(id: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/get/${id}`)
            .toPromise();
    }

    reviewPayroll(id: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/review/${id}`)
            .toPromise();
    }

    approvePayroll(id: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/approve/${id}`)
            .toPromise();
    }

    updatePayroll(id: any, data: any) {
        return this.http
            .put<any>(`${environment.myXalaryEndpoint}/v1/payroll/update/${id}`, data)
            .toPromise();
    }

    rejectPayroll(id: any, data: any) {
        return this.http
            .put<any>(`${environment.myXalaryEndpoint}/v1/payroll/reject/${id}`, data)
            .toPromise();
    }

    deletePayroll(id: any) {
        return this.http
            .delete<any>(`${environment.myXalaryEndpoint}/v1/payroll/delete/${id}`)
            .toPromise();
    }

    clonePayroll(id: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/clone/${id}`)
            .toPromise();
    }

    getPayrollAudits() {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/payroll_audit`)
            .toPromise();
    }

    getPayrollAuditsById(id: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/payroll_audit/${id}`)
            .toPromise();
    }

    getPayrollBudget() {
        return this.http.get<any>(`${environment.myXalaryEndpoint}/v1/payroll-budgets`).toPromise();
    }

    createPayrollBudget(data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/payroll-budgets/create`, data)
            .toPromise();
    }

    updatePayrollBudget(id: any, data: any) {
        return this.http
            .patch<any>(`${environment.myXalaryEndpoint}/v1/payroll-budgets/update/${id}`, data)
            .toPromise();
    }

    basicPayrollVariance(
        month1: any,
        year1: any,
        month2: any,
        year2: any,
        currentMonth: any,
        currentYear: any,
        ids?: any
    ) {
        month2 = month2 ? month2 : '';
        year2 = year2 ? year2 : '';
        const SecondParam = month2 && year2 ? `&second=${month2},${year2}` : '';
        const idParam = ids ? `&id=${ids}` : '';
        return this.http.get<any>(
            `${environment.myXalaryEndpoint}/v1/payroll/basicPayrollVariance?first=${month1},${year1}${SecondParam}&month=${currentMonth}&schemeType=gross&year=${currentYear}${idParam}`
        );
    }

    basicPayrollVarianceById(payrollID: any, month1: any, year1: any, month2: any, year2: any) {
        month2 = month2 ? month2 : '';
        year2 = year2 ? year2 : '';
        const SecondParam = month2 && year2 ? `&second=${month2},${year2}` : '';
        return this.http.get<any>(
            `${environment.myXalaryEndpoint}/v1/payroll/basicPayrollVariance?payrollID=${payrollID}&first=${month1},${year1}${SecondParam}`
        );
    }

    advancedPayrollVariance(
        month1: any,
        year1: any,
        month2: any,
        year2: any,
        currentMonth: any,
        currentYear: any,
        ids?: any
    ) {
        const url = `${environment.myXalaryEndpoint}/v1/payroll/advancePayrollVariance?dateFrom=${month1},${year1}&dateTo=${month2},${year2}&month=${currentMonth}&schemeType=gross&year=${currentYear}&id=${ids}`;
        this.downloadFile(url, 'file');
    }

    advancedPayrollVarianceById(month1: any, year1: any, month2: any, year2: any, payrollID: any) {
        const url = `${environment.myXalaryEndpoint}/v1/payroll/advancePayrollVariance?payrollID=${payrollID}&dateFrom=${month1},${year1}&dateTo=${month2},${year2}`;
        this.downloadFile(url, 'file');
    }

    exportPayroll(id: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/export/${id}`, {
                observe: 'response',
                //@ts-ignore
                responseType: 'blob',
            })
            .toPromise();
    }
    fileConverterDownload(link: string, format: string) {
        const httpOptions = {
            responseType: 'blob' as 'json',
        };

        return this.http.get(link, httpOptions).subscribe(
            (res: any) => {
                const file = new Blob([res], { type: format });
                const downloadUrl = window.URL.createObjectURL(file);
                const linkElement = document.createElement('a');
                linkElement.href = downloadUrl;
                linkElement.download = `attachment.${format}`;
                linkElement.target = '_blank';
                linkElement.click();
                URL.revokeObjectURL(downloadUrl);
            },
            (error) => {
                console.error('Error downloading file:', error);
            }
        );
    }

    exportPayrollWithOptions(id: any, data: any) {
        const httpOptions = {
            responseType: 'blob' as 'json',
        };

        this.http
            .post<Blob>(
                `${environment.myXalaryEndpoint}/v1/payroll/export/s/${id}`,
                data,
                httpOptions
            )
            .subscribe(
                (response: Blob) => {
                    const file = new Blob([response], { type: 'application/octet-stream' });
                    const url = URL.createObjectURL(file);

                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = 'payroll.xlsx';
                    anchor.style.display = 'none';

                    document.body.appendChild(anchor);
                    anchor.click();

                    URL.revokeObjectURL(url);
                    document.body.removeChild(anchor);
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    downloadFile(
        url: string,
        filename: any = null,
        options: object = {},
        fileExt: string = '.xlsx'
    ) {
        return this.http
            .get(url, { ...options, responseType: 'blob' as 'json' })
            .subscribe((response: any) => {
                const dataType = response.type;
                const fileExt2 = null;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(
                    new Blob(binaryData, { type: dataType })
                );
                if (filename) {
                    downloadLink.setAttribute('download', filename + (fileExt || fileExt2));
                }
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
    }

    exportTaxPensionPayroll(id: any) {
        const options: any = { observe: 'response', responseType: 'json' };
        const url = `${environment.myXalaryEndpoint}/v1//taxandpension/export-tax-pension-payroll?payrollID=${id}`;
        this.downloadFile(url, 'file');
    }

    downloadPaymentsTemplate(query: any = {}) {
        return this.http
            .get(`${environment.myXalaryEndpoint}/v1/payroll/payments/download`, {
                params: {
                    ...query,
                },
                observe: 'response',
                responseType: 'blob',
            })
            .toPromise();
    }

    uploadPayments({ data, type, headers = {}, query = {} }: any) {
        return this.http
            .post<any>(
                `${environment.myXalaryEndpoint}/v1/payroll/payments/upload/${type}${QueryGenerator(query)}`,
                data,
                {
                    ...headers,
                }
            )
            .toPromise();
    }

    generateEmployeePayslips(props: any) {
        const { params, query, headers } = props;
        let url = `${environment.myXalaryEndpoint}/v1/payroll/payslips/generate/${params.month}/${params.year}/${params.schemeType}`;
        // if (startDate && endDate) url = url.concat(`?startDate=${startDate}&endDate=${endDate}`);
        return this.http.get<any>(url, { headers, params: query }).toPromise();
    }

    generateEmployeePayslipsV2(props: any) {
        const { params, query, headers } = props;
        let url = `${environment.myXalaryEndpoint}/v1/payroll/payslips/generateV2/${params.month}/${params.year}/${params.paymentItem}`;
        return this.http.get<any>(url, { headers, params: query }).toPromise();
    }

    getPayrollSetup() {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/setup/get`)
            .toPromise();
    }

    updatePayrollSetup(data: any) {
        return this.http
            .put<any>(`${environment.myXalaryEndpoint}/v1/payroll/setup/update`, data)
            .toPromise();
    }

    updatePayrollSetupTax(data: any) {
        return this.http
            .put<any>(`${environment.myXalaryEndpoint}/v1/payroll/setup/tax-types`, data)
            .toPromise();
    }
    getPayrollWorkflows() {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/workflow/get`)
            .toPromise();
    }

    updatePayrollWorkflow(stage: any, data: any) {
        return this.http
            .put<any>(`${environment.myXalaryEndpoint}/v1/payroll/workflow/update/${stage}`, data)
            .toPromise();
    }

    editPayrollWorkflowName(id: any, data: any) {
        return this.http
            .patch<any>(`${environment.myXalaryEndpoint}/v1/payroll/workflow/edit/${id}`, data)
            .toPromise();
    }

    processPayment(id: any, billingId: any, reference?: any, amount?: any, body?: any) {
        let url = `${environment.myXalaryEndpoint}/v1/payroll/payment/process/${id}/${billingId}?`;
        if (reference) url = url.concat(`reference=${reference}&`);
        if (amount) url = url.concat(`amount=${amount}&`);
        return this.http.put<any>(url, body).toPromise();
    }

    // Generating OTP for payroll processing

    managePayrollProcessingOTP() {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/company/generate/otp`, '')
            .toPromise();
    }

    getPaymentLog() {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/billing/payment/log/get?limit=1000000000`)
            .toPromise();
    }

    getTransactions(recipientCode?: string) {
        let url = `${environment.myXalaryEndpoint}/v1/billing/wallet/get`;
        if (recipientCode) url = url.concat(`?recipientCode=${recipientCode}`);
        return this.http.get<any>(url).toPromise();
    }

    initiateBilling(type: any, amount?: any) {
        let url = `${environment.myXalaryEndpoint}/v1/billing/initiate?type=${type}`;
        if (amount) url = url.concat(`&amount=${amount}`);
        return this.http.get<any>(url).toPromise();
    }

    getBillings() {
        return this.http.get<any>(`${environment.myXalaryEndpoint}/v1/billing/get`).toPromise();
    }

    retryPayment(channel: any, data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/billing/payment/retry/${channel}`, data)
            .toPromise();
    }

    refundPayment(channel: any, data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/billing/payment/refund/${channel}`, data)
            .toPromise();
    }

    getCompanyBalance() {
        this.getTransactions();
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/billing/wallet/balance/get`)
            .toPromise();
    }

    getTransaction(type: any, reference: any) {
        return this.http
            .get<any>(
                `${environment.myXalaryEndpoint}/v1/billing/korapay/transaction/${type}/${reference}`
            )
            .toPromise();
    }

    reportTransaction(data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/billing/report/transaction`, data)
            .toPromise();
    }

    walletTransfer(data: any) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/billing/korapay/transfer`, data)
            .toPromise();
    }

    openAudit() {
        const auditModalOptions: NgbModalOptions = {
            size: 'lg',
            keyboard: true,
            windowClass: 'audit-modal',
        };
        this.modalService.hasOpenModals();
        this.modalService.dismissAll();
        return this.modalService.open(ViewAuditComponent, {
            ...auditModalOptions,
        });
    }

    openPayrollAudit(id: any) {
        const auditModalOptions: NgbModalOptions = {
            size: 'lg',
            keyboard: true,
            windowClass: 'audit-modal',
        };
        this.modalService.hasOpenModals();
        this.modalService.dismissAll();
        let instance = this.modalService.open(ViewPayrollAuditComponent, {
            ...auditModalOptions,
        }).componentInstance;
        instance.id = id;
    }

    removeRef(data: any) {
        return !data ? data : JSON.parse(JSON.stringify(data));
    }

    openSuccess() {
        return this.preModal(SuccessComponent);
    }

    preModal(Component: any) {
        this.modalService.hasOpenModals() && this.modalService.dismissAll();
        return this.modalService.open(Component, {
            ...this.defaultModalOptions,
        });
    }

    getSalarySchemeItems = (type: any) => {
        return enums.SALARY_SCHEME.ITEMS.filter((item) => item.type.indexOf(type) > -1);
    };

    getSalarySchemeItemsV2 = async (id: any, country: any) => {
        return await this.http
            .get(endpoints.MX_BE.V1.salaryScheme.employeePayItems + id, { params: { country } })
            .toPromise();
    };

    sumArrayObjectsByKey = (array: any, key: any) =>
        array.reduce((a: any, b: any) => a + Number(isNaN(b[key]) ? 0 : b[key]), 0);

    chargeAmount = (amount: any) => {
        if (!amount) return amount;
        if (amount.constructor === 'String'.constructor) amount = Number(amount);
        let fee = 0;
        if (amount > 126666) fee = 2000;
        else if (amount < 2500) fee = 0.0156 * amount;
        else fee = 0.01523 * amount + 102;

        return Math.ceil(fee > 2000 ? 2000 : fee);
    };

    approximateAmount = (amount: any) => Number(Number(amount).toFixed(2));

    dateArrayToString = (date: any) => `${date['year']}-${date['month']}-${date['day']}`;

    cloneArray = (array: any) => JSON.parse(JSON.stringify(array));

    titleCase = (str: string) =>
        (str || '')
            .toString()
            .replace(
                /\b\w+/g,
                (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()
            );

    swal(options: {
        title: string;
        text: string;
        icon: string;
        showConfirmButton?: boolean;
        showCancelButton?: boolean;
        html?: string;
        callback?: () => void;
    }) {
        const {
            title,
            text,
            icon,
            showCancelButton = false,
            showConfirmButton = true,
            html,
            callback = () => {},
        } = options;
        let config: any = { title, text, svgIcon: icon, btns: [], html };
        if (showCancelButton)
            config.btns.push({
                title: 'Cancel',
                routerLink: 'string',
                btnStyle: 'btn-secondary',
            });
        if (showConfirmButton)
            config.btns.push({
                title: 'Proceed',
                routerLink: 'string',
                btnStyle: 'btn-primary',
            });
        const swalModalOptions: NgbModalOptions = {
            size: 'lg',
            keyboard: true,
            windowClass: 'swal-modal',
        };
        this.modalService.hasOpenModals();
        this.modalService.dismissAll();
        const instance = this.modalService.open(SwalComponent, {
            ...swalModalOptions,
        });
        instance.componentInstance.config = config;
        instance.componentInstance.action.subscribe((btn: any) => {
            instance.dismiss();
            if (btn === 'Cancel') return;
            if (btn === 'Proceed') return callback();
        });
        instance.dismissed.subscribe(() => {
            if (!showConfirmButton && !showCancelButton) return callback();
        });
    }

    convertProseMirrorToHtml(content: any[]): string {
        let html = '';

        content.forEach((item) => {
            switch (item.type) {
                case 'heading':
                    const headingLevel = item.attrs.level;
                    const align = item.attrs.align || 'left';
                    html += `<h${headingLevel} style="text-align: ${align};">${this.convertProseMirrorToHtml(item.content)}</h${headingLevel}>`;
                    break;
                case 'paragraph':
                    const paragraphAlign = item.attrs.align || 'justify';
                    html += `<p style="text-align: ${paragraphAlign};">${this.convertProseMirrorToHtml(item.content)}</p>`;
                    break;
                case 'text':
                    let text = item.text;
                    item.marks?.forEach((mark: any) => {
                        switch (mark.type) {
                            case 'strong':
                                text = `<strong>${text}</strong>`;
                                break;
                            case 'text_color':
                                const textColor = mark.attrs.color;
                                text = `<span style="color: ${textColor};">${text}</span>`;
                                break;
                            case 'text_background_color':
                                const textBackgroundColor = mark.attrs.backgroundColor;
                                text = `<span style="background-color: ${textBackgroundColor};">${text}</span>`;
                                break;
                            default:
                                break;
                        }
                    });
                    html += text;
                    break;
                default:
                    break;
            }
        });

        return html;
    }

    createPensionType(body: { employee: number; employer: number; title: string }) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/pension-type/create`, body)
            .toPromise();
    }
    getPensionType() {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/pension-type/get`)
            .toPromise();
    }
    deletePensionType(id: string) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/salaryscheme/pension-type/delete/${id}`)
            .toPromise();
    }
    getESBCustomData(id: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/customESB`, { params: id })
            .toPromise();
    }
    getPayrollGLAccounts() {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/customESB/gl-accounts`)
            .toPromise();
    }
    updateGLAccounts(data: any) {
        return this.http
            .put<any>(`${environment.myXalaryEndpoint}/v1/payroll/customESB/update-accoount`, data)
            .toPromise();
    }

    getOrgCredAps() {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/company/getOrgCred`)
            .toPromise();
    }

    createOrgCredAPs(body: any) {
        return this.http.post(`${environment.myXalaryEndpoint}/v1/company/createCred`, body);
    }

    editOrgCredAPs(body: any, id: string) {
        return this.http.put(`${environment.myXalaryEndpoint}/v1/company/editCred/${id}`, body);
    }

    prepareNavisionData(body: any) {
        return this.http.post(
            `${environment.myXalaryEndpoint}/v1/payroll/prepareNavisionData`,
            body
        );
    }
    checkUserGlSubscribe(): Observable<boolean> {
        const url = `${environment.myXalaryEndpoint}/v1/payroll/customESB/findUsers`;
        return this.http.get<boolean>(url);
    }

    getNavisionToken() {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/users/navisionToken`)
            .toPromise();
    }

    manageNavisionToken(body: { token: string }) {
        return this.http
            .post<any>(`${environment.myXalaryEndpoint}/v1/users/manageNavisionToken`, body)
            .toPromise();
    }

    async getCompliance(storeAction: any) {
        const user = await storeAction.pipe(take(1)).toPromise();
        return user.companyData?.isCompliant;
    }

    editWorkflow(key: string, payload: any) {
        return this.http.put(this.baseUrl.updateWorkflow + key, { ...payload });
    }

    createWorkflow(payload: any) {
        return this.http.post(this.baseUrl.createWorkflow, { ...payload });
    }

    getFilteredPayroll({ month, year }: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/filtered-payroll`, {
                params: { month, year },
            })
            .toPromise();
    }

    getPayrollDashboard(params: any) {
        return this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/payroll/payroll-metrics`, {
                params,
            })
            .toPromise();
    }

    getItems({ defaultItems = [], type, data }: any) {
        let defItems = defaultItems.filter((v: any) => v.type.includes(type));
        let items = [...defItems];
        if (data) {
            items = [];
            const eItems = data.items || [];
            defItems.forEach((item: any) => {
                let selectedItem: any = item;
                const selectedIndex = eItems.findIndex((v: any) => v.name == item.name);
                if (selectedIndex > -1) {
                    const {
                        selected = item.selected,
                        isDefault = item.isDefault,
                        ...rest
                    } = eItems[selectedIndex];
                    selectedItem = {
                        ...rest,
                        selected,
                        isDefault,
                        meta: item.meta,
                        type: item.type,
                    };
                    eItems.splice(selectedIndex, 1);
                }
                items.push(<any>selectedItem);
            });
            eItems.forEach((item: any) => {
                const extra =
                    item.schedule == 'monthly'
                        ? enums.SALARY_SCHEME.DEFAULT_MONTHLY
                        : enums.SALARY_SCHEME.DEFAULT_NON_MONTHLY;

                items.push(<any>{
                    ...item,
                    selected: item.selected || false,
                    isDefault: false,
                    meta: extra.meta,
                    type: extra.type,
                });
            });
        }
        return items;
    }

    async getDefaults(country?: string) {
        let params = {};
        if (country) {
            params = { country };
        }
        return await this.http
            .get(endpoints.MX_BE.V1.salaryScheme.defaults, { params })
            .toPromise();
    }
}
