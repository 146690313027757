import { Component, OnInit } from '@angular/core';
import { PayrollService } from '../../services/payrolls.service';

@Component({
    selector: 'app-view-audit',
    templateUrl: './view-audit.component.html',
    styleUrls: ['./view-audit.component.scss'],
})
export class ViewAuditComponent implements OnInit {
    auditList: any = [];

    constructor(private payrollService: PayrollService) {}

    ngOnInit(): void {
        this.getPayrollAudits();
    }

    async getPayrollAudits() {
        this.payrollService.getPayrollAudits().then((res) => {
            this.auditList = res.response.reverse();
        });
    }

    //remove underscore from stage name
    removeUnderscores(id: string) {
        return id ? id.split('_').join(' ') : '';
    }
}
