<modal-cover [titleTemplate]="title">
    <div class="row w-100">
        <section class="h-100 col-md-6 col-sm-12 w-100">
            <form
                (ngSubmit)="openPreviewPage()"
                [formGroup]="formData"
                class="p-3 d-flex gap-10 h-100 flex-column"
            >
                <div class="form-group form-style w-100">
                    <label class="font-weight-bold" for="title"
                        >Amount<span class="text-danger">*</span></label
                    >
                    <p-inputNumber
                        mode="currency"
                        currency="NGN"
                        [max]="999000000000000"
                        formControlName="amount"
                        [min]="100"
                        class="w-100"
                        inputStyleClass="form-control "
                        inputId="integeronly"
                    >
                    </p-inputNumber>
                </div>
                <div class="d-flex btn-grp justify-content-between">
                    <button [disabled]="formData.invalid" type="submit" class="btn btn-primary">
                        top-up
                    </button>
                </div>
            </form>
        </section>
        <div class="col-md-6 col-sm-12">
            <div class="top-image"></div>
        </div>
    </div>
</modal-cover>
<ng-template #title>
    <div class="title-house">
        <span class="title-leader">Topup Balance</span>
        <span class="title-assist">Minimum topup amount is NGN100</span>
    </div>
</ng-template>

<ng-template let-data="data" #paymentModal>
    <modal-cover title="Make Payments">
        <div class="d-flex w-100 justify-content-center align-items-center">
            <iframe style="width: 550px; height: 600px; border: none" id="test"></iframe>
        </div>
    </modal-cover>
</ng-template>

<ng-template let-data="data" #optionsModal>
    <modal-cover title="Choose Payment Type">
        <div class="d-flex flex-column justify-content-center align-items-center gap-10 p-3">
            <span class="payment-text"> For bank Transfer, kindly use </span>
            <div *ngFor="let accounts of virtualAccounts" class="d-flex flex-column mb-2">
                <span class="payment-banks"
                    >Account Number: <b>{{ accounts.account }}</b></span
                >
                <span class="payment-banks"
                    >Bank: <b>{{ accounts.bank }}</b></span
                >
                <span class="payment-banks"
                    >Channel: <b>{{ accounts.channel }}</b></span
                >
            </div>
            <div class="d-flex flex-column gap-20">
                <button (click)="closePaymentOption()" class="btn btn-default-outline">
                    Bank Transfer
                </button>
                <button (click)="initiateTransaction()" class="btn btn-primary-outline">
                    View Other Payment Options
                </button>
            </div>
        </div>
    </modal-cover>
</ng-template>
