import { iTableData } from './../../models/interfaces';
import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { of } from 'rxjs';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
    @Input() tableData!: iTableData;
    @Output() selectedRowsChange = new EventEmitter<any[]>(); // Send selected rows to parent
    colspan: number = 0;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        const {
            tableData: {
                currentValue: { body },
            },
        } = changes;
        if (this.tableData?.config?.total?.display && body.length) {
            const { column } = this.tableData?.config.total;
            const total = this.tableData.body.reduce((prev, current) => prev + current[column], 0);
            this.tableData.config.total.amount = total;
        }
    }

    ngOnInit(): void {
        if (this.tableData?.config?.total?.display) {
            this.colspan = Object.keys(this.tableData.header).length - 1;
        }
    }

    // individual row selection
    onRowSelectionChange(): void {
        const selectedRows = this.getSelectedRows();
        this.selectedRowsChange.emit(selectedRows);
    }

    // Select all rows
    toggleSelectAll(event: Event): void {
        const isChecked = (event.target as HTMLInputElement).checked;
        this.tableData.body.forEach((row) => {
            row.selected = isChecked;
        });
        this.onRowSelectionChange();
    }

    // Are all rows are selected ?
    areAllRowsSelected(): boolean {
        return this.tableData.body.every((row) => row.selected);
    }

    // Track selected rows
    getSelectedRows(): any[] {
        return this.tableData.body.filter((row) => row.selected);
    }
}
