import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import {
    DataTableColumn,
    DataTableConfig,
    PAGINATOR,
    filterObjValue,
    filterValue,
} from '../../../interfaces/general';
import { QueryGenerator } from '../../../modules/recruitment/shared/helper';
import { GeneralService } from '../../services/general.service';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    options!: DataTableConfig;
    tableOptions: DataTableConfig = {
        searchOptions: [],
        columns: [],
        url: '',
        pageKey: 'page',
        limitKey: 'limit',
        limitDefault: 10,
    };
    @Input()
    data: any[] = [];

    @Input()
    pkg: 'mat-table' | 'p-table' = 'mat-table';

    @Input()
    getData!: (data: any) => any[];

    @Input()
    getPagination!: (data: any) => PAGINATOR;

    displayColumns: string[] = [];
    paginatorSetup: PAGINATOR = {
        currentPage: 1,
        totalCount: 0,
        totalPages: 1,
    };
    pageKey = 'page';
    limitKey = 'limit';
    limitDefault = 10;
    @Input()
    filterType: 'json' | 'query' = 'json';

    @Input()
    onChanges!: any;
    filter: any = {};
    @Input()
    customFilter!: any;
    loading: boolean = true;
    loaderOptions: AnimationOptions = {
        path: './assets/motions/loader.json',
    };
    dataSource: any[] = [];
    @Output()
    dataSourceChanged = new EventEmitter<{ data: any[], paginatorSetup: PAGINATOR }>();
    onChangesSub!: Subscription;
    sortCol = '';
    selection!: SelectionModel<any>;
    currentUrl!: string;
    localQuery: any = {};
    @Input()
    externalSelection!: any[];
    childLength: any[] = [];
    startIndex = 0;

    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    expandedElement!: any;
    currentParams!: any;
    expandSelection = new SelectionModel<any>(true, []);
    displaySubColumns: string[] = [];
    exportData: any[] = [];
    constructor(
        private http: HttpClient,
        private genServ: GeneralService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const customFilter = changes.customFilter;
        if (customFilter && customFilter.firstChange !== true) {
            delete this.filter[this.pageKey];
            this.onFilterChange({ [this.pageKey]: 1, ...this.filter });
        }
        if (changes.data) {
            this.setLocalData({
                query: this.filter,
            });
        }
        if (changes.options) {
            this.options = { ...this.options };
        }

        if (this.options.useCheckBox && !this.selection) {
            this.selection = new SelectionModel<any>(true, [], true, this.options.selectionCompare);
        }
        if (changes.externalSelection && this.selection && this.options.useCheckBox) {
            this.selection?.clear();
            this.externalSelection?.map((row: any) => this.selection.select(row));
        }

        if (this.options.childCount) {
            this.childLength = new Array(this.options.childCount).fill(0);
        }
    }
    log(k: any) {
        console.log('hello', {}, k);
    }

    async fetchData(query: any) {
        try {
            if (this.tableOptions.useLocalData) {
                this.setLocalData({ query });
            } else {
                const { filter, useLoader = true, ...rest } = query;
                const params = filter
                    ? `?filter=${encodeURIComponent(filter)}&${QueryGenerator(
                          { ...(this.options.fixedQuery || {}), ...rest },
                          'post'
                      )}`
                    : QueryGenerator({ ...(this.options.fixedQuery || {}), ...query });
                this.loading = useLoader;
                const response: any = await this.http
                    .get(`${this.options.url}${params}`, {
                        headers: {
                            nospinner: 'true',
                        },
                    })
                    .toPromise();
                const { data, payload, paging } = response || {};

                this.currentUrl = `${this.options.url}`;
                this.currentParams = params;
                this.loading = false;
                this.dataSource = this.getData
                    ? this.getData(response)
                    : data?.data || payload?.data;
                this.sortCol = '';
                if (paging || data?.paging || payload?.paging) {
                    this.paginatorSetup = paging || data?.paging || payload?.paging;
                } else if (this.getPagination) {
                    this.paginatorSetup = this.getPagination(response);
                }
                this.dataSourceChanged.emit({data:this.dataSource, paginatorSetup:this.paginatorSetup});
            }
        } catch (e: any) {
            this.loading = false;
            e?.details?.message
                ? this.genServ.alertInfo.next({
                      text: e?.details?.message || 'Error Occurred',
                      btnClass: 'alert-danger',
                      btnIcon: 'error',
                      timer: 3000,
                  })
                : null;
        }
    }

    ngOnInit(): void {
        this.tableOptions = {
            ...this.tableOptions,
            ...(this.options || {}),
        };
        if (this.tableOptions.showNumbers !== false) {
            this.displayColumns.push('#');
        }
        if (this.tableOptions.expand === true) {
            this.displayColumns.push('expand');
        }
        if (this.tableOptions.useCheckBox === true) {
            this.displayColumns.push('checkbox');
        }

        this.displayColumns.push(
            ...this.tableOptions.columns
                .filter((value) => value.show !== false)
                .map((value) => value.label)
        );
        if (this.options.childProps?.columns?.length) {
            this.displaySubColumns.push(
                ...this.options.childProps?.columns
                    .filter((value: any) => value.show !== false)
                    .map((value: any) => value.label)
            );
        }

        this.pageKey = this.options.pageKey ?? this.pageKey;
        this.limitKey = this.options.limitKey ?? this.limitKey;
        this.limitDefault = this.options.limitDefault ?? this.limitDefault;
        this.onFilterChange({
            [this.pageKey]: 1,
            [this.limitKey]: this.limitDefault,
            ...this.filter,
        });
        if (this.onChanges) {
            this.onChangesSub = this.onChanges.subscribe((value: any) => {
                this.onFilterChange({
                    [this.pageKey]: 1,
                    [this.limitKey]: this.limitDefault,
                    ...this.filter,
                });
            });
        }
        this.options.checkListener?.subscribe((message) => {
            if (typeof message === 'string' && message == 'clear') {
                this.selection.clear();
            }
        });
    }

    sortData(sort: { config: DataTableColumn; dir: number }) {
        const { key, type = 'string', getValue } = sort.config;
        return (a: any, b: any) => {
            let firstValue = typeof getValue === 'function' ? getValue(a, true) : a[key],
                secondValue = typeof getValue === 'function' ? getValue(b, true) : b[key];
            if (type === 'date') {
                firstValue = new Date(firstValue);
                secondValue = new Date(secondValue);
            } else if (type === 'number') {
                firstValue = Number(firstValue);
                secondValue = Number(secondValue);
            } else {
                firstValue = firstValue || '';
                secondValue = secondValue || '';
            }

            if (type === 'string') {
                return firstValue.localeCompare(secondValue);
            }
            return (firstValue > secondValue ? 1 : -1) * sort.dir;
        };
    }

    localFilter({ query }: any) {
        return this.genServ.localFilter({ query });
    }

    setLocalData({ page = 1, sort, query = {} }: any) {
        this.loading = true;
        const limit = query.limit || this.limitDefault;
        let data = [...this.data];
        if (sort?.dir) {
            data = data.sort(this.sortData(sort));
        }
        if (Object.keys(query).length) {
            data = data.filter(this.localFilter({ query }));
        }
        this.localQuery = { query };

        const length = data.length;
        const totalPages = Math.ceil(length / limit);
        this.exportData = [...data];
        data = data.splice((page - 1) * limit, limit);
        this.sortCol = '';
        this.paginatorSetup = {
            currentPage: page,
            totalCount: length,
            totalPages,
        };
        this.dataSource = data;
        this.loading = false;
    }

    onFilterChange($filter: filterValue, otherQuery = {}) {
        // if (this.tableOptions.useLocalData) return;
        let query: any = {};
        this.filter = { ...$filter };
        $filter = { ...$filter, ...this.customFilter };
        if (this.filterType === 'json') {
            let q: any = [];
            for (let key in $filter) {
                if (typeof $filter[key] !== 'object') {
                    q.push(`${key}=${$filter[key]}`);
                    continue;
                }
                const { value, operator } = <filterObjValue>$filter[key];
                q.push(`${key}${operator}${value}`);
            }
            query['filter'] = JSON.stringify({
                q: q.join('&'),
            });
        } else {
            query = $filter;
        }
        this.fetchData({ ...query, ...otherQuery });
    }

    handlePaginateEvent(event: any) {
        const { count } = event;
        delete this.filter[this.pageKey];
        if (this.data.length) {
            return this.setLocalData({ page: count, query: this.filter });
        }
        this.onFilterChange({ [this.pageKey]: count, ...this.filter });
    }

    onSort(sort: Sort) {
        const config = this.tableOptions.columns.find((value: DataTableColumn) => {
            return value.label === sort.active;
        });
        if (!config) return;
        const dirEnum: any = {
            asc: 1,
            desc: -1,
        };
        let dir = dirEnum[sort.direction.toLowerCase()];
        let query = {};
        if (dir) {
            query = {
                sort: `${config.sortKey || config.key}:${dir}`,
            };
        }
        this.sortCol = sort.active;
        if (this.tableOptions.useLocalData) {
            return this.setLocalData({ sort: { config, dir }, query: this.filter });
        }
        this.onFilterChange(this.filter, { ...query, useLoader: false });
    }

    get isAllChecked() {
        if (this.options.useLocalData) {
            return this.selection.selected.length === this.data.length;
        }
        return this.selection.selected.length === this.dataSource.length;
    }

    handleToggle() {
        const dt = this.options.useLocalData ? this.data : this.dataSource;
        this.isAllChecked
            ? this.selection.clear()
            : dt.forEach((row: any) => {
                  this.selection.select(row);
              });
        this.handleCheckListener();
    }

    handleCheck(row: any) {
        this.selection.toggle(row);
        this.handleCheckListener();
    }

    handleCheckListener() {
        if (this.options.checkListener) {
            this.options.checkListener.next(this.selection.selected);
        }
    }

    toggleRow(element: any) {
        this.expandSelection.toggle(element);
    }

    getExpandOptions(element: any) {
        let data = this.options.nestedKey ? element[this.options.nestedKey] : element;
        if (typeof this.options.childProps?.getData === 'function') {
            data = this.options.childProps?.getData(element);
        }
        return { data, ...(this.options.childProps || {}) };
    }

    ngOnDestroy(): void {
        this.onChangesSub?.unsubscribe();
    }

    get getIndex() {
        return ++this.startIndex;
    }
}
