import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { GeneralService } from 'src/app/shared/services/general.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-report-transactions',
    templateUrl: './report-transactions.component.html',
    styleUrls: ['./report-transactions.component.scss', '../../../../../formStyle.scss'],
})
export class ReportTransactionsComponent implements OnInit {
    @Input()
    data!: any;
    reportControl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
    constructor(
        private api: ApiService,
        private gs: GeneralService,
        private modal: NgbModal
    ) {}

    ngOnInit(): void {}

    async sendReport() {
        await this.api.reportTransaction({
            ...this.data,
            report: this.reportControl.value,
        });
        this.gs.alertInfo.next({
            text: 'Report Sent',
            btnClass: 'alert-success',
            btnIcon: 'checklist',
            timer: 3000,
        });
        this.modal.dismissAll();
    }
}
