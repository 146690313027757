import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { AppStore } from 'src/app/app-data';
import { Combos } from 'src/app/interfaces/general';
import { PermissionService } from 'src/app/services/permission.service';
import { buttonPositionEnum } from 'src/app/shared/components/button-link/button-link.data';
import { iHmoProvider } from 'src/app/shared/models/hmoProvider';
import { GeneralService } from '../../../../shared/services/general.service';
import { iContentHeader } from './../../../../shared/models/contentheader';
import { iTableData } from './../../../../shared/models/interfaces';
import { CsvServicesService } from './../../../../shared/services/csv-services.service';
import { PaymentPackageForm } from './../../health.data';
import { HealthService } from './../../health.service';
import { EmployeeSearchComponent } from './../employee-search/employee-search.component';
import {
    IHealthPayment,
    INSURANCE_PERIOD,
    IplanBreakdownControl,
    dummyPlanBreakdown,
} from './pay-via-myxalary.data';
import dayjs from 'dayjs';

@Component({
    selector: 'app-buy-plan',
    templateUrl: './pay-via-myxalary.component.html',
    styleUrls: ['./pay-via-myxalary.component.scss', '../../../../../formStyle.scss'],
    providers: [CurrencyPipe],
})
export class PayViaMyxalaryComponent implements OnInit {
    userHasAccess: boolean = false;
    userHasAccessErrorMessage: string = '';

    contentHeader: iContentHeader | undefined;
    hmoProviders$: Observable<iHmoProvider[]> | undefined;
    hmoProviders: iHmoProvider[] | undefined;
    buttonPosition = buttonPositionEnum;
    planBreakdown: iTableData = dummyPlanBreakdown;
    paymentForm!: UntypedFormGroup;
    insurancePeriod: string[] = INSURANCE_PERIOD;

    planBreakdownData!: IplanBreakdownControl[];
    logoUrl: string | undefined;
    selectedHmoName: string = '';
    isRenew = false;
    check = false;
    purchaseData!: any;
    purchaseData$!: Observable<any>;
    isFormCreated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    searchProviders = new Subject<string>();
    providers = new BehaviorSubject<iHmoProvider[]>([]);
    loadingProviders: boolean = false;
    employeeData: any[] = [];

    @ViewChild('csvReader') csvReader!: ElementRef;
    @ViewChild('planAmount') planAmountInput: ElementRef | undefined;

    constructor(
        private route: ActivatedRoute,
        private _formBuilder: UntypedFormBuilder,
        private _healthService: HealthService,
        private _csvService: CsvServicesService,
        private _router: Router,
        private currencyPipe: CurrencyPipe,
        private permissionService: PermissionService,
        private genServ: GeneralService,
        private appStore: AppStore
    ) {}

    ngOnInit(): void {
        const { userHasAccess, userHasAccessErrorMessage } =
            this.permissionService.checkUserHasAccess({
                allowedPermissions: this.route.snapshot.data.canAccess,
            });
        this.userHasAccess = userHasAccess;
        this.userHasAccessErrorMessage = userHasAccessErrorMessage;

        this.setup();
    }

    setup() {
        if (this.userHasAccess) {
            //setup search for providers
            this.getProviders();
            this.searchProviders
                .pipe(distinctUntilChanged(), debounceTime(1200))
                .subscribe((item) => {
                    this.getProviders(item);
                });
            this.isRenew = this._router.url.includes('/renew');
            this.appStore
                .getCombos()
                .pipe(take(1))
                .subscribe((combo: Combos) => (this.employeeData = combo.basicEmployees));

            this.contentHeader = {
                headerTitle: 'Health',
                actionButton: true,
                breadcrumb: {
                    type: '',
                    links: [
                        {
                            name: 'Pay Via NotchHR',
                            isLink: true,
                            link: '/pay',
                        },
                    ],
                },
            };

            this.createForm();
        }
    }

    async getProviders(search?: any) {
        this.loadingProviders = true;
        let query: any = {
            page: 1,
            limit: 10,
        };
        if (search) {
            query['providerName'] = `~${search}`;
        }
        const result = await this._healthService.fetchProviders(query, false).toPromise();
        this.providers.next(result.data);
        this.loadingProviders = false;
    }
    createForm() {
        const provider = history?.state?.data || {
            plans: [],
        };
        this.paymentForm = this._formBuilder.group({
            providerId: [provider?.providerId, Validators.required],
            accountManager: this._formBuilder.group({
                name: [provider?.accountManager?.name, Validators.required],
                email: [
                    provider?.accountManager?.email,
                    Validators.compose([Validators.email, Validators.required]),
                ],
            }),
            plans: this._formBuilder.array(
                provider.plans?.length
                    ? provider.plans.map((plan: any) => {
                          plan.startDate = dayjs(plan.startDate).format('YYYY-MM');
                          return this.addNewCustomPlan(plan);
                      })
                    : [this.addNewCustomPlan({})],
                [Validators.minLength(1)]
            ),
            autoRenewal: [false],
        });

        this.plans.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe({
            next: (values: IplanBreakdownControl[]) => {
                this.calculatePlanBreakdown();
            },
            complete: () => {},
        });
        if (provider.plans.length) {
            this.calculatePlanBreakdown();
        }
    }

    private calculatePlanBreakdown() {
        const values: PaymentPackageForm[] = this.plans.value; // this.paymentPackageFormArray.value;
        this.planBreakdownData = values.map((data) => ({
            ...data,
            planAmount: Number(String(data.amount).replace(/[^0-9.-]+/g, '')),
            cost:
                Number(String(data.amount).replace(/[^0-9.-]+/g, '')) *
                data.employees.length *
                this._healthService.getMultiplier(data.period),
            quantity: data.employees.length,
            period:
                (data.period || 'N/A') +
                ' (' +
                this._healthService.getMultiplier(data.period) +
                ' months)',
        }));

        this.planBreakdown = {
            ...this.planBreakdown,
            body: this.planBreakdownData,
        };
    }

    transformAmount(formArrayIndex: number, event: any) {
        const value = this.plans.at(formArrayIndex).value;
        const amount = Number(String(value.amount || 0).replace(/[^0-9.-]+/g, ''));
        const amountText = this.currencyPipe.transform(
            Number(String(amount).replace(/[^0-9.-]+/g, '')),
            '₦'
        );
        event.target.value = amountText;

        //check if amount exists;
        this.plans.value.forEach((item: any, index: number) => {
            if (index === formArrayIndex) return;
            if (amount == item.amount) {
                this.plans.at(formArrayIndex).patchValue({
                    amount: null,
                });
                return this.genServ.alertInfo.next({
                    text: `${amount} has been entered previous at ${index}`,
                    btnClass: 'alert-danger',
                    btnIcon: 'error',
                });
            }
        });
    }

    get plans() {
        return this.paymentForm.get('plans') as UntypedFormArray;
    }

    addNewEmployees(item: any) {
        return this._formBuilder.group({
            name: this._formBuilder.control(item.name, [Validators.required]),
            email: this._formBuilder.control(item.email, [Validators.email]),
            profileImgUrl: this._formBuilder.control(item.profileImgUrl),
            id: this._formBuilder.control(item.id, [Validators.required]),
            phone: this._formBuilder.control(item.phone, [Validators.required]),
        });
    }
    addNewCustomPlan(data: any) {
        return this._formBuilder.group({
            name: this._formBuilder.control('Custom', [Validators.required]),
            period: this._formBuilder.control(data?.period, [Validators.required]),
            startDate: this._formBuilder.control(data?.startDate, [Validators.required]),
            amount: this._formBuilder.control(data?.amount, [
                Validators.required,
                Validators.min(1),
            ]),
            employees: this._formBuilder.array(
                data?.employees
                    ? data?.employees?.map((item: any) => this.addNewEmployees(item))
                    : [],
                [Validators.required]
            ),
        });
    }

    get hmoControl() {
        return this.paymentForm.get('providerId') as UntypedFormControl;
    }

    get recurrentControl() {
        return this.paymentForm.get('autoRenewal') as UntypedFormControl;
    }

    addNewLine() {
        this.plans.push(this.addNewCustomPlan({}));
    }

    openEmployeeSearchModal(index: number) {
        const modal = this.genServ.preModal(EmployeeSearchComponent, {
            windowClass: 'window-class',
        });
        const instance = modal.componentInstance;
        instance.planIndex = index;
        instance.plans = this.paymentForm.value.plans;
        modal.result.then((result) => {
            if (typeof result === 'string') return;
            const employees = result.employees.map((item: any) => this.addNewEmployees(item));
            this.plans.at(index).patchValue({ ...result });
            const emp = this.plans.at(index).get('employees') as UntypedFormArray;
            emp.clear();
            emp.reset();
            employees.forEach((item: any) => emp.push(item));
        });
    }

    submitText(): string {
        let text = 'Proceed';

        if (this.isRenew) text = 'Proceed';

        return text;
    }

    submit() {
        const formVal: IHealthPayment = { ...this.paymentForm.value };

        const payload: IHealthPayment = {
            ...formVal,
            providerId: formVal.providerId?._id,
            type: 'myxalary',
        };

        this._healthService.makePayment(payload).subscribe({
            next: (response) => {
                if (response.responseCode === 201) {
                    this.plans.clear();
                    this.plans.reset();
                    this.paymentForm.reset();
                    this._healthService.handleSuccessModal(response.response);
                }
            },
        });
    }

    downloadSchedule() {
        this._csvService.downloadFile(
            this.employeeData.map((v) => ({ id: v.id, name: v.name, amount: null })),
            'emp_hmo_schedule'
        );
    }

    uploadSchedule() {
        this.csvReader.nativeElement.click();
    }

    async uploadListener(event: any) {
        try {
            const data: any[] = (await this._csvService.uploadListener(event)) as Array<any>;
            this.csvReader.nativeElement.value = '';

            if (!data.length) return;

            for (let [index, employ] of data.entries()) {
                let dt = this.employeeData.find((item) => item.id == employ.id);
                if (!dt) {
                    return this.genServ.alertInfo.next({
                        text: `${employ.name} does not exist!`,
                        btnClass: 'alert-danger',
                        btnIcon: 'error',
                    });
                }
                data[index] = dt;
            }

            const schedule = data.reduce((prev, current) => {
                const amount = current.amount || 0;
                prev[amount] = prev[amount] || [];
                prev[amount].push(current);
                return prev;
            }, {});

            this.plans.clear();
            this.plans.reset();
            Object.keys(schedule).forEach((rowKey: any) => {
                const rowData = schedule[rowKey];
                const form = this.addNewCustomPlan({
                    name: 'Custom',
                    amount: Number(rowKey),
                    period: null,
                    startDate: null,
                    employees: rowData,
                });
                this.plans.push(form);
            });
        } catch (error) {
            console.log('errror', error);
        }
    }
}
