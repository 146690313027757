import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    TemplateRef,
} from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '../../models/interfaces';

@Component({
    selector: 'custom-modal',
    templateUrl: './custom-modal.component.html',
    styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModal implements OnInit {
    @Input() public modalConfig: ModalConfig = {
        modalTitle: '',
        options: {},
        hideCloseButton: () => true,
        hideDismissButton: () => true,
    };
    @ViewChild('modal') private modalContent!: TemplateRef<CustomModal>;
    private modalRef!: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {}

    open(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.modalRef = this.modalService.open(
                this.modalContent,
                <NgbModalOptions>this.modalConfig.options
            );
            this.modalRef.result.then(resolve, resolve);
        });
    }

    async close(): Promise<void> {
        if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
            const result =
                this.modalConfig.onClose === undefined || (await this.modalConfig.onClose());
            this.modalRef.close(result);
        }
    }

    async dismiss() {
        if (
            this.modalConfig.shouldDismiss === undefined ||
            (await this.modalConfig.shouldDismiss())
        ) {
            const result =
                this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss());
            this.modalRef.dismiss(result);
        }
    }
}
