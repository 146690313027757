import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const API_URL = environment.lmsApiEndpoint;

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private http: HttpClient) {}

    async getRequest<ReturnType = any>(url: string, queryParams?: any, pathParams?: any) {
        let apiUrl = API_URL + url;
        apiUrl = this.replaceParamsWithValues(apiUrl, pathParams);
        apiUrl = this.addQueryParamsToUrl(apiUrl, queryParams);
        try {
            return await new Promise<ReturnType>((resolve, reject) => {
                this.http
                    .get(apiUrl)
                    .toPromise()
                    .then((response: any) => {
                        if (response?.success) {
                            resolve(response.data);
                        } else {
                            reject();
                        }
                    })
                    .catch((error) => {
                        reject();
                        const message = error.response && error.response.message ? error.response.data.message : error.message;
                        console.log(message);
                    });
            });
        } catch (e) {
            console.log(e);
            return e;
        }
    }
    async postRequest<ReturnType = any>(url: string, data?: any, isMultipart = false) {
        const apiUrl = API_URL + url;
        let formData = data;
        if (isMultipart) {
            formData = this.convertToFormData(data);
        }
        try {
            return await new Promise<ReturnType>((resolve, reject) => {
                this.http
                    .post(apiUrl, formData)
                    .toPromise()
                    .then((response: any) => {
                        if (response?.success) {
                            resolve(response.data);
                        } else {
                            reject();
                        }
                    })
                    .catch((error) => {
                        reject();
                        const message_1 = error.response && error.response.message ? error.response.message : error.message;

                        console.log(message_1);
                    });
            });
        } catch (e) {
            console.log(e);
            return e;
        }
    }
    async putRequest<ReturnType = any>(url: string, data?: any, isMultipart = false) {
        const apiUrl = API_URL + url;
        let formData = data;
        if (isMultipart) {
            formData = this.convertToFormData(data);
        }
        try {
            return await new Promise<ReturnType>((resolve, reject) => {
                this.http
                    .put(apiUrl, formData)
                    .toPromise()
                    .then((response: any) => {
                        if (response?.success) {
                            resolve(response.data);
                        } else {
                            reject(response.message);
                        }
                    })
                    .catch((error) => {
                        const message_1 = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message;
                        reject(message_1);
                    });
            });
        } catch (e) {
            console.log(e);
            return e;
        }
    }
    async deleteRequest<ReturnType = any>(url: string, data?: any, isMultipart = false) {
        const apiUrl = API_URL + url;
        let formData = data;
        if (isMultipart) {
            formData = this.convertToFormData(data);
        }
        try {
            return await new Promise<ReturnType>((resolve, reject) => {
                this.http
                    .delete(apiUrl, { body: formData })
                    .toPromise()
                    .then((response: any) => {
                        if (response?.success) {
                            resolve(response.data);
                        } else {
                            reject(response.message);
                        }
                    })
                    .catch((error) => {
                        const message_1 = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message;
                        reject(message_1);
                    });
            });
        } catch (e) {
            console.log(e);
            return e;
        }
    }
    addQueryParamsToUrl(url: string, queryParams: any) {
        if (queryParams && Object.keys(queryParams).length > 0) {
            let toReturn = url + '?';
            Object.keys(queryParams).forEach((key, index, arr) => {
                toReturn += `${key}=${queryParams[key]}`;
                toReturn += index === arr.length - 1 ? '' : '&';
            });
            return toReturn;
        }
        return url;
    }
    convertToFormData(data: any) {
        const formData = new FormData();
        Object.keys(data).forEach((k) => {
            if (Array.isArray(data[k])) {
                for (let subitem of data[k]) {
                    formData.append(k, subitem);
                }
            } else {
                formData.append(k, data[k]);
            }
        });
        return formData;
    }
    replaceParamsWithValues(url: string, data: any) {
        if (data && Object.keys(data).length > 0) {
            Object.keys(data).forEach((k) => {
                url = url.replace(`{${k}}`, data[k]);
            });
        }
        return url;
    }
}
