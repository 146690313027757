import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { iAnalyticsData, iAnalyticsItem } from './../../containers/dashboard/dashboard.data';

@Component({
    selector: 'health-analytics',
    template: ` <section class="analytics-container mt-3 p-4 card border-0 rounded">
        <div class="a-header  d-flex flex-row justify-content-between align-items-center w-100">
            <h4 class="title">Analytics</h4>
            <select
                class="form-control analytics-year float-end"
                (change)="onYearSelect($event)"
                [(ngModel)]="selectedYear"
            >
                <option *ngFor="let year of yearLists()">{{ year }}</option>
            </select>
        </div>

        <div class="analytics-content row mt-3">
            <app-analytics-item
                class="col-sm-6 col-md-3 p-2"
                *ngFor="let item of items"
                [item]="item"
            ></app-analytics-item>
        </div>
    </section>`,
    styles: [
        `
            .analytics-year {
                width: 100px;
            }
        `,
    ],
})
export class HealthAnalyticsComponent implements OnInit, OnChanges {
    @Input()
    analyticsData!: iAnalyticsData | null;
    @Output() onYearChange: EventEmitter<any> = new EventEmitter();
    @Input() analyticsYears: number[] | null = [];
    selectedYear: number = new Date().getFullYear();
    items!: Array<iAnalyticsItem>;

    ngOnChanges(changes: SimpleChanges): void {
        this.items = changes.analyticsData?.currentValue?.items as Array<iAnalyticsItem>;
    }
    ngOnInit() {
        this.selectedYear = new Date().getFullYear() as number;
        this.items = this.analyticsData?.items as Array<iAnalyticsItem>;
    }

    yearLists() {
        return this.analyticsYears?.sort((a, b) => b - a);
    }

    onYearSelect(event: any) {
        this.onYearChange.emit(event.target.value);
    }
}
