<div>
    <div *ngIf="link; else button">
        <a
            class="app-button p-2 rounded border-0"
            [routerLink]="link"
            [ngClass]="[color, position]"
        >
            {{ label }}
        </a>
    </div>

    <ng-template #button>
        <button
            class="app-button p-2 rounded border-0"
            [ngClass]="[color, isDisabled ? 'disabled' : '']"
            (click)="onButtonClicked()"
            [disabled]="isDisabled"
        >
            {{ label }}
        </button>
    </ng-template>
</div>
