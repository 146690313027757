<div class="each-hmo-card">
    <div class="each-hmo-img-wrapper">
        <img class="each-hmo-img" src="{{ hmo.logo }}" />
    </div>
    <div class="fill-section">
        <h5 class="text-center providerName">{{ hmo.providerName | titlecase }}</h5>
        <div class="text-center" style="height: 100px; overflow: hidden">
            <p class="about">{{ hmo.about | ellipses: 200 }}</p>
        </div>
    </div>
    <button class="primary-btn-2 mx-auto d-block mt-3" routerLink="../hmo/{{ hmo._id }}">
        Select
    </button>
</div>
