import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { PAGINATOR, searchOptions } from 'src/app/interfaces/general';
import { PermissionService } from 'src/app/services/permission.service';
import { TableConfigsService } from '../../../payment/services/table-configs.service';
import { HealthService } from '../../health.service';
import { IHealthProvider } from '../pay-via-myxalary/pay-via-myxalary.data';

@Component({
    selector: 'app-all-hmo-providers',
    templateUrl: './all-hmo-providers.component.html',
    styleUrls: ['./all-hmo-providers.component.scss'],
})
export class AllHmoProvidersComponent implements OnInit {
    userHasAccess: boolean = false;
    userHasAccessErrorMessage: string = '';

    allHmoArray$: BehaviorSubject<IHealthProvider[]> = new BehaviorSubject(<IHealthProvider[]>[]);
    public contentHeader!: object;
    filterOption: searchOptions[] = [
        {
            name: 'limit',
            width: '15%',
            type: 'select',
            options: this.ts.defaultPageOptions,
        },
        {
            name: 'search',
            width: '85%',
            type: 'search',
            transform: (value: any): any => {
                const cols = ['providerName'].join('?');
                return `${cols}^${value}~`;
            },
        },
    ];

    paging: PAGINATOR = {
        totalCount: 0,
        totalPages: 0,
        currentPage: 1,
    };

    query: any = {
        limit: 10,
        page: 1,
    };
    constructor(
        private route: ActivatedRoute,
        private _healthService: HealthService,
        private permissionService: PermissionService,
        private ts: TableConfigsService
    ) {}

    ngOnInit(): void {
        const { userHasAccess, userHasAccessErrorMessage } =
            this.permissionService.checkUserHasAccess({
                allowedPermissions: this.route.snapshot.data.canAccess,
            });
        this.userHasAccess = userHasAccess;
        this.userHasAccessErrorMessage = userHasAccessErrorMessage;

        this.setup();
    }

    setup() {
        if (this.userHasAccess) {
            this.contentHeader = {
                headerTitle: 'Health',
                actionButton: true,
                breadcrumb: {
                    type: '',
                    links: [
                        {
                            name: 'Health',
                            isLink: true,
                            link: '/health',
                        },
                        {
                            name: 'HMO',
                            isLink: false,
                        },
                    ],
                },
            };
            this.getHmoProviders();
        }
    }

    async getHmoProviders() {
        const result = await this._healthService.fetchProviders({ ...this.query }).toPromise();
        this.allHmoArray$.next(result.data);
        this.paging = result.paging;
    }

    onPageChange(event: any) {
        this.query = {
            ...this.query,
            page: event.count,
        };
        this.getHmoProviders();
    }

    onFilter(event: any) {
        if (!event.search) {
            delete this.query['search'];
        }
        this.query = {
            ...this.query,
            ...event,
            page: 1,
        };
        this.getHmoProviders();
    }
}
