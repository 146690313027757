import { selectExpiringPlans } from './../reducers/index';
import { iEmployee } from 'src/app/shared/models/interfaces';
import { iHmoProvider } from 'src/app/shared/models/hmoProvider';
import {
    selectEmployees,
    selectHmoProviders,
    selectPurchaseData,
    selectPurchaseHistories,
} from '../reducers';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

@Injectable()
export class HealthStore {
    constructor(private _store: Store<any>) {}

    getHmoProviders = (): Observable<iHmoProvider[]> =>
        this._store.pipe(select(selectHmoProviders));

    getEmployees = (): Observable<iEmployee[]> => this._store.pipe(select(selectEmployees));

    getPurchaseData = (): Observable<any> => this._store.pipe(select(selectPurchaseData));

    getPurchaseHistories = (): Observable<any> => this._store.pipe(select(selectPurchaseHistories));

    getExpiringPlans = (): Observable<any> => this._store.pipe(select(selectExpiringPlans));
}
