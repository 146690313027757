import { Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '../../../services/permission.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-no-license',
    templateUrl: './no-license.component.html',
    styleUrl: './no-license.component.scss',
})
export class NoLicenseComponent implements OnInit, OnDestroy {
    userHasAccessErrorMessage!: string;
    subs!: Subscription;
    constructor(
        private router: Router,
        private permission: PermissionService
    ) {}

    ngOnInit(): void {
        this.subs = this.permission.errorMessage.asObservable().subscribe((item: any) => {
            if (!item) {
                this.router.navigate(['dashboard']);
                return;
            }
            this.userHasAccessErrorMessage = item.userHasAccessErrorMessage;
            if (!item.userHasAccess && !this.userHasAccessErrorMessage) {
                this.router.navigate(['dashboard']);
            }
            return;
        });
    }

    ngOnDestroy() {
        this.subs?.unsubscribe();
    }
}
