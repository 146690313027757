import { NavigationStart, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
} from '@angular/core';

import { AppStore } from '../../app-data/stores/app.store';
import { environment } from '../../../environments/environment';
import { GeneralService } from 'src/app/shared/services/general.service';
import { SwitchCompanyModalService } from 'src/app/shared/services/switch-company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewNotificationsComponent } from '../../shared/components/view-notifications/view-notifications.component';
import { BehaviorSubject } from 'rxjs';
import { IFeed, Notify } from 'src/app/shared/models/interfaces';
import { AppSocketService } from 'src/app/shared/services/app-socket.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CompaniesListComponent } from '../../shared/components/companies-list/companies-list.component';
import { Location } from '@angular/common';
import { SIDEBAR } from 'src/app/interfaces/general';
import { filter } from 'rxjs/operators';
import { ViewUploadProgressComponent } from 'src/app/shared/components/view-upload-progress/view-upload-progress.component';
import { NotchhrWrappedComponent } from 'src/app/modules/dashboard/containers/notchhr-wrapped/notchhr-wrapped.component';
import appConstants from 'src/app/utils/constants';

declare let $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
    count = 0;
    name = '';
    v1Url = '';
    profileImg = '';
    list: any[] = [];
    activeRoute = '';
    authToken!: string;
    keys: string[] = [];
    v1DashboardUrl!: string;
    contactList: { [key: string]: any } = {};
    defaultImage = '/assets/images/user.png';
    notifications = new BehaviorSubject<Notify[]>(<any>[]);
    notificationCount = new BehaviorSubject<number>(0);
    user: any = {};
    menuList: SIDEBAR[] = [];
    features = appConstants.features;
    constructor(
        private route: Router,
        private _appStore: AppStore,
        private elementRef: ElementRef,
        private genSrv: GeneralService,
        public domsaniter: DomSanitizer,
        private modal: NgbModal,
        private notify: NotificationService,
        private socket: AppSocketService,
        private location: Location,
        private router: Router,
        private cRef: ChangeDetectorRef
    ) {
        this.v1Url = environment.myXalaryV1;
        this.authToken = this.genSrv.getAppToken();
        this.v1DashboardUrl = this.v1Url + `/dashboard?eaid=${this.authToken}`;
    }

    ngAfterViewInit() {
        this.appendScript('/assets/template-files/js/off-canvas.js');
        this.appendScript('/assets/template-files/js/template.js');
    }

    getActiveMenu(slug: string) {
        const route = this.activeRoute || window.location.pathname;
        return this.genSrv.getActiveDash({ route, index: 1, slug });
    }

    getImage(company: any) {
        return this.genSrv.getCompanyImage(company);
    }
    ngOnInit(): void {
        this.menuList = this.genSrv.sideBarMenu.filter((v: SIDEBAR) => !v.noHeader);
        this.toggler();
        this._appStore.getUserInfo().subscribe((value) => {
            if (Object.keys(value).length) {
                this.name = value.firstName + ' ' + value.lastName;
                this.profileImg = value.profileImgUrl;
                this.user = { ...value };
                this.cRef.detectChanges();
            }
        });
        this.notificationCount = this.notify.notifyCount;
        this._appStore.getNotification().subscribe(async (value: any[] = []) => {
            value = JSON.parse(JSON.stringify(value));
            this.notifications.next(value.splice(0, 4));
            this.cRef.detectChanges();
        });

        this.socket.newNotify.subscribe((data) => {
            this.notify.updateNotification(data);
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: any) => {
                this.activeRoute = event.url;
                this.menuList = JSON.parse(JSON.stringify([...this.menuList]));
                this.cRef.detectChanges();
            });
    }

    appendScript(link: string) {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = link || 'src/assets/template-files/js/dashboard.js';
        this.elementRef.nativeElement.appendChild(s);
    }

    switchCompany(event: any) {
        event.preventDefault();
        this.modal.hasOpenModals() && this.modal.dismissAll();
        return this.modal.open(CompaniesListComponent, {
            size: 'lg',
            centered: true,
            windowClass: 'recipient-modal',
            backdrop: 'static',
        });
    }

    logout(event: any) {
        event.preventDefault();
        this.genSrv.removeAppToken();
        this.route.navigate(['login']);
    }

    goToV1(event: any) {
        event.preventDefault();
        document.location.href = `${environment.myXalaryV1}`;
    }

    toggler() {
        var body = $('body');
        $('[data-toggle="minimize"]').on('click', function () {
            if (body.hasClass('sidebar-toggle-display') || body.hasClass('sidebar-absolute')) {
                body.toggleClass('sidebar-hidden');
            } else {
                body.toggleClass('sidebar-icon-only');
            }
        });
    }

    gotoV1(link: string) {
        const navigation = this.v1Url + `${link}?eaid=${this.authToken}`;
        window.location.assign(navigation);
    }

    openNotification() {
        this.modal.open(ViewNotificationsComponent, {
            size: 'lg',
            centered: true,
            windowClass: 'recipient-modal',
            backdrop: 'static',
        });
    }

    open(data: Notify) {
        this.notify.openNotification(data);
    }

    goBack() {
        this.location.back();
    }

    get nCount() {
        let count = this.notificationCount.getValue();
        return count > 99 ? '99+' : count;
    }

    openProgress(progress: any) {
        this.genSrv.preModal(ViewUploadProgressComponent).componentInstance.data = progress;
    }

    openNotchHRRewind() {
        this.modal.open(NotchhrWrappedComponent, {
            windowClass: 'windowClass',
            size: 'xl',
        });
    }
}
