import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Employee } from '../../lms.types';
import { LMSService } from '../../services/lms.service';
import { HandleLicenseModuleCheck } from 'src/app/utils/license';

@Component({
    selector: 'app-employee-select',
    templateUrl: './employee-select.component.html',
    styleUrls: ['./employee-select.component.scss'],
})
export class EmployeeSelectComponent implements OnInit {
    @Input() selected: any = [];
    @Output() selectedChange = new EventEmitter<any>();
    @Input() multiple: boolean = true;
    @Input() employeeList: any = [];

    employees: Employee[] = [];
    loading = false;

    constructor(private lmsService: LMSService) {}

    ngOnInit(): void {
        if (this.employeeList?.length) this.employees = this.employeeList;
        this.fetchAllEmployees();
    }

    async fetchAllEmployees() {
        if (this.employees.length > 0) return;

        this.loading = true;
        const employees = await this.lmsService.fetchAllEmployees();
        this.loading = false;
        if (employees) {
            this.employees = HandleLicenseModuleCheck({
                employees: employees,
                module: 'ELearning',
            });
        }
    }

    onSelectChange(newValues: any) {
        this.selectedChange.emit(newValues);
    }

    searchFn(term: string, item: Employee) {
        term = term.toLowerCase();
        return item.name?.toLowerCase().includes(term) || item.email?.toLowerCase().includes(term);
    }
}
