<div>
    <section>
        <div class="card-body">
            <h4 class="card-title">{{ info.title }}</h4>
            <p class="text-sm text-gray">{{ info.subtitle }}</p>

            <div class="icon-section mb-4">
                <mat-icon [svgIcon]="info.svgIcon" class="icon"></mat-icon>
            </div>
            <p class="text-sm text-gray text-center">{{ info.subtitle2 }}</p>

            <div class="row">
                <div class="col-12 mt-2">
                    <div class="d-flex w-100 justify-content-center">
                        <button
                            class="btn mr-4"
                            [ngClass]="btn.btnStyle"
                            *ngFor="let btn of info.btn"
                            (click)="handleButtonEvent(btn.title)"
                        >
                            {{ btn.title }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="modal-close-icon" (click)="closeModal()">
        <mat-icon svgIcon="cancel-circle-white"></mat-icon>
    </div>
</div>
