import { Component, Input, ViewChild } from '@angular/core';
import { CSVRecord } from '../../models/CsvRecord';

@Component({
    selector: 'app-csv-uploader',
    templateUrl: './csv-uploader.component.html',
    styleUrls: ['./csv-uploader.component.scss'],
})
export class CsvUploaderComponent {
    @Input() headers: string[] = [];
    constructor() {}

    ngOnInit(): void {}

    public records: any[] = [];
    @ViewChild('csvReader') csvReader: any;

    uploadListener($event: any): void {
        let text = [];
        let files = $event.srcElement.files;

        if (this.isValidCSVFile(files[0])) {
            let input = $event.target;
            let reader = new FileReader();
            reader.readAsText(input.files[0]);

            reader.onload = () => {
                let csvData = reader.result;
                let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

                let headersRow = this.getHeaderArray(csvRecordsArray);

                this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow);
            };

            reader.onerror = function () {
                console.log('error is occured while reading file!');
            };
        } else {
            alert('Please import valid .csv file.');
            this.fileReset();
        }
    }

    getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerArray: string[]) {
        let csvArr = [];

        for (let i = 1; i < csvRecordsArray.length; i++) {
            let curruntRecord = (<string>csvRecordsArray[i]).split(',');
            if (curruntRecord.length == headerArray.length) {
                let csvRecord: any = {};
                headerArray.forEach((header, index) => {
                    csvRecord[header] = curruntRecord[index].trim();
                });
                csvArr.push(csvRecord);
            }
        }
        return csvArr;
    }

    isValidCSVFile(file: any) {
        return file.name.endsWith('.csv');
    }

    getHeaderArray(csvRecordsArr: any) {
        let headers = (<string>csvRecordsArr[0]).split(',');
        let headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }

    fileReset() {
        this.csvReader.nativeElement.value = '';
        this.records = [];
    }
}
