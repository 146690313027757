<div class="container">
    <div class="video-container" [id]="'video-container-' + src.name">
        <div class="playback-animation" [id]="'playback-animation-' + src.name">
            <svg class="svg playback-icons">
                <use class="hidden" [attr.href]="'#play-icon-' + src.name"></use>
                <use [attr.href]="'#pause-' + src.name"></use>
            </svg>
        </div>

        <video
            [style.height]="gHeight"
            #target
            controls
            class="video"
            [id]="'video-' + src.name"
            preload="metadata"
        >
            <source [src]="src.url" [type]="src.type" />
        </video>

        <div class="video-controls hidden" [id]="'video-controls-' + src.name">
            <div class="video-progress">
                <progress [id]="'progress-bar-' + src.name" value="0" min="0"></progress>
                <input
                    class="seek"
                    [id]="'seek-' + src.name"
                    value="0"
                    min="0"
                    type="range"
                    step="1"
                />
                <div class="seek-tooltip" [id]="'seek-tooltip-' + src.name">00:00</div>
            </div>

            <div class="bottom-controls">
                <div class="left-controls">
                    <button class="button" data-title="Play (k)" [id]="'play-' + src.name">
                        <svg class="svg playback-icons">
                            <use [attr.href]="'#play-icon-' + src.name"></use>
                            <use class="hidden" [attr.href]="'#pause-' + src.name"></use>
                        </svg>
                    </button>

                    <div class="volume-controls">
                        <button
                            data-title="Mute (m)"
                            class="volume-button button"
                            [id]="'volume-button-' + src.name"
                        >
                            <svg class="svg">
                                <use class="hidden" [attr.href]="'#volume-mute-' + src.name"></use>
                                <use class="hidden" [attr.href]="'#volume-low-' + src.name"></use>
                                <use [attr.href]="'#volume-high-' + src.name"></use>
                            </svg>
                        </button>

                        <input
                            class="volume"
                            [id]="'volume-' + src.name"
                            value="1"
                            data-mute="0.5"
                            type="range"
                            max="1"
                            min="0"
                            step="0.01"
                        />
                    </div>

                    <div class="time">
                        <time [id]="'time-elapsed-' + src.name">00:00</time>
                        <span> / </span>
                        <time [id]="'duration-' + src.name">00:00</time>
                    </div>
                </div>

                <div class="right-controls">
                    <button
                        data-title="PIP (p)"
                        class="pip-button button"
                        [id]="'pip-button-' + src.name"
                    >
                        <svg class="svg">
                            <use [attr.href]="'#pip-' + src.name"></use>
                        </svg>
                    </button>
                    <button
                        data-title="Full screen (f)"
                        class="fullscreen-button button"
                        [id]="'fullscreen-button-' + src.name"
                    >
                        <svg class="svg">
                            <use [attr.href]="'#fullscreen-' + src.name"></use>
                            <use [attr.href]="'#fullscreen-exit-' + src.name" class="hidden"></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<svg class="svg" style="display: none">
    <defs>
        <symbol [id]="'pause-' + src.name" viewBox="0 0 24 24">
            <path
                d="M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z"
            ></path>
        </symbol>

        <symbol [id]="'play-icon-' + src.name" viewBox="0 0 24 24">
            <path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z"></path>
        </symbol>

        <symbol [id]="'volume-high-' + src.name" viewBox="0 0 24 24">
            <path
                d="M14.016 3.234q3.047 0.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-0.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484v-2.063zM16.5 12q0 2.813-2.484 4.031v-8.063q1.031 0.516 1.758 1.688t0.727 2.344zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z"
            ></path>
        </symbol>

        <symbol [id]="'volume-low-' + src.name" viewBox="0 0 24 24">
            <path
                d="M5.016 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6zM18.516 12q0 2.766-2.531 4.031v-8.063q1.031 0.516 1.781 1.711t0.75 2.32z"
            ></path>
        </symbol>

        <symbol [id]="'volume-mute-' + src.name" viewBox="0 0 24 24">
            <path
                d="M12 3.984v4.219l-2.109-2.109zM4.266 3l16.734 16.734-1.266 1.266-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-0.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016h-3.984v-6h4.734l-4.734-4.734zM18.984 12q0-2.391-1.383-4.219t-3.586-2.484v-2.063q3.047 0.656 5.016 3.117t1.969 5.648q0 2.203-1.031 4.172l-1.5-1.547q0.516-1.266 0.516-2.625zM16.5 12q0 0.422-0.047 0.609l-2.438-2.438v-2.203q1.031 0.516 1.758 1.688t0.727 2.344z"
            ></path>
        </symbol>

        <symbol [id]="'fullscreen-' + src.name" viewBox="0 0 24 24">
            <path
                d="M14.016 5.016h4.969v4.969h-1.969v-3h-3v-1.969zM17.016 17.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 9.984v-4.969h4.969v1.969h-3v3h-1.969zM6.984 14.016v3h3v1.969h-4.969v-4.969h1.969z"
            ></path>
        </symbol>

        <symbol [id]="'fullscreen-exit-' + src.name" viewBox="0 0 24 24">
            <path
                d="M15.984 8.016h3v1.969h-4.969v-4.969h1.969v3zM14.016 18.984v-4.969h4.969v1.969h-3v3h-1.969zM8.016 8.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 15.984v-1.969h4.969v4.969h-1.969v-3h-3z"
            ></path>
        </symbol>

        <symbol [id]="'pip-' + src.name" viewBox="0 0 24 24">
            <path
                d="M21 19.031v-14.063h-18v14.063h18zM23.016 18.984q0 0.797-0.609 1.406t-1.406 0.609h-18q-0.797 0-1.406-0.609t-0.609-1.406v-14.016q0-0.797 0.609-1.383t1.406-0.586h18q0.797 0 1.406 0.586t0.609 1.383v14.016zM18.984 11.016v6h-7.969v-6h7.969z"
            ></path>
        </symbol>
    </defs>
</svg>
