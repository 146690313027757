import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HealthActionTypeEnums, setHmoProviders } from '../actions';
import { HealthService } from './../../health.service';

@Injectable()
export class HealthEffects {
    constructor(
        private _actions: Actions,
        private _healthService: HealthService
    ) {}

    fetchHmoProviders$ = createEffect(() =>
        this._actions.pipe(
            ofType(HealthActionTypeEnums.fetchProviders),
            mergeMap(({ payload }) =>
                this._healthService.fetchProviders(payload).pipe(
                    map((providers) => setHmoProviders({ payload: providers })),
                    catchError((error) => {
                        console.log(error);
                        return EMPTY;
                    })
                )
            )
        )
    );
}
