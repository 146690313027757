<section class="pt-3">
    <form *ngIf="formInput" class="w-100 section" [formGroup]="formInput">
        <div formArrayName="levels">
            <div
                [formGroupName]="i"
                *ngFor="let levels of list?.controls; let i = index"
                class="layout"
                [style.padding-left]="i * 10 + 'px'"
            >
                <span class="num">{{ i + 1 }}.</span>
                <div class="form-group w-100">
                    <div class="app-ng-autocomplete">
                        <app-afl-ngs
                            [roles]="possibleRoles"
                            [index]="i"
                            [allList]="list.value"
                            [value]="list.value[i].id"
                            (handleSelect)="handleSelect($event, i)"
                        ></app-afl-ngs>
                    </div>
                </div>
                <div *ngIf="levels.value.id === defaultRoles[1].id" class="form-group w-100">
                    <div class="app-ng-autocomplete">
                        <ng-select
                            formControlName="employee"
                            [placeholder]="'Select User'"
                            class="ng-input"
                            [items]="possibleUsers | async"
                            bindLabel="name"
                            [clearable]="false"
                        >
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <app-e-drop-ui [item]="item"></app-e-drop-ui>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <span
                    *ngIf="i !== 0"
                    (click)="removeLevel(i)"
                    class="material-symbols-outlined clickable"
                    >cancel</span
                >
            </div>
        </div>
        <div *ngIf="!canNotAdd" (click)="addLevelToForm()" class="addApproval">
            + Add Approval Level
        </div>
        <div>
            <p class="desc">
                Emails will be sent to notify the appropriate people when an item is waiting for
                their review.
            </p>
        </div>
    </form>
</section>
