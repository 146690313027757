import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { userModel } from '../../models/interfaces';

@Component({
    selector: 'app-profile-image',
    templateUrl: './profile-image.component.html',
    styleUrls: ['./profile-image.component.scss'],
})
export class ProfileImageComponent implements OnChanges {
    @Input()
    user!: any;

    @Input()
    size: number = 43;

    @Input()
    color = {
        background: 'F7D3EA',
        color: 'D62196',
    };

    @Input()
    key!: string;

    @Input()
    profileImgUrl!: string;

    constructor() {}

    ngOnChanges(): void {
        this.profileImgUrl =
            this.user.profileImgUrl ?? (this.key ? this.user[this.key] : this.profileImgUrl);
        if (!this.profileImgUrl) {
            this.profileImgUrl = `'https://ui-avatars.com/api/?background=${this.color.background}&color=${this.color.color}&name=${this.user.name || this.user.fullName || this.user.firstName + ' ' + this.user.lastName}'`;
        } else {
            this.profileImgUrl = `'${this.profileImgUrl}'`;
        }
    }
}
