import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PROVIDER_ICONS, SVG_ICONS } from './custom-icon-list';

@Injectable()
export class CustomIconService {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {}

    init(): void {
        SVG_ICONS.forEach((icon) => {
            const iconName = icon.replace('.svg', '');
            this.matIconRegistry.addSvgIcon(
                iconName,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}`)
            );
        });
        PROVIDER_ICONS.forEach((icon) => {
            const iconName = icon.replace('.svg', '');
            this.matIconRegistry.addSvgIcon(
                iconName,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/provider/${icon}`)
            );
        });
    }
}
