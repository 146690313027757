import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/modules/recruitment/settings-component/service/settings.service';
import { EmployeeModuleServiceService } from '../../service/employee-module-service.service';

@Component({
    selector: 'myxalary-competency-comparison',
    templateUrl: './employee-competency-comparison.component.html',
    styleUrls: ['./employee-competency-comparison.component.scss'],
})
export class EmployeeCompetencyComparisonComponent implements OnInit, AfterViewInit {
    employeeSkills: any[] = [];
    employeeJobRoleRequiredSkills: any[] = [];
    @Input() employeeId: any;
    @Input() jobRoleId: any;
    @Input() isJobShow: any;
    jobRoleSkills: any = [];

    constructor(
        private _settingsService: SettingsService,
        private _employeeService: EmployeeModuleServiceService
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.getEmployeeDetails();
    }

    getEmployeeDetails() {
        this._employeeService.eachEmployeeProfile(this.employeeId).subscribe(
            (res: any) => {
                let skills: any[] = res.employee.competency;
                this.fetchSingleJobRoleDetails(skills);
            },
            (error) => {
                console.error(error);
            }
        );
    }

    private fetchSingleJobRoleDetails(skills: any[]) {
        let jobId = this.jobRoleId;

        this._settingsService.getJobRoleById(jobId).subscribe(
            (resp) => {
                const requiredSkills: any[] = resp.payload.skills;
                this.jobRoleSkills = resp.payload.skills;

                const requiredSkillRatings: any = {};
                requiredSkills.forEach((skill) => {
                    requiredSkillRatings[skill.skill] = skill.rating;
                });

                const result: {
                    name: string;
                    employee_rating: number;
                    required_rating: any;
                    meets_requirements: boolean;
                }[] = [];

                skills.forEach((employeeSkill) => {
                    const skillName = employeeSkill.name;
                    const employeeRating = parseInt(employeeSkill.rating, 10);
                    if (requiredSkillRatings.hasOwnProperty(skillName)) {
                        const requiredRating = requiredSkillRatings[skillName];
                        const meetsRequirements = employeeRating >= requiredRating;
                        result.push({
                            name: skillName,
                            employee_rating: employeeRating,
                            required_rating: requiredRating,
                            meets_requirements: meetsRequirements,
                        });
                    } else {
                        result.push({
                            name: skillName,
                            employee_rating: employeeRating,
                            required_rating: null,
                            meets_requirements: false,
                        });
                    }
                });
                this.employeeSkills = result;
            },
            (error) => {
                // handle the error
                console.error('Error fetching job role details', error);
            }
        );
    }
}
