import {
    AfterContentInit,
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { chartOption } from '../../../interfaces/general';
Chart.register(...registerables);

@Component({
    selector: 'app-charts',
    templateUrl: './charts.component.html',
    styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent
    implements OnInit, AfterViewInit, OnDestroy, AfterContentInit, OnChanges
{
    chart?: Chart;
    @Input() chartOptions!: chartOption;
    DEFAULT_WIDTH = '800px';
    constructor() {}
    ngAfterContentInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.chartOptions || changes.chartOptions.firstChange) return;
        this.chart?.clear();
        this.chart?.destroy();
        const ctx = (<HTMLCanvasElement>(
            document.getElementById(this.chartOptions.id.toString())
        ))?.getContext('2d');
        this.chart = new Chart(<any>ctx, this.chartOptions.options);
    }
    ngAfterViewInit(): void {
        if (this.chartOptions) {
            this.chart = new Chart(this.chartOptions.id, this.chartOptions.options);
        }
    }
    ngOnInit(): void {}
    ngOnDestroy(): void {
        this.chart?.clear();
        this.chart?.destroy();
    }
}
