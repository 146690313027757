<button
    angular4-paystack
    class="btn btn-primary"
    [class]="'btn btn-primary'"
    [paystackOptions]="options"
    (paymentInit)="paymentInit()"
    (onClose)="paymentCancel()"
    (callback)="paymentDone($event)"
    [disabled]="disabled"
>
    {{ title }}
</button>
