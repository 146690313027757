export class Company {
    public companyName: string | undefined;
    public userEmail: string | undefined;
    public firstName: string | undefined;
    public contactSource: string = 'null';
    // public otherContactSource: string;
    public lastName: string | undefined;
    public password: string | undefined;
    public token: string | undefined;
    public phone: number | undefined;
    public sla: boolean | undefined;
}
