import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { io } from 'socket.io-client';
import { AppDispatcher } from 'src/app/app-data';
import { environment } from 'src/environments/environment';
import { iDirectChatResponseV2 } from '../../../shared/models/interfaces';
export type OnlineUsers = {
    connectionId: string;
    employeeId: string;
};
@Injectable({
    providedIn: 'root',
})
export class ChatSocketService {
    socket!: any;
    token!: any;
    onlineUsers: Subject<OnlineUsers[]> = new Subject();
    newMessage: Subject<iDirectChatResponseV2> = new Subject();
    likeChat: Subject<iDirectChatResponseV2> = new Subject();
    refresh: Subject<boolean> = new Subject();
    constructor(private dispatcher: AppDispatcher) {}

    connect() {
        const token = localStorage.getItem('APP_TOKEN');
        this.token = `Bearer ${token}`;
        //connect
        this.socket = io(environment.chatEndpoint, {
            auth: {
                headers: {
                    authorization: this.token,
                },
            },
        });
        this.setup();
        return this.socket;
    }

    emit({ event, data, callback }: { event: string; data: any; callback?: Function }) {
        this.socket.emit(event, data, callback);
    }

    setup() {
        //get all online users
        this.socket.on('new_online_user', (users: any) => {
            this.dispatcher.setOnlineUsers(users.values);
        });

        this.socket.on('getUnreadCount', (records: any) => {
            this.dispatcher.setUnReadCount(records);
        });
        //new Message
        this.socket.on('new_message', (records: any) => {
            const chatInfo = records;
            //first send new Message
            this.newMessage.next(chatInfo);
            //get updated unread count
            this.socket.emit('get_unread_count');
        });

        this.socket.on('like_chat', (records: any) => {
            const chatInfo = records;
            //first send new Message
            this.likeChat.next(chatInfo);
        });

        this.socket.on('REFRESH_CONTACT', (records: any) => {
            console.log(`refresh`);
            this.refresh.next(true);
        });

        //get new group
        this.socket.on('join_group', (group: any) => {
            console.log(group);
            this.socket.emit('join_group', group);
            this.refresh.next(true);
        });

        // //get new group
        // this.socket.on("getUnreadCount", (records: any) => {
        //   this.unReadCount$.next(records);
        // })
        // //get notification
        // this.socket.on("notifications", async (records: any) => {
        //   if (records.status) {
        //     try {
        //       this._appDispatcher.setNotification([...records.data]);
        //     } catch (e) {
        //       console.log(e)
        //     }
        //   }
        // })
        // // get mentions notification
        // this.socket.on("Mention", async (records: any) => {
        //   await this.handleIncomingNotification(records)
        // })
        // this.socket.on("FeedMention", async (records: any) => {
        //   await this.handleIncomingNotification(records)
        // })
        // this.socket.on("ChatMention", async (records: any) => {
        //   await this.handleIncomingNotification(records)
        // })
        // //get message notification
        // this.socket.on("Message", async (records: any) => {
        //   await this.handleIncomingNotification(records)
        // })

        // //new Message
        // this.socket.on("new_message", (records: any) => {
        //   const chatInfo = records;
        //   const currentReplyTo = this.currentReplyTo.getValue();
        //   const currentUser = this.currentUser.getValue();
        //   //(chatInfo.replyTo == currentReplyTo)
        //   if (chatInfo) {
        //     if ((chatInfo.chatBy == currentReplyTo && chatInfo.replyTo === currentUser._id) || (chatInfo.replyTo == currentReplyTo && chatInfo.chatBy !== currentUser._id)) {
        //       //display on chats
        //       //push to chat messages
        //       const listOfChats = this.chatMessages$.getValue();
        //       this.chatMessages$.next([chatInfo, ...(listOfChats || [])]);
        //       let chatBy = chatInfo.chatBy;
        //       if (currentUser._id !== chatInfo.replyTo) {
        //         chatBy = chatInfo.replyTo;
        //       }
        //       this.emitEvent({ event: "onViewMessage", payload: { chatBy, replyTo: currentUser._id, companyId: currentUser.companyID }, options: () => { } })
        //     } else {
        //       const readCount = this.unReadCount$.getValue();
        //       const key = chatInfo.useReplyTo ? chatInfo.replyTo : chatInfo.chatBy;
        //       if (readCount[key]) {
        //         readCount[key] += 1;
        //       } else {
        //         readCount[key] = 1;
        //       }
        //       this.unReadCount$.next({ ...readCount });
        //       let val = this.newNotification$.getValue();
        //       val["message"] = "You have a new message from @name";
        //       val["key"] = key;
        //       this.newNotification$.next({ ...val });
        //     }
        //     const lastMessages = this.newLastMessage$.getValue() || [];
        //     lastMessages.push(chatInfo)

        //     this.newLastMessage$.next(lastMessages);
        //   }
        // })

        // this.socket.on("connect_error", (err: any) => {
        //   const { status = 500, error: message = '' } = err?.data || {};
        //   if (status == 401) {
        //     this.handleError(message)
        //     return;
        //   }
        //   this.socket.connect();
        // })
    }
}
