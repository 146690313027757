import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { GeneralService } from 'src/app/shared/services/general.service';
import { AppStore } from '../../../app-data/stores/app.store';
import { ButtonFilter, Combos, filterOptions } from '../../../interfaces/general';
import { Analytics } from '../../../services/analytics/analytics.service';

@Component({
    selector: 'main-app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit, OnDestroy {
    @Input() filterOption!: filterOptions;
    @Output() infoOutput = new EventEmitter();
    @Input() clearable: boolean = true;
    @Input() externalClass!: string;
    filterValue: any = {};
    model: any = {};
    selectedValues: any = {};
    combos!: Combos;
    defaultFilters: { [key: string]: ButtonFilter & { model: any } } = {
        year: {
            placeholder: 'Year',
            options: [],
            name: 'years',
            model: '',
        },
        department: {
            name: 'department',
            label: 'departmentName',
            value: '',
            placeholder: 'Department',
            options: [],
            model: '',
        },
        month: {
            placeholder: 'month',
            name: 'month',
            options: [],
            model: null,
        },
    };
    months: string[] = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    constructor(
        private _analytics: Analytics,
        private store: AppStore,
        private genServ: GeneralService
    ) {}

    ngOnInit(): void {
        this.store
            .getCombos()
            .pipe(take(1))
            .subscribe((value) => {
                this.defaultFilters.year.options = value.years;
                this.filterOption.useYearsDefault &&
                    (this.defaultFilters.year.default = value.years[0]);
                this.defaultFilters.department.options = value.departments;
                this.defaultFilters.month.options = this.months;
                this.filterOption.useMonthDefault &&
                    (this.defaultFilters.month.default = this.months[new Date().getMonth()]);
            });
        this.filterOption.filterButtons = this.filterOption?.filterButtons ?? [];
        this.filterOption.useDepartment &&
            this.filterOption.filterButtons.push(this.defaultFilters.department);
        this.filterOption.useMonth &&
            this.filterOption.filterButtons.push(this.defaultFilters.month);
        this.filterOption.useYears &&
            this.filterOption.filterButtons.push(this.defaultFilters.year);
        this.filterOption.filterButtons.forEach((value: ButtonFilter) => {
            if (!value.default) return;
            this.selectedValues[value.name] = value.default;
        });
        this.genServ.generalFilter.next(this.selectedValues);
    }

    changeUpdate(opts: any, name: string) {
        // this.selectedValues[name] = opts;
        let query: any = {};
        for (let item in this.selectedValues) {
            if (!this.selectedValues[item]) continue;
            query[item] = this.selectedValues[item];
        }
        this.genServ.generalFilter.next(query);
        this.infoOutput.emit(query);
    }

    toSentenceCase(word: string) {
        return this._analytics.toSentenceCase(word);
    }

    ngOnDestroy(): void {}
}
