<modal-cover [title]="'Start New Transfer'">
    <div class="row w-100">
        <section class="h-100 col-md-6 col-sm-12 w-100">
            <form
                [formGroup]="formData"
                (ngSubmit)="save()"
                style="gap: 5px"
                class="p-3 d-flex h-100 flex-column"
            >
                <div class="form-group form-style w-100">
                    <label class="font-weight-bold" for="title"
                        >Amount<span class="text-danger">*</span></label
                    >
                    <p-inputNumber
                        mode="currency"
                        currency="NGN"
                        [max]="999000000000000"
                        formControlName="amount"
                        [min]="100"
                        class="w-100"
                        inputStyleClass="form-control "
                        inputId="integeronly"
                    >
                    </p-inputNumber>
                    <span class="avabal" for="title"
                        >Available Balance: {{ balance | async | currency: '₦' }}</span
                    >
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="title"
                        >Beneficiary<span class="text-danger">*</span></label
                    >
                    <div class="app-ng-autocomplete ml-1">
                        <ng-select
                            [typeahead]="searchBenefit"
                            [placeholder]="'Select Beneficiary'"
                            bindValue="recipientCode"
                            [loading]="loadingBenefits"
                            bindLabel="accountName"
                            class="ng-input"
                            [items]="benefitList | async"
                            [hideSelected]="true"
                            formControlName="beneficiary"
                            [clearable]="true"
                        >
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <div class="d-flex flex-column">
                                    <span>{{ item.accountName }}</span>
                                    <span>{{ item.accountNumber }}</span>
                                    <span>{{ item.bankName }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <span (click)="openAddBenefit()" class="add-bene clickable" for="title">
                        + Add Beneficiary</span
                    >
                </div>
                <div class="form-group form-style w-100">
                    <label class="font-weight-bold" for="title"
                        >Narration<span class="text-danger">*</span></label
                    >
                    <textarea
                        rows="5"
                        formControlName="narration"
                        class="w-100 form-control"
                    ></textarea>
                </div>
                <div class="form-group form-style w-100">
                    <label class="font-weight-bold" for="title"
                        >Reference Number<span class="text-danger">*</span></label
                    >
                    <input formControlName="reference" class="form-control" />
                </div>
                <div class="form-group form-style w-100">
                    <label class="font-weight-bold" for="title"
                        >Pin <span class="text-danger">*</span></label
                    >
                    <p-password
                        [feedback]="false"
                        formControlName="pin"
                        inputStyleClass="form-control "
                    ></p-password>
                </div>
                <div class="form-group form-style w-100">
                    <label class="font-weight-bold" for="title"
                        >OTP<span class="text-danger">*</span></label
                    >
                    <p-inputMask
                        formControlName="otp"
                        class="w-100"
                        inputStyleClass="form-control w-100 "
                        mask="999999"
                        placeholder="xxxxxx"
                    ></p-inputMask>
                    <span
                        [style.color]="otpBtn.color"
                        *ngIf="canRequestOTP"
                        (click)="getOTP()"
                        class="add-bene clickable"
                        for="title"
                        >{{ otpBtn.label }}</span
                    >
                </div>
                <div class="d-flex btn-grp justify-content-between">
                    <button [disabled]="formData.invalid" type="submit" class="btn btn-primary">
                        Transfer
                    </button>
                </div>
            </form>
        </section>
        <div class="col-md-6 col-sm-12">
            <div class="transfer-image"></div>
        </div>
    </div>
</modal-cover>
