<div class="row d-flex">
    <div
        [ngClass]="
            useDescription && useRating ? 'col-lg-4' : !useDescription ? 'col-lg-6' : 'col-lg-11'
        "
    >
        <div class="form-group form-style mb-4">
            <label for="jobRole " class="skill-title">Competency</label>
            <div class="app-ng-autocomplete">
                <ng-autocomplete
                    class="flex2"
                    [data]="skillsData"
                    searchKeyword="name"
                    [(ngModel)]="skillInput.name"
                    placeholder="Type to Search Competency"
                    [isLoading]="jobRoleSkillLoader"
                    [debounceTime]="30"
                    (selected)="selectEventSkills($event)"
                    (inputChanged)="onSearchSkills($event)"
                    [itemTemplate]="itemTemplate3"
                    [notFoundTemplate]="notFoundTemplate3"
                    #skills
                >
                </ng-autocomplete>
            </div>
        </div>
    </div>
    <div *ngIf="useRating" [ngClass]="!useDescription ? 'col-lg-5' : 'col-lg-3'">
        <div class="form-group form-style mb-4 w-100">
            <label for="title" class="skill-title">Rating</label>
            <select
                [(ngModel)]="skillInput.rating"
                class="form-control"
                id="degreeClass"
                placeholder="Rating"
            >
                <option *ngFor="let rating of ratings" [value]="rating.level">
                    {{ rating.level + '/' + rating.description }}
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="useDescription" class="col-lg-4">
        <div class="form-group form-style mb-4">
            <label for="jobRole" class="skill-title">Description</label>
            <input class="form-control w-100" [(ngModel)]="skillInput.description" />
        </div>
    </div>
    <div class="col-lg-1 justify-content-center align-items-center d-flex">
        <button
            (click)="onAdd()"
            type="button"
            [disabled]="!isValid"
            class="btn btn-slim mybtn justify-content-center btn-default custom-margin"
        >
            <mat-icon class="mat-icon">add_circle_outline</mat-icon>
        </button>
    </div>
    <ng-template #itemTemplate3 let-item>
        <a [innerHTML]="item.name"></a>
    </ng-template>
    <ng-template #notFoundTemplate3 let-notFound>
        <div [innerHTML]="'No Competency Matches Search'"></div>
        <button
            type="button"
            class="btn btn-slim btn-primary add-button"
            (click)="handleAddSkills(skills)"
        >
            Add Competency
        </button>
    </ng-template>
</div>
