<section *ngIf="!swal">
    <ng-template [ngIf]="!hideHeader">
        <div class="d-flex pl-3 pt-4 w-100 justify-content-between mb-2">
            <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
            <h4 style="font-size: 1.2rem; font-weight: 900">{{ title }}</h4>
            <ng-container [ngTemplateOutlet]="template"></ng-container>
            <div class="modal-close-icon" (click)="closeModal()">
                <mat-icon svgIcon="cancel-circle-white" class="icon"></mat-icon>
            </div>
        </div>
        <hr />
    </ng-template>
    <div class="modal-close-icon" (click)="closeModal()">
        <mat-icon svgIcon="cancel-circle-white" class="icon"></mat-icon>
    </div>
    <div class="d-flex mt-2 w-100">
        <ng-content></ng-content>
    </div>
</section>
<div *ngIf="swal" class="w-100">
    <div class="modal-close-icon" (click)="swal.onClose('close')">
        <mat-icon svgIcon="cancel-circle-white" class="icon"></mat-icon>
    </div>
    <div class="d-flex pt-3 pb-3 flex-column justify-content-between align-items-center gap-5">
        <h4 style="font-size: 1.2rem; font-weight: 900">{{ swal.title }}</h4>
        <p style="font-size: 14px; font-weight: 500; padding: 2%; text-align: center">
            {{ swal.text }}
        </p>
        <div class="d-flex gap-10">
            <button (click)="swal.onClose('close')" class="btn btn-danger">Close</button>

            <button (click)="swal.proceed()" class="btn btn-primary">Proceed</button>
        </div>
    </div>
</div>
