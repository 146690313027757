import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsageTrackingService } from './shared/services/tracking.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private translate: TranslateService,
        private usageTrackingService: UsageTrackingService
    ) {}

    ngOnInit(): void {
        // Load user's preferred language
        const bLang = window.localStorage.getItem('lang') || 'en';
        this.translate.use(bLang);
        this.translate.setDefaultLang(bLang);
        window.localStorage.setItem('lang', bLang);
    }
}
