import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { utils, writeFileXLSX } from 'xlsx';
@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.scss'],
})
export class ExportComponent implements OnInit {
    @Input()
    url!: string;

    @Input()
    options!: any;

    @Input()
    params: string = '';

    @Input()
    types: string[] = ['xlsx'];

    @Input()
    local: boolean = false;

    @Input()
    config!: any;

    @Input()
    justifyEnd: boolean = true;

    @Input()
    label: string = '';

    @Input()
    fileName: string = new Date().toDateString();

    @Input()
    data!: any[];

    @Input()
    useButton: boolean = false;

    @Input()
    usePost: boolean = false;

    @Input()
    body: any = {};
    exportTypes: any = {
        xlsx: {
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        pdf: {
            Accept: 'application/pdf',
        },
        csv: {
            Accept: 'text/csv',
        },
    };
    constructor(private http: HttpClient) {}

    ngOnInit(): void {}

    async download(type: string) {
        if (this.local) {
            this.downloadLocally();
        } else {
            this.downloadUpstream(type);
        }
    }

    async downloadUpstream(type: string) {
        const config = this.exportTypes[type];
        if (this.url.includes('?')) {
            this.params = this.params.replace('?', '&');
        }
        this.http
            .request(this.usePost ? 'post' : 'get', this.url + this.params, {
                body: this.body,
                responseType: 'blob' as 'json',
                observe: 'response',
                headers: {
                    Accept: config['Accept'],
                },
            })
            .subscribe(
                (result: any) => {
                    const blob = new Blob([result.body], { type: config['Accept'] });
                    const downloadUrl = window.URL.createObjectURL(result.body);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = `${this.fileName}_${new Date()}`;
                    link.target = '_blank';
                    link.click();
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    prepareData(data: any) {
        const labels = this.options?.config?.labels;
        return data.map((v: any) => {
            let myCol: any = {};
            for (let key in labels) {
                myCol[labels[key]] = v[key];
            }
            return myCol;
        });
    }

    async downloadLocally() {
        let data = JSON.parse(JSON.stringify(this.data));
        if (this.options?.config?.labels) {
            data = this.prepareData(data);
        }

        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        writeFileXLSX(wb, this.fileName + '.xlsx');
    }
}
