<!-- app-breadcrumb start -->
<div
    class="breadcrumb-wrapper d-flex"
    [ngClass]="{
        'justify-content-center': breadcrumb.alignment == 'center',
        'justify-content-end': breadcrumb.alignment == 'right',
    }"
>
    <p-breadcrumb *ngIf="!useOld" class="max-w-full no-background" [model]="items" [home]="home" />
    <ol
        *ngIf="useOld"
        class="breadcrumb"
        [ngClass]="{
            'mr-1': breadcrumb.alignment == 'right',
        }"
    >
        <li class="breadcrumb-item" *ngFor="let link of breadcrumb.links">
            <a *ngIf="link.isLink" routerLink="{{ link.link }}" [queryParams]="link.query">{{
                breadcrumb.useTitleCase ? (link.name | titlecase) : link.name
            }}</a>
            <span *ngIf="!link.isLink">{{
                breadcrumb.useTitleCase ? (link.name | titlecase) : link.name
            }}</span>
        </li>
    </ol>
</div>
<!-- app-breadcrumb end -->
