<section *ngIf="options?.length">
  <ng-container>
    <div class="filter-box">
      <form class="pl-3 pr-3 mb-3" [formGroup]="formInput" (ngSubmit)="filter()">
        <div class="d-flex justify-content-between">
          <div *ngFor="let formInput of options" class="mr-2 mb-2" [ngSwitch]="formInput.type"
            [ngStyle]="{ width: formInput.width }">
            <select *ngSwitchCase="'select'" class="form-control" [name]="formInput.name"
              [formControlName]="formInput.name">
              <option *ngFor="let option of formInput.options" [selected]="option.selected ?? null"
                [value]="option.value" [disabled]="option.disabled" [hidden]="option.hidden">
                {{ option.key }}
              </option>
            </select>

            <div *ngSwitchCase="'search'" class="filter-search">
              <img *ngIf="!search.value" class="search-icon" src="assets/icons/search.svg" alt="" />
              <input class="searchInput" [formControlName]="formInput.name" #search [name]="formInput.name" type="text"
                placeholder="Search" />
            </div>

            <input *ngSwitchCase="'date'" type="date" class="form-control" [formControlName]="formInput.name"
              placeholder="Date" />

            <input *ngSwitchCase="'text'" type="text" class="form-control" [formControlName]="formInput.name"
              [placeholder]="formInput.placeholder" />

            <div *ngSwitchCase="'compare'" [formGroupName]="formInput.name" class="compare d-flex">
              <select class="form-control" formControlName="operator" style="width: 40%" #operator>
                <option>></option>
                <option>>=</option>
                <option [value]="'<='">&lt;=</option>
                <option>&lt;</option>
                <option>=</option>
              </select>

              <input style="width: 60%" class="form-control" [name]="formInput.name" type="text"
                [formControlName]="'value'" [placeholder]="formInput.placeholder ?? ''" />
            </div>
            <p-calendar class="date-range" appendTo="body" *ngSwitchCase="'dateRange'" [formControlName]="formInput.name" [monthNavigator]="true"
              [yearNavigator]="true" yearRange="2000:2100" dateFormat="yy-mm-dd"
              [placeholder]="formInput.placeholder ?? ''" [showIcon]="true" selectionMode="range"
              [readonlyInput]="true"></p-calendar>
          </div>

          <div class="mb-2">
            <button type="submit" class="btn btn-disabled btn-slim mb-">Filter</button>
          </div>
          <div class="mb-2">
            <button (click)="onClear()" type="button" class="btn btn-default btn-slim ml-1">
              X
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</section>
