export function GetCheckboxesTruthyValues(checks: any, checkboxName: any) {
    const objectKeys: any[] = Object.keys(checks[checkboxName]);
    return objectKeys.filter((key) => {
        if (checks[checkboxName][key]) {
            return key;
        }
    });
}

export function QueryGenerator(values: any, position: string = 'pre'): string {
  let result = position === 'pre' ? '?' : '';

  const serialize = (key: string, value: any) => {
      if (typeof value === 'object' && value !== null) {
          return Object.keys(value)
              .map(subKey => `${key}[${subKey}]=${encodeURIComponent(value[subKey])}`)
              .join('&');
      }
      return `${key}=${encodeURIComponent(value)}`;
  };

  Object.keys(values).forEach((el, i) => {
      if (values[el]) {
          const serialized = serialize(el, values[el]);
          result += `${!result || i === 0 ? '' : '&'}${serialized}`;
      }
  });

  return result;
}
export function GenerateFileName(): string {
    return Date.now().toString();
}

export function GenerateDateList(
    min: number = new Date().getFullYear(),
    max?: number,
    count: number = 10
): number[] {
    max = max ? max : min + count;
    // console.log;
    let years = [];
    for (let i = min; i <= max; i++) {
        years.push(i);
    }
    return years;
}
