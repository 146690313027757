import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    ActivatedRoute,
} from '@angular/router';

import { AuthService } from './../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const tokenQuery = new URLSearchParams(window.location.search).get('eaid');
        const loggedIn = this.authenticationService.isLoggedIn();
        if (tokenQuery || loggedIn) {
            next;
            return true;
        }

        let url = '/login';
        if (window.location.pathname !== '/') {
            url = `/login?redirectTo=${window.location.pathname}`;
        }

        this.router.navigateByUrl(url);
        return false;
    }
}
