<modal-cover [title]="title">
    <div class="container shadow rounded p-3">
        <div class="w-100 d-flex flex-col">
            <div class="w-100 d-flex flex-row align-items-center justify-content-around">
                <button class="btn btn-pink" (click)="onRemove()">Yes Remove</button>

                <button class="btn btn-primary" (click)="dontRemove()">No, Don't remove</button>
            </div>
        </div>
    </div>
</modal-cover>
