// dynamic-component.directive.ts
import {
    Directive,
    Type,
    Input,
    OnInit,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentRef,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

export interface ComponentWrapper {
    component: Type<any>;
    inputs?: { [key: string]: any };
}

@Directive({
    selector: '[dynamicComponent]',
})
export class DynamicComponentDirective implements OnInit, OnChanges {
    @Input() dynamicComponent!: ComponentWrapper;

    private componentRef!: ComponentRef<any>;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnInit() {
        this.loadComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dynamicComponent && !changes.dynamicComponent.isFirstChange()) {
            this.loadComponent();
        }
    }

    private loadComponent() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            this.dynamicComponent.component
        );
        this.componentRef = this.viewContainerRef.createComponent(componentFactory);

        if (this.dynamicComponent.inputs) {
            for (const [key, value] of Object.entries(this.dynamicComponent.inputs)) {
                this.componentRef.instance[key] = value;
            }
        }

        this.componentRef.changeDetectorRef.detectChanges();
    }
}
