import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { AnalyticsInfo } from 'src/app/interfaces/general';
import { HealthService } from '../../../health.service';
import { IHealthPayment, IHealthPlans } from '../../pay-via-myxalary/pay-via-myxalary.data';

@Component({
    selector: 'health-purchase-details',
    templateUrl: 'purchase-details.component.html',
    styleUrls: ['purchase-details.component.scss'],
    providers: [CurrencyPipe],
})
export class HealthPurchaseDetailsComponent implements OnInit {
    pageSize = 10;
    showRenew = false;

    data!: IHealthPayment;
    analyticsData: AnalyticsInfo[] = [
        {
            title: '0',
            description: 'Total Employees',
            icon: 'user-plus',
            avatarBackground: 'bg-light-primary',
        },
        {
            title: '0',
            description: 'Total Amount',
            icon: 'dollar-sign',
            avatarBackground: 'bg-light-info',
        },
        {
            title: '0',
            description: 'Status',
            icon: 'compass',
            avatarBackground: 'bg-light-secondary',
        },
    ];

    constructor(
        private _router: Router,
        private currencyPipe: CurrencyPipe,
        private _healthService: HealthService
    ) {}

    ngOnInit() {
        this.analyticsData = [
            {
                title: this.data?.plans
                    ?.reduce((sum, cur) => (sum += cur.employees?.length || 0), 0)
                    .toString(),
                description: 'Total Employees',
                icon: 'user-plus',
                avatarBackground: 'bg-light-primary',
            },
            {
                title:
                    this.currencyPipe.transform(
                        this.data?.payments?.totalGross || 0,
                        'NGN',
                        'symbol-narrow'
                    ) || '0',
                description: 'Total Amount',
                icon: 'dollar-sign',
                avatarBackground: 'bg-light-info',
            },
            {
                title: this.data.status || '',
                description: 'Status',
                icon: 'compass',
                avatarBackground: 'bg-light-secondary',
            },
        ];
    }

    renew() {
        this._router.navigateByUrl('health/renew');
    }

    getCard(plan: IHealthPlans) {
        return [
            {
                title: plan.name,
                description: 'Plan Name',
                icon: 'user-plus',
                avatarBackground: 'bg-light-primary',
            },
            {
                title: this.currencyPipe.transform(plan.amount || 0, 'NGN', 'symbol-narrow') || '0',
                description: 'Amount',
                icon: 'dollar-sign',
                avatarBackground: 'bg-light-info',
            },
            {
                title:
                    this.currencyPipe.transform(
                        Number(plan.amount) *
                            plan.employees.length *
                            this._healthService.getMultiplier(plan.period),
                        'NGN',
                        'symbol-narrow'
                    ) || '0',
                description: 'Total Amount',
                icon: 'dollar-sign',
                avatarBackground: 'bg-light-info',
            },
            {
                title: plan.period || '',
                description: 'Period',
                icon: 'compass',
                avatarBackground: 'bg-light-secondary',
            },
            {
                title: dayjs(plan.startDate).format('MMM, YYYY'),
                description: 'Start Date',
                icon: 'compass',
                avatarBackground: 'bg-light-secondary',
            },
            {
                title: dayjs(plan.endDate).format('MMM, YYYY'),
                description: 'End Date',
                icon: 'compass',
                avatarBackground: 'bg-light-secondary',
            },
        ];
    }
}
