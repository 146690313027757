import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import appConstants from 'src/app/utils/constants';

@Component({
    selector: 'app-lang',
    templateUrl: './lang.component.html',
    styleUrl: './lang.component.scss',
})
export class LangComponent {
    flag = appConstants.features.globalization;
    constructor(private translate: TranslateService) {}

    switchLanguage(lang: any) {
        this.translate.use(lang);
        this.translate.setDefaultLang(lang);
        window.localStorage.setItem('lang', lang);
    }

    get lang() {
        return window.localStorage.getItem('lang') || 'en';
    }

    set lang(value: string) {
        window.localStorage.setItem('lang', value);
    }
}
