import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-swal',
    templateUrl: './swal.component.html',
    styleUrls: ['./swal.component.scss'],
})
export class SwalComponent implements OnInit {
    @Input() config!: any;
    @Output() action = new EventEmitter();
    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        if (this.config.html)
            this.config.html = this.sanitizer.bypassSecurityTrustHtml(this.config.html);
    }

    handleButton(title: string) {
        this.action.emit(title);
    }
}
