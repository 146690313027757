import { iTableData } from './../../models/interfaces';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { of } from 'rxjs';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
    @Input() tableData!: iTableData;
    colspan: number = 0;
    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        const {
            tableData: {
                currentValue: { body },
            },
        } = changes;
        if (this.tableData?.config?.total?.display && body.length) {
            const { column } = this.tableData?.config.total;
            const total = this.tableData.body.reduce((prev, current) => prev + current[column], 0);
            this.tableData.config.total.amount = total;
        }
    }

    ngOnInit(): void {
        if (this.tableData?.config?.total?.display) {
            this.colspan = Object.keys(this.tableData.header).length - 1;
        }
    }
}
