<div class="card">
    <div class="card-header">
        <span class="donut-title">
            {{ config.title }}
        </span>
    </div>
    <div class="card-body">
        <ng-container *ngIf="!loading; else loader">
            <div class="gap-10 d-flex w-100 align-items-end justify-content-end">
                <ng-select
                    [items]="years"
                    bindValue="value"
                    bindLabel="label"
                    [(ngModel)]="typeSelected"
                    (change)="onSelectType($event)"
                    [placeholder]="'Select Year'"
                    class="ng-input"
                    [clearable]="false"
                >
                </ng-select>
            </div>
            <div class="donut-chart-area hov">
                <div class="donut-chart">
                    <div
                        *ngIf="chartOptions"
                        style="margin-bottom: 50px; display: block; position: relative"
                    >
                        <app-charts [chartOptions]="chartOptions"></app-charts>
                    </div>
                </div>
                <div *ngIf="kpiData" class="metric">
                    <div *ngIf="kpiData?.up" class="d-flex top flex-column">
                        <span class="number">{{ kpiData.up.value }}</span>
                        <span class="number-desc">{{ kpiData?.up?.label }}</span>
                    </div>

                    <div *ngIf="kpiData?.down" class="d-flex top flex-column">
                        <span class="number">{{ kpiData?.down?.value }}</span>
                        <span class="number-desc">{{ kpiData?.down?.label }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #loader>
            <div class="d-flex justify-content-center w-100 align-items-center">
                <div class="table-spinner-wrapper">
                    <ng-lottie [options]="loaderOptions"></ng-lottie>
                </div>
            </div>
        </ng-template>
        <div class="more">
            {{ config.description }}
        </div>
    </div>
</div>
