import { Injectable } from '@angular/core';

type filterType = { [key: string]: any };

@Injectable({
    providedIn: 'any',
})
export class Chart {
    getGradients(gradients: string[]) {
        return function (context: any) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            let gradient, width, height;

            if (!chartArea) {
                // This case happens on initial chart load
                return;
            }
            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (!gradient || width !== chartWidth || height !== chartHeight) {
                // Create the gradient because this is either the first render
                // or the size of the chart has changed
                width = chartWidth;
                height = chartHeight;
                gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                for (let [index, item] of gradients.entries()) {
                    gradient.addColorStop(index, item);
                }
            }

            return gradient;
        };
    }

    getNegativeGradients(gradients: string[]) {
        return function (context: any) {
            const chart = context.chart;
            const { ctx, chartArea, scales } = chart;
            let gradient, width, height;

            if (!chartArea) {
                // This case happens on initial chart load
                return;
            }
            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (!gradient || width !== chartWidth || height !== chartHeight) {
                // Create the gradient because this is either the first render
                // or the size of the chart has changed
                width = chartWidth;
                height = chartHeight;
                const pointZero = scales.y.getPixelsForValue(0);
                const pointZeroHeight = pointZero - chartArea.top;
                const pointZeroPer = pointZeroHeight / chartHeight;
                gradient = ctx.createLinearGradient(
                    0,
                    chartArea.bottom,
                    0,
                    chartArea.top + chartHeight
                );
                gradient.addColorStop(pointZeroPer, gradients[0]);
                gradient.addColorStop(pointZeroPer, gradients[1]);
            }

            return gradient;
        };
    }
}
