import {
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationStart } from '@angular/router';

import { filter, tap } from 'rxjs/operators';

import { SIDEBAR } from 'src/app/interfaces/general';
import { environment } from 'src/environments/environment';
import { GeneralService } from 'src/app/shared/services/general.service';

const url = environment.myXalaryV1;
declare let $: any;

@Component({
    selector: 'app-sidebar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    authToken!: string;
    env = environment.env;
    activeRoute = '';
    v1Url = environment.myXalaryV1;
    v1DashboardUrl!: string;
    sidebarMenu: SIDEBAR[] = [];
    currentSlug = '';
    constructor(
        private router: Router,
        private genSrv: GeneralService,
        private elementRef: ElementRef,
        public domsaniter: DomSanitizer,
        private cRef: ChangeDetectorRef
    ) {
        this.authToken = this.genSrv.getAppToken();

        this.v1DashboardUrl = this.v1Url + `/dashboard?eaid=${this.authToken}`;
    }

    ngOnInit(): void {
        this.sidebarMenu = this.genSrv.sideBarMenu;
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: any) => {
                this.activeRoute = event.url;
                if (this.activeRoute.split('/').includes(this.currentSlug)) return;
                this.sidebarMenu = JSON.parse(JSON.stringify([...this.sidebarMenu]));
                this.cRef.detectChanges();
            });
    }

    //TO-REMOVE
    handleActiveSidebar(url: string) {
        const path = url.split('/')[1];
        this.sidebarMenu.forEach((menuItem) => {
            if (menuItem.isExternalLink) return;
            const key = `#${menuItem.subMenu?.length ? menuItem.slug : menuItem.id}`;
            let parentAnchorTag = document.querySelector(key)?.parentElement?.firstElementChild
                ?.firstElementChild as HTMLAnchorElement;
            if (parentAnchorTag) {
                parentAnchorTag.classList.remove('activeDash');
            }
        });

        let element: HTMLElement = document.querySelector(`#${path}`) as HTMLElement;
        const parentAnchorTag = element?.parentElement?.firstElementChild
            ?.firstElementChild as HTMLAnchorElement;

        if (parentAnchorTag) {
            parentAnchorTag.classList.add('activeDash');
        }
    }

    ngAfterViewInit() {
        this.appendScript('/assets/template-files/js/off-canvas.js');
        this.appendScript('/assets/template-files/js/template.js');
    }

    appendScript(link: string) {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = link || 'src/assets/template-files/js/dashboard.js';
        this.elementRef.nativeElement.appendChild(s);
    }

    getActiveMenu({ slug, index, super: parent }: any) {
        if (!this.activeRoute) {
            this.activeRoute = window.location.pathname;
        }
        const route = this.genSrv.getActiveDash({
            route: this.activeRoute,
            slug,
            index,
            super: parent,
        });
        if (route && !parent) {
            this.currentSlug = slug;
        }
        return route;
    }

    toggler() {
        var body = $('body');
        $('[data-toggle="minimize"]').on('click', function () {
            if (body.hasClass('sidebar-toggle-display') || body.hasClass('sidebar-absolute')) {
                body.toggleClass('sidebar-hidden');
            } else {
                body.toggleClass('sidebar-icon-only');
            }
        });
    }
}
