import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
})
export class ConfirmActionComponent implements OnInit {
  @Output() status = new EventEmitter<boolean>();
  @Input() title!: string;
  modalSub!: Subscription;

  constructor(private _activeModal: NgbActiveModal,) { }

  ngOnInit(): void { }

  dontRemove() {
    this._activeModal.dismiss();
  }

  onRemove() {
    this.status.emit(true);
    this._activeModal.dismiss();
  }
}
