import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-cover',
    templateUrl: './modal-cover.component.html',
    styleUrls: ['./modal-cover.component.scss', '../container.scss'],
})
export class ModalCoverComponent implements OnInit {
    @Input() template!: TemplateRef<any>;
    @Input() title!: string;
    constructor(private _active: NgbActiveModal) {}

    ngOnInit(): void {}

    closeModal() {
        this._active.dismiss();
    }
}
