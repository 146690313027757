import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'myxalary-competency-template',
    template: `<div class="main-card">
        <div class="inner-main-card" *ngFor="let skill of competencies; let i = index">
            <div class="competency-main-card">
                <div class="competency-card">
                    <div class="competency-title " [ngbTooltip]="skill.name">
                        <span class="truncate-text text-capitalize">{{ skill.name }} </span>
                    </div>
                </div>
            </div>
            <div class="rating-card">
                <div class="single-rating-card">
                    <div
                        [ngStyle]="
                            skill.rating == 1
                                ? { background: '#C00B0B' }
                                : { background: '#DACFE5' }
                        "
                        class="single-rating-card-cover"
                    ></div>
                    <div
                        [ngStyle]="skill.rating == 1 ? { color: '#ffffff' } : { color: '#5E5873' }"
                        class="single-rating-card-text"
                    >
                        1
                    </div>
                </div>
                <div class="single-rating-card">
                    <div
                        [ngStyle]="
                            skill.rating == 2
                                ? { background: '#D62196' }
                                : { background: '#DACFE5' }
                        "
                        class="single-rating-card-cover"
                    ></div>
                    <div
                        [ngStyle]="skill.rating == 2 ? { color: '#ffffff' } : { color: '#5E5873' }"
                        class="single-rating-card-text"
                    >
                        2
                    </div>
                </div>
                <div class="single-rating-card">
                    <div
                        [ngStyle]="
                            skill.rating == 3
                                ? { background: '#19335D' }
                                : { background: '#DACFE5' }
                        "
                        class="single-rating-card-cover"
                    ></div>
                    <div
                        [ngStyle]="skill.rating == 3 ? { color: '#ffffff' } : { color: '#5E5873' }"
                        class="single-rating-card-text"
                    >
                        3
                    </div>
                </div>
                <div class="single-rating-card">
                    <div
                        [ngStyle]="
                            skill.rating == 4
                                ? { background: '#499ED7' }
                                : { background: '#DACFE5' }
                        "
                        class="single-rating-card-cover"
                    ></div>
                    <div
                        [ngStyle]="skill.rating == 4 ? { color: '#ffffff' } : { color: '#5E5873' }"
                        class="single-rating-card-text"
                    >
                        4
                    </div>
                </div>
                <div class="single-rating-card">
                    <div
                        [ngStyle]="
                            skill.rating == 5
                                ? { background: '#219537' }
                                : { background: '#DACFE5' }
                        "
                        class="single-rating-card-cover"
                    ></div>
                    <div
                        [ngStyle]="skill.rating == 5 ? { color: '#ffffff' } : { color: '#5E5873' }"
                        class="single-rating-card-text"
                    >
                        5
                    </div>
                </div>
            </div>
            <div
                class="competency-description"
                [ngbTooltip]="
                    skill.description ? skill.description : 'No description for this competency'
                "
            >
                <div class="description-card">
                    <span class="truncate-description">
                        {{
                            skill.description
                                ? skill.description
                                : 'No description for this competency'
                        }}
                    </span>
                </div>
                <div
                    style=" position: relative"
                    class="avatar ml-4 bg-light-danger"
                    (click)="deleteCompetency(i)"
                >
                    <i data-feather="trash" class="avatar-icon"></i>
                </div>
            </div>
        </div>
    </div> `,
    styleUrls: ['./competency-template.component.scss'],
})
export class CompetencyTemplateComponent implements OnInit {
    @Input() competencies: any;
    @Output() removeCompetency = new EventEmitter<number>();

    constructor() {}

    ngOnInit(): void {}

    deleteCompetency(data: number) {
        this.removeCompetency.emit(data);
    }
}
