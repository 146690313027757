import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-button-link',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonLinkComponent implements OnInit {
    @Input() link?: string;
    @Input() label!: string;
    @Input() color!: string;
    @Input() position?: string;
    @Input() isDisabled?: boolean;

    @Output() buttonClicked: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    onButtonClicked() {
        this.buttonClicked.emit(true);
    }
}
