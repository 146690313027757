import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatStyleModule } from 'src/app/mat-style.module';

@NgModule({
    declarations: [SnackbarComponent],
    providers: [
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 10000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: 'default-snackbar',
            },
        },
    ],
    imports: [CommonModule, MatStyleModule],
    exports: [SnackbarComponent],
})
export class SnackbarModule {}
