import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/shared/services/general.service';

@Component({
    selector: 'app-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
    token: any;
    successMsg: string = '';

    constructor(
        private authService: AuthService,
        private gs: GeneralService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            var token = params.get('token');
            this.token = token;
        });
        this.confirm(this.token);
    }

    confirm(token: any) {
        this.authService.confirmation(token).subscribe(
            (data) => {
                if (data.responseCode == 200) {
                    this.successMsg = data.message;
                } else {
                    this.successMsg = data.message;
                }
            },
            (err) => {
                this.gs.alertInfo.next({
                    text: err.message,
                    btnClass: 'alert-danger',
                    btnIcon: 'error',
                    timer: 5000,
                });
            }
        );
    }
}
