import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { interval, Subject, takeUntil } from 'rxjs';
import endpoints from 'src/app/utils/endpoints';

@Injectable({
    providedIn: 'root',
})
export class UsageTrackingService {
    private startTime: number = 0;
    private currentPage: string = '';
    private module: string = '';
    private destroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.trackNavigation();

        // Send data every 5 minutes (300,000 ms)
        interval(100000)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.sendUsageData();
            });

        // Ensure data is sent when user leaves the app
        window.addEventListener('beforeunload', () => {
            this.sendUsageData();
        });
    }

    private trackNavigation(): void {
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                this.saveUsageData(); // Save previous page data before switching
                let module = event.url.split('/')[1];
                module = module.split('?')[0];
                if (['login', 'error', 'not-available', ''].includes(module)) {
                    this.startTime = 0;
                    this.currentPage = '';
                    this.module = '';
                    return;
                }
                this.startTime = Date.now();
                this.currentPage = event.url;
                this.module = module;
            } else if (event instanceof NavigationEnd) {
                this.startTime = Date.now();
            }
        });
    }

    private saveUsageData(): void {
        if (this.currentPage && this.startTime) {
            const duration = Date.now() - this.startTime;
            if (duration > 0) {
                const usageData = this.getUsageData();
                usageData.push({
                    module: this.module,
                    page: this.currentPage,
                    duration: duration / 1000,
                    timestamp: new Date(),
                });
                this.setUsageData(usageData);
            }
        }
    }

    private getUsageData(): any[] {
        return JSON.parse(localStorage.getItem('usageTracking') || '[]');
    }

    private setUsageData(data: any[]): void {
        localStorage.setItem('usageTracking', JSON.stringify(data));
    }

    private sendUsageData(): void {
        const usageData = this.getUsageData();
        if (usageData.length > 0) {
            this.http
                .post(
                    endpoints.MX_BE.V1.commandCenter.track,
                    { usageData },
                    {
                        headers: {
                            nospinner: 'true',
                            noerroralert: 'true',
                        },
                    }
                )
                .subscribe(() => {
                    localStorage.removeItem('usageTracking'); // Clear after sending
                });
        }
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.sendUsageData(); // Send any remaining data
    }
}
