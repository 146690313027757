<div class="row">
    <div class="col-md-12 p-3">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <div class="d-flex justify-content-between w-100">
            <h2>Pending Workflow Requests</h2>
        </div>
    </div>
    <div class="w-100 card-body p-4">
        <app-data-table *ngIf="tableConfig; else noData" [options]="tableConfig"></app-data-table>

        <ng-template #noData>
            <app-empty-data-view class="mx-auto"></app-empty-data-view>
        </ng-template>

        <ng-template let-data="data" #viewRequestTmpl>
            <button class="btn btn-primary btn-sm" (click)="reviewWorkflow(data)">
                View Request
            </button>
        </ng-template>

        <ng-template let-data="data" #viewRequestType>
            <span> {{ retrieveWorkflowTitle(data.type) }} </span>
        </ng-template>
    </div>
</div>
