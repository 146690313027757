import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-e-dropdown',
    templateUrl: './e-dropdown.component.html',
    styleUrls: ['./e-dropdown.component.scss', '../../../../formStyle.scss'],
})
export class EDropdownComponent implements OnInit {
    @Output()
    onSelect = new EventEmitter();

    @Input()
    items: any = [];

    myControl: UntypedFormControl = new UntypedFormControl('');

    constructor() {}

    ngOnInit(): void {}

    onSelectShared() {}
}
