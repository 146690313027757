<section class="card rounded mt-3 p-4 border-0">
    <div class="hmo-header d-flex flex-row justify-content-between align-items-center w-100 mb-2">
        <h4 class="title">Expiring Plans</h4>
        <a routerLink="../purchase-history" class="text text-primary">View all</a>
    </div>

    <div>
        <!-- <app-table [tableData]="expiringPlans"></app-table> -->
        <table class="table-bordered w-100" mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="#">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.index }}
                </td>
            </ng-container>

            <!--  Job Title Column -->
            <ng-container matColumnDef="providerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>HMO Provider</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.providerName | titlecase }}
                </td>
            </ng-container>

            <!-- Location Column -->
            <ng-container matColumnDef="cost">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.amount | currency: '₦' }}
                </td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Date</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.createdAt | date: 'dd/MM/yyyy' }}
                </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let row">
                    <span class="status-badge">
                        {{ row.status | titlecase }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell *matCellDef="let row">
                    <div class="d-flex">
                        <div class="ml-2" *ngFor="let action of tableActions">
                            <app-button-link
                                *ngIf="showRenew(row, action)"
                                class="col-sm-6"
                                [label]="action.title | titlecase"
                                [color]="'primary'"
                                (buttonClicked)="action.clickHandler(row)"
                                [isDisabled]="false"
                            ></app-button-link>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="mt-4 mb-3 float-right">
            <app-paginator
                [paginatorSetup]="paginatorSetup"
                (paginateEvent)="handlePaginateEvent($event)"
            ></app-paginator>
        </div>
    </div>
</section>
