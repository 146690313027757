<div *ngIf="(hmoProviders$ | async)?.length">
    <div class="mt-3 p-4 card border-0 rounded">
        <div class="hmo-header d-flex flex-row justify-content-between align-items-center w-100">
            <h4 class="title">Health Management Organizations (HMO)</h4>
            <a routerLink="../all-hmo-providers" class="text text-primary">View all</a>
        </div>
        <div class="hmo-wrapper">
            <ng-container *ngFor="let hmo of hmoProviders$ | async">
                <app-hmo-provider [hmo]="hmo"></app-hmo-provider>
            </ng-container>
        </div>
    </div>
</div>
