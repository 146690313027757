<div
    [ngClass]="justifyEnd ? 'justify-content-end' : 'justify-content-start'"
    class="d-flex gap-10 p-1"
>
    @if (useButton) {
        <button
            (click)="download(type)"
            [matTooltip]="fileName"
            *ngFor="let type of types"
            class="btn btn-primary-outline"
        >
            <mat-icon [svgIcon]="type" class="export-icon-button"></mat-icon> {{ label }}
        </button>
    } @else {
        <div
            (click)="download(type)"
            [matTooltip]="fileName"
            class="d-flex clickable gap-5"
            *ngFor="let type of types"
        >
            <mat-icon [svgIcon]="type" class="export-icon"></mat-icon>
            <span *ngIf="label">{{ label }}</span>
        </div>
    }
</div>
