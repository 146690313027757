import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/shared/services/general.service';
import endpoints from 'src/app/utils/endpoints';
import { ApiService } from '../../services/api.service';
import { AppStore } from 'src/app/app-data';
import { take } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import $ from 'jquery';
@Component({
    selector: 'app-top-up',
    templateUrl: './top-up.component.html',
    styleUrls: ['./top-up.component.scss'],
})
export class TopUpComponent implements OnInit, AfterViewInit {
    @ViewChild('paymentModal', { static: true }) paymentModal!: TemplateRef<any>;
    @ViewChild('optionsModal', { static: true }) optionsModal!: TemplateRef<any>;
    formData: UntypedFormGroup = new UntypedFormGroup({
        amount: new UntypedFormControl('', [Validators.required]),
    });
    virtualAccounts: any[] = [];
    user: any;
    payModal!: NgbModalRef;
    paymentData: any;
    constructor(
        private gs: GeneralService,
        private api: ApiService,
        private appStore: AppStore,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.getVirtualAccounts();
    }

    ngAfterViewInit(): void {}
    openPreviewPage() {
        this.payModal = this.gs.preModal(this.optionsModal, {
            size: 'sm',
            windowClass: 'option-modal',
        });
    }

    openPaymentPage() {
        this.payModal = this.gs.preModal(this.paymentModal, {});
        this.payModal.result.finally(async () => {
            const result = await this.api.createBilling('card', {
                reference: this.paymentData.reference,
            });
            this.gs.alertInfo.next({
                text: 'Top up Successful',
                btnClass: 'alert-success',
                btnIcon: 'checklist',
                timer: 3000,
            });
        });
        $('#test').attr('src', this.paymentData.link);
    }

    closePaymentOption() {
        this.payModal.close();
    }

    async getVirtualAccounts() {
        this.user = await this.appStore.getUserInfo().pipe(take(1)).toPromise();
        const result: any = await this.api.getVirtualAccounts();
        this.virtualAccounts = result;
    }
    async initiateTransaction() {
        const result: any = await this.api.initiateTransaction({
            type: 'card',
            amount: this.formData.value.amount,
        });
        this.paymentData = result.response;
        this.openPaymentPage();
    }
}
