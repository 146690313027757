import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableConfig } from '../../../../interfaces/general';
import { GeneralService } from 'src/app/shared/services/general.service';
import { ApiService } from '../../services/api.service';

@Component({
    selector: 'app-view-beneficiaries',
    templateUrl: './view-beneficiaries.component.html',
    styleUrls: ['./view-beneficiaries.component.scss'],
})
export class ViewBeneficiariesComponent implements OnInit {
    @ViewChild('amount', { static: true }) amount!: TemplateRef<any>;
    @ViewChild('description', { static: true }) description!: TemplateRef<any>;
    @ViewChild('createdOn', { static: true }) createdOn!: TemplateRef<any>;
    @ViewChild('action', { static: true }) action!: TemplateRef<any>;
    config!: DataTableConfig;

    @Input()
    recipientCode: string = '';

    @Input()
    accountName: string = '';
    data: any[] = [];
    totalTransaction = 0;
    totalAmount = 0;

    constructor(
        private gs: GeneralService,
        private api: ApiService
    ) {}

    ngOnInit(): void {
        this.init();
    }

    async init() {
        const data: any = await this.api.getBeneficiaryTransactions(this.recipientCode);
        const txns = data.response;
        this.data = txns;
        this.totalTransaction = this.data.length;
        this.totalAmount = this.data.reduce((prev, curr) => {
            if (!/[0-9.]+/.test(curr.amount)) return prev;
            return Number(curr.amount) + prev;
        }, 0);
    }
    ngAfterViewInit(): void {
        this.config = {
            useLocalData: true,
            url: '',
            searchOptions: [
                {
                    name: 'limit',
                    width: '20%',
                    type: 'select',
                    options: this.gs.defaultPageOptions,
                },
                {
                    name: 'amount',
                    width: '60%',
                    type: 'search',
                    placeholder: 'Search Cycle Name',
                },
                {
                    name: 'created_date',
                    width: '20%',
                    type: 'date',
                },
            ],
            columns: [
                {
                    key: 'amount',
                    label: 'Amount',
                    template: this.amount,
                },
                {
                    key: 'description',
                    label: 'Description',
                    template: this.description,
                },
                {
                    key: 'created_date',
                    label: 'Created On',
                    template: this.createdOn,
                },
            ],
        };
    }
}
