import { Injectable } from '@angular/core';
import { GeneralService } from '../../../shared/services/general.service';
import { TopUpComponent } from '../components/top-up/top-up.component';
import { TransferComponent } from '../components/transfer/transfer.component';
import { BenefitsComponent } from '../components/benefits/benefits.component';
import { ReportTransactionsComponent } from '../components/report-transactions/report-transactions.component';
import { ViewTransactionsComponent } from '../components/view-transactions/view-transactions.component';
import { ViewBeneficiariesComponent } from '../components/view-beneficiaries/view-beneficiaries.component';
import { SuccessComponent } from '../../learning/containers/success/success.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    showFlashNews = new BehaviorSubject<any[]>([]);
    beneficiaryTable = new Subject();
    balance = new BehaviorSubject(0);
    dashcards = new BehaviorSubject<any[]>([]);
    transactions$ = new BehaviorSubject<any[]>([]);
    transferTable = new Subject();
    totalBeneficiciaries$ = new BehaviorSubject<number>(0);
    wallets$ = new BehaviorSubject<any[]>([]);
    public showNeedHelpModal = new BehaviorSubject<boolean>(false);
    public user$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public userObserver$ = this.user$.asObservable();
    public utils$: BehaviorSubject<any> = new BehaviorSubject<any>({
        countries: [],
        timezones: [],
        pfas: [],
    });
    public preloadedData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    data$: BehaviorSubject<any> = new BehaviorSubject({ setups: [], done: 0, allCompleted: false });
    allCompleted: boolean = false;
    teams: any;

    constructor(
        private gs: GeneralService,
        private authService: AuthService,
        private http: HttpClient
    ) {}

    async init(user: any) {
        if (this.allCompleted) {
            return;
        }
        const data: { setups: any[]; done: number; allCompleted: boolean } = {
            setups: [],
            done: 0,
            allCompleted: false,
        };
        data.done = 0;

        this.http
            .get<any>(`${environment.myXalaryEndpoint}/v1/company/getSetups`)
            .toPromise()
            .then((res) => {
                const setups = res.response[0]?.setups || [];
                data.setups = setups;
                data.done = setups.filter((setup: { done: any }) => setup.done).length;
                data.allCompleted = data.done === setups.length;
            });

        this.data$.next(data);
    }

    openTopUp() {
        this.gs.preModal(TopUpComponent);
    }

    openTransfer() {
        this.gs.preModal(TransferComponent);
    }
    openReportTransaction(data: any) {
        const instance = this.gs.preModal(ReportTransactionsComponent);
        instance.componentInstance.data = data;
    }
    openViewTransaction(data: any) {
        const instance = this.gs.preModal(ViewTransactionsComponent);
        instance.componentInstance.data = data;
    }
    addBenefit() {
        this.gs.preModal(BenefitsComponent);
    }

    openViewBeneficiary(recipientCode: string, accountName: string) {
        const instance = this.gs.preModal(ViewBeneficiariesComponent).componentInstance;
        instance.recipientCode = recipientCode;
        instance.accountName = accountName;
    }

    openSuccessView(text = 'Beneficiary Added!') {
        const instance = this.gs.preModal(SuccessComponent);
        instance.componentInstance.config = {
            title: text,
            btns: [
                {
                    title: 'Ok',
                    btnStyle: 'btn btn-primary',
                },
            ],
            svgIcon: 'success',
        };
        instance.componentInstance.action.subscribe(() => {
            instance.dismiss();
        });
    }
    openWarning({ title = 'Warning!!!', text }: any) {
        const instance = this.gs.preModal(SuccessComponent);
        instance.componentInstance.config = {
            title: title,
            text: text,
            btns: [
                {
                    title: 'Ok',
                    btnStyle: 'btn btn-primary',
                },
                {
                    title: 'Close',
                    btnStyle: 'btn btn-default',
                },
            ],
            svgIcon: 'warning',
        };
        return instance;
    }
}
