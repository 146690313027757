import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableConfig, filterValue } from 'src/app/interfaces/general';
import { environment } from 'src/environments/environment';
import { DMSResponseTypes } from './testData';

@Injectable({
    providedIn: 'root',
})
export class DmsService {
    dmstableUpdate = new Subject();
    defaultPageOptions = [
        {
            key: '10',
            value: '10',
            selected: true,
        },
        {
            key: '20',
            value: '20',
        },
        {
            key: '50',
            value: '50',
        },
        {
            key: '100',
            value: '100',
        },
    ];

    constructor(private http: HttpClient) {}

    async getDMSTableConfig(): Promise<DataTableConfig> {
        return <DataTableConfig>{
            searchOptions: [
                {
                    name: 'limit',
                    width: '15%',
                    type: 'select',
                    options: this.defaultPageOptions,
                },
                //   {
                //     name: "Recent",
                //     width: "15%",
                //     type: "select",
                //     options: this.defaultPageOptions
                // },
                {
                    name: 'search',
                    width: '40%',
                    type: 'search',
                    transform: (value: any): filterValue => {
                        const cols = ['name', 'documentType', 'category'].join('?');

                        return {
                            operator: '=',
                            value: `${cols}^${value}~`,
                        };
                    },
                },
                {
                    name: 'By Document Type',
                    width: '20%',
                    type: 'select',
                    options: [
                        {
                            key: 'By Type',
                            value: '',
                            selected: true,
                        },
                        {
                            key: 'Organization Document',
                            value: 'organization-document',
                        },
                        {
                            key: 'Employee Document',
                            value: 'employees-document',
                        },
                    ],
                },
                {
                    name: 'Category',
                    width: '20%',
                    type: 'select',
                    options: [
                        {
                            key: 'By Category',
                            value: '',
                            selected: true,
                        },
                    ],
                },
            ],
            columns: [
                {
                    label: 'Name',
                    key: 'name',
                },
                {
                    label: 'Document Type',
                    key: 'documentType',
                    noSort: true,
                },
                {
                    label: 'Category',
                    key: 'category',
                },
                {
                    label: 'Date Added',
                    key: 'dateAdded',
                    type: 'date',
                },
                {
                    label: 'Last Updated',
                    key: 'lastUpdated',
                    type: 'date',
                },
            ],
            // url: `${environment.learningEndPoint}/training/list`
            url: './testData',
        };
    }

    async getDMSDocType(): Promise<DataTableConfig> {
        return <DataTableConfig>{
            searchOptions: [
                {
                    name: 'limit',
                    width: '10%',
                    type: 'select',
                    options: this.defaultPageOptions,
                },
                //   {
                //     name: "Recent",
                //     width: "15%",
                //     type: "select",
                //     options: this.defaultPageOptions
                // },
                {
                    name: 'search',
                    width: '80%',
                    type: 'search',
                    transform: (value: any): filterValue => {
                        const cols = ['name', 'category', 'subcategory'].join('?');

                        return {
                            operator: '=',
                            value: `${cols}^${value}~`,
                        };
                    },
                },
            ],
            columns: [
                {
                    label: 'Name',
                    key: 'name',
                },
                {
                    label: 'Category',
                    key: 'category',
                    noSort: true,
                },
                {
                    label: 'Sub Category',
                    key: 'subcategory',
                    // getValue: (data:any) => {
                    //     return data?.subcategory?.name
                    // },
                    // template: subCat
                },
                {
                    label: 'Date Created',
                    key: 'createdAt',
                    type: 'date',
                },
            ],
            url: `${environment.dmsEndpoint}docs/doc-type/all`,
        };
    }

    deleteSingleDocType(id: any) {
        return this.http.delete(`${environment.dmsEndpoint}docs/doc-type/${id}`);
    }

    createDocType(data: any) {
        return this.http.post(`${environment.dmsEndpoint}docs/doc-type/new`, data);
    }

    updateDocType(data: any, id: any) {
        return this.http.put(`${environment.dmsEndpoint}docs/doc-type/${id}`, data);
    }

    renameDocument(data: any, id: any) {
        return this.http.put(`${environment.dmsEndpoint}docs/rename/${id}`, data);
    }

    docTypeForDropDown() {
        return this.http.get(`${environment.dmsEndpoint}docs/doc-type/dropdown`);
    }

    getCategory(name: string) {
        let params = new HttpParams();
        params = params.set('name', name);
        return this.http.get(`${environment.dmsEndpoint}docs/doc-type/category`, { params });
    }

    getSubCategory(type: string, cat: string) {
        let params = new HttpParams();
        params = params.set('docType', type);
        params = params.set('category', cat);

        return this.http.get(`${environment.dmsEndpoint}docs/doc-type/subcategory`, { params });
    }

    uploadFile(data: any) {
        return this.http.post<any>(`${environment.dmsEndpoint}files`, data);
    }

    createDocument(data: any) {
        return this.http.post(`${environment.dmsEndpoint}docs/`, data);
    }

    recentDoc() {
        return this.http.get(`${environment.dmsEndpoint}docs/recentDoc`);
    }

    getDocumentForDownloadEmployee(id: any) {
        return this.http.get(`${environment.dmsEndpoint}docs/recentDoc/${id}`);
    }

    getDocumentForDownloadOrg() {
        return this.http.get(`${environment.dmsEndpoint}docs/recentOrgDoc`);
    }

    deleteSingleDoc(id: string) {
        return this.http.delete(`${environment.dmsEndpoint}docs/${id}`);
    }

    getSingleDoc(id: string) {
        return this.http.get(`${environment.dmsEndpoint}docs/${id}`);
    }

    updateDoc(data: any, id: any) {
        return this.http.put(`${environment.dmsEndpoint}docs/${id}`, data);
    }

    analytics() {
        return this.http.get(`${environment.dmsEndpoint}docs/analytics`);
    }
}
