<app-auth-wrapper [listener]="listenerApp" [permission]="VIEW_BUY_LICENSE">
    <ng-template>
        <div class="w-100">
            <div class="row mb-4">
                <div class="col-12 mb-3 d-flex justify-content-center align-items-center">
                    <p class="mr-3 h6">Monthly</p>
                    <label class="switch">
                        <input type="checkbox" (change)="toggleAnnualPayment()" />
                        <span class="slider round"></span>
                    </label>
                    <p class="ml-3 h6">Annually</p>
                </div>
                <div class="col-12">
                    <button class="btn btn-primary float-right" (click)="reviewCart()">
                        <span class="mr-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-shopping-cart"
                            >
                                <circle cx="9" cy="21" r="1" />
                                <circle cx="20" cy="21" r="1" />
                                <path
                                    d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                                />
                            </svg>
                        </span>
                        View Cart and Checkout
                        <span
                            class="badge badge-pill badge-danger ml-2 text-small"
                            *ngIf="cartItemsCount > 0"
                            >{{ cartItemsCount }}</span
                        >
                    </button>
                </div>
            </div>

            <div class="lgrid">
                <div class="col px-md-2 align-top" *ngFor="let license of licenses; let i = index">
                    <div class="px-3 py-4 border align-top d-flex justify-content-center">
                        <div class="row">
                            <div class="col col-12 text-center">
                                <div class="h6">{{ license.name }}</div>
                                <div class="mb-4">(Per Active Employee)</div>
                                <div
                                    *ngIf="license.name === 'HR Essentials'; else otherPlans"
                                    class="row mb-4 w-1oo text-primary"
                                >
                                    <div class="col col-6 text-small border-right border-primary">
                                        <div class="mb-2 h1">Free</div>
                                        Monthly with Payroll
                                    </div>
                                    <div class="col col-6 text-small">
                                        <div
                                            *ngIf="nairaCurrency; else usdCurrency"
                                            class="mb-2 h1"
                                        >
                                            ₦{{ license.price[paymentPeriod].NGN }}
                                        </div>
                                        <ng-template #usdCurrency>
                                            <div class="mb-2 h1">
                                                ${{ license.price[paymentPeriod].USD }}
                                            </div>
                                        </ng-template>
                                        Monthly without Payroll
                                    </div>
                                </div>
                                <ng-template #otherPlans>
                                    <div class="mb-4 w-1oo text-primary">
                                        <div
                                            *ngIf="nairaCurrency; else usdCurrency"
                                            class="mb-2 h1"
                                        >
                                            ₦{{ license.price[paymentPeriod].NGN }}
                                        </div>
                                        <ng-template #usdCurrency>
                                            <div class="mb-2 h1">
                                                ${{ license.price[paymentPeriod].USD }}
                                            </div>
                                        </ng-template>
                                        {{ annualPayment ? 'Annually' : 'Monthly' }}
                                    </div>
                                </ng-template>
                                <div class="mb-4 w-100">
                                    <!-- <div class="input-group"> -->
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="num-employees"
                                        #employeeCount
                                        min="{{ license.employees.minimum || 1 }}"
                                        oninput="this.value|=0"
                                        [attr.max]="
                                            license.employees.maximum
                                                ? license.employees.maximum
                                                : null
                                        "
                                    />
                                    <br />
                                    <!-- <div class="input-group-append"> -->
                                    <button
                                        id="add-to-cart-btn"
                                        style="
                                            padding-top: 14px !important;
                                            padding-bottom: 14px !important;
                                        "
                                        class="btn btn-primary btn-lg"
                                        (click)="addLicenseToCart(i, employeeCount.value)"
                                    >
                                        <span class="mr-2">
                                            <svg
                                                id="plus-icon"
                                                width="20"
                                                height="21"
                                                viewBox="0 0 20 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    id="plus-icon-path"
                                                    d="M10 4.66669V16.3334"
                                                    stroke="white"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    id="plus-icon-path"
                                                    d="M4.16666 10.5H15.8333"
                                                    stroke="white"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        Add to Cart
                                    </button>
                                    <!-- </div>
                  </div> -->
                                    <br />
                                    <small>
                                        Minimum Employees:
                                        {{ license.employees.minimum || 1 }}
                                    </small>
                                    <br />
                                    <small>
                                        Maximum Employees:
                                        {{
                                            license.employees.maximum
                                                ? license.employees.maximum
                                                : 'Not applicable'
                                        }}
                                    </small>
                                    <br />
                                    <small>
                                        Is Addon:
                                        {{ license.isAddon || 'false' }}
                                    </small>
                                </div>
                                <p>Includes:</p>
                            </div>
                            <div class="col col-12 text-left">
                                <ul class="p-0 m-0">
                                    <li
                                        *ngFor="let module of handleLicensesFilter(license.modules)"
                                        class="py-2 d-flex align-content-center border-bottom"
                                    >
                                        <img
                                            src="../../../../../assets/icons/license/check-circle.svg"
                                            alt=""
                                        />
                                        <span class="ml-2">{{ module }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-auth-wrapper>
