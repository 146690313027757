<modal-cover [title]="title | appTranslate">
    <div class="container shadow rounded p-3">
        <div class="w-100 d-flex flex-col">
            <div class="w-100 d-flex flex-row align-items-center justify-content-around">
                <button class="btn btn-pink" (click)="onRemove()">
                    {{ 'Yes Remove' | appTranslate }}
                </button>

                <button class="btn btn-primary" (click)="dontRemove()">
                    {{'No, Don't remove' | appTranslate}}
                </button>
            </div>
        </div>
    </div>
</modal-cover>
