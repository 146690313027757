import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApprovalWorkflowService {
    url: string = `${environment.myXalaryEndpoint}/v1/approval-workflow`;

    constructor(private http: HttpClient) {}

    getRoles = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-roles`);
    };

    getEmployees = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-employees`);
    };

    getMyWorkflow = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/my/pending`);
    };

    getApprovalWorkflow = (workflowType: string): Observable<any> => {
        return this.http.get<any>(`${this.url}/workflow/${workflowType}`);
    };

    viewWorkflowPendingEmployees = (workflowTypeId: any): Observable<any> => {
        return this.http.get<any>(`${this.url}/view-workflow/${workflowTypeId}`);
    };

    saveApprovalWorkflow = (workflow: any): Observable<any> => {
        return this.http.post<any>(`${this.url}/workflow`, workflow);
    };

    updateApprovalWorkflow = (workflowId: string, workflowProcess: any): Observable<any> => {
        return this.http.put<any>(`${this.url}/workflow/${workflowId}`, workflowProcess);
    };

    getModelApprovalWorkflow = (workflowTypeAndId: string): Observable<any> => {
        return this.http.get<any>(`${this.url}/${workflowTypeAndId}`);
    };

    getModelApprovalWorkflowV2 = (workflowTypeAndId: string): Observable<any> => {
        return this.http.get<any>(`${this.url}/${workflowTypeAndId}`);
    };

    updateModelApprovalWorkflow = (
        workflowTypeAndId: string,
        workflowApprovalData: any
    ): Observable<any> => {
        return this.http.put<any>(`${this.url}/${workflowTypeAndId}`, workflowApprovalData);
    };
    updateModelApprovalWorkflowV2 = (
        workflowTypeAndId: string,
        workflowApprovalData: any
    ): Observable<any> => {
        return this.http.put<any>(
            `${this.url}/approve-decline/${workflowTypeAndId}`,
            workflowApprovalData
        );
    };
    updateModelApprovalWorkflowV2Bulk = (workflowApprovalData: any): Observable<any> => {
        return this.http.put<any>(`${this.url}/approve-decline/bulk`, workflowApprovalData);
    };

    fetchSingleApprovalRequest = (approvalId: string): Observable<any> => {
        return this.http.get<any>(`${this.url}/${approvalId}`);
    };
}
