import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs';
import { AppStore } from 'src/app/app-data';

@Component({
    selector: 'app-country-dropdown',
    templateUrl: './country-dropdown.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountryDropdownComponent),
            multi: true,
        },
    ],
    styleUrls: ['./country-dropdown.component.scss', '../../../../filterDD.scss'],
})
export class CountryDropdownComponent implements OnInit, ControlValueAccessor {
    country = {
        name: 'Nigeria',
        currency: 'NGN',
    };

    private onChange2 = (value: string) => {};
    private onTouched = () => {};

    @Output()
    onChange = new EventEmitter();

    countries: any[] = [];
    constructor(private appStore: AppStore) {}

    ngOnInit(): void {
        this.appStore
            .getCombos()
            .pipe(take(1))
            .subscribe((value) => {
                this.countries = Object.keys(value.defaults.countries).reduce((prev, cur) => {
                    const country = value.defaults.countries[cur];
                    prev.push({
                        name: cur,
                        currency: country.currency,
                    });
                    return prev;
                }, [] as any);
            });

        this.onChange.emit({ isFirst: true, ...this.country });
    }

    writeValue(value: any): void {
        this.country = value;
    }

    // Registers a callback function that should be called when the value changes
    registerOnChange(fn: any): void {
        this.onChange2 = fn;
    }

    // Registers a callback function that should be called when the control is touched
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Updates the value and triggers onChange
    onInputChange(event: any): void {
        this.country = event;
        this.onChange2(event);
        this.onChange.emit(this.country);
    }
}
