import { FormArray, FormGroup } from '@angular/forms';
import { PayrollService } from './payrolls.service';
import { Injectable } from '@angular/core';
const regex = /(\([\s\S]*?\)|@\w+((\s*\w+)?)*|\d+(\.\d+)?|[+\-*/])/g;
//const regex = /^(\s*(\([\s\S]*?\)|@\w+|\d+(\.\d+)?|[+\-*/])\s*)+$/
const operatorRegex = /^[+\-*/]$/;
const placeholderRegex = /@\w+((\s*\w+)?)*/;
const numberRegex = /^\d+(\.\d+)?$/;

@Injectable()
export class FormulaScanner {
    constructor(private ps: PayrollService) {}

    items!: FormArray<any>;
    scan(items: FormArray<any>, structured: boolean = false) {
        this.items = items;
        for (let item of items.controls) {
            try {
                if (!structured && !item.value.derivative) continue;

                if (structured && !item.value.selected) continue;

                const value = this.ps.approximateAmount(
                    structured ? item.value.value : this.dress(item.value.formula, item.value.name)
                );
                if (value <= 0) {
                    item.patchValue({
                        value: 0,
                        error: `${value} is not a valid value`,
                    });
                    continue;
                }

                item.patchValue({
                    value,
                    error: null,
                });
            } catch (e: any) {
                item.patchValue({
                    value: 0,
                    error: e.message,
                });
                console.log('Error', e.message);
            }
        }
    }

    private dress(formula: string, currentName: string) {
        const matches = formula.match(regex);
        if (!matches?.length) {
            throw new Error('No Matches');
        }
        const val = this.getValues(matches, currentName);
        return val;
    }

    private getValues(matches: string[], currentName: string) {
        const values: any[] = [];
        for (let [index, match] of matches.entries()) {
            let oldValue = values.at(-1);
            const currentValue = match.trim();
            const mid = this.getTypeOfValue(currentValue, oldValue, currentName);
            if (!mid?.success) {
                throw new Error(mid.message);
            }
            values.push(mid.value);
        }
        return this.calculator(values);
    }

    private getTypeOfValue(value: string, oldValue: any, currentName: string) {
        //handle bracket items
        if (value.includes('(')) {
            value = value.replace('(', '');
            value = value.replace(')', '');
            return {
                success: true,
                br: true,
                value: this.dress(value, currentName),
            };
        }
        if (oldValue == undefined) {
            //value cannot be +,-,/,*
            if (operatorRegex.test(value)) {
                return {
                    status: false,
                    message: 'incorrect format. cannot start with an operator  ',
                };
            }
        }
        if (operatorRegex.test(oldValue) && operatorRegex.test(value)) {
            return {
                status: false,
                message: 'numeric operator cannot follow each other',
            };
        }

        if (
            (numberRegex.test(oldValue) || placeholderRegex.test(oldValue)) &&
            (numberRegex.test(value) || placeholderRegex.test(value))
        ) {
            return {
                status: false,
                message: `${oldValue} and ${value} value cannot follow each other. please add and operator between`,
            };
        }

        //handle numbers
        if (numberRegex.test(value)) {
            return {
                success: true,
                num: true,
                value: Number(value),
            };
        }

        if (placeholderRegex.test(value)) {
            return {
                success: true,
                placeholder: true,
                value: this.getValueFromPlaceHolder(value, currentName),
            };
        }

        //handle operators
        if (operatorRegex.test(value)) {
            return {
                success: true,
                ops: true,
                value: value,
            };
        }

        return {
            success: false,
            message: 'Invalid Entry',
        };
    }

    private calculator(array: any[]) {
        let nextOperator = '';
        return array.reduce((total, value) => {
            if (operatorRegex.test(value)) {
                nextOperator = value;
            } else if (numberRegex.test(value)) {
                switch (nextOperator) {
                    case '+': {
                        total += value;
                        break;
                    }
                    case '-': {
                        total -= value;
                        break;
                    }
                    case '*': {
                        total *= value;
                        break;
                    }
                    case '/': {
                        total /= value;
                        break;
                    }
                    default:
                        total = value;
                }
            }
            return total;
        }, 0);
    }

    private getValueFromPlaceHolder(label: string, currentName: string) {
        //find item;
        label = label.replace('@', '');
        const item = this.items?.value?.find((v: any) => v.name === label);
        if (!item) {
            throw new Error(`${label} not found`);
        }
        if (!item.selected) {
            throw new Error(`${label} not selected`);
        }
        if (!item.derivative) {
            return item.value;
        }
        if (!item.formula) {
            throw new Error(`${label} is does not have a formula`);
        }
        if (item.formula.includes('@' + currentName)) {
            throw new Error(`${label} is already dependent on ${currentName}`);
        }

        return this.dress(item.formula, currentName);
    }
}
