import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'btn-list',
    templateUrl: './btn-list.component.html',
    styleUrls: ['./btn-list.component.scss'],
})
export class BtnListComponent implements OnInit {
    @Output('onRemove')
    onRemove = new EventEmitter();

    @Output('onRemoveByValue')
    onRemoveByValue = new EventEmitter();

    @Output('onClick')
    onClick = new EventEmitter<number>();

    @Output('onClickByValue')
    onClickByValue = new EventEmitter();

    @Input() remove = true;

    @Input()
    items: any[] = [];

    @Input()
    key!: string;

    @Input()
    btnClass!: string;

    @Input()
    isLink: boolean = false;

    @Input()
    btnStyle!: { [key: string]: string };
    constructor() {}

    ngOnInit(): void {}

    handleRemove(index: number, items: any) {
        this.onRemoveByValue.emit(items);
        this.onRemove.emit(index);
    }

    handleClick(index: number, items: any) {
        if (this.isLink) {
            window.open(items.url, '_blank');
        }
        this.onClickByValue.emit(items);
        this.onClick.emit(index);
    }
}
