<div>
    <div class="variants">
        <div class="file" *ngIf="!multiple">
            <label [for]="fileName + inputID" [ngClass]="{ disabled: disableUpload || loading }">
                <ng-container *ngIf="!loading; else loadingG">
                    <ng-container *ngIf="svgIcon; else uploadIcon">
                        <mat-icon [svgIcon]="svgIcon" class="icon"></mat-icon>
                        {{ btnName }}
                    </ng-container>
                    <ng-template #uploadIcon>
                        <i class="material-icons">cloud_queue </i>Select a file
                    </ng-template>
                </ng-container>
                <ng-template #loadingG>
                    Uploading
                    <img class="loadingGif" src="assets/motions/loader.gif" alt="" />
                </ng-template>
            </label>
            <input
                [id]="fileName + inputID"
                type="file"
                (change)="onFileChange($event)"
                [disabled]="disableUpload || loading"
                (click)="myFileInput.value = ''"
                #myFileInput
            />
            <ng-container *ngFor="let data of uploadProgress$ | async">
                <span *ngIf="disableUpload && data.status !== 'Invalid File - Not Uploaded'">
                    <button
                        class="btn btn-dark ml-3"
                        [disabled]="disableDeletion"
                        (click)="handleReupload(data)"
                    >
                        Reupload
                    </button>
                </span>
            </ng-container>
        </div>

        <div class="file" *ngIf="multiple" style="display: block">
            <label [for]="fileName + inputID" [ngClass]="{ disabled: disableUpload || loading }">
                <ng-container *ngIf="!loading; else loadingG">
                    <i class="material-icons">cloud_queue </i>{{ btnName }}
                </ng-container>
                <ng-template #loadingG>
                    Uploading
                    <img class="loadingGif" src="assets/motions/loader.gif" alt="" />
                </ng-template>
            </label>
            <input
                [id]="fileName + inputID"
                type="file"
                (change)="onFileChange($event)"
                multiple="multiple"
                [disabled]="disableUpload || loading"
                (click)="myFileInput2.value = ''"
                #myFileInput2
            />
        </div>
    </div>
    <small>
        <i class="text-danger">File Type Allowed: {{ fileFormat }}</i>
    </small>
    <br />
    <table class="table">
        <thead *ngIf="disableUpload || loading">
            <tr>
                <th scope="col">#</th>
                <th scope="col">Filename</th>
                <th scope="col">
                    <span *ngIf="multiple">
                        <button
                            class="btn btn-slimmer btn-dark ml-3"
                            (click)="uploadAdditionalFile()"
                        >
                            <ng-container *ngIf="!loading"> Add File </ng-container>
                            <ng-container *ngIf="loading">
                                <img class="loadingGif" src="assets/motions/loader.gif" alt="" />
                                Uploading
                            </ng-container>
                        </button>
                        <input
                            title="Add File"
                            id="addMultipleFile"
                            class="d-none"
                            type="file"
                            (change)="onFIleChangeAddtional($event)"
                            (click)="myFileInput3.value = ''"
                            #myFileInput3
                        />
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of uploadProgress$ | async; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ data.filename }}</td>
                <td>
                    <button
                        *ngIf="data.status !== 'Invalid File - Not Uploaded'"
                        class="btn btn-danger ml-3"
                        [id]="'delete' + i"
                        (click)="handleReupload(data, i)"
                    >
                        Remove
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <br />
    <table *ngIf="showUploads">
        <tr *ngFor="let file of uploads">
            <td class="mr-2">{{ file }}</td>
            <td>
                <button class="btn btn-danger ml-2 mr-2" (click)="deleteFile(file)">DELETE</button>
            </td>
            <td class="mr-2">
                <button class="btn btn-default" (click)="downloadFile(file)" download="pic">
                    DOWNLOAD
                </button>
            </td>
        </tr>
    </table>
</div>

<ng-container *ngIf="false">
    <h4>Downloaded</h4>
    <img *ngFor="let blob of uploadsDownloaded" src="{{ blob }}" height="100px" />
</ng-container>
