import { iEmployee, iTableData } from 'src/app/shared/models/interfaces';
import { IBuyPlanPayload } from '../../health.data';

export const INSURANCE_PERIOD = ['monthly', 'quarterly', 'biannually', 'yearly'];

export enum insurancePeriodEnum {
    monthly = 'monthly',
    yearly = 'yearly',
}
export enum healthEnum {
    myxalary = 'myxalary',
    direct = 'direct',
}

export const dummyPlanBreakdown: iTableData = {
    header: [
        {
            title: 'Amount',
            key: 'planAmount',
            isCurrency: true,
        },
        {
            title: 'Period',
            key: 'period',
        },
        {
            title: 'Quantity',
            key: 'quantity',
        },

        {
            title: 'Cost',
            key: 'cost',
            isCurrency: true,
        },
    ],
    body: [],
    config: {
        total: {
            display: true,
            column: 'cost',
        },
        isBorderRadius: true,
    },
};

export const employees: iEmployee[] = [
    {
        id: 1,
        name: 'Ayotunde Paseda',
    },
    {
        id: 2,
        name: 'ayotunde samson',
    },
    {
        id: 3,
        name: 'Festus',
    },
    {
        id: 4,
        name: 'Yinka',
    },
    {
        id: 5,
        name: 'ayotunde',
    },
    {
        id: 6,
        name: 'ayotunde samson',
    },
    {
        id: 7,
        name: 'Festus',
    },
    {
        id: 8,
        name: 'Yinka',
    },
];

export interface IPaymentFormControls {
    insurancePeriod: insurancePeriodEnum;
    startDate: string;
    nameOfAccountManager: string;
    emailOfAccountManager: string;
    paymentPackages: Array<IplanBreakdownControl>;
    recurrentBilling: boolean;
    providerId: string;
}

export interface IplanBreakdownControl {
    planAmount: number;
    employees: iEmployee[];
    cost?: number;
}

// export interface IPaymentPayload {
//   hmoProviderID?: string;
//   providerName: string;
//   insurancePeriod: string;
//   startDate: string;
//   emailOfAccountManager: string;
//   nameOfAccountManager: string;
//   plans: Array<IPaymentBreakdown>;
//   paymentPackages: any;
//   paymentType?: paymentTypeEnum;
//   recurrentBilling?: boolean;
// }

export interface IPaymentPayload extends IBuyPlanPayload {
    insurancePeriod: insurancePeriodEnum;
    emailOfAccountManager: string;
    nameOfAccountManager: string;
    startDate: string;
    recurrentBilling: boolean;
    paymentPackages?: any;
}
export interface IHealthPaymentManager {
    name: string;
    email: string;
}

export interface IHealthEmployee {
    name: string;
    email: string;
    profileImgUrl: string;
    fid: string;
    id: string;
    admin: boolean;
}

export interface IHealthPlans {
    name: string;
    period: insurancePeriodEnum;
    startDate: string;
    endDate?: string;
    amount: string;
    employees: IHealthEmployee[];
    details: string[];
}

export interface IHealthNetworks {
    name: string;
    address: string;
    phone: string;
}
export interface IHealthProvider {
    providerName: string;
    logo: string;
    plans: IHealthPlans[];
    about: string;
    network?: IHealthNetworks[];
    _id?: string;
}

export interface IHealthPayment {
    type: 'myxalary' | 'provider';
    providerId: any;
    accountManager?: IHealthPaymentManager;
    plans: IHealthPlans[];
    autoRenewal: boolean;
    status?: 'NOT PAID' | 'PAID';
    payments?: {
        totalGross: number;
        totalNet: number;
        payrollId: string;
    };
}

export interface IPaymentBreakdown {
    planAmount: number;
    employees: Array<{ name: string; id: string | number }>;
    planName?: string;
    quantity?: number;
}

export enum paymentTypeEnum {
    new = 'new_card',
}
