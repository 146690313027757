import { Component, Input, OnInit } from '@angular/core';
import { PayrollService } from '../../services/payrolls.service';

@Component({
    selector: 'app-view-payroll-audit',
    templateUrl: './view-payroll-audit.component.html',
    styleUrls: ['./view-payroll-audit.component.scss'],
})
export class ViewPayrollAuditComponent implements OnInit {
    @Input() id!: string;
    auditList: any = [];

    constructor(private payrollService: PayrollService) {}

    ngOnInit(): void {
        this.getPayrollAuditsById();
    }

    async getPayrollAuditsById() {
        this.payrollService.getPayrollAuditsById(this.id).then((res) => {
            this.auditList = res.response.reverse();
        });
    }

    //remove underscore from stage name
    removeUnderscores(id: string) {
        return id ? id.split('_').join(' ') : '';
    }
}
