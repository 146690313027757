import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/shared/services/general.service';
import { ApiService } from '../../services/api.service';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-benefits',
    templateUrl: './benefits.component.html',
    styleUrls: ['./benefits.component.scss', '../../../../../formStyle.scss'],
})
export class BenefitsComponent implements OnInit {
    confirm = new UntypedFormControl(false, [Validators.required]);
    banks: any[] = [];
    formData: UntypedFormGroup = new UntypedFormGroup({
        account: new UntypedFormControl('', [Validators.required]),
        bank: new UntypedFormControl('', [Validators.required]),
        bank_name: new UntypedFormControl('', [Validators.required]),
        account_name: new UntypedFormControl('', [Validators.required]),
    });

    loadingVerify: boolean = false;
    label = {
        text: '+ Verify Account',
        color: '#D62196',
    };

    constructor(
        private gs: GeneralService,
        private api: ApiService,
        private utils: UtilsService
    ) {}

    ngOnInit(): void {
        this.init();
    }

    async init() {
        const banks = await this.gs.getBanks();
        this.banks = banks.data;
    }

    async verifyAccount() {
        if (this.loadingVerify) return;
        this.loadingVerify = true;
        const { bank, account } = this.formData.value;
        this.label = {
            text: 'Verifying  Account...',
            color: '#440f7c',
        };
        try {
            const data: any = await this.api.verifyAccount(account, bank.code);

            this.formData.patchValue({
                account_name: data.response.account_name,
                bank_name: bank.name,
            });
        } catch (e) {}
        this.loadingVerify = false;
        this.label = {
            text: '+ Verify Account',
            color: '#D62196',
        };
    }

    get canVerify() {
        const { bank, account } = this.formData.value;
        return bank.code && account;
    }
    async add() {
        const payload = this.formData.value;
        payload.bank = payload.bank.code;
        await this.api.addBeneficiary(payload);
        this.utils.beneficiaryTable.next(1);
        this.utils.openSuccessView();
    }
}
