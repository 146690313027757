<div class="w-100">
    <div class="title">
        <span class="main-text"> Select only some employees by </span>
        <span class="selected"> {{ totalSelected }} Employees Selected </span>
    </div>
    <ngb-accordion *ngFor="let category of data; let i = index">
        <ngb-panel [id]="'active-accord-' + i">
            <ng-template ngbPanelHeader let-opened="opened" class="template-header">
                <div
                    class="accordion-button flex-row d-flex justify-content-between w-100 align-items-center"
                    [class.collapsed]="!opened"
                >
                    <span class="m-0 ml-1 font-weight-bolder accord-title">{{
                        category.category | titlecase
                    }}</span>
                    <span class="">
                        <span class="accord-title">{{ selectNumb(category.category).label }}</span>
                        <button ngbPanelToggle class="btn btn-sm ms-2 arrow">
                            <i *ngIf="!opened" class="fa fa-arrow-circle-down"></i>
                            <i *ngIf="opened" class="fa fa-arrow-circle-up"></i>
                        </button>
                    </span>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="d-flex flex-column">
                    <mat-checkbox
                        [checked]="isChecked(item, category.category)"
                        (change)="onSelect(category.category, item)"
                        *ngFor="let item of category.data"
                        >{{ item.name | titlecase }}</mat-checkbox
                    >
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
