import { iHmoProvider } from 'src/app/shared/models/hmoProvider';
import { iEmployee } from 'src/app/shared/models/interfaces';
import { PAGINATOR } from '../../../../interfaces/general';
import * as fromAction from '../actions';
import { IPurchase } from './../../health.data';

//define state blueprint
export interface IState {
    hmoProviders: iHmoProvider[];
    employees: iEmployee[];
    purchaseData: IPurchase | null;
    purchaseHistories: { data: IPurchase[]; paging: PAGINATOR };
    expiringPlans: { data: IPurchase[]; paging: PAGINATOR };
}

// initial state
export const initialAppState: IState = {
    hmoProviders: [],
    employees: [],
    purchaseData: null,
    purchaseHistories: { data: [], paging: {} as PAGINATOR },
    expiringPlans: { data: [], paging: {} as PAGINATOR },
};

export function reducer(state: IState = initialAppState, action: any): IState {
    //switch actions
    switch (action.type) {
        case fromAction.HealthActionTypeEnums.setProviders: {
            return {
                ...state,
                hmoProviders: [...action.payload],
            };
        }

        case fromAction.HealthActionTypeEnums.setEmployees: {
            return {
                ...state,
                employees: [...action.payload],
            };
        }

        case fromAction.HealthActionTypeEnums.setPurchaseItem: {
            return {
                ...state,
                purchaseData: { ...action.payload },
            };
        }

        case fromAction.HealthActionTypeEnums.setPurchaseHistories: {
            return {
                ...state,
                purchaseHistories: { ...action.payload },
            };
        }

        case fromAction.HealthActionTypeEnums.setExpiringPurchase: {
            return {
                ...state,
                expiringPlans: { ...action.payload },
            };
        }

        default:
            return state;
    }
}
