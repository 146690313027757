import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { GeneralService } from 'src/app/shared/services/general.service';
import { Swal } from 'src/app/interfaces/general';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PayrollService } from 'src/app/modules/payment/services/payrolls.service';
import { ApiService } from 'src/app/modules/dashboard/services/api.service';

@Component({
    selector: 'app-ask-for-otp',
    templateUrl: './ask-for-otp.component.html',
    styleUrls: ['./ask-for-otp.component.scss', '../../../../formStyle.scss'],
})
export class AskForOtpComponent implements OnInit {
    formData: FormGroup = new FormGroup({
        otp: new FormControl('', [Validators.required]),
        pin: new FormControl('', [Validators.required]),
    });
    loadingOTP: boolean = false;
    otpBtn = {
        color: '#D62196',
        label: '+ Get OTP',
    };

    @Input()
    data: any;

    @Input()
    message: any;

    payload = new EventEmitter();

    swal!: Swal | null;
    constructor(
        private dashService: ApiService,
        private genService: GeneralService,
        private activeModal: NgbActiveModal,
        private payrollService: PayrollService
    ) {}

    ngOnInit(): void {}

    async getOTP() {
        if (this.loadingOTP) return;
        this.loadingOTP = false;
        this.otpBtn = {
            color: '#440f7c',
            label: 'Generating OTP...',
        };
        const token: any = await this.dashService.getOTP();
        this.formData.patchValue({
            token: token.response,
        });
        this.otpBtn = {
            color: 'green',
            label: 'OTP Successfully Sent to Registered Phone Number and Email Address...Click to Resend',
        };
        this.loadingOTP = false;
        this.genService.alertInfo.next({
            text: token.message,
            btnClass: 'alert-success',
            btnIcon: 'checklist',
            timer: 3000,
        });
    }

    closeSwal() {
        this.swal = null;
    }
    move() {
        this.swal = {
            title: `Proceed?`,
            text: `${this.message}`,
            onClose: this.closeSwal.bind(this),
            proceed: (async () => {
                const val = this.formData.value;
                this.payload.emit(val);
                this.activeModal.close(val);
            }).bind(this),
        };
    }
}
