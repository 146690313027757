import { iPlan } from './../../shared/models/hmoProvider';
import {
    insurancePeriodEnum,
    IPaymentBreakdown,
} from './containers/pay-via-myxalary/pay-via-myxalary.data';

export interface IBuyPlanPayload {
    //   providerName?: string;
    insurancePeriod: string;
    plans: Array<IPaymentBreakdown>;
    //   paymentType?: paymentTypeEnum;
    providerId?: string;
    type?: 'myxalary' | 'provider';
}

export interface IPurchase {
    _id: string;
    provider: {
        _id: string;
        providerName: string;
    };
    amount: number;
    insurancePeriod: insurancePeriodEnum;
    status: string;
    recurrent: string;
    startDate: string;
    createdOn: string;
    plans: iPlan[];
    type?: 'myxalary' | 'provider';
    //   employeePlanData?: Array<{ name: string; plan: string; amount: number }>;
}

export const LABEL = {
    DASHBOARD: {
        BUY_HEALTH_PLAN: 'Buy a health plan through MyXalary',
        PAY_VIA_MYXALARY: 'Do you have an existing Health Coverage for your employees?',
    },
};

export type TableAction = {
    title: string;
    clickHandler: (item: any) => void;
};

export type TableActions = TableAction[];

export type PaymentPackageForm = {
    amount: number;
    name: string;
    startDate: string;
    period: string;
    employees: { id: string; name: string }[];
};

export type Analytics = {
    totalEmployees: number;

    totalPlans: number;

    totalActiveSubscription: number;

    totalAmountSpent: number;
};

// export const PAYMENT_HISTORY: IPurchase[] = Array(10).fill({
//   employeeName: 'Omobosola Ayeni',
//   providerName: 'Lead way',
//   planName: 'lite',
//   cost: 25000,
//   startDate: '12/05/2020',
//   endDate: '12/05/2021',
//   status: 'Active',
// });
