import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import SelectionModel from 'src/app/utils/localSelection';
import { NgSelectComponent } from '@ng-select/ng-select';
import { GeneralService } from '../../services/general.service';
import { AppStore } from 'src/app/app-data';
import { BranchModule } from '../../../modules/employee/branch/branch.module';
import { Combos } from 'src/app/interfaces/general';
import { take } from 'rxjs';
type Keys =
    | 'cadre'
    | 'jobRole'
    | 'department'
    | 'employees'
    | 'branch'
    | 'all employees'
    | 'specific employees';
@Component({
    selector: 'app-select-categories',
    templateUrl: './select-categories.component.html',
    styleUrls: ['./select-categories.component.scss', '../../../../formStyle.scss'],
})
export class SelectCategoriesComponent implements OnInit {
    @Input() applyTo: Keys[] = ['cadre', 'jobRole', 'department', 'employees'];

    @Input({
        required: true,
    })
    module!: string;
    @Input({
        required: true,
    })
    applyToLabel!: string;
    @Input({
        required: true,
    })
    listLabel!: string;

    @Input() isColumn: boolean = false;

    @Input() applyDefault!: string;
    @Input() data!: any;
    @Output() onChange = new EventEmitter();
    combos!: Partial<{ [key in Keys]: any[] }>;

    listControl = new UntypedFormControl('');
    inviteesSelection = new SelectionModel(true, [], 'key');

    applyToForm = new UntypedFormGroup({
        applyTo: new UntypedFormControl(this.applyDefault, [Validators.required]),
        list: new UntypedFormArray([], [Validators.required]),
    });

    constructor(
        private gs: GeneralService,
        private appStore: AppStore
    ) {}

    ngOnInit(): void {
        this.applyToFrm.setValue(this.data?.applyTo || this.applyDefault);
        this.data?.list && this.data?.list.forEach((v: any) => this.onSelectList(v));
        this.applyToForm.valueChanges.subscribe((item) => {
            this.onChange.emit(item);
        });
        this.getCombos();
    }

    async getCombos() {
        const combos: Combos = await this.appStore.getCombos().pipe(take(1)).toPromise();
        const shared = this.gs.transformData(
            combos.activeEmployees,
            ['jobRole', 'branch', 'cadre', 'department'],
            this.module
        );
        this.combos = {
            'cadre': combos.cadres?.map((v) => ({
                key: v._id,
                value: v.cadreName,
            })),
            'jobRole': combos.jobRoles?.map((v) => ({
                key: v._id,
                value: v.name,
            })),
            'department': combos.departments?.map((v) => ({
                key: v._id,
                value: v.departmentName,
            })),
            'employees': shared.map((v) => ({
                ...v,
                key: v.id,
                value: v.name,
            })),
            'specific employees': shared?.map((v) => ({
                ...v,
                key: v.id,
                value: v.name,
            })),
            'branch': combos.branches?.map((v) => ({
                key: v._id,
                value: v.branchName,
            })),
        };
    }

    get listForm() {
        return this.applyToForm.get('list') as UntypedFormArray;
    }
    get applyToFrm() {
        return this.applyToForm.get('applyTo') as UntypedFormControl;
    }
    onSelectApplyTo() {
        this.listForm.clear();
        this.listForm.reset();
        this.inviteesSelection.clear();
    }
    onSelectList($event: any) {
        if (!$event) return;
        this.inviteesSelection.toggle($event);
        this.listForm.clear();
        this.listForm.reset();
        this.inviteesSelection.selected.map((invitee: any) => {
            this.listForm.push(new UntypedFormControl(invitee));
        });

        this.listControl.reset();
        // if (this.applyToFrm.value !== "employees") {
        //   template.close()
        // }
    }
    get list() {
        if (!this.combos) {
            return [];
        }
        return this.combos[this.applyToForm.get('applyTo')?.value as Keys] || [];
    }

    removeList($event: any) {
        const value = this.listForm.value[$event];
        this.listForm.removeAt($event);
        this.inviteesSelection.toggle(value);
    }

    onSelectAll() {
        this.list.map((v) => this.onSelectList(v));
    }
    onClearAll() {
        this.listForm.clear();
        this.listForm.reset();
        this.inviteesSelection.clear();
    }
}
