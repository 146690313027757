import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberInput',
})
export class NumberInputPipe implements PipeTransform {
    transform(value: any): number | undefined {
        if (value == undefined) return 0;

        let stringValue = String(value);
        stringValue = stringValue.replace(/[-+e]/g, '');
        if (stringValue.trim() === '') return 0;

        const parsedValue = parseFloat(stringValue);

        return parsedValue;
    }
}
