import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-empty-data-view',
    templateUrl: './empty-data-view.component.html',
    styleUrls: ['./empty-data-view.component.scss'],
})
export class EmptyDataViewComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
