<section>
    <modal-cover title="Report Transaction">
        <form (ngSubmit)="sendReport()" style="gap: 5px" class="p-3 d-flex w-50 h-100 flex-column">
            <div class="form-group form-style w-100">
                <label class="font-weight-bold" for="title"
                    >Report<span class="text-danger">*</span></label
                >
                <textarea [formControl]="reportControl" rows="5" class="form-control"></textarea>
            </div>
            <div class="d-flex btn-grp justify-content-between">
                <button [disabled]="reportControl.invalid" type="submit" class="btn btn-default">
                    Send Report
                </button>
            </div>
        </form>
    </modal-cover>
</section>
