<div class="rps_4091">
    <div width="100%" style="margin: 0; padding: 0 !important; background-color: #f4f4f4">
        <div style="width: 100%; background-color: #f4f4f4">
            <div
                class="container-fluid"
                style="
                    background-color: #8453bd;
                    background-repeat: no-repeat;
                    background-size: cover;
                "
            >
                <div class="row justify-content-center" style="height: 320px">
                    <div class="col text-center pl-4">
                        <div
                            class="d-flex justify-content-between align-items-end"
                            style="background-color: transparent"
                        >
                            <a href="#" target="_blank" rel="noopener noreferrer"
                                ><img
                                    src="../../../../../assets/images/notch-hr.png"
                                    width="200"
                                    height="180"
                                    alt="NotchHR Logo"
                            /></a>
                            <span class="text-white font-weight-bold head-container">&nbsp;</span>
                        </div>
                        <div class="text-left head">
                            Experience the new world of<br />work with NotchHR
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center bg-white" style="height: 600px">
                    <div class="col text-center">
                        <p
                            class="text-justify p-4"
                            style="
                                font-family: 'Nunito', Helvetica, Arial, sans-serif;
                                font-size: 40px;
                                line-height: 50px;
                                color: #330662;
                            "
                        >
                            Hi there,<br />We’ve successfully confirmed your email 🎉.<br />Please
                            click here to continue and we’ll see you on the other side 😁.
                        </p>
                        <div *ngIf="successMsg" class="text-justify px-4 button-container">
                            <a href="#" class="button" [routerLink]="['/login']">Login</a>
                        </div>
                        <div *ngIf="!successMsg" class="text-justify px-4 button-container">
                            <a href="#" class="button" [routerLink]="['/login']">Login</a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center" style="background-color: #8453bd">
                    <div class="col text-left p-4">
                        <div class="d-flex justify-content-between align-items-start pb-3 px-4">
                            <div class="mt-4">
                                <a href="#" target="_blank" rel="noopener noreferrer"
                                    ><img
                                        src="../../../../../assets/images/notch-hr.png"
                                        width="200"
                                        height="180"
                                        alt="NotchHR Logo"
                                /></a>
                                <p class="address py-2">
                                    94, Norman Williams Street, Ikoyi, Lagos State
                                </p>
                                <p class="address py-2">
                                    Not interested in our updates?
                                    <a
                                        href="#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style="color: #00ff84; text-decoration: none"
                                        >Unsubscribe here.</a
                                    >
                                </p>
                            </div>
                            <div class="d-flex">
                                <a href="#" target="_blank" rel="noopener noreferrer" class="pr-2"
                                    ><img
                                        src="https://cdn.getblueshift.com/pictures/44196/content/facebook-color.png"
                                        alt="NotchHR on Facebook"
                                        width="22"
                                /></a>
                                <a href="#" target="_blank" rel="noopener noreferrer" class="pr-2"
                                    ><img
                                        src="https://cdn.getblueshift.com/pictures/44197/content/twitter-color.png"
                                        alt="NotchHR on Twitter"
                                        width="22"
                                /></a>
                                <a href="#" target="_blank" rel="noopener noreferrer" class="pr-2"
                                    ><img
                                        src="https://cdn.getblueshift.com/pictures/44198/content/instagram-color.png"
                                        alt="NotchHR on Instagram"
                                        width="22"
                                /></a>
                            </div>
                            <div class="text-right">
                                <a
                                    href="#"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="d-block mb-2"
                                    ><img
                                        src="https://myx3.com/img_uploads/appstore.png"
                                        alt="Appstore"
                                        width="112"
                                /></a>
                                <a href="#" target="_blank" rel="noopener noreferrer"
                                    ><img
                                        src="https://myx3.com/img_uploads/google-play.png"
                                        alt="Google Play"
                                        width="112"
                                /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
