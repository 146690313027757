<div class="row">
    <div class="col-md-12 p-3">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <div class="w-25">
            <h2>Workflow Requests</h2>

            <div class="app-ng-autocomplete app-ng-prompt ml-auto">
                <ng-select
                    [placeholder]="'Select Request Status'"
                    bindLabel="label"
                    class="ng-input"
                    [clearable]="false"
                    [(ngModel)]="requestStatus"
                    (change)="requestStatusChange($event)"
                >
                    <ng-option *ngFor="let item of requestStatusType" [value]="item.value">{{
                        item.label
                    }}</ng-option>
                </ng-select>
            </div>
        </div>
    </div>

    <div class="w-100 card-body p-4">
        <ng-container *ngIf="requestStatus == workflowStat.Own">
            <app-data-table
                *ngIf="tableConfig; else noData"
                [options]="tableConfig"
            ></app-data-table>
            <ng-template let-data="data" #viewRequestTmpl>
                <button
                    class="btn btn-primary btn-sm"
                    (click)="reviewWorkflow(data, workflowStat.Own)"
                >
                    View Request
                </button>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="requestStatus == workflowStat.Pending">
            <app-data-table
                *ngIf="requestConfig; else noData"
                [options]="requestConfig"
            ></app-data-table>
        </ng-container>

        <ng-container *ngIf="requestStatus == workflowStat.Approved">
            <app-data-table
                *ngIf="approvedConfig; else noData"
                [options]="approvedConfig"
            ></app-data-table>
        </ng-container>

        <ng-container *ngIf="requestStatus == workflowStat.Rejected">
            <app-data-table
                *ngIf="declinedConfig; else noData"
                [options]="declinedConfig"
            ></app-data-table>
        </ng-container>

        <ng-template #noData>
            <app-empty-data-view class="mx-auto"></app-empty-data-view>
        </ng-template>

        <ng-template let-data="data" #viewRequestType>
            <span> {{ retrieveWorkflowTitle(data.workflowType ?? data.type) }} </span>
        </ng-template>

        <ng-template let-data="data" #viewRequestHistoryTmpl>
            <button class="btn btn-primary btn-sm" (click)="reviewWorkflow(data, workflowStat.Own)">
                View Request
            </button>
        </ng-template>

        <ng-template let-data="data" #viewRequestHistoryPendingTmpl>
            <button
                class="btn btn-primary btn-sm"
                (click)="reviewWorkflow(data, workflowStat.Pending)"
            >
                View History
            </button>
        </ng-template>

        <ng-template let-data="data" #viewRequestHistoryApprovedTmpl>
            <button
                class="btn btn-primary btn-sm"
                (click)="reviewWorkflow(data, workflowStat.Approved)"
            >
                View History
            </button>
        </ng-template>

        <ng-template let-data="data" #viewRequestHistoryDeclinedTmpl>
            <button
                class="btn btn-primary btn-sm"
                (click)="reviewWorkflow(data, workflowStat.Rejected)"
            >
                View History
            </button>
        </ng-template>
    </div>
</div>
