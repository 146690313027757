import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-cofirm-dialog-modal',
    templateUrl: './cofirm-dialog-modal.component.html',
    styleUrls: ['./cofirm-dialog-modal.component.scss'],
})
export class CofirmDialogModalComponent implements OnInit {
    @Input() title: any;
    @Input() text: any;
    closeSwalModal = new EventEmitter();
    constructor() {}

    ngOnInit(): void {}

    proceedSwal(status: boolean) {
        if (status) {
            this.closeSwalModal.emit('reload');
        } else {
            this.closeSwalModal.emit();
        }
    }
}
