import { createAction, props } from '@ngrx/store';
import { iHmoProvider } from 'src/app/shared/models/hmoProvider';
import { iEmployee } from 'src/app/shared/models/interfaces';
import { PAGINATOR } from '../../../../interfaces/general';
import { IPurchase } from './../../health.data';

export enum HealthActionTypeEnums {
    fetchProviders = '[HEALTH] Fetch HMO Providers',
    setProviders = '[HEALTH] Set HMO Providers',
    getProviders = '[HEALTH] Get HMO Providers',
    getEmployees = '[HEALTH] Get Employees',
    setEmployees = '[HEALTH] Set Employees',
    fetchEmployees = '[HEALTH] Fetch Employees',
    setPurchaseItem = '[HEALTH] Set Purchase Item',
    fetchPurchaseItem = '[HEALTH] Fetch Purchase Item',
    fetchPurchaseHistories = '[HEALTH] Fetch Purchase Histories',
    setPurchaseHistories = '[HEALTH] Set Purchase Histories',
    fetchExpiringPurchase = '[HEALTH] Fetch Expiring Purchase',
    setExpiringPurchase = '[HEALTH] Set Expiring Purchase',
}

export interface IFetchHmoProviders {
    type: HealthActionTypeEnums.fetchProviders;
}

export const fetchHmoProviders = createAction(
    HealthActionTypeEnums.fetchProviders,
    props<{ payload?: any }>()
);
export const setHmoProviders = createAction(
    HealthActionTypeEnums.setProviders,
    props<{ payload: iHmoProvider[] }>()
);
export const getHmoProviders = createAction(HealthActionTypeEnums.getProviders);

export const fetchEmployees = createAction(HealthActionTypeEnums.fetchEmployees);
export const setEmployees = createAction(
    HealthActionTypeEnums.setEmployees,
    props<{ payload: iEmployee[] }>()
);
export const getEmployees = createAction(HealthActionTypeEnums.getEmployees);

export const fetchPurchaseHistories = createAction(
    HealthActionTypeEnums.fetchPurchaseHistories,
    props<{ payload: any }>()
);
export const setPurchaseHistories = createAction(
    HealthActionTypeEnums.setPurchaseHistories,
    props<{ payload: { data: IPurchase[]; paging: PAGINATOR } }>()
);

export const fetchExpiringPurchase = createAction(
    HealthActionTypeEnums.fetchExpiringPurchase,
    props<{ payload: any }>()
);
export const setExpiringPurchase = createAction(
    HealthActionTypeEnums.setExpiringPurchase,
    props<{ payload: { data: IPurchase[]; paging: PAGINATOR } }>()
);

export const setPurchaseItem = createAction(
    HealthActionTypeEnums.setPurchaseItem,
    props<{ payload: IPurchase }>()
);
export const fetchPurchaseItem = createAction(HealthActionTypeEnums.fetchPurchaseItem);
