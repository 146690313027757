<div class="like-cont">
    <div *ngFor="let item of users">
        <div class="name-cont">
            <div
                [style.backgroundImage]="'url(' + item.profileImgUrl + ')'"
                class="like-user-image"
            ></div>
            <div>
                <span class="name">{{ item.firstName }} {{ item.lastName }}</span>
            </div>
        </div>
    </div>
</div>
