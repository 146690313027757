import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';

// Breadcrumb component interface
export interface Breadcrumb {
    type?: string;
    alignment?: string;
    useTitleCase?: boolean;
    links?: Array<{
        name: string;
        isLink: boolean;
        link?: string;
        query?: any;
    }>;
}

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    providers: [TitleCasePipe],
})
export class BreadcrumbComponent implements OnChanges {
    @Input() breadcrumb!: Breadcrumb;
    @Input() useOld: boolean = false;
    items: MenuItem[] = [];
    home!: MenuItem;

    constructor(private titleCase: TitleCasePipe) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.items = <MenuItem[]>this.breadcrumb?.links?.map((value: any) => {
            return {
                label: this.titleCase.transform(value.name),
                routerLink: value.isLink ? value.link : null,
            };
        });
    }
    ngOnInit() {
        // this.items = [
        //     { label: 'Electronics' },
        //     { label: 'Computer' },
        //     { label: 'Accessories' },
        //     { label: 'Keyboard' },
        //     { label: 'Wireless' }
        // ];

        this.home = { icon: 'pi pi-home', routerLink: '/' };

        this.breadcrumb.useTitleCase = this.breadcrumb.useTitleCase ?? true;
    }
}
