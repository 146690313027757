//TODO:  handle single selection
export default class SelectionModel {
    private data: any[] = [];
    private multiple = true;
    private key!: string;

    constructor(multiple: boolean, initialData: any, key?: any) {
        this.data = initialData || [];
        this.multiple = multiple || true;
        this.key = key;
    }

    getData() {
        return this.data.map((item) => {
            if (typeof item === 'object') {
                return JSON.stringify(item);
            }
            return item;
        });
    }
    private toggleKey(row: any) {
        const temp = this.data;
        return temp.findIndex((item) => item[this.key] === row[this.key]);
    }
    private toggleNormal(row: any) {
        let compareRow = row;
        if (typeof row === 'object') {
            compareRow = JSON.stringify(row);
        }
        let temp = this.getData();
        return temp.findIndex((item) => item[this.key] === row[this.key]);
    }

    toggle(row: any) {
        const index = this.key ? this.toggleKey(row) : this.toggleNormal(row);
        if (index >= 0) {
            this.data.splice(index, 1);
        } else {
            this.data.push(row);
        }
    }

    isSelectedKey(row: any) {
        let temp = this.data;
        return !!temp.find((item) => item[this.key] === row[this.key]);
    }

    isSelectedNormal(row: any) {
        let compareRow = row;
        if (typeof row === 'object') {
            compareRow = JSON.stringify(row);
        }
        let temp = this.getData();
        return temp.includes(compareRow);
    }

    isSelected(row: any) {
        return this.key ? this.isSelectedKey(row) : this.isSelectedNormal(row);
    }

    toggleRange(rows: any) {
        rows.forEach((item: any) => this.toggle(item));
    }
    get selected() {
        return this.data;
    }

    clear() {
        this.data = [];
    }
}
