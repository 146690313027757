import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LicenseService {
    allLicenseTable = new Subject<any>();
    manageLicenseTable = new Subject<any>();
    url: string = `${environment.myXalaryEndpoint}/v1/license/v2`;

    constructor(private http: HttpClient) {}

    getAllLicenses = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-licenses`);
    };

    getAllAvailableLicenses = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-available-licenses`);
    };

    getAllOrgNonActiveLicenses = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-company-non-licenses`);
    };

    getAllOrgActiveLicenses = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-company-active-licenses`);
    };

    getAllOrgActiveFullLicenses = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-company-active-full-licenses`);
    };

    getAllEmployees = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-employees`);
    };

    getAllOrgLicenses = (query?: any): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-company-licenses`, { params: query });
    };

    getAllOrgLicensesAnalytics = (): Observable<any> => {
        return this.http.get<any>(`${this.url}/all-company-licenses-analytics`);
    };

    getOrgLicense = (licenseId: string): Observable<any> => {
        return this.http.get<any>(`${this.url}/company-license/${licenseId}`);
    };

    cancelOrgLicense = (licenseId: string): Observable<any> => {
        return this.http.delete<any>(`${this.url}/cancel-license-subscription/${licenseId}`);
    };

    renewOrgLicense = (data: any): Observable<any> => {
        return this.http.post<any>(`${this.url}/renew-company-license`, data);
    };

    moreOrgLicense = (data: any): Observable<any> => {
        return this.http.post<any>(`${this.url}/purchase-more-company-license`, data);
    };

    initializePayment = (data: any): Observable<any> => {
        return this.http.post<any>(`${this.url}/initialize-payment/`, data);
    };

    verifyPayment = (transactionId: any, data: any): Observable<any> => {
        return this.http.post<any>(`${this.url}/verify-payment/${transactionId}`, data);
    };

    checkoutCart = (data: any): Observable<any> => {
        return this.http.post<any>(`${this.url}/checkout-cart`, data);
    };

    assignLicenseToEmployee = (data: any): Observable<any> => {
        return this.http.post<any>(`${this.url}/assign-license`, data);
    };

    removeLicenseFromEmployee = (employeeId: string, licenseId: string): Observable<any> => {
        return this.http.delete<any>(`${this.url}/remove-license/${employeeId}/${licenseId}`);
    };
}
