import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import SM from 'src/app/utils/localSelection';

@Component({
    selector: 'app-e-drop-ui',
    templateUrl: './e-drop-ui.component.html',
    styleUrl: './e-drop-ui.component.scss',
})
export class EDropUiComponent implements OnInit {
    @Input()
    item!: any;

    @Input()
    nameKey!: string;

    @Input()
    multiple: boolean = false;

    @Input()
    selection!: SM | SelectionModel<any>;

    ngOnInit(): void {}
}
