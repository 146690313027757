import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { filterValue } from '../../../interfaces/general';

@Injectable({
    providedIn: 'root',
})
export class TableConfigsService {
    defaultPageOptions = [
        {
            key: '10',
            value: '10',
            selected: true,
        },
        {
            key: '20',
            value: '20',
        },
        {
            key: '50',
            value: '50',
        },
        {
            key: '100',
            value: '100',
        },
    ];
    defaultPeriodOptions = [
        {
            key: 'All',
            value: '',
            selected: true,
        },
        {
            key: 'Last 7 Days',
            value: '7',
        },
        {
            key: 'Last 14 days',
            value: '14',
        },
        {
            key: 'Last 30 days',
            value: '30',
        },
    ];
    courseCategory: any[] = [];

    constructor() {}

    periodTransform(value: any): filterValue {
        const date = dayjs().subtract(value, 'day').toDate().toISOString().split('T')[0];
        return {
            operator: '>=',
            value: date,
        };
    }
}
