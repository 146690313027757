import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarMonthViewBeforeRenderEvent,
    CalendarMonthViewDay,
    CalendarView,
} from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { calendarOptions } from '../../../interfaces/general';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./calendar.component.scss'],
})
/**
 * This component can be expanded to handle other scenerios
 */
export class CalendarComponent implements OnInit, OnChanges {
    @ViewChild('modalContent', { static: true }) modalContent!: TemplateRef<any>;
    viewDate: Date = new Date();
    activeDayIsOpen!: boolean;
    view: CalendarView = CalendarView.Month;
    refresh = new Subject<void>();
    selectedDay!: CalendarMonthViewDay;
    modalData!: {
        action: string;
        event: CalendarEvent;
    };

    options: calendarOptions = {
        toolTip: {
            show: true,
        },
        events: [],
        badge: 20,
        showNavigation: false,
    };
    @Input()
    calendarOption!: calendarOptions;

    @Output()
    onDateChanged = new EventEmitter();

    @Output()
    onDateSelect = new EventEmitter();

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fas fa-fw fa-pencil-alt"></i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<i class="fas fa-fw fa-trash-alt"></i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.options.events = this.options.events.filter((iEvent) => iEvent !== event);
                this.handleEvent('Deleted', event);
            },
        },
    ];

    CalendarView = CalendarView;
    constructor(private modal: NgbModal) {}

    ngOnInit(): void {
        this.options = { ...this.options, ...this.calendarOption };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.calendarOption)
            this.options = { ...this.options, ...changes.calendarOption.currentValue };
    }

    dayClicked(day: CalendarMonthViewDay): void {
        this?.selectedDay?.cssClass?.replace('cal-day-selected', '');
        this.selectedDay = day;
        day.cssClass = 'cal-day-selected';
        this.selectedDay = day;
        const { date, events } = day;
        if (isSameMonth(date, this.viewDate)) {
            if (this.options.showEvent) {
                if (
                    (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                    events.length === 0
                ) {
                    this.activeDayIsOpen = false;
                } else {
                    this.activeDayIsOpen = true;
                }
            }
            this.viewDate = date;
            this.onDateSelect.emit(date);
        }
    }

    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
        this.options.events = this.options.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }
    handleEvent(action: string, event: CalendarEvent): void {
        if (!this.options?.showEvent) return;
        this.modalData = { event, action };
        this.modal.open(this.modalContent, { size: 'lg' });
    }
    beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
        renderEvent.body.forEach((day) => {
            if (!this.selectedDay && day.isToday) {
                this.selectedDay = day;
            }
            if (this.selectedDay?.date.getTime() === day.date.getTime()) {
                return (day.cssClass = 'height cal-day-selected');
            }

            return (day.cssClass = 'height');
        });
    }
    closeOpenMonthViewDay(type?: string) {
        this.activeDayIsOpen = false;
        this.onDateChanged.emit({ date: type ? new Date() : this.viewDate, view: this.view });
    }
}
