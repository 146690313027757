<modal-cover [title]="accountName">
    <div class="d-flex flex-column w-100">
        <div class="d-flex justify-content-between p-3 w-100">
            <div class="box">
                <div class="box-child">
                    <span class="text-up">
                        {{ totalAmount | currency: '₦' }}
                    </span>
                    <span class="text-down"> Total </span>
                </div>
            </div>

            <div class="box">
                <div class="box-child">
                    <span class="text-up">
                        {{ totalTransaction }}
                    </span>
                    <span class="text-down"> Transactions </span>
                </div>
            </div>
        </div>
        <div class="p-5 row w-100">
            <app-data-table
                filterType="query"
                style="width: 100%"
                *ngIf="config"
                [data]="data"
                [options]="config"
            ></app-data-table>
        </div>
    </div>
</modal-cover>

<ng-template let-data="data" #amount>
    {{ data.amount | currency: '₦' }}
</ng-template>
<ng-template let-data="data" #description>
    <div style="width: 90%; word-break: break-all">
        {{ data.description }}
    </div>
</ng-template>
<ng-template let-data="data" #createdOn>
    {{ data.created_date | date: 'YYYY-MM-dd' }}
</ng-template>
