import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { Company } from 'src/app/shared/models/company';
import { GeneralService } from 'src/app/shared/services/general.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss', '../../../../formStyle.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    model = new Company();
    submitted = false;
    error: string = '';
    showError = false;
    isLoading = false;

    constructor(
        private route: Router,
        private genSrv: GeneralService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {}

    onSubmit(form: NgForm) {
        if (!form.valid) {
            return;
        }

        this.isLoading = true;
        this.authService.forgot(form.value).subscribe((res) => {
            this.isLoading = false;
            this.genSrv.alertInfo.next({
                btnClass: 'success',
                text: 'Password reset successful. Check your email for your new password to login',
                btnIcon: 'alert-info',
                timer: 5000,
            });
            this.route.navigate(['login']);
        });
    }
}
