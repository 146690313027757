<!-- app-breadcrumb start -->
<div
    class="breadcrumb-wrapper d-flex"
    [ngClass]="{
        'justify-content-center': breadcrumb.alignment == 'center',
        'justify-content-end': breadcrumb.alignment == 'right',
    }"
>
    <ol
        class="breadcrumb"
        [ngClass]="{
            'mr-1': breadcrumb.alignment == 'right',
        }"
    >
        <li class="breadcrumb-item" *ngFor="let link of breadcrumb.links">
            <a *ngIf="link.isLink" routerLink="{{ link.link }}" [queryParams]="link.query">{{
                link.name | titlecase
            }}</a>
            <span *ngIf="!link.isLink">{{ link.name | titlecase }}</span>
        </li>
    </ol>
</div>
<!-- app-breadcrumb end -->
