import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { userModel } from '../../models/interfaces';

@Component({
    selector: 'app-avatars',
    templateUrl: './avatars.component.html',
    styleUrls: ['./avatars.component.scss'],
})
export class AvatarsComponent implements OnInit {
    @Input()
    data: userModel[] | any[] = [];

    @Output()
    onClick = new EventEmitter();

    @Input()
    max: number = 2;

    @Input()
    nstyle: any = {};

    @Input()
    avgClass: any = '';

    @Input()
    key: any = '';

    @Input()
    imgKey: any = 'profileImgUrl';

    @Input()
    avgExtraClass: any = 'app-count-avatar';
    constructor() {}

    ngOnInit(): void {}

    getImage(item: userModel) {
        const url = item[this.imgKey as keyof userModel];
        if (!url)
            return `https://ui-avatars.com/api/?background=F7D3EA&color=D62196&name=${item.name || item.fullName || item.firstName + ' ' + item.lastName}}`;
        return url;
    }

    returnData(invitee: any) {
        this.onClick.emit(invitee);
    }
}
