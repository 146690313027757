export function getThumbnailForVideo(videoUrl: string) {
    const video = document.createElement('video');
    const canvas = <HTMLCanvasElement>document.createElement('canvas');
    video.style.display = 'none';
    canvas.style.display = 'none';

    // Trigger video load
    // new Promise((resolve, reject): void => {
    video.addEventListener('loadedmetadata', () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        // Seek the video to 25%
        video.currentTime = video.duration * 0.25;
    });
    video.addEventListener('seeked', () => {
        video.src = videoUrl;
    });

    //  });

    // Draw the thumbnailz
    canvas?.getContext('2d')?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const imageUrl = canvas.toDataURL('image/png');
    return imageUrl;
}

export function getVideoCover(url: string): Promise<any> {
    //   console.log("getting video cover for file: ", url);
    return new Promise((resolve, reject) => {
        // load the file to a video player
        const videoPlayer = document.createElement('video');
        videoPlayer.setAttribute('src', url);
        videoPlayer.load();
        videoPlayer.crossOrigin = '*';
        videoPlayer.addEventListener('error', (ex: any) => {
            reject(ex);
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener('loadedmetadata', () => {
            // seek to user defined timestamp (in seconds) if possible
            if (videoPlayer.duration < 0.5) {
                reject('video is too short.');
                return;
            }
            // delay seeking or else 'seeked' event won't fire on Safari
            setTimeout(() => {
                videoPlayer.currentTime = 0.5;
            }, 200);
            // extract video thumbnail once seeking is complete
            videoPlayer.addEventListener('seeked', () => {
                console.log('video is now paused at %ss.', 0.5);
                // define a canvas to have the same dimension as the video
                const canvas = document.createElement('canvas');
                canvas.width = videoPlayer.videoWidth;
                canvas.height = videoPlayer.videoHeight;
                // draw the video frame to canvas
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                // return the canvas image as a blob
                resolve(canvas.toDataURL('image/png'));
                // canvas.toBlob(
                //     blob => {
                //         resolve(blob);
                //     },
                //     "image/jpeg",
                //     0.75 /* quality */
                // );
            });
        });
    });
}
