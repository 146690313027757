import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appTrimInput]',
})
export class TrimInputDirective {
    constructor(
        private el: ElementRef,
        private control: NgControl
    ) {}

    @HostListener('blur') onBlur() {
        const value = this.el.nativeElement.value.trim();
        this.control.control?.setValue(value, { emitEvent: false });
    }
}
