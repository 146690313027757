import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { GeneralService } from './general.service';
import { AppStore } from 'src/app/app-data';
import { AppDispatcher } from '../../app-data/dispatcher/index';
import { Notify } from '../models/interfaces';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppSocketService {
    url: string = environment.notificationEndPoint;
    appSocket!: any;
    newNotify = new Subject<Notify>();
    bulkProgress = new Subject<Notify>();
    constructor(private genServ: GeneralService) {}
    listeners() {
        this.appSocket = io(`${this.url}admin`, {
            path: '/notifications',
            extraHeaders: {
                authorization: `Bearer ${this.genServ.getAppToken()}`,
            },
        });
        this.appSocket.on('new_notification', (data: any) => {
            if (!data) return;
            this.newNotify.next(data);
        });
        this.appSocket.on('bulk_update', (data: any) => {
            if (!data) return;
            this.genServ.alertInfo.next({
                text: data.title,
                sn: data.taskID,
                progress: true,
                btnClass: 'alert-info',
                btnIcon: 'sync',
                data: {
                    ...data,
                },
            });
            this.bulkProgress.next({ ...data });
        });
    }
}
